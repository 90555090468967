import { Box } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Resource } from "../../common/Resource";
import { RowClickEvent } from "../../common/Table";
import { ColumnModel } from "../../model/ColumnModel";
import { MicrobiltTableDrawer, MicrobiltTablePayload } from "./widget/MicrobiltTableDrawer";

export const resource = "admin/generic-views/collection_user_info";

const tableColumns = [
  { field: "id", width: "120px", title: "id", show: true },
  { field: "user_id", width: "120px", title: "user id", show: false },
  { field: "item_type", width: "200px", title: "item type", show: true },
  { field: "item_value", width: "200px", title: "item value", show: true },
]

export const UserInfoCollection: React.FC<Props> = ({ initialFilters }) => {
  const [microbiltTablePayload, setMicrobiltTablePayload] = useState<MicrobiltTablePayload | undefined>(undefined);

  const handleHistoricalTxnClose = () => {
    setMicrobiltTablePayload(undefined);
  }

  const handleRowClick = ({ dataItem }: RowClickEvent) => {
    const payload = { userId: dataItem.user_id };
    console.log(">>> ", payload);
    setMicrobiltTablePayload(payload);
  }

  const columnModel = useMemo(() => {
    console.log(resource, 'call just once');
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Box sx={{ position: "relative" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`User info: ${initialFilters.user_id.eq}`}
        </h4>
      </Box>

      <MicrobiltTableDrawer
        payload={microbiltTablePayload}
        onClose={handleHistoricalTxnClose}
      />

      <Resource
        resource={resource}
        initialFilters={initialFilters}
        onRowClick={handleRowClick}
        columnModel={columnModel}
      />

    </Box>
  );
}

type Props = {
  initialFilters: { "user_id": { "eq": string } }
}