import { Box } from "@mui/material";
import React, { useMemo } from "react";
import {
  DateInput,
  FilterButton,
  NumberInput,
  TextInput,
} from "react-admin"
import { Button } from 'react-admin';
import { BottomDrawer } from '../../../common/BottomDrawer';
import { DollarInput } from "../../../common/DollarInput";
import { Resource } from "../../../common/Resource";
import { ColumnModel } from "../../../model/ColumnModel";

export const resource = "admin/generic-views/collection_historical_plaid_transactions";

export type HistoricalTablePayload = {
  plaidAccountId: string
}

const tableColumns = [
  { field: "created_at", width: "180px", title: "created at", show: true, format: "yyyy-MM-dd" },
  { field: "plaid_transaction_id", width: "180px", title: "plaid transaction id", show: true },
  { field: "plaid_amount", width: "120px", title: "plaid amount", show: true, isCents: true },
  { field: "plaid_category_id", width: "120px", title: "plaid category id", show: true },
  { field: "plaid_authorized_date", width: "200px", title: "plaid authorized date", show: true, format: "yyyy-MM-dd" },
  { field: "plaid_name", width: "120px", title: "plaid name", show: true },
  { field: "plaid_merchant_name", width: "120px", title: "plaid merchant name", show: true },
  { field: "plaid_payment_channel", width: "120px", title: "plaid payment channel", show: true },
  { field: "plaid_transaction_code", width: "120px", title: "plaid transaction code", show: true },
  // { field: "plaid_account_id", width: "120px", title: "plaid account id", show: true },
  { field: "plaid_date", width: "120px", title: "plaid date", show: true, format: "yyyy-MM-dd" },
  { field: "related_statement_id", width: "120px", title: "related statement id", show: true },
  { field: "plaid_datetime", width: "120px", title: "plaid datetime", show: true, format: "yyyy-MM-dd" },
  { field: "plaid_authorized_datetime", width: "120px", title: "plaid authorized datetime", show: true },
  { field: "plaid_personal_finance_category_primary", width: "300px", title: "finance category primary", show: true },
  { field: "plaid_personal_finance_category_detailed", width: "300px", title: "finance category detailed", show: true },
  { field: "geo_location", width: "300px", title: "geo_location (no sort)", show: true },
]

const filters = [
  <TextInput label="plaid_name" source="plaid_name.eq" alwaysOn />,
  <TextInput label="plaid_transaction_id" source="plaid_transaction_id.eq" />,
  <TextInput label="plaid_category_id" source="plaid_category_id.eq" />,
  <TextInput label="plaid_name" source="plaid_name.eq" />,
  <TextInput label="plaid_transaction_code" source="plaid_transaction_code.eq" />,

  <DateInput label="created_at gt" source="created_at.gt" />,
  <DateInput label="created_at lt" source="created_at.lt" />,

  <DollarInput label="plaid_amount gt (Dollar)" source="plaid_amount.gt" />,
  <DollarInput label="plaid_amount lt (Dollar)" source="plaid_amount.lt" />,

  <DateInput label="plaid_authorized_date gt" source="plaid_authorized_date.gt" />,
  <DateInput label="plaid_authorized_date lt" source="plaid_authorized_date.lt" />,

  <TextInput label="plaid_merchant_name" source="plaid_merchant_name.eq" />,

  <DateInput label="plaid_date gt" source="plaid_date.gt" />,
  <DateInput label="plaid_date lt" source="plaid_date.lt" />,

  <DateInput label="plaid_datetime gt" source="plaid_datetime.gt" />,
  <DateInput label="plaid_datetime lt" source="plaid_datetime.lt" />,

  <DateInput label="plaid_authorized_datetime gt" source="plaid_authorized_datetime.gt" />,
  <DateInput label="plaid_authorized_datetime lt" source="plaid_authorized_datetime.lt" />,

  <TextInput label="plaid_personal_finance_category_primary" source="plaid_personal_finance_category_primary.eq" />,
  <TextInput label="plaid_personal_finance_category_detailed" source="plaid_personal_finance_category_detailed.eq" />,
]

export const HistoricalPlaidTransactionTableDrawer: React.FC<Props> = (props) => {
  const {
    payload,
    onClose,
    disableUrlFilerListener = true,
  } = props;

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, []);

  if (payload !== undefined && payload?.plaidAccountId === undefined) {
    return (
      <Box sx={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        no plaid account id
      </Box>
    )
  }

  return (
    <BottomDrawer
      isShow={payload !== undefined}
      onClose={onClose}
      title={`Historical plaid transactions: ${payload?.plaidAccountId}`}>

      {
        payload !== undefined && (
          <Box>
            <Resource
              resource={resource}
              initialFilters={{ plaid_account_id: { eq: payload.plaidAccountId } }}
              filters={filters}
              columnModel={columnModel}
              disableUrlFilterListener={disableUrlFilerListener}
              tableActions={
                <>
                  <Button label="close" onClick={onClose} />
                  <FilterButton {...props} />
                </>
              }
            />
          </Box>
        )
      }

    </BottomDrawer>
  );
}

type Props = {
  payload: HistoricalTablePayload | undefined
  onClose: () => void
  disableUrlFilerListener?: boolean
}