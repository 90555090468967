import { ProductOverview } from "../types/productOverview";
import { AdminRequest, PlacidResponse, PostError } from "./AdminRequest";

export type UpdateCreditCardDataPayload = {
  userId: number
  cardId: number
  apr: number
  limit: number
}

export type UpdateStatementDDPayload = {
  userId: number
  billingCycleId: number
  dueDate: string
}

export type MoveDirection = 'Back' | 'Forward'

export type UpdateStatementCyclePayload = {
  userId: number
  lastCycleDay: number
  newAutopayDay?: number
  moveDirection: MoveDirection
}
export class ClientActions {
  constructor(private adminRequest: AdminRequest) { };

  async updatePersonalInfo({ userId, payload }: {
    userId: number,
    payload: { [index: string]: any },
  }): Promise<PostError> {
    try {
      return await this.adminRequest.postAsUser({
        userId: `${userId}`,
        path: 'users/save-personal-info',
        payload,
      });
    } catch (e) {
      return (e as Error).message;
    }
  }

  async closeCreditCard(userId: number, accountId: number): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/credit-cards/close',
          JSON.stringify({ userId, accountId, comment: "Closed by Admin" })
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async openCreditCard(userId: number, accountId: number): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/credit-cards/open',
          JSON.stringify({ userId, accountId, })
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async closeDepositoryAccount(userId: number, accountId: number): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/depository-accounts/close',
          JSON.stringify({ userId, accountId, comment: "Closed by Admin" })
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async openDepositoryAccount(userId: number, accountId: number): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/depository-accounts/open',
          JSON.stringify({ userId, accountId, })
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async dropCardNumber(userId: number, accountId: number): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/credit-cards/drop-number',
          JSON.stringify({ userId, accountId, })
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async updateCreditCardData(payload: UpdateCreditCardDataPayload): Promise<PostError> {
    const { userId } = payload;
    return this.adminRequest.postAsUser({
      userId,
      path: "credit-card-accounts/v2/edit",
      payload,
    });
  }

  async saveChosenPrimaryAccount(
    userId: number,
    itemId: string,
    accountId: string,
  ): Promise<PostError> {
    try {
      return this.adminRequest.postAsUser({
        userId,
        path: "depository-accounts/save-chosen-primary-accounts",
        payload: { itemId, chosenAccounts: [accountId], },
      });
    } catch (e) {
      console.log(e);
      return "Request error";
    }
  }

  async fetchProductsOverview(
    userId: number,
  ): Promise<ProductOverview | string> {
    const response = await this.adminRequest.post(
      "/admin/manage-user/get-products-overview",
      JSON.stringify({ userId }),
    );

    if (response.success) {
      return response.result;
    } else {
      return response.error;
    }
  }

  async updateStatementDD(
    payload: UpdateStatementDDPayload
  ): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          "/admin/billing-cycles/update-due-date",
          JSON.stringify(payload),
        )
      )
    } catch (e) {
      console.log(e);
      if (e instanceof Error) {
        return e.message;
      }

      return "Update statement dd request failed";
    }
  }

  async updateStatementCycle(
    payload: UpdateStatementCyclePayload
  ): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          "/admin/billing-cycles/move-bounds",
          JSON.stringify(payload),
        )
      )
    } catch (e) {
      console.log(e);
      if (e instanceof Error) {
        return e.message;
      }

      return "Update statement dd request failed";
    }
  }

  _checkPlacidError(placidResponse: PlacidResponse): PostError {
    if (placidResponse.success) {
      return null
    } else {
      return placidResponse.error.message ?? "Something went wrong";
    }
  }
}
