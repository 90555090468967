import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DateInput,
  FilterButton,
  NumberInput,
  SelectInput,
  TextInput,
} from "react-admin"
import {
  RowActions,
} from "./widget/RowActions";
import { RowClickEvent } from "../../common/Table";
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { DollarInput } from '../../common/DollarInput';
import { PlaidRawAccountsDrawer, PlaidRawAccountsDrawerPayload } from './widget/PlaidRawAccountsDrawer';
import { Box } from '@mui/material';
import { BankAccountsDrawer, BankAccountsDrawerPayload } from './widget/BankAccountsDrawer';
import { CreditCardsDrawer, CreditCardsDrawerPayload } from './widget/CreditCardsDrawer';
import { PlaidTokensDrawer, PlaidTokensPayload } from './widget/PlaidTokensDrawer';
import { PlaidLinkEventsDrawer, PlaidLinkEventsPayload } from './widget/PlaidLinkEventsDrawer';

export const resource = "admin/generic-views/clients";

export enum drawers {
  plaidRawAccounts,
  bankAccounts,
  creditCards,
  plaidTokens,
  plaidLinkEvents,
}

export const tableColumns = [
  { field: "id", width: "80px", title: "id", show: true },
  { field: "created_at", width: "180px", title: "created_at", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "status", width: "150px", title: "status", show: true },
  { field: "first_name", width: "200px", title: "first name", show: true },
  { field: "last_name", width: "200px", title: "last name", show: true },
  { field: "email", width: "150px", title: "email", show: true },
  { field: "last_seen", width: "100px", title: "last_seen", show: true, format: "yyyy-MM-dd" },
  { field: "date_of_birth", width: "100px", title: "date_of_birth", show: true, format: "yyyy-MM-dd" },
  { field: "line_1", width: "150px", title: "line_1", show: true },
  { field: "zip", width: "150px", title: "zip", show: true },
  { field: "state", width: "150px", title: "state", show: true },
  { field: "city", width: "150px", title: "city", show: true },
  { field: "ip", width: "150px", title: "ip", show: true },
  { field: "bill_pay_limit", width: "150px", title: "bill_pay_limit", show: true, isCents: true },
  { field: "balance_transfer_limit", width: "100px", title: "balance_transfer_limit", show: true, isCents: true },
  { field: "bill_pay_balance", width: "150px", title: "bill_pay_balance", show: true, isCents: true },
  { field: "balance_transfer_balance", width: "100px", title: "balance_transfer_balance", show: true, isCents: true },
  { field: "phone", width: "100px", title: "phone", show: true },
  { field: "due_date", width: "100px", title: "due_date", show: true },
  { field: "debt_collection", width: "100px", title: "debt collection", show: true },
  { field: "statement_date", width: "120px", title: "statement date", show: true },
  { field: "products", width: "120px", title: "products", show: true },

  { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="id" source="id.eq" alwaysOn />,
  <TextInput label="first name" source="first_name.eq" />,
  <TextInput label="last name" source="last_name.eq" />,
  <TextInput label="email" source="email.eq" />,
  <TextInput label="phone" source="phone.eq" />,

  <DateInput label="created at gt" source="created_at.gt" />,
  <DateInput label="created at lt" source="created_at.lt" />,

  <SelectInput source="status" choices={[
    { id: 'Active', name: 'Active' },
    { id: 'Deactivated', name: 'Deactivated' },
    { id: 'Pending', name: 'Pending' },
    { id: 'Paused ResigningAgreement', name: 'Paused ResigningAgreement' },
  ]} />,

  <DateInput label="last seen gt" source="last_seen.gt" />,
  <DateInput label="last seen lt" source="last_seen.lt" />,

  <DateInput label="due date gt" source="due_date.gt" />,
  <DateInput label="due date lt" source="due_date.lt" />,

  <DateInput label="date of birth gt" source="date_of_birth.gt" />,
  <DateInput label="date of birth lt" source="date_of_birth.lt" />,

  <DateInput label="statement date gt" source="statement_date.gt" />,
  <DateInput label="statement date lt" source="statement_date.lt" />,

  <TextInput label="city" source="city.eq" />,
  <TextInput label="line1" source="line1.eq" />,
  <TextInput label="zip" source="zip.eq" />,
  <TextInput label="state" source="state.eq" />,

  <TextInput label="ip" source="ip.eq" />,

  <DollarInput label="bill_pay_limit gt" source="bill_pay_limit.gt" />,
  <DollarInput label="bill_pay_limit lt" source="bill_pay_limit.lt" />,

  <DollarInput label="balance_transfer_limit gt" source="balance_transfer_limit.gt" />,
  <DollarInput label="balance_transfer_limit lt" source="balance_transfer_limit.lt" />,

  <DollarInput label="bill_pay_balance gt" source="bill_pay_balance.gt" />,
  <DollarInput label="bill_pay_balance lt" source="bill_pay_balance.lt" />,

  <DollarInput label="balance_transfer_balance gt" source="balance_transfer_balance.gt" />,
  <DollarInput label="balance_transfer_balance lt" source="balance_transfer_balance.lt" />,
];

export const ClientsCollection: React.FC<Props> = (props) => {
  const {
    onSet,
    disableUrlFilterListener,
    initialFilters,
  } = props

  const [plaidRawAccountsDrawerPayload, setPlaidRawAccountsDrawerPayload] = useState<PlaidRawAccountsDrawerPayload | undefined>(undefined);
  const [bankAccountsDrawerPayload, setBankAccountsDrawerPayload] = useState<BankAccountsDrawerPayload | undefined>(undefined);
  const [creditCardsDrawerPayload, setCreditCardsDrawerPayload] = useState<CreditCardsDrawerPayload | undefined>(undefined);
  const [plaidTokensPayload, setPlaidTokensPayload] = useState<PlaidTokensPayload | undefined>(undefined);
  const [plaidLinkEventsPayload, setPlaidLinkEventsPayload] = useState<PlaidLinkEventsPayload | undefined>(undefined);

  const onOpenDrawer = ({ drawer, record }: { drawer: drawers, record: { [index: string]: any } }) => {
    switch (drawer) {
      case drawers.plaidRawAccounts:
        return setPlaidRawAccountsDrawerPayload({ userId: record.id });
      case drawers.bankAccounts:
        return setBankAccountsDrawerPayload({ userId: record.id });
      case drawers.creditCards:
        return setCreditCardsDrawerPayload({ userId: record.id });
      case drawers.plaidTokens:
        return setPlaidTokensPayload({ userId: record.id });
      case drawers.plaidLinkEvents:
        return setPlaidLinkEventsPayload({ userId: record.id });
    }
  }

  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions
    record={record}
    onOpenDrawer={onOpenDrawer} />

  const handleRowClick = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    if (onSet === null || onSet === undefined) return;

    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    onSet(dataItem);
  }

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Box>

      <PlaidRawAccountsDrawer
        payload={plaidRawAccountsDrawerPayload}
        onClose={() => setPlaidRawAccountsDrawerPayload(undefined)}
      />

      <BankAccountsDrawer
        payload={bankAccountsDrawerPayload}
        onClose={() => setBankAccountsDrawerPayload(undefined)}
      />

      <CreditCardsDrawer
        payload={creditCardsDrawerPayload}
        onClose={() => setCreditCardsDrawerPayload(undefined)}
      />

      <PlaidTokensDrawer
        payload={plaidTokensPayload}
        onClose={() => setPlaidTokensPayload(undefined)}
      />

      <PlaidLinkEventsDrawer
        payload={plaidLinkEventsPayload}
        onClose={() => setPlaidLinkEventsPayload(undefined)}
      />

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        filters={filters}
        disableUrlFilterListener={disableUrlFilterListener}
        rowActionBuilder={rowActionBuilder}
        onRowClick={handleRowClick}
        columnModel={columnModel}
        rowPerPageOptions={[25, 50, 100]}
        tableActions={
          <>
            <FilterButton {...props} />
          </>
        }
        idLink={(data: { [index: string]: any }) => {
          const id = data["id"];
          return `/dash/client/${id}/`;
        }}
      />

    </Box>
  )
}

type Props = {
  onSet?: (id: { [index: string]: any } | null | undefined) => void,
  initialFilters?: { [index: string]: any }
  disableUrlFilterListener: boolean
}