import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, Keys, getTabIndex, createPropsContext, withPropsContext, kendoThemeMaps } from '../../../../kendo-react-common';
import { FloatingLabel } from '../../../../kendo-react-labels';
import ListContainer from '../common/ListContainer';
import ListFilter from '../common/ListFilter';
import ListDefaultItem from '../common/ListDefaultItem';
import List from '../common/List';
import DropDownBase from '../common/DropDownBase';
import { isPresent, getItemValue, sameCharsOnly, shuffleData, matchText, areSame, preventDefaultNonInputs, getFocusedItem } from '../common/utils';
import { validatePackage } from '../../../../kendo-react-common';
import { packageMetadata } from '../package-metadata';
import { Button } from '../../../../kendo-react-buttons';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var VALIDATION_MESSAGE = 'Please select a value from the list!';
var sizeMap = kendoThemeMaps.sizeMap, roundedMap = kendoThemeMaps.roundedMap;
/**
 * @hidden
 */
var DropDownListWithoutContext = /** @class */ (function (_super) {
    __extends(DropDownListWithoutContext, _super);
    function DropDownListWithoutContext(props) {
        var _this = _super.call(this, props) || this;
        /**
         * @hidden
         */
        _this.state = {};
        _this._element = null;
        _this.base = new DropDownBase(_this);
        _this.searchState = { word: '', last: '' };
        _this._select = null;
        _this._skipFocusEvent = false;
        _this._filterInput = null;
        _this._navigated = false;
        /**
         * @hidden
         */
        _this.focus = function () {
            if (_this.base.wrapper) {
                _this.base.wrapper.focus();
            }
        };
        /**
         * @hidden
         */
        _this.handleItemSelect = function (index, state) {
            var _a = _this.props, _b = _a.data, data = _b === void 0 ? [] : _b, virtual = _a.virtual, dataItemKey = _a.dataItemKey, defaultItem = _a.defaultItem;
            var skip = virtual ? virtual.skip : 0;
            var item = (index === -1 && defaultItem !== undefined) ?
                defaultItem : data[index - skip];
            var newSelected = !areSame(item, _this.value, dataItemKey);
            _this.triggerOnChange(item, state);
            if (newSelected) {
                _this.base.triggerPageChangeCornerItems(item, state);
            }
        };
        _this.componentRef = function (element) {
            _this._element = element;
            _this.base.wrapper = element;
        };
        _this.dummySelect = function (value) {
            /* Dummy component to support forms */
            return (React.createElement("select", { name: _this.props.name, ref: function (el) { _this._select = el; }, tabIndex: -1, "aria-hidden": true, title: _this.props.label, style: { opacity: 0, width: 1, border: 0, zIndex: -1, position: 'absolute', left: '50%' } },
                React.createElement("option", { value: _this.props.valueMap ? _this.props.valueMap.call(undefined, value) : value })));
        };
        _this.renderListContainer = function () {
            var _a;
            var _b = _this.props, header = _b.header, footer = _b.footer, dir = _b.dir, _c = _b.data, data = _c === void 0 ? [] : _c, size = _b.size;
            var base = _this.base;
            var popupSettings = base.getPopupSettings();
            var opened = _this.props.opened !== undefined ? _this.props.opened : _this.state.opened;
            var popupWidth = popupSettings.width !== undefined ? popupSettings.width : base.popupWidth;
            var listContainerProps = {
                dir: dir !== undefined ? dir : base.dirCalculated,
                width: popupWidth,
                popupSettings: {
                    popupClass: classNames(popupSettings.popupClass, 'k-list-container'),
                    className: popupSettings.className,
                    animate: popupSettings.animate,
                    anchor: _this.element,
                    show: opened,
                    onOpen: _this.onPopupOpened,
                    onClose: _this.onPopupClosed,
                    appendTo: popupSettings.appendTo
                },
                itemsCount: [data.length]
            };
            return (React.createElement(ListContainer, __assign({}, listContainerProps),
                _this.renderListFilter(),
                header && React.createElement("div", { className: "k-list-header" }, header),
                React.createElement("div", { className: classNames('k-list', (_a = {}, _a["k-list-".concat(sizeMap[size] || size)] = size, _a['k-virtual-list'] = _this.base.vs.enabled, _a)) },
                    _this.renderDefaultItem(),
                    _this.renderList()),
                footer && React.createElement("div", { className: "k-list-footer" }, footer)));
        };
        _this.renderList = function () {
            var _a = _this.props, _b = _a.data, data = _b === void 0 ? [] : _b, textField = _a.textField, dataItemKey = _a.dataItemKey, _c = _a.virtual, virtual = _c === void 0 ? { skip: 0 } : _c, listNoDataRender = _a.listNoDataRender, itemRender = _a.itemRender;
            var vs = _this.base.vs;
            var skip = virtual.skip;
            var opened = _this.props.opened !== undefined ? _this.props.opened : _this.state.opened;
            var popupSettings = _this.base.getPopupSettings();
            var translate = "translateY(".concat(vs.translate, "px)");
            return (React.createElement(List, { id: _this.base.listBoxId, show: opened, data: data.slice(), focusedIndex: _this.focusedIndex(), value: _this.value, textField: textField, valueField: dataItemKey, optionsGuid: _this.base.guid, listRef: function (list) { return vs.list = _this.base.list = list; }, wrapperStyle: { maxHeight: popupSettings.height }, wrapperCssClass: "k-list-content", listStyle: vs.enabled ? { transform: translate } : undefined, key: "listkey", skip: skip, onClick: _this.handleItemClick, itemRender: itemRender, noDataRender: listNoDataRender, onScroll: function (e) {
                    vs.scrollHandler(e);
                }, wrapperRef: vs.scrollerRef, scroller: _this.base.renderScrollElement() }));
        };
        _this.renderListFilter = function () {
            var filterText = _this.props.filter !== undefined ? _this.props.filter : _this.state.text;
            return _this.props.filterable && (React.createElement(ListFilter, { value: filterText, ref: function (filter) { return _this._filterInput = filter && filter.input; }, onChange: _this.handleListFilterChange, onKeyDown: _this.handleKeyDown, size: _this.props.size, rounded: _this.props.rounded, fillMode: _this.props.fillMode }));
        };
        _this.renderDefaultItem = function () {
            var _a = _this.props, textField = _a.textField, defaultItem = _a.defaultItem, dataItemKey = _a.dataItemKey;
            return defaultItem !== undefined && (React.createElement(ListDefaultItem, { defaultItem: defaultItem, textField: textField, selected: areSame(_this.value, defaultItem, dataItemKey), key: "defaultitemkey", onClick: _this.handleDefaultItemClick }));
        };
        _this.search = function (event) {
            clearTimeout(_this._typingTimeout);
            if (!_this.props.filterable) {
                _this._typingTimeout = window.setTimeout(function () { return _this.searchState.word = ''; }, _this.props.delay);
                _this.selectNext(event);
            }
        };
        _this.selectNext = function (event) {
            var _a = _this.props, _b = _a.data, data = _b === void 0 ? [] : _b, dataItemKey = _a.dataItemKey;
            var mappedData = data.map(function (item, idx) {
                return { item: item, itemIndex: idx };
            });
            var word = _this.searchState.word;
            var last = _this.searchState.last;
            var isInLoop = sameCharsOnly(word, last);
            var dataLength = mappedData.length;
            var startIndex = Math.max(0, data.findIndex(function (i) { return areSame(i, _this.value, dataItemKey); }));
            var defaultItem;
            if (_this.props.defaultItem) {
                defaultItem = { item: _this.props.defaultItem, itemIndex: -1 };
                dataLength += 1;
                startIndex += 1;
            }
            startIndex += isInLoop ? 1 : 0;
            mappedData = shuffleData(mappedData, startIndex, defaultItem);
            var text, loopMatch, nextMatch, index = 0;
            var _c = _this.props, textField = _c.textField, ignoreCase = _c.ignoreCase;
            for (; index < dataLength; index++) {
                text = getItemValue(mappedData[index].item, textField);
                loopMatch = isInLoop && matchText(text, last, ignoreCase);
                nextMatch = matchText(text, word, ignoreCase);
                if (loopMatch || nextMatch) {
                    index = mappedData[index].itemIndex;
                    break;
                }
            }
            if (index !== dataLength) {
                var state = _this.base.initState();
                state.syntheticEvent = event;
                _this.handleItemSelect(index, state);
                _this.applyState(state);
                _this._valueDuringOnChange = undefined;
            }
        };
        _this.handleKeyDown = function (event) {
            var _a = _this.props, _b = _a.data, data = _b === void 0 ? [] : _b, filterable = _a.filterable, disabled = _a.disabled, _c = _a.leftRightKeysNavigation, leftRightKeysNavigation = _c === void 0 ? true : _c, _d = _a.virtual, virtual = _d === void 0 ? { skip: 0, total: 0, pageSize: 0 } : _d;
            var opened = _this.props.opened !== undefined ? _this.props.opened : _this.state.opened;
            var keyCode = event.keyCode;
            var homeOrEndKeys = keyCode === Keys.home || keyCode === Keys.end;
            var upOrDownKeys = keyCode === Keys.up || keyCode === Keys.down;
            var shouldOpen = !opened &&
                ((event.altKey && keyCode === Keys.down) || keyCode === Keys.enter || keyCode === Keys.space);
            var shouldClose = opened && ((event.altKey && keyCode === Keys.up) || keyCode === Keys.esc);
            var leftOrRightKeys = leftRightKeysNavigation && (keyCode === Keys.left || keyCode === Keys.right);
            var shouldNavigate = upOrDownKeys || (!filterable && (leftOrRightKeys || homeOrEndKeys));
            var state = _this.base.initState();
            state.syntheticEvent = event;
            if (disabled) {
                return;
            }
            else if (homeOrEndKeys && _this.base.vs.enabled) {
                if (keyCode === Keys.home) {
                    if (virtual.skip !== 0) {
                        _this.base.triggerOnPageChange(state, 0, virtual.pageSize);
                        _this._navigated = true;
                    }
                    else {
                        _this.triggerOnChange(data[0], state);
                    }
                }
                else {
                    if (virtual.skip < virtual.total - virtual.pageSize) {
                        _this.base.triggerOnPageChange(state, virtual.total - virtual.pageSize, virtual.pageSize);
                        _this._navigated = true;
                    }
                    else {
                        _this.triggerOnChange(data[data.length - 1], state);
                    }
                }
            }
            else if (opened && keyCode === Keys.enter) {
                var focusedIndex = _this.focusedIndex();
                if (focusedIndex !== undefined) {
                    _this.handleItemSelect(focusedIndex, state);
                }
                _this.base.togglePopup(state);
                event.preventDefault();
            }
            else if (shouldOpen || shouldClose) {
                _this.base.togglePopup(state);
                event.preventDefault();
            }
            else if (shouldNavigate) {
                _this.onNavigate(state, keyCode);
                event.preventDefault();
            }
            _this.applyState(state);
        };
        _this.handleItemClick = function (index, event) {
            _this.base.handleItemClick(index, event);
            _this._valueDuringOnChange = undefined;
        };
        _this.handleFocus = function (event) {
            if (_this._skipFocusEvent) {
                return;
            }
            _this.base.handleFocus(event);
        };
        _this.handleBlur = function (event) {
            if (_this._skipFocusEvent || !_this.state.focused) {
                return;
            }
            var opened = _this.props.opened !== undefined ? _this.props.opened : _this.state.opened;
            var state = _this.base.initState();
            state.syntheticEvent = event;
            state.data.focused = false;
            state.events.push({ type: 'onBlur' });
            if (opened) {
                _this.base.togglePopup(state);
            }
            _this.applyState(state);
        };
        _this.handleDefaultItemClick = function (event) {
            var state = _this.base.initState();
            state.syntheticEvent = event;
            _this.base.togglePopup(state);
            _this.triggerOnChange(_this.props.defaultItem, state);
            _this.applyState(state);
        };
        _this.handleWrapperClick = function (event) {
            if (event.isPropagationStopped()) {
                return;
            }
            var state = _this.base.initState();
            state.syntheticEvent = event;
            if (!_this.state.focused) {
                state.data.focused = true;
            }
            _this.base.togglePopup(state);
            _this.applyState(state);
        };
        _this.handleKeyPress = function (event) {
            if (_this.props.filterable || event.which === 0 || event.keyCode === Keys.enter) {
                return;
            }
            var character = String.fromCharCode(event.charCode || event.keyCode);
            if (_this.props.ignoreCase) {
                character = character.toLowerCase();
            }
            if (character === ' ') {
                event.preventDefault();
            }
            _this.searchState = {
                word: _this.searchState.word + character,
                last: _this.searchState.last + character
            };
            _this.search(event);
        };
        _this.handleListFilterChange = function (event) {
            var state = _this.base.initState();
            state.syntheticEvent = event;
            if (_this.props.filter === undefined) {
                state.data.text = event.target.value;
            }
            _this.base.filterChanged(event.target.value, state);
            _this.applyState(state);
        };
        _this.onPopupOpened = function () {
            if (_this._filterInput) {
                _this.focusElement(_this._filterInput);
            }
        };
        _this.onPopupClosed = function () {
            if (_this.state.focused) {
                window.setTimeout(function () {
                    if (_this.state.focused && _this.base.wrapper) {
                        _this.focusElement(_this.base.wrapper);
                    }
                });
            }
        };
        _this.setValidity = function () {
            if (_this._select && _this._select.setCustomValidity) {
                _this._select.setCustomValidity(_this.validity.valid
                    ? ''
                    : _this.props.validationMessage || VALIDATION_MESSAGE);
            }
        };
        validatePackage(packageMetadata);
        return _this;
    }
    Object.defineProperty(DropDownListWithoutContext.prototype, "element", {
        /**
         * @hidden
         */
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DropDownListWithoutContext.prototype, "value", {
        /**
         * The value of the DropDownList.
         */
        get: function () {
            var value;
            if (this._valueDuringOnChange !== undefined) {
                value = this._valueDuringOnChange;
            }
            else if (this.props.value !== undefined) {
                value = this.props.value;
            }
            else if (this.state.value !== undefined) {
                value = this.state.value;
            }
            else if (this.props.defaultValue !== undefined) {
                value = this.props.defaultValue;
            }
            if (!isPresent(value) && this.props.defaultItem !== undefined) {
                value = this.props.defaultItem;
            }
            return value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DropDownListWithoutContext.prototype, "index", {
        /**
         * The index of the selected item.
         */
        get: function () {
            var _a = this.props, _b = _a.data, data = _b === void 0 ? [] : _b, dataItemKey = _a.dataItemKey;
            var value = this.value;
            // TO DO: deprecate it!
            return data.findIndex(function (i) { return areSame(i, value, dataItemKey); });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DropDownListWithoutContext.prototype, "name", {
        /**
         * Gets the `name` property of the DropDownList.
         */
        get: function () {
            return this.props.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DropDownListWithoutContext.prototype, "validity", {
        /**
         * Represents the validity state into which the DropDownList is set.
         */
        get: function () {
            var customError = this.props.validationMessage !== undefined;
            var isValid = (!this.required
                || (this.value !== null && this.value !== '' && this.value !== undefined));
            var valid = this.props.valid !== undefined ? this.props.valid : isValid;
            return {
                customError: customError,
                valid: valid,
                valueMissing: this.value === null
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DropDownListWithoutContext.prototype, "validityStyles", {
        get: function () {
            return this.props.validityStyles !== undefined
                ? this.props.validityStyles
                : DropDownListWithoutContext.defaultProps.validityStyles;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DropDownListWithoutContext.prototype, "required", {
        /**
         * @hidden
         */
        get: function () {
            return this.props.required !== undefined
                ? this.props.required
                : DropDownListWithoutContext.defaultProps.required;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    DropDownListWithoutContext.prototype.componentDidUpdate = function (prevProps, prevState) {
        var _a = this.props, _b = _a.data, data = _b === void 0 ? [] : _b, dataItemKey = _a.dataItemKey, virtual = _a.virtual;
        var prevTotal = prevProps.virtual ? prevProps.virtual.total : 0;
        var opened = this.props.opened !== undefined ? this.props.opened : this.state.opened;
        var prevOpened = prevProps.opened !== undefined ? prevProps.opened : prevState.opened;
        var opening = !prevOpened && opened;
        var closing = prevOpened && !opened;
        if (!this.base.getPopupSettings().animate) {
            if (opening) {
                this.onPopupOpened();
            }
            else if (closing) {
                // this.onPopupClosed();
            }
        }
        if (virtual && virtual.total !== prevTotal) {
            this.base.vs.calcScrollElementHeight();
            this.base.vs.reset();
        }
        else {
            var selectedItem_1 = this.value;
            var prevSelectedItem = prevProps.value !== undefined ? prevProps.value : prevState.value;
            var selectedItemIndex = data.findIndex(function (i) { return areSame(i, selectedItem_1, dataItemKey); });
            var selectedItemChanged = !areSame(prevSelectedItem, selectedItem_1, dataItemKey);
            if (opening && virtual) {
                this.base.scrollToVirtualItem(virtual, selectedItemIndex);
            }
            else if (opening && !virtual) {
                this.base.scrollToItem(selectedItemIndex);
            }
            else if (opened && prevOpened && selectedItem_1 && selectedItemChanged && !this._navigated) {
                this.base.scrollToItem(selectedItemIndex);
            }
            else if (opened && prevOpened && this._navigated) {
                if (this._navigated && virtual && virtual.skip === 0) {
                    this.base.vs.reset();
                }
                else if (this._navigated && virtual && virtual.skip === virtual.total - virtual.pageSize) {
                    this.base.vs.scrollToEnd();
                }
            }
        }
        this._navigated = false;
        this.setValidity();
    };
    /**
     * @hidden
     */
    DropDownListWithoutContext.prototype.componentDidMount = function () {
        this.base.didMount();
        this.setValidity();
    };
    /**
     * @hidden
     */
    DropDownListWithoutContext.prototype.render = function () {
        var _a;
        var _this = this;
        var _b = this.props, style = _b.style, className = _b.className, label = _b.label, dir = _b.dir, virtual = _b.virtual, size = _b.size, rounded = _b.rounded, fillMode = _b.fillMode;
        var opened = this.props.opened !== undefined ? this.props.opened : this.state.opened;
        var value = this.value;
        var text = getItemValue(value, this.props.textField);
        var isValid = !this.validityStyles || this.validity.valid;
        var base = this.base;
        var vs = base.vs;
        vs.enabled = virtual !== undefined;
        if (virtual !== undefined) {
            base.vs.skip = virtual.skip;
            base.vs.total = virtual.total;
            base.vs.pageSize = virtual.pageSize;
        }
        var _c = this.props, dataItemKey = _c.dataItemKey, _d = _c.data, data = _d === void 0 ? [] : _d, disabled = _c.disabled, tabIndex = _c.tabIndex, loading = _c.loading, iconClassName = _c.iconClassName, valueRender = _c.valueRender;
        var focused = this.state.focused;
        var selectedIndex = data.findIndex(function (i) { return areSame(i, value, dataItemKey); });
        var valueDefaultRendering = (React.createElement("span", { className: "k-input-inner" },
            React.createElement("span", { className: "k-input-value-text" }, text)));
        var valueElement = valueRender !== undefined ?
            valueRender.call(undefined, valueDefaultRendering, value) : valueDefaultRendering;
        var dropdownlist = (React.createElement("span", { ref: this.componentRef, className: classNames('k-dropdownlist k-picker', className, (_a = {},
                _a["k-picker-".concat(sizeMap[size] || size)] = size,
                _a["k-rounded-".concat(roundedMap[rounded] || rounded)] = rounded,
                _a["k-picker-".concat(fillMode)] = fillMode,
                _a['k-focus'] = focused,
                _a['k-disabled'] = disabled,
                _a['k-invalid'] = !isValid,
                _a['k-loading'] = loading,
                _a['k-required'] = this.required,
                _a)), style: !label
                ? style
                : __assign(__assign({}, style), { width: undefined }), dir: dir, onMouseDown: opened ? preventDefaultNonInputs : undefined, onFocus: this.handleFocus, onBlur: this.handleBlur, tabIndex: getTabIndex(tabIndex, disabled), accessKey: this.props.accessKey, onKeyDown: this.handleKeyDown, onKeyPress: this.handleKeyPress, onClick: disabled ? undefined : this.handleWrapperClick, role: 'combobox', "aria-required": this.required, "aria-disabled": disabled || undefined, "aria-haspopup": 'listbox', "aria-expanded": opened || false, "aria-owns": this.base.listBoxId, "aria-activedescendant": opened ? ('option-' + this.base.guid + '-' + (selectedIndex + (virtual ? virtual.skip : 0))) : undefined, "aria-label": this.props.ariaLabel || this.props.label, "aria-labelledby": this.props.ariaLabelledBy, "aria-describedby": this.props.ariaDescribedBy, id: this.props.id, title: this.props.title },
            valueElement,
            loading && React.createElement("span", { className: "k-icon k-i-loading k-input-loading-icon", key: "loading" }),
            React.createElement(Button, { tabIndex: -1, type: "button", "aria-hidden": true, size: size, fillMode: fillMode, iconClass: iconClassName, className: "k-input-button", themeColor: 'base', icon: !iconClassName ? 'caret-alt-down' : undefined, onMouseDown: function (e) { return _this.state.focused && e.preventDefault(); } }),
            this.dummySelect(value),
            this.renderListContainer()));
        return label ? (React.createElement(FloatingLabel, { label: label, editorValue: text, editorValid: isValid, editorDisabled: this.props.disabled, style: { width: style ? style.width : undefined }, children: dropdownlist })) : dropdownlist;
    };
    /**
     * @hidden
     */
    DropDownListWithoutContext.prototype.onNavigate = function (state, keyCode) {
        var _a = this.props, _b = _a.data, data = _b === void 0 ? [] : _b, defaultItem = _a.defaultItem, dataItemKey = _a.dataItemKey, _c = _a.virtual, virtual = _c === void 0 ? { skip: 0, total: 0, pageSize: 0 } : _c;
        var vs = this.base.vs;
        var value = this.value;
        var index = data.findIndex(function (i) { return areSame(i, value, dataItemKey); });
        var newIndex = this.base.navigation.navigate({
            current: virtual.skip + index,
            max: (vs.enabled ? virtual.total : data.length) - 1,
            min: defaultItem !== undefined ? -1 : 0,
            keyCode: keyCode
        });
        if (newIndex !== undefined) {
            this.handleItemSelect(newIndex, state);
        }
        this.applyState(state);
    };
    DropDownListWithoutContext.prototype.focusedIndex = function () {
        var filterText = isPresent(this.props.filter) ? this.props.filter : this.state.text;
        var _a = this.props, _b = _a.data, data = _b === void 0 ? [] : _b, _c = _a.virtual, virtual = _c === void 0 ? { skip: 0 } : _c, dataItemKey = _a.dataItemKey, textField = _a.textField, focusedItemIndex = _a.focusedItemIndex;
        var value = this.value;
        var selectedIndex = data.findIndex(function (i) { return areSame(i, value, dataItemKey); });
        var hasSelected = !(selectedIndex < 0 && !this.props.defaultItem);
        if (!hasSelected && filterText && virtual.skip === 0) {
            return focusedItemIndex ? focusedItemIndex(data, filterText, textField) :
                data.indexOf(getFocusedItem(data, filterText, textField));
        }
        else {
            return !hasSelected && virtual.skip === 0 ? 0 : undefined;
        }
    };
    DropDownListWithoutContext.prototype.focusElement = function (element) {
        var _this = this;
        this._skipFocusEvent = true;
        element.focus();
        window.setTimeout(function () { return _this._skipFocusEvent = false; }, 30);
    };
    DropDownListWithoutContext.prototype.triggerOnChange = function (item, state) {
        if (areSame(this.value, item, this.props.dataItemKey)) {
            return;
        }
        if (this.props.value === undefined) {
            state.data.value = item;
        }
        this._valueDuringOnChange = item;
        state.events.push({ type: 'onChange' });
    };
    DropDownListWithoutContext.prototype.applyState = function (state) {
        this.base.applyState(state);
        this._valueDuringOnChange = undefined;
    };
    DropDownListWithoutContext.displayName = 'DropDownList';
    /**
     * @hidden
     */
    DropDownListWithoutContext.propTypes = __assign({ delay: PropTypes.number, ignoreCase: PropTypes.bool, iconClassName: PropTypes.string, defaultItem: PropTypes.any, valueRender: PropTypes.func, valueMap: PropTypes.func, validationMessage: PropTypes.string, required: PropTypes.bool, id: PropTypes.string, ariaLabelledBy: PropTypes.string, ariaDescribedBy: PropTypes.string, ariaLabel: PropTypes.string, leftRightKeysNavigation: PropTypes.bool, title: PropTypes.string }, DropDownBase.propTypes);
    /**
     * @hidden
     */
    DropDownListWithoutContext.defaultProps = __assign(__assign({ delay: 500, tabIndex: 0, ignoreCase: true }, DropDownBase.defaultProps), { required: false, size: 'medium', rounded: 'medium', fillMode: 'solid' });
    return DropDownListWithoutContext;
}(React.Component));
export { DropDownListWithoutContext };
/**
 * Represents the PropsContext of the `DropDownList` component.
 * Used for global configuration of all `DropDownList` instances.
 *
 * For more information, refer to the [Dropdowns Props Context]({% slug props-context_dropdowns %}) article.
 */
export var DropDownListPropsContext = createPropsContext();
/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the component the same as the type */
/**
 * Represents the [KendoReact DropDownList component]({% slug overview_dropdownlist %}).
 *
 * Accepts properties of type [DropDownListProps]({% slug api_dropdowns_dropdownlistprops %}).
 * Obtaining the `ref` returns an object of type [DropDownListHandle]({% slug api_dropdowns_dropdownlisthandle %}).
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *    dropdownlist = null;
 *    render() {
 *       return (
 *          <div>
 *              <DropDownList
 *                  data={[ "Albania", "Andorra", "Austria", "Belarus" ]}
 *                  ref={component => this.dropdownlist = component}
 *              />
 *              <button onClick={() => alert(this.dropdownlist.value)}>alert value</button>
 *          </div>
 *       );
 *    }
 * }
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var DropDownList = withPropsContext(DropDownListPropsContext, DropDownListWithoutContext);
DropDownList.displayName = 'KendoReactDropDownList';
