import React, { useMemo } from 'react';
import {
  DateInput,
  FilterButton,
  NumberInput,
  TextInput,
} from "react-admin"
import {
  RowActions,
} from "./widget/RowActions";
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { DollarInput } from '../../common/DollarInput';
import { Box } from '@mui/material';
import { PaymentPromiseDialogButton } from './widget/PaymentPromiseDialogButton';

export const resource = "admin/generic-views/collection_payment_promise";

const tableColumns = [
  { field: "id", width: "120px", title: "id", show: true },
  { field: "created_at", width: "120px", title: "created at", show: true, format: "yyyy-MM-dd" },
  { field: "user_id", width: "120px", title: "user id", show: false },
  { field: "promised_to_pay_at", width: "120px", title: "promised_to_pay_at", show: true },
  { field: "fulfilled_at", width: "120px", title: "fulfilled at", show: true, format: "yyyy-MM-dd" },
  { field: "comment", width: "400px", title: "comment", show: true },

  { field: "actions", width: "140px", title: "actions", show: true },
]

export const PaymentPromiseCollection: React.FC<Props> = (props) => {
  const {
    initialFilters,
  } = props;

  const userId = initialFilters?.user_id.eq;

  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions
    record={record}
  />

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Box sx={{ position: "relative" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`Payment promise: ${userId}`}
        </h4>
      </Box>

      <Resource
        resource={resource}
        filters={[]}
        initialFilters={initialFilters}
        rowActionBuilder={rowActionBuilder}
        columnModel={columnModel}
        tableActions={
          <>
            {userId !== undefined && <PaymentPromiseDialogButton userId={userId} />}
            <FilterButton {...props} />
          </>
        }
      />
    </Box>
  );

}

type Props = {
  initialFilters?: { [index: string]: any }
}