import React, { useMemo, useState } from 'react';
import {
  Button,
  DateInput,
  FilterButton,
  SelectInput,
  TextInput,
} from "react-admin"
import {
  RowActions,
} from "./widget/RowActions";
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { Box } from '@mui/material';
import { AddACHAccountDialog } from './widget/AddACHAccountDialog';

export const resource = "admin/generic-views/number_verification";

const tableColumns = [
  { field: "id", width: "80px", title: "id", show: true },

  { field: "user_id", width: "100px", title: "user id", show: true },
  { field: "number_verification_created_at", width: "120px", title: "created at", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "number_verification_status", width: "120px", title: "verif status", show: true },
  { field: "plaid_account_id", width: "300px", title: "plaid account id", show: true },
  { field: "candidate_mfi_account_id", width: "250px", title: "candidate mfi id", show: true },
  { field: "verified_mfi_account_id", width: "250px", title: "verified mfi id", show: true },
  { field: "verified_mfi_account_status", width: "120px", title: "mfi acc status", show: true },

  { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="number verification id" source="number_verification_id.eq" alwaysOn />,

  <DateInput label="number_verification_created_at gt" source="number_verification_created_at.gt" />,
  <DateInput label="number_verification_created_at lt" source="number_verification_created_at.lt" />,

  <SelectInput source="number_verification_status" choices={[
    { id: 'PendingVerification', name: 'PendingVerification' },
    { id: 'Verified', name: 'Verified' },
    { id: 'Invalid', name: 'Invalid' },
  ]} alwaysOn />,

  <TextInput label="candidate_mfi_account_id" source="candidate_mfi_account_id.eq" />,
  <TextInput label="plaid_account_id" source="plaid_account_id.eq" />,
  <TextInput label="verified_mfi_account_id" source="verified_mfi_account_id.eq" />,

  <SelectInput source="verified_mfi_account_status" choices={[
    { id: "null", name: "null" },
    { id: "invalidated", name: "invalidated" },
    { id: "rejected", name: "rejected" },
  ]} alwaysOn />,
];

export const AchNumberVerificationCollection: React.FC<Props> = (props) => {
  const {
    initialFilters,
  } = props;

  const [
    isAchAccountDialogOpen,
    setIsAchAccountDialogOpen
  ] = useState<boolean>(false)

  const rowActionBuilder = (record: { [index: string]: any }) =>
    <RowActions record={record} />

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Box sx={{ position: "relative", paddingTop: "60px" }}>

      <AddACHAccountDialog
        hideDialog={() => setIsAchAccountDialogOpen(false)}
        isShow={isAchAccountDialogOpen}
      />

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {"Ach number verification"}
        </h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        filters={filters}
        rowActionBuilder={rowActionBuilder}
        columnModel={columnModel}
        rowPerPageOptions={[25, 50]}
        tableActions={
          <>
            <Button
              label="Add ACH account"
              onClick={() => setIsAchAccountDialogOpen(true)} />,
            <FilterButton {...props} />
          </>
        }
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
}
