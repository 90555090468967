import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { Resource } from "../../common/Resource";
import { ColumnModel } from "../../model/ColumnModel";
import { DateInput, FilterButton, NumberInput, TextInput } from "react-admin";

export const resource = "admin/generic-views/subscription_groups_overview";
const tableColumns = [
  { field: "group_id", width: "200px", title: "group_id", show: true },
  { field: "name", width: "150px", title: "name", show: true },
  { field: "percentage", width: "400px", title: "percentage", show: true },
  { field: "plans", width: "400px", title: "plans", show: true },
  { field: "add_ons", width: "400px", title: "addons", show: true },
]

const filters = [
  <NumberInput label="group_id" source="group_id.eq" alwaysOn />,

  <NumberInput label="group_id gt" source="group_id.gt" />,
  <NumberInput label="group_id lt" source="group_id.lt" />,

  <TextInput label="name" source="name.like" alwaysOn />,

  <NumberInput label="percentage" source="percentage.eq" alwaysOn />,

  <NumberInput label="percentage gt" source="percentage.gt" />,
  <NumberInput label="percentage lt" source="percentage.lt" />,
];


export const SubscriptionsGroupPlansCollection: React.FC<Props> = (props) => {
  const { initialFilters } = props;

  const columnModel = useMemo(() => {
    console.log(resource, 'call just once');
    return new ColumnModel(resource, tableColumns);
  }, [])

  const customValueFormat = {
    "plans": (plans?: any) => {
      if (plans === null || plans === undefined) return "";
      let result = "";
      for (const p of plans) {
        result += `id: ${p.id}\n`;
        result += `name: ${p.name}\n`;
        result += `price: ${p.price}\n`;
        result += `type: ${p.type}\n`;
        result += `period duration: ${p.period_duration}\n`;
        result += `products: ${p.products.join(", ")}\n`;
        result += "\n";
      }
      return result;
    },
    "add_ons": (addons?: any) => {
      if (addons === null || addons === undefined) return "";

      let result = "";
      for (const a of addons) {
        result += `id: ${a.id}\n`;
        result += `name: ${a.name}\n`;
        result += `price: ${a.price}\n`;
        result += `product: ${a.product}\n`;
        result += "\n";
      }
      return result;

    }
  }

  return (
    <Box sx={{ position: "relative", paddingTop: "60px" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px"
      }}>
        <h4>
          {`Subscriptions Group plans`}
        </h4>
      </Box>

      <Resource
        resource={resource}
        initialFilters={initialFilters}
        columnModel={columnModel}
        customValueFormat={customValueFormat}
        isCustomValueFormatPre={true}
        filters={filters}
        tableActions={
          <>
            <FilterButton {...props} />
          </>
        }
      />

    </Box>
  );
}

type Props = {
  initialFilters?: { [index: string]: any }
}