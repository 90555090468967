import * as React from 'react';
import { normalize } from '../interfaces/GridSortSettings';
import { ColumnResizer } from '../drag/ColumnResizer';
import { ColumnDraggable } from '../drag/ColumnDraggable';
import { GridHeaderCell } from './GridHeaderCell';
import { classNames, Keys } from '../../../../kendo-react-common';
import { HeaderThElement } from '../../../../kendo-react-data-tools';
import { registerForLocalization, provideLocalizationService as intl } from '../../../../kendo-react-intl';
import { messages, sortAriaLabel } from '../messages';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * @hidden
 */
var sortSeqMap = {
    true: { 'asc': 'desc', 'desc': '', '': 'asc' },
    false: { 'asc': 'desc', 'desc': 'asc', '': 'asc' }
};
/**
 * @hidden
 */
var ariaSortMap = {
    'none': 'none',
    'asc': 'ascending',
    'desc': 'descending'
};
/**
 * @hidden
 */
var HeaderRow = /** @class */ (function (_super) {
    __extends(HeaderRow, _super);
    function HeaderRow(props) {
        var _this = _super.call(this, props) || this;
        _this.serviceIndex = 0;
        _this.index = -1;
        _this.cellKeyDown = function (event, column) {
            if (event.isDefaultPrevented()) {
                return;
            }
            if (event.keyCode === Keys.enter) {
                _this.cellClick(event, column);
            }
        };
        _this.cells = function (rowIndexes) { return rowIndexes.map(function (columnIndex) {
            var column = _this.props.columns[columnIndex];
            var sortable = _this.props.sortable && column.sortable;
            var sortIndex = _this.props.sort ? _this.props.sort.findIndex(function (s) { return s.field === column.field; }) : -1;
            var sortDir = sortIndex >= 0 ? ((_this.props.sort)[sortIndex].dir || 'none') : 'none';
            var columnMenu = (column.columnMenu === null) ? null : (column.columnMenu || _this.props.columnMenu);
            var className = classNames({
                'k-first': column.kFirst,
                'k-filterable': Boolean(columnMenu),
                'k-header': true,
                'k-grid-header-sticky': column.locked,
                'k-sorted': _this.props.sort && _this.props.sort.some(function (descriptor) { return descriptor.field === column.field; })
            }, column.headerClassName);
            var style = column.left !== undefined
                ? !_this.props.isRtl
                    ? { left: column.left, right: column.right }
                    : { left: column.right, right: column.left }
                : {};
            var sortLabel = intl(_this).toLanguageString(sortAriaLabel, messages[sortAriaLabel]);
            var ariaAttrs = column.isAccessible ? {
                ariaSort: ariaSortMap[sortDir],
                role: 'columnheader',
                ariaColumnIndex: column.ariaColumnIndex,
                ariaSelected: false,
                ariaDescription: sortable ? sortLabel : ''
            } : {
                'role': 'presentation'
            };
            var columnMenuWrapperProps = {
                column: {
                    field: column.field,
                    filter: column.filter
                },
                sortable: sortable && _this.props.sortable,
                sort: _this.props.sort,
                onSortChange: _this.props.sortChange,
                filter: _this.props.filter,
                filterable: _this.props.filterable && column.filterable,
                filterOperators: _this.props.filterOperators,
                onFilterChange: _this.props.filterChange,
                group: _this.props.group,
                groupable: _this.props.groupable,
                onGroupChange: _this.props.groupChange,
                columnMenu: columnMenu
            };
            var headerCellProps = {
                field: column.field,
                onClick: sortable && (function (e) { return _this.cellClick(e, column); }) || undefined,
                selectionChange: _this.props.selectionChange,
                title: column.title,
                selectionValue: column.headerSelectionValue,
                render: _this.props.cellRender,
                children: _this.sortIcon(sortIndex),
                columnMenuWrapperProps: columnMenuWrapperProps
            };
            var key = (column.declarationIndex >= 0) ? ++_this.index : --_this.serviceIndex;
            return (React.createElement(HeaderThElement, __assign({}, ariaAttrs, { key: key, colSpan: column.colSpan, rowSpan: column.rowSpan, className: className, style: style, columnId: column.id, navigatable: column.navigatable, onKeyDown: sortable && (function (e) { return _this.cellKeyDown(e, column); }) || undefined }),
                column.headerCell ? React.createElement(column.headerCell, __assign({}, headerCellProps)) : React.createElement(GridHeaderCell, __assign({}, headerCellProps)),
                _this.props.columnResize && _this.props.columnResize.resizable && column.resizable &&
                    React.createElement(ColumnResizer, { key: 2, resize: function (e, element, end) {
                            return _this.props.columnResize &&
                                _this.props.columnResize.dragHandler(e, column, element, end);
                        }, autofit: function (e) {
                            return _this.props.columnResize &&
                                _this.props.columnResize.dblClickHandler(e, [column.id]);
                        } })));
        }); };
        _this.cellClick = _this.cellClick.bind(_this);
        return _this;
    }
    HeaderRow.prototype.cellClick = function (e, column) {
        e.preventDefault();
        if (!this.props.sortChange) {
            return;
        }
        var _a = normalize(this.props.sortable || false, column.sortable || false), allowUnsort = _a.allowUnsort, mode = _a.mode;
        var oldDescriptor = (this.props.sort || []).filter(function (d) { return d.field === column.field; })[0];
        var dir = sortSeqMap[allowUnsort][oldDescriptor && oldDescriptor.dir || ''];
        var newDescriptor = (mode === 'single') ?
            [] : (this.props.sort || []).filter(function (d) { return d.field !== column.field; });
        if (dir !== '' && column.field) {
            newDescriptor.push({ field: column.field, dir: dir });
        }
        this.props.sortChange(newDescriptor, e);
    };
    HeaderRow.prototype.sortIcon = function (sortIndex) {
        if (!this.props.sort) {
            return null;
        }
        return sortIndex >= 0 && [
            React.createElement("span", { key: 1, className: 'k-icon k-i-sort-' + this.props.sort[sortIndex].dir + '-small' }),
            this.props.sort.length > 1 && React.createElement("span", { key: 2, className: "k-sort-order" }, sortIndex + 1)
        ];
    };
    HeaderRow.prototype.render = function () {
        var _this = this;
        this.serviceIndex = 0;
        this.index = -1;
        return this.props.columnsMap.map(function (rowIndexes, i) {
            return _this.props.pressHandler && (React.createElement(ColumnDraggable, { key: i, pressHandler: _this.props.pressHandler, dragHandler: _this.props.dragHandler, releaseHandler: _this.props.releaseHandler, ariaRowIndex: i + 1, dragClue: _this.props.dragClue, headerRef: _this.props.headerRef, containerRef: _this.props.containerRef }, _this.cells(rowIndexes))) || React.createElement("tr", { role: "row", "aria-rowindex": i + 1 }, _this.cells(rowIndexes));
        });
    };
    return HeaderRow;
}(React.Component));
export { HeaderRow };
registerForLocalization(HeaderRow);
