import * as React from 'react';
import { registerForLocalization, provideLocalizationService as intl } from '../../../kendo-react-intl';
import { messages, noRecords } from './messages';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable max-len */
/**
 * Represents the GridNoRecords component. The component is rendered when the `data` property of the Grid is empty or `null`.
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *     render() {
 *         return (
 *             <Grid data={[]}>
 *                 <GridNoRecords>
 *                     There is no data available
 *                 </GridNoRecords>
 *                 <GridColumn field="id" />
 *                 <GridColumn field="name" />
 *             </Grid>
 *         );
 *     }
 * }
 * ReactDOM.render(
 *     <App />,
 *     document.querySelector('my-app')
 * );
 * ```
 */
// tslint:enable:max-line-length
var GridNoRecords = /** @class */ (function (_super) {
    __extends(GridNoRecords, _super);
    function GridNoRecords() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @hidden
     */
    GridNoRecords.prototype.render = function () {
        return this.props.children || intl(this).toLanguageString(noRecords, messages[noRecords]);
    };
    /**
     * @hidden
     */
    GridNoRecords.displayName = 'KendoReactGridNoRecords';
    return GridNoRecords;
}(React.Component));
export { GridNoRecords };
registerForLocalization(GridNoRecords);
