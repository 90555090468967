import * as React from 'react';
import { kendoThemeMaps, classNames } from '../../../../kendo-react-common';
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var sizeMap = kendoThemeMaps.sizeMap, roundedMap = kendoThemeMaps.roundedMap;
/**
 * @hidden
 */
var ListFilter = /** @class */ (function (_super) {
    __extends(ListFilter, _super);
    function ListFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.input = null;
        return _this;
    }
    ListFilter.prototype.render = function () {
        var _a;
        var _this = this;
        var _b = this.props, size = _b.size, rounded = _b.rounded, fillMode = _b.fillMode, inputRef = _b.inputRef;
        return (React.createElement("div", { className: "k-list-filter" },
            React.createElement("span", { className: classNames('k-searchbox k-input', (_a = {},
                    _a["k-input-".concat(sizeMap[size] || size)] = size,
                    _a["k-rounded-".concat(roundedMap[rounded] || rounded)] = rounded,
                    _a["k-input-".concat(fillMode)] = fillMode,
                    _a)) },
                React.createElement("span", { className: "k-input-icon k-icon k-i-search" }),
                React.createElement("input", { ref: inputRef ? inputRef : function (input) { return _this.input = input; }, value: this.props.value || '', className: "k-input-inner", onChange: this.props.onChange, onKeyDown: this.props.onKeyDown, tabIndex: this.props.tabIndex, onClick: function (e) { return e.stopPropagation(); } }))));
    };
    return ListFilter;
}(React.Component));
export default ListFilter;
