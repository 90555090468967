
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import {
  UWProductWithMonitoring
} from '../../../network/UnderwritingActions';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';

export type ForceVeriffDialogPayload = {
  userId: number
} | null;

export const ForceVefiffDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const [uwProduct, setUwProduct] =
    useState<UWProductWithMonitoring>("BalanceTransferContract");

  const { underwritingActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    setUwProduct("BalanceTransferContract");
  }, [payload]);

  const handleSendClick = async () => {
    if (payload === null) return;

    const { userId } = payload;

    const _userId = parseInt(userId.toString());

    const payloadForSend = {
      userId: _userId,
      uwProduct: uwProduct,
    };

    const error = await underwritingActions.forceVeriff(payloadForSend);

    if (error !== null) {
      notify(error);
    } else {
      notify("Done");
      refresh();
      hideDialog();
    }

  }

  const handleChangeUWProduct = useCallback((e: SelectChangeEvent<UWProductWithMonitoring>) => {
    setUwProduct(e.target.value as UWProductWithMonitoring);
  }, [uwProduct]);

  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title="Force veriff"
      onSend={handleSendClick}
      actionTitle="Run">

      <FormControl fullWidth>
        <InputLabel id="method-select-label-1">Product</InputLabel>
        <Select
          autoFocus={false}
          onFocus={() => { }}
          labelId="method-select-label-1"
          id="demo-simple-select"
          value={uwProduct}
          label="Method"
          onChange={handleChangeUWProduct}>
          <MenuItem value={"BillPayOneStep"}>BillPayOneStep</MenuItem>
          <MenuItem value={"BillPayOffer"}>BillPayOffer</MenuItem>
          <MenuItem value={"BillPayContract"}>BillPayContract</MenuItem>
          <MenuItem value={"BillPayMonitoring"}>BillPayMonitoring</MenuItem>
          <MenuItem value={"BalanceTransferOneStep"}>BalanceTransferOneStep</MenuItem>
          <MenuItem value={"BalanceTransferOffer"}>BalanceTransferOffer</MenuItem>
          <MenuItem value={"BalanceTransferContract"}>BalanceTransferContract</MenuItem>
          <MenuItem value={"BalanceTransferContinuous"}>BalanceTransferContinuous</MenuItem>
          <MenuItem value={"BalanceTransferIncreaseLimit"}>BalanceTransferIncreaseLimit</MenuItem>
          <MenuItem value={"BalanceTransferMonitoring"}>BalanceTransferMonitoring</MenuItem>
          <MenuItem value={"BillPayContinuous"}>BillPayContinuous</MenuItem>
        </Select>
      </FormControl>

    </SimpleDialog>
  );
}

type Props = {
  payload: ForceVeriffDialogPayload;
  hideDialog: () => void;
}

