import { extendDataItem, getter, Keys, mapTree, hasRelativeStackingContext } from '../../../../kendo-react-common';
import { TABLE_COL_INDEX_ATTRIBUTE, TABLE_ROW_INDEX_ATTRIBUTE } from './constants';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var GROUP_SUBITEMS_COLLECTION = 'items';
var LAST_SELECTION_STATE = {};
var getCellSelection = function (currentSelection, selectedColumn) {
    if (!currentSelection || (Array.isArray(currentSelection) && currentSelection.length === 0)) {
        return [selectedColumn];
    }
    else {
        var foundIndex = currentSelection.findIndex(function (columnIndex) { return columnIndex === selectedColumn; });
        if (foundIndex > -1) {
            return currentSelection.filter(function (columnIndex) { return columnIndex === selectedColumn; });
        }
        else {
            return __spreadArray(__spreadArray([], currentSelection, true), [selectedColumn], false);
        }
    }
};
/** @hidden */
export var closestTagName = function (target, tagName) {
    var currentTarget = target;
    while (currentTarget) {
        if (currentTarget.tagName === tagName) {
            return currentTarget;
        }
        else if (currentTarget.tagName === 'TABLE') {
            return null;
        }
        currentTarget = currentTarget.parentElement;
    }
    return null;
};
/** @hidden */
export var getRowIndex = function (element) {
    if (!element) {
        return;
    }
    var rowIndex = element.getAttribute(TABLE_ROW_INDEX_ATTRIBUTE);
    return rowIndex ? parseInt(rowIndex, 10) : undefined;
};
/** @hidden */
export var getColumnIndex = function (element) {
    if (!element) {
        return;
    }
    var colIndex = element.getAttribute(TABLE_COL_INDEX_ATTRIBUTE);
    return colIndex ? parseInt(colIndex, 10) : undefined;
};
/**
 * @hidden
 *
 * Apply the selected field to the data items based on the selected state.
 *
 * @param {{data: any, selectedState:  {[id: string]: boolean | number[]}, dataItemKey: string; selectedField: string; subItemsField?: string; }} options
 * @returns {any[]}
 */
export var setSelectedState = function (options) {
    var subItemsField = options.subItemsField || GROUP_SUBITEMS_COLLECTION;
    var idGetter = getter(options.dataItemKey);
    return mapTree(options.data, subItemsField, function (item) {
        var _a;
        return extendDataItem(item, subItemsField, (_a = {},
            _a[options.selectedField] = options.selectedState[idGetter(item)],
            _a));
    });
};
/**
 * Get selected state from the component KeyDown event.
 *
 * @param {{event: TableKeyDownEvent, selectedState:  {[id: string]: boolean | number[]}, dataItemKey: string}} options
 * @returns {{[id: string]: boolean | number[]}} - The new selected state.
 */
export var getSelectedStateFromKeyDown = function (options) {
    var event = options.event, dataItemKey = options.dataItemKey, selectedState = options.selectedState;
    var syntheticEvent = event.syntheticEvent, nativeEvent = event.nativeEvent, dataItems = event.dataItems, componentId = event.componentId, mode = event.mode, cell = event.cell, selectedField = event.selectedField;
    var target = nativeEvent.target, ctrlKey = nativeEvent.ctrlKey, metaKey = nativeEvent.metaKey, altKey = nativeEvent.altKey, shiftKey = nativeEvent.shiftKey;
    if (syntheticEvent.isDefaultPrevented() || nativeEvent.keyCode !== Keys.enter) {
        return selectedState;
    }
    var tdElement = closestTagName(target, 'TD');
    var trElement = closestTagName(target, 'TR');
    var colIndex = getColumnIndex(tdElement);
    var rowIndex = getRowIndex(trElement);
    var selectionState = {};
    if (tdElement && trElement && rowIndex !== undefined && colIndex !== undefined) {
        selectionState = getSelectedState({
            selectedState: selectedState,
            dataItemKey: dataItemKey,
            event: {
                ctrlKey: ctrlKey,
                metaKey: metaKey,
                altKey: altKey,
                shiftKey: shiftKey,
                dataItems: dataItems,
                cell: cell,
                mode: mode,
                selectedField: selectedField,
                componentId: componentId,
                startColIndex: colIndex, endColIndex: colIndex, startRowIndex: rowIndex, endRowIndex: rowIndex,
                isDrag: false,
                syntheticEvent: syntheticEvent,
                nativeEvent: nativeEvent,
                target: event.target,
                dataItem: null
            }
        });
    }
    return selectionState;
};
/**
 * Get selected state from the component selection event.
 *
 * @param {{event: TableSelectionChangeEvent, selectedState:  {[id: string]: boolean | number[]}, dataItemKey: string}} options
 * @returns {{[id: string]: boolean | number[]}} - The new selected state.
 */
export var getSelectedState = function (options) {
    var event = options.event, dataItemKey = options.dataItemKey, prevSelectedState = options.selectedState;
    var dataItems = event.dataItems, startRowIndex = event.startRowIndex, endRowIndex = event.endRowIndex, startColIndex = event.startColIndex, endColIndex = event.endColIndex, cell = event.cell, isDrag = event.isDrag, ctrlKey = event.ctrlKey, shiftKey = event.shiftKey, metaKey = event.metaKey, componentId = event.componentId, mode = event.mode, dataItem = event.dataItem;
    var idGetter = getter(dataItemKey);
    var selectionState = {};
    var isInputEvent = dataItem !== null;
    if (mode === 'single') {
        var currentDataItem = dataItems.slice(startRowIndex, startRowIndex + 1)[0];
        var itemId = idGetter(currentDataItem);
        LAST_SELECTION_STATE[componentId] = {
            rowIndex: startRowIndex,
            colIndex: startColIndex
        };
        selectionState[itemId] = cell ? [startColIndex] : true;
    }
    else if (isDrag || (!shiftKey && !ctrlKey && !metaKey && !isInputEvent)) {
        if (!isDrag) {
            LAST_SELECTION_STATE[componentId] = {
                rowIndex: startRowIndex,
                colIndex: startColIndex
            };
        }
        var selectedColumns_1 = [];
        for (var i = startColIndex; i <= endColIndex; i++) {
            selectedColumns_1.push(i);
        }
        var selectedDataItems = dataItems.slice(startRowIndex, endRowIndex + 1);
        selectedDataItems.forEach(function (currentItem) {
            var itemId = idGetter(currentItem);
            selectionState[itemId] = cell ? __spreadArray([], selectedColumns_1, true) : true;
        });
    }
    else {
        if (ctrlKey || metaKey || isInputEvent) {
            LAST_SELECTION_STATE[componentId] = {
                rowIndex: startRowIndex,
                colIndex: startColIndex
            };
            var selectedDataItems = dataItems.slice(startRowIndex, endRowIndex + 1);
            var currentDataItem = selectedDataItems[0];
            var itemId = idGetter(currentDataItem);
            selectionState = __assign({}, prevSelectedState);
            selectionState[itemId] = cell && !isInputEvent ? getCellSelection(selectionState[itemId], startColIndex) : !selectionState[itemId];
        }
        else if (shiftKey) {
            var lastRowIndex = (LAST_SELECTION_STATE[componentId] && LAST_SELECTION_STATE[componentId].rowIndex) || 0;
            var lastColIndex = (LAST_SELECTION_STATE[componentId] && LAST_SELECTION_STATE[componentId].colIndex) || 0;
            var currentStartRowIndex = Math.min(lastRowIndex, startRowIndex, endRowIndex);
            var currentEndRowIndex = Math.max(lastRowIndex, startRowIndex, endRowIndex);
            var currentStarColIndex = Math.min(lastColIndex, startColIndex, endColIndex);
            var currentEndColIndex = Math.max(lastColIndex, startColIndex, endColIndex);
            var selectedColumns_2 = [];
            for (var i = currentStarColIndex; i <= currentEndColIndex; i++) {
                selectedColumns_2.push(i);
            }
            var selectedDataItems = dataItems.slice(currentStartRowIndex, currentEndRowIndex + 1);
            selectedDataItems.forEach(function (currentItem) {
                var itemId = idGetter(currentItem);
                selectionState[itemId] = cell ? __spreadArray([], selectedColumns_2, true) : true;
            });
        }
    }
    return selectionState;
};
/** @hidden */
var HAS_RELATIVE_STACKING_CONTEXT = hasRelativeStackingContext();
/** @hidden */
export var relativeContextElement = function (element) {
    if (!element || !HAS_RELATIVE_STACKING_CONTEXT) {
        return null;
    }
    var node = element.parentElement;
    while (node) {
        if (window.getComputedStyle(node).transform !== 'none') {
            return node;
        }
        node = node.parentElement;
    }
};
/** @hidden */
export var getOffset = function (offsetParent) {
    if (offsetParent && offsetParent.ownerDocument) {
        if (offsetParent !== offsetParent.ownerDocument.body) {
            var rect = offsetParent.getBoundingClientRect();
            return {
                left: rect.left - offsetParent.scrollLeft,
                top: rect.top - offsetParent.scrollTop
            };
        }
    }
    return { left: 0, top: 0 };
};
/** @hidden */
export var getSelectionOptions = function (selectable) {
    var enabled = selectable ?
        (typeof selectable.enabled === 'boolean' ? selectable.enabled : true) :
        false;
    var drag = selectable ? Boolean(selectable.drag) : false;
    var mode = selectable && selectable.mode ? selectable.mode : 'multiple';
    var cell = Boolean(selectable && selectable.cell);
    return { enabled: enabled, drag: drag, mode: mode, cell: cell };
};
