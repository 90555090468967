import React, { FC, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';
import { Button } from 'react-admin';

export const SimpleDialog: FC<Props> = ({
  children,
  isShow,
  onClose,
  onSend,
  title,
  actionTitle,
}) => {
  return (
    <Dialog
      fullWidth
      
      open={isShow}
      aria-label={title}>

      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        {children}
      </DialogContent>

      <DialogActions>
        <Button
          label="ra.action.cancel"
          onClick={onClose}
          disabled={false} >
          <IconCancel />
        </Button>
        {
          onSend !== undefined &&
          <Button
            label={actionTitle ?? "Send"}
            onClick={onSend}
            disabled={false} />
        }
      </DialogActions>

    </Dialog>
  )
}

type Props = {
  title: string;
  actionTitle?: string;
  isShow: boolean;
  onClose: () => void;
  onSend?: () => void;
}