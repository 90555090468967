import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { Resource } from "../../common/Resource";
import { ColumnModel } from "../../model/ColumnModel";
import { DateInput, FilterButton, SelectInput, TextInput } from "react-admin";
import { RowActions } from "./widget/RowActions";

export const resource = "admin/generic-views/seemingly_died_tokens";

const tableColumns = [
  { field: "id", width: "70px", title: "id", show: true },
  { field: "user_id", width: "100px", title: "user id", show: true },
  { field: "user_status", width: "100px", title: "user status", show: true },
  { field: "token_created_at", width: "200px", title: "token created at", show: true, format: 'yyyy-MM-dd' },
  { field: "item_id", width: "100px", title: "item id", show: true },
  { field: "last_successful_update_at", width: "100px", title: "last successful update at", show: true },
  { field: "bank_name", width: "100px", title: "bank name", show: true },
  { field: "status", width: "100px", title: "status", show: true },
  { field: "is_expired", width: "100px", title: "is expired", show: true },
  { field: "total_accounts_active", width: "100px", title: "total accounts active", show: true },

  { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="user id" source="user_id.eq" alwaysOn />,

  <SelectInput source="status" choices={[
    { id: 'Active', name: 'Active' },
    { id: 'Revoked', name: 'Revoked' },
    { id: 'OnHold', name: 'OnHold' },
  ]} alwaysOn />,

  <TextInput label="token id" source="token_id.eq" alwaysOn />,
  <TextInput label="Bank name" source="bank_name.eq" alwaysOn />,

  <DateInput label="token_created_at gt" source="token_created_at.gt" alwaysOn />,
  <DateInput label="token_created_at lt" source="token_created_at.lt" />,

  <DateInput label="last_successful_update_at gt" source="last_successful_update_at.gt" alwaysOn />,
  <DateInput label="last_successful_update_at lt" source="last_successful_update_at.lt" />,

  <SelectInput source="is_expired" choices={[
    { id: 'true', name: 'True' },
    { id: 'false', name: 'False' },
  ]} alwaysOn />,

];


export const SeeminglyDiedTokenCollection: React.FC<Props> = (props) => {
  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />

  const {
    initialFilters,
  } = props;

  const columnModel = useMemo(() => {
    console.log(resource, 'call just once');
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (

    <Box sx={{ position: "relative", padding: "40px 0px 0px 0px" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "10px",
      }}>
        <h4>
          {`Seemingly died tokens`}
        </h4>
      </Box>

      <Resource
        resource={resource}
        initialFilters={initialFilters}
        columnModel={columnModel}
        filters={filters}
        rowActionBuilder={rowActionBuilder}
        tableActions={
          <>
            <FilterButton {...props} />
          </>
        }
      />

    </Box>
  );
}

type Props = {
  initialFilters?: { [index: string]: any }
}