import { Box } from "@mui/material";
import { reduce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Resource } from "../../common/Resource";
import { useInjection } from "../../di/useInjection";
import { AccountsOverview, DepositoryAccount, DepositoryAccountWrapper, } from "../../types/accountsOverview";
import { BillingCycle } from "../../types/billingCycles";
import { RowActions } from "./widget/RowActios";
import { ChargesActions } from "./widget/ChargesActions";
import { ColumnModel } from "../../model/ColumnModel";

export const resource = "admin/generic-views/collection_scheduled_pb_transactions";

export const selectDepositoryAccounts = (accountsOverview: AccountsOverview): DepositoryAccount[] => {
  return reduce(
    accountsOverview.depositoryAccount,
    (acc: Array<DepositoryAccount>, item: DepositoryAccountWrapper) => [
      ...acc,
      ...item.accounts,
    ],
    [],
  );
}

const tableColumns = [
  { field: "id", width: "100px", title: "id", show: true },
  { field: "user_id", width: "100px", title: "user_id", show: false },
  { field: "single_bill_cycle_id", width: "150px", title: "single bill cycle id", show: true },
  { field: "scheduled_date", width: "150px", title: "scheduled date", show: true, format: "yyyy-MM-dd" },
  { field: "payment_due_date", width: "150px", title: "paynt due date", show: true, format: "yyyy-MM-dd" },
  { field: "amount", width: "150px", title: "amount", show: true, isCents: true },
  { field: "status", width: "150px", title: "status", show: true },
  { field: "status_description", width: "200px", title: "status_description", show: true },
  { field: "from_account_type", width: "150px", title: "from account type", show: true },
  { field: "from_account_id", width: "150px", title: "from account id", show: false },
  { field: "scheduled_by", width: "150px", title: "scheduled by", show: true },
  { field: "bank_name", width: "100px", title: "bank name", show: true },
  { field: "mask", width: "100px", title: "mask", show: true },
  { field: "current_balance", width: "150px", title: "current_balance", show: true, isCents: true },
  { field: "token_expired_at", width: "150px", title: "token expired at", show: true, format: "yyyy-MM-dd" },
  { field: "transaction_error", width: "150px", title: "transaction error", show: true },

  { field: "actions", width: "120px", title: "actions", show: true },
]

export const ChargesCollection: React.FC<Props> = ({ initialFilters }) => {
  const [accountsOverview, setAccountsOverview] = useState<AccountsOverview | undefined>(undefined)
  const [billingCycles, setBillingCycles] = useState<BillingCycle[] | undefined>(undefined);

  const { debtActions } = useInjection();

  useEffect(() => {
    setAccountsOverview(undefined);

    if (initialFilters === undefined) {
      return;
    }

    const userId = initialFilters.user_id.eq;

    debtActions.fetchAccountsOverview(userId).then((response) => {
      setAccountsOverview(response);
    });

    debtActions.fetchBillingCycle(userId).then((response) => {
      setBillingCycles(response);
    });

  }, [initialFilters.user_id.eq]);

  const rowActionBuilder = (record: { [index: string]: any }) =>
    <RowActions accountsOverview={accountsOverview} record={record} />

  const chargesActions = <ChargesActions
    accountsOverview={accountsOverview}
    billingCycles={billingCycles}
    initialFilters={initialFilters}
  />

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return <Box sx={{ position: 'relative' }}>

    <Box sx={{
      position: "absolute",
      background: "#2a41b0",
      color: "#fff",
      padding: "0 20px",
      borderRadius: "10px 10px 0 0",
      top: "30px",
    }}>
      <h4>
        {`Charges: ${initialFilters.user_id.eq}`}
      </h4>
    </Box>

    <Resource
      resource={resource}
      initialFilters={initialFilters}
      rowActionBuilder={rowActionBuilder}
      tableActions={chargesActions}
      columnModel={columnModel}
    />

  </Box>
}

type Props = {
  initialFilters: { "user_id": { "eq": string } }
}