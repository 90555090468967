import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { Resource } from "../../common/Resource";
import { ColumnModel } from "../../model/ColumnModel";

export const resource = "admin/generic-views/collection_repayments";

const tableColumns = [
  { field: "id", width: "100px", title: "id", show: true },
  { field: "user_id", width: "100px", title: "user id", show: false },
  { field: "valid_since", width: "300px", title: "valid since", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "valid_until", width: "300px", title: "valid until", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "remaining_payments", width: "300px", title: "remaining payments", show: true },
  { field: "last_transaction_amount", width: "300px", title: "last transaction amount", show: true, isCents: true },
  { field: "last_transaction_time", width: "300px", title: "last transaction time", show: true, isCents: true },
];

export const RepaymentsDebtCollection: React.FC<Props> = ({
  initialFilters,
}) => {
  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Box sx={{ position: "relative" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`Repayments: ${initialFilters.user_id.eq}`}
        </h4>
      </Box>

      <Resource
        resource={resource}
        initialFilters={initialFilters}
        columnModel={columnModel}
      />

    </Box>
  );
}

type Props = {
  initialFilters: { "user_id": { "eq": string } }
}