import React from 'react';
import { List, ResourceContextProvider, } from 'react-admin';
import { RowActionBuilder, RowClickEvent } from './Table';
import { Column, ColumnModel } from '../model/ColumnModel';
import { Box } from '@mui/material';
import { VerticalTable } from './VerticalTable/VerticalTable';
import { SpecialFormatValue } from './VerticalTable/VerticalRow';

export const ResourceVertical: React.FC<Props> = ({
  resource,
  filters,
  tableActions,
  initialFilters = {},
  columnModel,
  specialFormat,
}) => {
  return (
    <Box sx={{ minWidth: "400px", marginRight: "20px" }}>

      <ResourceContextProvider value={resource}>
        <List
          disableSyncWithLocation
          filters={filters}
          filterDefaultValues={initialFilters}
          pagination={false}
          actions={tableActions}>

          <VerticalTable
            columnModel={columnModel}
            size={400}
            specialFormat={specialFormat}
          />

        </List>
      </ResourceContextProvider>

    </Box>
  )
}

type Props = {
  resource: string
  disableUrlFilterListener?: boolean
  onRowClick?: (event: RowClickEvent) => void
  filters?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactElement<any, string | React.JSXElementConstructor<any>>[] | undefined
  rowActionBuilder?: RowActionBuilder
  tableActions?: React.ReactElement
  initialFilters?: { [index: string]: any }
  columnModel: Column[]
  specialFormat?: SpecialFormatValue
}
