import { useState } from 'react'
import { makeStyles, alpha } from '@material-ui/core/styles'
import { Button, Box, Snackbar } from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'
import * as React from 'react'
import { useRefresh } from 'react-admin'
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid'

import { dataProvider } from '../providers/dataProvider'
import {
  IPBTransactionAddProps,
  IPBTransactionEditProps,
  IPlacidBillTxnProps,
} from '../types'
import { centsToDollars } from '../helpers'
import { PBTransactionAdd } from './PBTransactionAdd'
import { PBTransactionEdit } from './PBTransactionEdit'
import { CustomToolbar } from './CustomToolbar'
import { Constants } from '../constants'

const useStyles = makeStyles({
  root: {
    padding: 15,
    '& .MuiDataGrid-row': {
      maxHeight: 'unset!important',
    },
    '& .MuiDataGrid-cell': {
      whiteSpace: 'normal!important',
      maxHeight: 'unset!important',
      height: 'unset!important',
    },
  },
})

export const PlacidChargesTable = (props: {
  charges: IPlacidBillTxnProps[]
  id: string
  billingCycleId: number
}) => {
  const classes = useStyles()

  const refresh = useRefresh()

  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([])
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const actionButton = () =>
    selectionModel.length > 0 ? (
      <Button
        onClick={() => setOpenEdit(true)}
        startIcon={<Edit />}
        variant="contained"
        color="primary"
      >
        Edit
      </Button>
    ) : (
      <Button
        onClick={() => setOpenAdd(true)}
        startIcon={<Add />}
        variant="contained"
        color="primary"
      >
        Add
      </Button>
    )

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Planned payment date',
      width: 120,
    },
    {
      field: 'amount',
      headerName: 'Planned payment amount',
      width: 120,
      sortable: false,
    },
    { field: 'type', headerName: 'Type', width: 150, sortable: false },
    { field: 'status', headerName: 'Status', width: 120, sortable: false },
    { field: 'bank', headerName: 'Bank name', width: 110, sortable: false },
    {
      field: 'accountName',
      headerName: 'Account name',
      width: 110,
      sortable: false,
    },
    {
      field: 'accountNumber',
      headerName: 'Account number',
      width: 110,
      sortable: false,
    },
    {
      field: 'balance',
      headerName: 'Balance before payment day',
      width: 110,
      sortable: false,
    },
  ]

  const rows = props.charges.map((row, index) => ({
    id: index,
    date: row.plannedPaymentDate,
    amount: centsToDollars(row.plannedPaymentAmount),
    type: row.accountType,
    status: row.status,
    bank: row.bankName !== null ? row.bankName : '',
    accountName: row.accountName !== null ? row.accountName : '',
    accountNumber:
      row.accountNumber !== null ? row.accountNumber.toString() : '',
    balance:
      row.balanceBeforePaymentDay !== null
        ? centsToDollars(
            row.balanceBeforePaymentDay.pbBalance +
              row.balanceBeforePaymentDay.btBalance +
              row.balanceBeforePaymentDay.interestBalance +
              row.balanceBeforePaymentDay.savingsBalance +
              row.balanceBeforePaymentDay.subscriptionBalance
          )
        : '',
  }))

  const handleAdd = (data: IPBTransactionAddProps) => {
    setIsLoading(true)
    dataProvider
      .update(Constants.PB_CREATE, {
        id: parseInt(props.id),
        data: data,
        previousData: { id: props.id },
      })
      .then((response) => {
        if (response.data.success) {
          refresh()
          setOpenAdd(false)
        } else {
          setError(response.data.error.message)
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        setError(e.toString())
      })
  }

  const handleDelete = (data: { transactionId: number; comment: string }) => {
    setIsDeleting(true)

    dataProvider
      .update(Constants.PB_CANCEL, {
        id: parseInt(props.id),
        data: {
          userId: parseInt(props.id),
          transactionId: data.transactionId,
          comment: data.comment,
        },
        previousData: { id: props.id },
      })
      .then((response) => {
        if (response.data.success) {
          refresh()
          setOpenEdit(false)
        } else {
          setError(response.data.error.message)
        }
        setIsDeleting(false)
      })
      .catch((e) => {
        setIsDeleting(false)
        setError(e.toString())
      })
  }

  const handleEdit = (data: IPBTransactionEditProps) => {
    setIsLoading(true)
    dataProvider
      .update(Constants.PB_ADJUST, {
        id: parseInt(props.id),
        data: data,
        previousData: { id: props.id },
      })
      .then((response) => {
        if (response.data.success) {
          refresh()
          setOpenEdit(false)
        } else {
          setError(response.data.error.message)
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        setError(e.toString())
      })
  }

  return (
    <>
      <Box height={rows.length > 0 ? 400 : '100%'} width="100%">
        <DataGrid
          components={{
            Toolbar: () =>
              CustomToolbar({
                title: 'Placid Bill transactions',
                action: actionButton(),
              }),
          }}
          classes={{ root: classes.root }}
          hideFooterPagination={rows.length === 0}
          density="compact"
          disableColumnMenu
          rows={rows}
          columns={columns}
          pageSize={5}
          disableColumnFilter
          onPageChange={() => setSelectionModel([])}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(
              newSelectionModel.toString() !== selectionModel.toString()
                ? newSelectionModel
                : []
            )
          }}
          selectionModel={selectionModel}
        />
      </Box>
      <PBTransactionAdd
        id={props.id}
        isOpen={openAdd}
        setOpen={setOpenAdd}
        billingCycleId={props.billingCycleId}
        isLoading={isLoading}
        handleAdd={handleAdd}
      />
      {selectionModel.length > 0 && (
        <PBTransactionEdit
          id={props.id}
          isOpen={openEdit}
          setOpen={setOpenEdit}
          transaction={props.charges[Number(selectionModel[0])]}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          isLoading={isLoading}
          isDeleting={isDeleting}
        />
      )}
      <Snackbar
        open={error.length > 0}
        message={error}
        onClose={() => setError('')}
      />
    </>
  )
}
