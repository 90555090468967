import { Box } from '@mui/material';
import React from 'react';
import { useListContext } from 'react-admin';
import { Column } from '../../model/ColumnModel';
import { VerticalCarousel } from './VerticalCarousel';
import { VerticalRow } from './VerticalRow';

const messageStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "200px"
}

export const MAX_DISPLAY_DATA_LENGTH = 3;

export const VerticalTable: React.FC<Props> = (props) => {
  const { size = 400 } = props;
  const listData = useListContext(props);

  const { data } = listData;

  if (data === undefined) {
    return <Box sx={messageStyle}>Loading...</Box>
  }

  if (data === undefined || data?.length === 0) {
    return <Box sx={messageStyle}>No data</Box>
  }

  if (data.length <= MAX_DISPLAY_DATA_LENGTH) {
    return (
      <Box sx={{ maxWidth: `${size * data.length}px` }}>
        <Box sx={{ display: "flex" }}>
          {data.map((row, index) => <VerticalRow key={index} row={row} {...props} />)}
        </Box>
      </Box>
    );
  } else {
    return <VerticalCarousel
      data={data}
      size={size}
      {...props}
    />
  }
}


export type SpecialFormatValue = { [index: string]: (value: any) => string }

type Props = {
  columnModel: Column[]
  size?: number
  specialFormat?: SpecialFormatValue
}