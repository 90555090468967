
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { Account, AccountTag } from '../../../network/RepaymentsActions';
import currency from 'currency.js';
import { useInjection } from '../../../di/useInjection';
import { DepositoryAccount } from '../../../types/accountsOverview';
import { format } from 'date-fns/esm';
import { PostError } from '../../../network/AdminRequest';
import { useNotify, useRefresh } from 'react-admin';
import { ManualAccountPayloadType } from '../../../network/DepositoryActions';

export type ManualRawAccountDialogPayload = {
  userId: number
} | undefined;

export const ManualRawAccountsDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { depositoryActions } = useInjection();
  const notify = useNotify();
  const refresh = useRefresh();

  const [balanceLimit, setBalanceLimit] = useState<number>(0);
  const [mask, setMask] = useState<string>("");
  const [nameAccount, setNameAccount] = useState<string>("");

  const [type, setType] = useState<ManualAccountPayloadType>("checking");

  const [error, setError] = useState<PostError>(null);

  useEffect(() => {
    if (payload !== undefined) {
      setBalanceLimit(0);
      setMask("");
      setNameAccount("");
      setType("checking");
      setError(null);
    }
  }, [payload]);

  const handleSendClick = async () => {
    if (payload === undefined) return;

    const { userId } = payload;

    const _balanceLimit = currency(balanceLimit).intValue;
    console.log(_balanceLimit, mask, nameAccount, type);

    const error = await depositoryActions.createManualAccount({
      userId: parseInt(userId.toString()),
      balanceLimit: _balanceLimit,
      mask,
      nameAccount,
      type,
    });

    if (error === null) {
      notify("Done");
      hideDialog();
      refresh();
    } else {
      setError(error);
    }
  }

  const handleTypeChange = (e: SelectChangeEvent<ManualAccountPayloadType>) => {
    setType(e.target.value as ManualAccountPayloadType);
  }

  const handleBalanceLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = parseFloat(e.target.value);
      setBalanceLimit(value);
    } catch (_) { }
  }

  const handleNameAccountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNameAccount(value);
  }

  const handleMaskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMask(value);
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Create raw account"
      onSend={handleSendClick}
      actionTitle="Create">

      <TextField
        fullWidth
        id="blance limit"
        label="balance limit (dollar)"
        type="number"
        defaultValue={balanceLimit}
        value={balanceLimit}
        InputLabelProps={{ shrink: true }}
        onChange={handleBalanceLimitChange}
      />

      <TextField
        fullWidth
        id="mask"
        label="mask"
        type="text"
        defaultValue={mask}
        value={mask}
        onChange={handleMaskChange}
      />

      <TextField
        fullWidth
        id="nameAccount"
        label="Name account"
        type="text"
        defaultValue={nameAccount}
        value={nameAccount}
        onChange={handleNameAccountChange}
      />


      <FormControl fullWidth>
        <InputLabel id="type-select">From account</InputLabel>
        <Select
          autoFocus={false}
          onFocus={() => { }}
          labelId="type-select"
          id="demo-simple-select1"
          value={type}
          label="from account tag"
          onChange={handleTypeChange}>
          <MenuItem key={"checking"} value={"checking"}>checking</MenuItem>
          <MenuItem key={"saving"} value={"saving"}>saving</MenuItem>
          <MenuItem key={"prepaid"} value={"prepaid"}>prepaid</MenuItem>
        </Select>
      </FormControl>

      {error !== null && <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload: ManualRawAccountDialogPayload
  hideDialog: () => void
}