import { Box } from "@mui/material";
import React, { useMemo } from "react";
import {
  DateInput,
  FilterButton,
  NumberInput,
  SelectInput,
  TextInput,
} from "react-admin"
import { Button } from 'react-admin';
import { BottomDrawer } from '../../../common/BottomDrawer';
import { DollarInput } from "../../../common/DollarInput";
import { Resource } from "../../../common/Resource";
import { ColumnModel } from "../../../model/ColumnModel";

export const resource = "admin/generic-views/raw_accounts";

export type PlaidRawAccountsDrawerPayload = {
  userId: string
}

const tableColumns = [
  { field: "item_id", width: "120px", title: "item_id", show: false },
  { field: "account_id", width: "120px", title: "account_id", show: false },
  { field: "raw_account_id", width: "80px", title: "raw_account_id", show: true },
  // { field: "user_id", width: "120px", title: "user_id", show: true },
  { field: "bank", width: "120px", title: "bank", show: true },
  { field: "mask", width: "120px", title: "mask", show: true },
  { field: "name", width: "120px", title: "name", show: true },
  { field: "balance", width: "120px", title: "balance", show: true, isCents: true },
  { field: "available", width: "120px", title: "available", show: true, isCents: true },
  { field: "income", width: "120px", title: "income", show: true },
  { field: "primary_status", width: "120px", title: "primary_status", show: true },
  { field: "subtype", width: "120px", title: "subtype", show: true },
  { field: "limit", width: "120px", title: "limit", show: true, isCents: true },
  { field: "type", width: "120px", title: "type", show: true },
  { field: "status", width: "120px", title: "status", show: true },
  { field: "token_status", width: "120px", title: "token_status", show: true },
  { field: "may_expire_at", width: "120px", title: "may_expire_at", show: true, format: "yyyy-MM-dd" },
  { field: "expired_at", width: "120px", title: "expired_at", show: true, format: "yyyy-MM-dd" },
  { field: "pending_auth", width: "120px", title: "pending_auth", show: true },
  { field: "micro_deposits_status", width: "120px", title: "micro_deposits_status", show: true },
  { field: "PREVIOUS_PLAID_RAW_ACCOUNT", width: "120px", title: "PREVIOUS_PLAID_RAW_ACCOUNT", show: false },

  // { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="name" source="name.eq" alwaysOn />,

  <TextInput label="bank" source="bank.eq" />,

  <TextInput label="mask" source="mask.eq" />,

  // <TextInput label="table id" source="table_id.eq" />,

  <DollarInput label="balance gt (Dollar)" source="balance.gt" />,
  <DollarInput label="balance lt (Dollar)" source="balance.lt" />,

  <DollarInput label="available gt (Dollar)" source="available.gt" />,
  <DollarInput label="available lt (Dollar)" source="available.lt" />,

  <DollarInput label="limit gt (Dollar)" source="limit.gt" />,
  <DollarInput label="limit lt (Dollar)" source="limit.lt" />,

  <TextInput label="type" source="type.eq" />,
  <TextInput label="subtype" source="subtype.eq" />,

  <SelectInput source="status" choices={[
    { id: "ACTIVE", name: "ACTIVE" },
    { id: "DISAPPEARED", name: "DISAPPEARED" },
    { id: "CONFIRMED_CLOSE", name: "CONFIRMED_CLOSE" },
    { id: "CLOSED_PERMANENTLY", name: "CLOSED_PERMANENTLY" },
  ]} />,

  <SelectInput source="token_status" choices={[
    { id: "Active", name: "Active" },
    { id: "OnHold", name: "OnHold" },
    { id: "Revoked", name: "Revoked" },
  ]} />,

  <SelectInput source="primary_status" choices={[
    { id: 'NotPrimary', name: 'NotPrimary' },
    { id: 'PrimarySystemConfirmed', name: 'PrimarySystemConfirmed' },
    { id: 'PrimaryAdminConfirmed', name: 'PrimaryAdminConfirmed' },
  ]} />,

  <SelectInput source="pending_auth" choices={[
    { id: 'true', name: 'Enabled' },
    { id: 'false', name: 'Disabled' },
  ]} />,

  <SelectInput source="micro_deposits_status" choices={[
    { id: 'Verified', name: 'Verified' },
    { id: 'Expired', name: 'Expired' },
  ]} />,

  <TextInput label="PREVIOUS_PLAID_RAW_ACCOUNT" source="PREVIOUS_PLAID_RAW_ACCOUNT.eq" />,
];

export const PlaidRawAccountsDrawer: React.FC<Props> = (props) => {
  const {
    payload,
    onClose,
  } = props;

  const columnModel = useMemo(() => {
    console.log(resource, 'call just once');
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <BottomDrawer
      isShow={payload !== undefined}
      onClose={onClose}
      title="Plaid raw accounts">

      {
        payload !== undefined && (
          <Box>
            <Resource
              resource={resource}
              initialFilters={{ user_id: { eq: payload.userId } }}
              filters={filters}
              columnModel={columnModel}
              tableActions={
                <>
                  <Button label="close" onClick={onClose} />
                  <FilterButton {...props} />
                </>
              }
            />
          </Box>
        )
      }

    </BottomDrawer>
  );
}

type Props = {
  payload: PlaidRawAccountsDrawerPayload | undefined
  onClose: () => void
}