import * as React from 'react';
import * as PropTypes from 'prop-types';
import VirtualScroll from './VirtualScroll';
import { Navigation } from './Navigation';
import { scrollToItem, areSame } from './utils';
import { guid } from '../../../../kendo-react-common';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * @hidden
 */
var DropDownBase = /** @class */ (function () {
    function DropDownBase(component) {
        var _this = this;
        this.wrapper = null;
        this.list = null;
        this.vs = new VirtualScroll();
        this.navigation = new Navigation();
        this.handleItemClick = function (index, event) {
            var state = _this.initState();
            state.syntheticEvent = event;
            event.stopPropagation();
            _this.component.handleItemSelect(index, state);
            _this.togglePopup(state);
            _this.applyState(state);
        };
        this.handleFocus = function (event) {
            if (!_this.component.state.focused) {
                var state = _this.initState();
                state.data.focused = true;
                state.events.push({ type: 'onFocus' });
                state.syntheticEvent = event;
                _this.applyState(state);
            }
        };
        this.filterChanged = function (text, state) {
            var _a = _this.component.props, textField = _a.textField, filterable = _a.filterable;
            if (filterable) {
                state.events.push({
                    type: 'onFilterChange',
                    filter: {
                        field: textField,
                        operator: 'contains',
                        ignoreCase: true,
                        value: text
                    }
                });
            }
        };
        this.togglePopup = function (state) {
            var props = _this.component.props;
            var opened = props.opened !== undefined ? props.opened : _this.component.state.opened;
            if (props.opened === undefined) {
                state.data.opened = !opened;
            }
            if (opened) {
                state.events.push({ type: 'onClose' });
            }
            else {
                state.events.push({ type: 'onOpen' });
                _this.calculatePopupWidth();
            }
        };
        this.pageChange = function (page, syntheticEvent) {
            var state = _this.initState();
            state.syntheticEvent = syntheticEvent;
            _this.triggerOnPageChange(state, page.skip, page.take);
            _this.applyState(state);
        };
        this.scrollToVirtualItem = function (virtual, selectedItemIndex) {
            var vs = _this.vs;
            vs.enabled = false;
            if (virtual.skip === 0) {
                vs.reset();
            }
            else if (virtual.skip + virtual.pageSize === virtual.total) {
                vs.scrollToEnd();
            }
            else {
                var scrollTop = vs.translate;
                if (scrollTop === 0) {
                    vs.calcScrollElementHeight();
                    scrollTop = (vs.itemHeight * virtual.skip);
                    vs.translateTo(scrollTop - vs.itemHeight);
                }
                if (vs.container) {
                    vs.container.scrollTop = scrollTop;
                }
                _this.scrollToItem(selectedItemIndex, true);
            }
            window.setTimeout(function () { return vs.enabled = true; }, 10);
        };
        this.renderScrollElement = function () {
            var vs = _this.vs;
            return vs.enabled && (React.createElement("div", { ref: function (element) { return vs.scrollElement = element; }, key: 'scrollElementKey' }));
        };
        this.listBoxId = guid();
        this.guid = guid();
        this.component = component;
        this.vs.PageChange = this.pageChange;
    }
    DropDownBase.prototype.didMount = function () {
        var props = this.component.props;
        var popupSettings = props.popupSettings || {};
        var style = props.style || {};
        var popupWidth = popupSettings.width;
        var shouldUpdate = props.opened === true;
        if (popupWidth === undefined) {
            this.calculatePopupWidth();
        }
        if (props.dir === undefined && style.direction === undefined) {
            this.calculateDir();
            shouldUpdate = true;
        }
        if (shouldUpdate) {
            this.component.forceUpdate();
        }
    };
    DropDownBase.prototype.calculateDir = function () {
        var element = this.component.element;
        if (element && element.ownerDocument && element.ownerDocument.defaultView) {
            this.dirCalculated = element.ownerDocument.defaultView.getComputedStyle(element).direction || undefined;
        }
    };
    DropDownBase.prototype.calculatePopupWidth = function () {
        if (this.wrapper) {
            this.popupWidth = this.wrapper.offsetWidth + 'px';
        }
    };
    DropDownBase.prototype.scrollToItem = function (itemIndex, vsEnabled) {
        var list = this.list || this.vs.list;
        if (list && itemIndex >= 0) {
            var vs = this.vs;
            var scrollElement = (vs.container || list.parentNode);
            var virtualScroll = vsEnabled !== undefined ? vsEnabled : vs.enabled;
            scrollToItem(scrollElement, list, itemIndex, vs.translate, virtualScroll);
        }
    };
    DropDownBase.prototype.initState = function () {
        var state = {
            data: {},
            events: [],
            syntheticEvent: undefined
        };
        return state;
    };
    DropDownBase.prototype.applyState = function (state) {
        var _this = this;
        if (Object.keys(state.data).length > 0) {
            this.component.setState(state.data);
        }
        var eventArgs = {
            syntheticEvent: state.syntheticEvent,
            nativeEvent: state.syntheticEvent ? state.syntheticEvent.nativeEvent : undefined,
            target: this.component,
            value: this.component.value
        };
        state.events.forEach(function (eventData) {
            var type = eventData.type;
            delete eventData.type;
            var handler = type && _this.component.props[type];
            if (handler) {
                handler.call(undefined, __assign(__assign({}, eventArgs), eventData));
            }
        });
    };
    DropDownBase.prototype.triggerOnPageChange = function (state, skip, take) {
        var virtual = this.component.props.virtual;
        if (virtual) {
            var newSkip = Math.min(Math.max(0, skip), Math.max(0, virtual.total - take));
            if (newSkip !== virtual.skip) {
                state.events.push({
                    type: 'onPageChange',
                    page: { skip: newSkip, take: take }
                });
            }
        }
    };
    DropDownBase.prototype.triggerPageChangeCornerItems = function (item, state) {
        var props = this.component.props;
        var _a = props.data, data = _a === void 0 ? [] : _a, dataItemKey = props.dataItemKey, virtual = props.virtual;
        var opened = props.opened !== undefined ? props.opened : this.component.state.opened;
        if (item && virtual && this.vs.enabled) {
            if (virtual.skip > 0 && areSame(item, data[0], dataItemKey)) {
                this.triggerOnPageChange(state, virtual.skip - 1, virtual.pageSize);
            }
            else if (!opened && virtual.skip + virtual.pageSize < virtual.total &&
                areSame(item, data[data.length - 1], dataItemKey)) {
                this.triggerOnPageChange(state, virtual.skip + 1, virtual.pageSize);
            }
        }
    };
    DropDownBase.prototype.getPopupSettings = function () {
        return Object
            .assign({}, DropDownBase.defaultProps.popupSettings, this.component.props.popupSettings);
    };
    DropDownBase.basicPropTypes = {
        opened: PropTypes.bool,
        disabled: PropTypes.bool,
        dir: PropTypes.string,
        tabIndex: PropTypes.number,
        accessKey: PropTypes.string,
        data: PropTypes.array,
        textField: PropTypes.string,
        className: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
        popupSettings: PropTypes.shape({
            animate: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
                    openDuration: PropTypes.number,
                    closeDuration: PropTypes.number
                })]),
            popupClass: PropTypes.string,
            className: PropTypes.string,
            appendTo: PropTypes.any,
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        }),
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        itemRender: PropTypes.func,
        listNoDataRender: PropTypes.func,
        focusedItemIndex: PropTypes.func,
        header: PropTypes.node,
        footer: PropTypes.node
    };
    DropDownBase.propTypes = __assign(__assign({}, DropDownBase.basicPropTypes), { value: PropTypes.any, defaultValue: PropTypes.any, filterable: PropTypes.bool, filter: PropTypes.string, virtual: PropTypes.shape({
            pageSize: PropTypes.number.isRequired,
            skip: PropTypes.number.isRequired,
            total: PropTypes.number.isRequired
        }), onFilterChange: PropTypes.func, onPageChange: PropTypes.func });
    DropDownBase.defaultProps = {
        popupSettings: {
            height: '200px'
        },
        required: false,
        validityStyles: true
    };
    return DropDownBase;
}());
export default DropDownBase;
