import {
  Theme,
  createStyles,
  makeStyles,
  alpha,
} from '@material-ui/core/styles'
import { useState } from 'react'
import { Button, Snackbar } from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'
import * as React from 'react'
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid'
import { dataProvider } from '../providers/dataProvider'
import { useRefresh } from 'react-admin'

import { ICCPaymentAddProps, ICCPaymentEditProps, ICCTxnProps } from '../types'
import { centsToDollars } from '../helpers'
import { CCPaymentAdd } from './CCPaymentAdd'
import { CCPaymentEdit } from './CCPaymentEdit'
import { CustomToolbar } from './CustomToolbar'
import { Constants } from '../constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 15,
      '& .MuiDataGrid-row': {
        maxHeight: 'unset!important',
      },
      '& .MuiDataGrid-cell': {
        whiteSpace: 'normal!important',
        maxHeight: 'unset!important',
        height: 'unset!important',
      },
    },
  })
)

export const CCPaymentTable = (props: {
  payments: ICCTxnProps[]
  id: string
}) => {
  const classes = useStyles()

  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([])
  const refresh = useRefresh()
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const actionButton = () =>
    selectionModel.length > 0 ? (
      <Button
        onClick={() => setOpenEdit(true)}
        startIcon={<Edit />}
        variant="contained"
        color="primary"
      >
        Edit
      </Button>
    ) : (
      <Button
        onClick={() => setOpenAdd(true)}
        startIcon={<Add />}
        variant="contained"
        color="primary"
      >
        Add
      </Button>
    )

  const columns: GridColDef[] = [
    {
      field: 'bank',
      headerName: 'Bank name',
      width: 120,
    },
    {
      field: 'creditCardName',
      headerName: 'Credit card name',
      width: 150,
      sortable: false,
    },
    {
      field: 'creditCardNumber',
      headerName: 'Credit card number',
      width: 80,
      sortable: false,
    },
    {
      field: 'dueDate',
      headerName: 'Due date',
      width: 110,
      sortable: false,
    },
    {
      field: 'plannedDate',
      headerName: 'Planned payment date',
      width: 110,
      sortable: false,
    },
    {
      field: 'expectedPayment',
      headerName: 'Expected payment',
      width: 80,
      sortable: false,
    },
    {
      field: 'extraPlanned',
      headerName: 'Extra planned',
      width: 80,
      sortable: false,
    },
    {
      field: 'plannedAmount',
      headerName: 'Planned payment amount',
      width: 80,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      sortable: false,
    },
  ]

  const rows = props.payments.map((row, index) => ({
    id: index,
    bank: row.bankName,
    creditCardName: row.creditCardName,
    creditCardNumber: row.cardId,
    dueDate: row.dueDate,
    plannedDate: row.plannedPaymentDate,
    expectedPayment: centsToDollars(row.expectedPayment),
    extraPlanned: centsToDollars(row.extraPlanned),
    plannedAmount: centsToDollars(row.plannedPaymentAmount),
    status: row.status,
  }))

  const handleDelete = (data: { transactionId: number; comment: string }) => {
    const { transactionId, comment } = data
    setIsDeleting(true)
    dataProvider
      .update(Constants.CC_CANCEL, {
        id: props.id,
        data: {
          userId: parseInt(props.id),
          transactionId: transactionId,
          comment: comment,
        },
        previousData: { id: props.id },
      })
      .then((response) => {
        if (response.data.success) {
          refresh()
          setOpenEdit(false)
        } else {
          setError(response.data.error.message)
        }
        setIsDeleting(false)
      })
      .catch((e) => {
        setIsDeleting(false)
        setError(e.toString())
      })
  }

  const handleEdit = (data: ICCPaymentEditProps) => {
    setIsLoading(true)
    dataProvider
      .update(Constants.CC_ADJUST, {
        id: props.id,
        data: data,
        previousData: { id: props.id },
      })
      .then((response) => {
        if (response.data.success) {
          refresh()
          setOpenEdit(false)
        } else {
          setError(response.data.error.message)
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        setError(e.toString())
      })
  }

  const handleAdd = (data: ICCPaymentAddProps) => {
    setIsLoading(true)
    dataProvider
      .update(Constants.CC_CREATE, {
        id: props.id,
        data: data,
        previousData: { id: props.id },
      })
      .then((response) => {
        if (response.data.success) {
          refresh()
          setOpenAdd(false)
        } else {
          setError(response.data.error.message)
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        setError(e.toString())
      })
  }

  return (
    <>
      <div style={{ height: rows.length > 0 ? 420 : '100%', width: '100%' }}>
        <DataGrid
          components={{
            Toolbar: () =>
              CustomToolbar({
                title: 'CC transactions',
                action: actionButton(),
              }),
          }}
          hideFooterPagination={rows.length === 0}
          classes={{ root: classes.root }}
          density="compact"
          disableColumnMenu
          rows={rows}
          columns={columns}
          pageSize={5}
          disableColumnFilter
          onPageChange={() => setSelectionModel([])}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(
              newSelectionModel.toString() !== selectionModel.toString()
                ? newSelectionModel
                : []
            )
          }}
          selectionModel={selectionModel}
        />
      </div>
      <CCPaymentAdd
        id={props.id}
        isOpen={openAdd}
        setOpen={setOpenAdd}
        handleAdd={handleAdd}
        isLoading={isLoading}
      />
      {selectionModel.length > 0 && (
        <CCPaymentEdit
          id={props.id}
          isOpen={openEdit}
          setOpen={setOpenEdit}
          isLoading={isLoading}
          isDeleting={isDeleting}
          transaction={props.payments[Number(selectionModel[0])]}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
        />
      )}
      <Snackbar
        open={error.length > 0}
        message={error}
        onClose={() => setError('')}
      />
    </>
  )
}
