
import * as React from 'react';
import { createPortal } from 'react-dom';
import { canUseDOM } from '../../../../kendo-react-common';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * @hidden
 */
var DragClue = /** @class */ (function (_super) {
    __extends(DragClue, _super);
    function DragClue(props) {
        var _this = _super.call(this, props) || this;
        _this.elementRef = React.createRef();
        _this.state = {
            visible: false,
            top: 0,
            left: 0,
            innerText: '',
            status: 'k-i-cancel'
        };
        _this.hiddenElementRef = React.createRef();
        return _this;
    }
    Object.defineProperty(DragClue.prototype, "element", {
        get: function () {
            return this.elementRef.current;
        },
        enumerable: false,
        configurable: true
    });
    DragClue.prototype.render = function () {
        var hiddenElement = this.hiddenElementRef.current;
        var dragClue = this.state.visible && canUseDOM && createPortal((React.createElement("div", { ref: this.elementRef, className: "k-header k-drag-clue", style: {
                display: 'block',
                position: 'absolute',
                zIndex: 20000,
                padding: '8px 12px',
                top: this.state.top + 'px',
                left: this.state.left + 'px'
            } },
            React.createElement("span", { className: 'k-icon k-drag-status ' + this.state.status + ' k-icon-with-modifier' },
                React.createElement("span", { className: "k-icon k-icon-modifier" })),
            this.state.innerText)), (hiddenElement && hiddenElement.ownerDocument ?
            hiddenElement.ownerDocument.body :
            document.body));
        return (React.createElement(React.Fragment, null,
            dragClue,
            React.createElement("div", { ref: this.hiddenElementRef, style: { display: 'none' } })));
    };
    return DragClue;
}(React.PureComponent));
export { DragClue };
