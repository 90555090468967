import { Keys } from '../../../../kendo-react-common';
import { useTableKeyboardNavigation } from '../../../../kendo-react-data-tools';
import { useLocalization } from '../../../../kendo-react-intl';
import * as React from 'react';
import { messages, groupCollapse, groupExpand } from '../messages';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * @hidden
 */
export var GridGroupCell = function (props) {
    var defaultRendering = null;
    var columnIndex = props.columnIndex, level = props.level, columnsCount = props.columnsCount, rowType = props.rowType, dataItem = props.dataItem, field = props.field, onChange = props.onChange, expanded = props.expanded, render = props.render, dataIndex = props.dataIndex, ariaColumnIndex = props.ariaColumnIndex;
    var definedField = field || '';
    var navigationAttributes = useTableKeyboardNavigation(props.id);
    var localization = useLocalization();
    var onKeyDownHandler = React.useCallback(function (event) {
        if (event.isDefaultPrevented()) {
            return;
        }
        if (event.keyCode === Keys.enter && onChange) {
            event.preventDefault();
            onChange({
                dataItem: dataItem,
                dataIndex: dataIndex,
                syntheticEvent: event,
                field: undefined,
                value: !expanded
            });
        }
    }, [expanded, onChange, dataItem, dataIndex]);
    if (columnIndex === undefined || level === undefined || columnIndex < level ||
        columnsCount === undefined || rowType !== 'groupHeader' || dataItem[definedField] === undefined) {
        defaultRendering = (React.createElement("td", { style: props.style, key: 'g' + columnIndex, className: "k-group-cell", role: "presentation" }));
    }
    else if (columnIndex <= level) {
        var baseMessage = expanded ? groupCollapse : groupExpand;
        var message = localization.toLanguageString(baseMessage, messages[baseMessage]);
        defaultRendering = (React.createElement("td", __assign({ style: props.style, key: 'g-colspan', colSpan: columnsCount - columnIndex, role: 'gridcell', "aria-selected": false, "aria-expanded": expanded, "aria-colindex": ariaColumnIndex, onKeyDown: onKeyDownHandler }, navigationAttributes),
            React.createElement("p", { className: "k-reset" },
                React.createElement("a", { onClick: function (e) {
                        e.preventDefault();
                        if (onChange) {
                            onChange({
                                dataItem: dataItem,
                                dataIndex: dataIndex,
                                syntheticEvent: e,
                                field: undefined,
                                value: !expanded
                            });
                        }
                    }, href: "#", tabIndex: -1, "aria-label": message, className: expanded ? 'k-i-caret-alt-down k-icon' : 'k-i-caret-alt-right k-icon' }),
                ((dataItem[definedField] instanceof Date) && dataItem[definedField].toString) ?
                    dataItem[definedField].toString() :
                    dataItem[definedField])));
    }
    return render ?
        render.call(undefined, defaultRendering, props) :
        defaultRendering;
};
