
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, kendoThemeMaps, validatePackage } from '../../../kendo-react-common';
import { packageMetadata } from './package-metadata';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/**
 * @hidden
 */
function iconElement(_a) {
    var imageUrl = _a.imageUrl, icon = _a.icon, iconClass = _a.iconClass, imageAlt = _a.imageAlt;
    if (imageUrl) {
        return (React.createElement("img", { role: "presentation", className: 'k-button-icon', alt: imageAlt, src: imageUrl }));
    }
    else if (icon) {
        var iconClasses = classNames('k-button-icon k-icon', 'k-i-' + icon);
        return (React.createElement("span", { role: "presentation", className: iconClasses }));
    }
    else if (iconClass) {
        return (React.createElement("span", { role: "presentation", className: classNames('k-button-icon', iconClass) }));
    }
    return null;
}
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button(props) {
        var _this = _super.call(this, props) || this;
        _this._element = null;
        _this.handleClick = function (event) {
            _this.toggleIfApplicable();
            if (_this.props.onClick) {
                _this.props.onClick.call(undefined, event);
            }
        };
        validatePackage(packageMetadata);
        _this.state = { selected: _this.props.togglable === true && _this.props.selected === true };
        return _this;
    }
    Object.defineProperty(Button.prototype, "element", {
        /**
         * Gets the DOM element of the Button component.
         */
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "selected", {
        /**
         * Returns `true` when the component is togglable and selected ([see example]({% slug toggleable_button %})).
         * Otherwise, returns `false`.
         */
        get: function () {
            return this._selectedTemp !== undefined ? this._selectedTemp : this.state.selected;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    Button.getDerivedStateFromProps = function (props, state) {
        if (props.togglable && props.selected !== undefined && props.selected !== state.selected) {
            return { selected: props.selected };
        }
        return null;
    };
    /**
     * @hidden
     */
    Button.prototype.render = function () {
        var _a;
        var _this = this;
        var _b = this.props, children = _b.children, togglable = _b.togglable, icon = _b.icon, iconClass = _b.iconClass, imageUrl = _b.imageUrl, imageAlt = _b.imageAlt, className = _b.className, onClick = _b.onClick, _c = _b.size, size = _c === void 0 ? Button.defaultProps.size : _c, _d = _b.shape, shape = _d === void 0 ? Button.defaultProps.shape : _d, _e = _b.rounded, rounded = _e === void 0 ? Button.defaultProps.rounded : _e, _f = _b.fillMode, fillMode = _f === void 0 ? Button.defaultProps.fillMode : _f, _g = _b.themeColor, themeColor = _g === void 0 ? Button.defaultProps.themeColor : _g, htmlAttributes = __rest(_b, ["children", "togglable", "icon", "iconClass", "imageUrl", "imageAlt", "className", "onClick", "size", "shape", "rounded", "fillMode", "themeColor"]);
        var hasIcon = (icon !== undefined || iconClass !== undefined || imageUrl !== undefined);
        var hasChildren = children !== undefined;
        var iconEl = iconElement({
            icon: icon,
            iconClass: iconClass,
            imageUrl: imageUrl,
            imageAlt: imageAlt
        });
        return (React.createElement("button", __assign({ ref: function (button) { return _this._element = button; }, "aria-pressed": togglable ? this.state.selected : undefined }, htmlAttributes, { onClick: this.handleClick, className: classNames('k-button', (_a = {},
                _a["k-button-".concat(kendoThemeMaps.sizeMap[size] || size)] = size,
                _a["k-button-".concat(shape)] = shape,
                _a["k-button-".concat(fillMode)] = fillMode,
                _a["k-button-".concat(fillMode, "-").concat(themeColor)] = Boolean(fillMode && themeColor),
                _a["k-rounded-".concat(kendoThemeMaps.roundedMap[rounded] || rounded)] = rounded,
                _a['k-icon-button'] = !hasChildren && hasIcon,
                _a['k-disabled'] = this.props.disabled,
                _a['k-selected'] = this.state.selected,
                _a['k-rtl'] = this.props.dir === 'rtl',
                _a), this.props.className) }),
            iconEl,
            children && (React.createElement("span", { className: "k-button-text" }, children))));
    };
    Button.prototype.toggleIfApplicable = function () {
        var _this = this;
        if (this.props.togglable && this.props.selected === undefined) {
            var selected = !this.state.selected;
            this._selectedTemp = selected;
            this.setState({ selected: selected }, function () { return _this._selectedTemp = undefined; });
        }
    };
    /**
     * @hidden
     */
    Button.propTypes = {
        children: PropTypes.node,
        selected: PropTypes.bool,
        togglable: PropTypes.bool,
        icon: PropTypes.string,
        iconClass: PropTypes.string,
        imageUrl: PropTypes.string,
        imageAlt: PropTypes.string,
        size: PropTypes.oneOf([null, 'small', 'medium', 'large']),
        shape: PropTypes.oneOf([null, 'rectangle', 'square']),
        rounded: PropTypes.oneOf([null, 'small', 'medium', 'large', 'full']),
        fillMode: PropTypes.oneOf([null, 'flat', 'link', 'outline', 'solid']),
        // eslint-disable-next-line max-len
        themeColor: PropTypes.oneOf([null, 'base', 'primary', 'secondary', 'tertiary', 'info', 'success', 'warning', 'error', 'dark', 'light', 'inverse'])
    };
    /**
     * @hidden
     */
    Button.defaultProps = {
        togglable: false,
        size: 'medium',
        shape: null,
        rounded: 'medium',
        fillMode: 'solid',
        themeColor: 'base'
    };
    return Button;
}(React.Component));
export { Button };
