import * as React from 'react';
import Button from '@mui/material/Button';
import LinkIcon from '@mui/icons-material/Link';
import { Menu, MenuItem, MenuProps } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useInjection } from '../../../di/useInjection';
import { useNotify } from 'react-admin';
import { PdfPlace } from '../EquifaxReportsCollection';

const getS3Link = (userId: number, reportId: string) => {
  return `https://s3.console.aws.amazon.com/s3/object/placid-customers-private-documents-prod?region=us-east-2&prefix=equifax-reports/User-${userId}-report-${reportId}.pdf`
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export const RowActions: React.FC<Props> = ({
  record,
  onPdfOpen,
}) => {
  const { underwritingActions } = useInjection();
  const notify = useNotify();

  const { user_id: userId, report_id: reportId } = record;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const close = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(null);
  };

  const handleLinkReportClick = () => {
    window.open(getS3Link(userId, reportId), "_blank");
  }

  const handleOpenPdfClick = async (e: React.MouseEvent<HTMLElement>, pdfPlace: PdfPlace) => {
    e.preventDefault();
    e.stopPropagation();

    const { id } = record;


    const result = await underwritingActions.getEquifaxReportLink(id);

    if (typeof result === "string") {
      notify(result);
    } else {
      onPdfOpen(pdfPlace, result.url);
    }

    setAnchorEl(null);
  }

  return (
    <div>
      <Button
        size='small'
        color='inherit'
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}>
        Actions
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={close}>

        <div>
          <MenuItem onClick={handleLinkReportClick} disableRipple>
            <LinkIcon />
            To report
          </MenuItem>

          <MenuItem onClick={(e) => handleOpenPdfClick(e, PdfPlace.left)} disableRipple>
            <LinkIcon />
            Open pdf left
          </MenuItem>

          <MenuItem onClick={(e) => handleOpenPdfClick(e, PdfPlace.right)} disableRipple>
            <LinkIcon />
            Open pdf right
          </MenuItem>
        </div>

      </StyledMenu>

    </div>

  );
}

type Props = {
  record: { [index: string]: any }
  onPdfOpen: (pdfPlace: PdfPlace, src: string) => void
}