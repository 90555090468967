import { Box } from "@mui/material";
import React, { useMemo } from "react";
import {
  TextInput,
} from "react-admin"
import { Button } from 'react-admin';
import { BottomDrawer } from '../../../common/BottomDrawer';
import { Resource } from "../../../common/Resource";
import { ColumnModel } from "../../../model/ColumnModel";

export const resource = "admin/generic-views/plaid_identities";

export type PlaidIdentityDrawerPayload = {
  id: string
}

const tableColumns = [
  { field: "primary_name", width: "150px", title: "primary name", show: true },
  { field: "names", width: "150px", title: "names", show: true },
  { field: "email", width: "150px", title: "email", show: true },
  { field: "phone_number", width: "150px", title: "phone_number", show: true },
  { field: "address", width: "300px", title: "address", show: true },
  { field: "name", width: "150px", title: "linked bank", show: true },
  { field: "user_id", width: "150px", title: "user_id", show: true },
];

export const PlaidIdentityDrawer: React.FC<Props> = (props) => {
  const {
    payload,
    onClose,
  } = props;

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <BottomDrawer
      isShow={payload !== undefined}
      onClose={onClose}
      title="Plaid identities">

      {
        payload !== undefined && (
          <Box>
            <Resource
              resource={resource}
              initialFilters={{ id: { eq: payload.id } }}
              columnModel={columnModel}
              tableActions={
                <>
                  <Button label="close" onClick={onClose} />
                </>
              }
            />
          </Box>
        )
      }

    </BottomDrawer>
  );
}

type Props = {
  payload: PlaidIdentityDrawerPayload | undefined
  onClose: () => void
}