import React, { useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useInjection } from '../../di/useInjection';
import { ExperianResult, ExperianResultItem } from '../../network/UnderwritingActions';
import { PDFDisplay } from '../../common/PDFDisplay';

export const resource = "admin/generic-views/experian_reports";

export const ExperianReportsCollection: React.FC<Props> = ({
  userId,
}) => {
  const { underwritingActions } = useInjection();
  const [error, setError] = useState<{ code: string, message: string } | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [experian, setExperian] = useState<Array<ExperianResultItem & { user: string }>>([]);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const handleLoadExperianReports = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setError(null);
    setLoading(true);

    const experianResponse = await underwritingActions
      .getExperianReportLink(
        [userId].map(parseInt)
      );

    setLoading(false);


    if (!experianResponse.success) {
      setError(experianResponse.error);
      return;
    }

    const experianResult = experianResponse.result!;

    const _experian: Array<{ user: string } & ExperianResultItem> = [];

    for (const user in experianResult) {
      _experian.push({
        user,
        ...experianResult[user],
      });
    }

    setExperian(_experian);
  }

  const handleSetUrl = (url: string) => {
    if (pdfUrl === url) {
      setPdfUrl(null);
    } else {
      setPdfUrl(url);
    }
  }

  return (
    <Box>
      <Button variant="outlined" disabled={isLoading} onClick={handleLoadExperianReports}>
        Load experian reports
        {isLoading && <CircularProgress sx={{ ml: "20px" }} size={"15px"} color={"secondary"} />}
      </Button>

      {
        error !== null &&
        <Alert sx={{ mt: "10px", maxWidth: "600px" }} severity="error">{error.code}: {error.message}</Alert>
      }

      {
        experian.map((experian) => {
          return <ExperianItem onSetUrl={handleSetUrl} experian={experian} />
        })
      }

      <Box sx={{ maxWidth: "100%", mb: "20px", mt: "20px" }}>
        {
          pdfUrl !== null &&
          <Box sx={{ p: "releative" }}>
            <PDFDisplay src={pdfUrl} />
          </Box>
        }
      </Box>

    </Box>
  )
}

const ExperianItem: React.FC<ExperianItemProps> = ({ experian, onSetUrl }) => {
  const { user, error, success, result } = experian;

  return (<Box sx={{ mt: "20px", maxWidth: "600px" }}>
    <Typography variant="h6" gutterBottom>
      user: {user}
    </Typography>

    {
      success
        ? <ExperianPDF onClick={onSetUrl} url={result!.url} />
        : <ExperianError error={error!} />
    }

  </Box>
  )
}

const ExperianPDF: React.FC<ExperianProps> = ({ url, onClick }) => {
  const clickHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onClick(url);
  }

  return <Button onClick={clickHandler} size="small" variant="outlined">Open pdf</Button>
}

const ExperianError: React.FC<{ error?: { code: string, message: string } }> = ({ error }) => {
  if (error === undefined || error === null) {
    return <Alert severity='error'>
      Unknown error
    </Alert>
  }

  return (
    <Alert sx={{ maxWidth: "600px" }} severity='error'>
      {error.code}: {error.message}
    </Alert>
  );
}


type Props = {
  userId: string
}

type ExperianProps = {
  url: string
  onClick: (url: string) => void
}

type ExperianItemProps = {
  experian: { user: string } & ExperianResultItem
  onSetUrl: (url: string) => void
}