import { useState, useEffect } from 'react'
import 'date-fns'
import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { useRefresh } from 'react-admin'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core'
import { Delete } from '@material-ui/icons'

import { IPBTransactionEditProps, IPlacidBillTxnProps } from '../types'

const useStyles = makeStyles({
  field: {
    paddingBottom: 15,
  },
  actions: {
    justifyContent: 'space-between',
    marginRight: 10,
    marginLeft: 10,
  },
})

const schema = yup
  .object({
    amount: yup.number().min(0).required(),
    comment: yup.string().required(),
  })
  .required()

export const PBTransactionEdit = (props: {
  id: string
  isOpen: boolean
  transaction: IPlacidBillTxnProps
  setOpen: (v: boolean) => void
  isLoading: boolean
  isDeleting: boolean
  handleEdit: (v: IPBTransactionEditProps) => void
  handleDelete: (data: { transactionId: number; comment: string }) => void
}) => {
  const {
    id,
    isOpen,
    setOpen,
    transaction,
    isLoading,
    isDeleting,
    handleDelete,
    handleEdit,
  } = props

  const classes = useStyles()

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })

  const [scheduledDate, setScheduledDate] = useState<Date | null>(null)

  useEffect(() => {
    setScheduledDate(new Date(transaction.plannedPaymentDate))
  }, [transaction.plannedPaymentDate])

  const buildData = (): IPBTransactionEditProps => {
    const data: IPBTransactionEditProps = {
      userId: parseInt(id),
      transactionId: transaction.txnId,
      source: 'Depository',
      scheduledDate: format(scheduledDate!, 'yyyy-MM-dd'),
      comment: watch('comment'),
      amount: parseInt(watch('amount')),
      fromAccount: { tag: 'ManualAccount' },
    }

    return data
  }

  const handleClose = () => {
    setScheduledDate(new Date(transaction.plannedPaymentDate))
    clearErrors()
    setOpen(false)
  }

  const isTransactionStatusWrong = () => transaction.status === 'Canceled'

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Scheduled date"
              format="yyyy/MM/dd"
              value={scheduledDate}
              onChange={setScheduledDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <TextField
            error={errors.amount !== undefined}
            helperText={errors.amount?.message}
            className={classes.field}
            label="Amount"
            type="number"
            fullWidth
            defaultValue={transaction.plannedPaymentAmount}
            {...register('amount')}
          />
          <TextField
            error={errors.comment !== undefined}
            helperText={errors.comment?.message}
            label="comment"
            type="text"
            fullWidth
            className={classes.field}
            {...register('comment')}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleSubmit(() =>
              handleDelete({
                comment: watch('comment'),
                transactionId: transaction.txnId,
              })
            )}
            variant="outlined"
            startIcon={isDeleting ? <CircularProgress size={14} color='inherit' /> : <Delete />}
            disabled={isLoading || isDeleting|| isTransactionStatusWrong()}
          >
            Delete
          </Button>
          <div>
            <Button />
            <Button
              onClick={handleClose}
              color="primary"
              variant="outlined"
              disabled={isLoading || isDeleting}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(() => handleEdit(buildData()))}
              color="primary"
              variant="contained"
              style={{ marginLeft: 15 }}
              startIcon={isLoading && <CircularProgress size={14} color='inherit' />}
              disabled={
                isLoading ||
                isDeleting ||
                scheduledDate === null ||
                isTransactionStatusWrong()
              }
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}
