import {
  Alert,
  Card,
  Button as MuiButton,
  TextField,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { BTLimits } from "../../../network/BTActions";
import { LimitsCard } from "../../client-collection/widget/LimitsCard";
import { useInjection } from "../../../di/useInjection";
import currency from "currency.js";

export const CalculateLimits: FC<Props> = ({
  onCalc
}) => {
  const { btActions } = useInjection();

  const [calcLimitError, setCalcLimitError] = useState<string | null>(null);
  const [baseLimit, setBaseLimit] = useState<string>("");
  const [approvedLimit, setApprovedLimit] = useState<string>("");
  const [maxApprovedLimit, setMaxApprovedLimit] = useState<string>("");
  const [calcLimits, setCalcLimits] = useState<BTLimits | null>(null);

  useEffect(() => {
    setApprovedLimit("");
    setBaseLimit("");
    setMaxApprovedLimit("");
    setCalcLimits(null);
    setCalcLimitError(null);
  }, []);

  const onBaseLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setBaseLimit(value);
  }

  const onApprovedLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setApprovedLimit(value);
  }

  const onMaxApprovedLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMaxApprovedLimit(value);
  }

  const calculateHandleClick = async () => {
    if (baseLimit.length === 0 && approvedLimit.length === 0) return;

    const payload = baseLimit.length !== 0 ? {
      baseLimit: currency(baseLimit).intValue,
    } : {
      approvedLimit: currency(approvedLimit).intValue,
    }

    const limitsResponse = await btActions.calcUserLimitsDetails(payload);

    const { error, result } = limitsResponse;

    if (error !== null && error !== undefined) {
      setCalcLimitError(limitsResponse.error.message);
      return;
    }

    if (result !== null && result !== undefined) {
      setCalcLimits(result as BTLimits);
      const baseLimit = currency((result as BTLimits).baseLimit, { fromCents: true }).value.toString()
      onCalc(baseLimit);
    }
  }

  return (<>
    <TextField
      fullWidth
      type="number"
      label="Base Limit (dollars)"
      value={baseLimit}
      onChange={onBaseLimitChange}
      onFocus={() => setApprovedLimit("")}
      variant="outlined"
    />

    <TextField
      fullWidth
      type="number"
      label="Approved Limit (dollars)"
      value={approvedLimit}
      onChange={onApprovedLimitChange}
      onFocus={() => setBaseLimit("")}
      variant="outlined"
    />

    <TextField
      fullWidth
      type="number"
      label="Max Approved Limit (dollars)"
      value={maxApprovedLimit}
      onChange={onMaxApprovedLimitChange}
      variant="outlined"
    />

    <MuiButton
      sx={{ marginTop: "10px" }}
      onClick={calculateHandleClick}
      size="medium">Calculate</MuiButton>

    <Card sx={{ padding: "20px" }}>
      <h4>Limits</h4>

      {calcLimitError !== null && <Alert severity="info">{calcLimitError}</Alert>}

      {calcLimitError === null
        && calcLimits !== null
        && <LimitsCard currentLimits={calcLimits} />
      }
    </Card>
  </>);
}

type Props = {
  onCalc: (bl: string) => void
}
