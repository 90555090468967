import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Button } from 'react-admin';

export const LinkToUnderwritingUser = () => {
  const [userId, setUserId] = useState<string | undefined>(undefined);

  const changeInputHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setUserId(e.target.value);
  }

  useEffect(() => {
    const input = document.getElementById('id.eq') as HTMLInputElement;

    const userId = input.value;
    if (userId !== undefined) {
      setUserId(userId);
    }

    input?.addEventListener("input", changeInputHandler);

    return () => input?.removeEventListener("input", changeInputHandler);
  }, []);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    window.open(`/dash/underwriting-user/${userId}/`);
  }

  return (
    <Box sx={{ height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>

      <Button
        label={`to user underwriting ${userId}`}
        onClick={handleClick}
      />

    </Box>
  )
}