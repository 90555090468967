import { FC, useCallback, useEffect, useState } from 'react';
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { PostError } from '../../../network/AdminRequest';
import { useInjection } from '../../../di/useInjection';
import { PrimaryStatus, UpdatePrimaryStatusPayload } from '../../../network/UnderwritingActions';

export type AddACHAccountDialogPayload = {
  plaidAccountId: string
  userId: number
}

export const AddACHAccountDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { underwritingActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [accountNumber, setAccountNumber] = useState<string>("");
  const [routingNumber, setRoutingNumber] = useState<string>("");
  const [error, setError] = useState<PostError>(null);

  useEffect(() => {
    setAccountNumber("")
    setError(null);
  }, [payload]);

  const handleUpdateAccountNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountNumber(e.target.value);
  };

  const handleUpdateRoutingNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoutingNumber(e.target.value);
  };

  const handleSendClick = async () => {
    if (payload === null) return;

    const {
      plaidAccountId,
      userId,
    } = payload;

    const error = await underwritingActions.updateACHAccountAndRoutingNumber({
      accountNumber,
      routingNumber,
      plaidAccountId,
      userId,
    });

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("updated");
    }
  }


  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title="Update account and routing number"
      onSend={handleSendClick}
      actionTitle="Update">

      <TextField
        fullWidth
        type="text"
        label="account number"
        value={accountNumber}
        onChange={handleUpdateAccountNumber}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="text"
        label="routing number"
        value={routingNumber}
        onChange={handleUpdateRoutingNumber}
        variant="outlined"
      />

      {
        error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>
      }

    </SimpleDialog>
  );
}

type Props = {
  payload: AddACHAccountDialogPayload | null;
  hideDialog: () => void;
}