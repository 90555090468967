import { createStyles, makeStyles, alpha } from '@material-ui/core/styles'
import { Box, Grid, Paper } from '@material-ui/core'

import { UserInfo } from './UserInfo'
import { CCPaymentTable } from './CCPaymentTable'
import { UnderwritingsTable } from './UnderwritingsTable'
import { PlacidChargesTable } from './PlacidChargesTable'
import { StatementsTable } from './StatementsTable'
import { ICreditLineProps } from '../types'

const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      fontSize: 20,
      fontWeight: 600,
      paddingLeft: 15,
    },
    details: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    section: {
      marginBottom: 20,
    },
  })
)

export const CreditLineOverview = (props: { user: ICreditLineProps }) => {
  const {
    overviewUserInfo: userInfo,
    overviewCreditCardTxns,
    overviewUnderwritings,
    overviewPlacidBillTxns,
    overviewSingleBillCycles,
    id,
  } = props.user
  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Paper className={classes.section} variant="outlined">
          <UserInfo userInfo={userInfo} id={id} />
        </Paper>
        <Box className={classes.section}>
          <CCPaymentTable payments={overviewCreditCardTxns} id={id} />
        </Box>

        <Box className={classes.section}>
          <StatementsTable statements={overviewSingleBillCycles} />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={classes.section}>
          <PlacidChargesTable
            charges={overviewPlacidBillTxns}
            id={id}
            billingCycleId={
              overviewSingleBillCycles.length > 0
                ? overviewSingleBillCycles[0].billingCycle
                : 0
            }
          />
        </Box>
        <Box className={classes.section}>
          <UnderwritingsTable payments={overviewUnderwritings} id={id} />
        </Box>
      </Grid>
    </Grid>
  )
}
