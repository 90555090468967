
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { PostError } from '../../../network/AdminRequest';
import { useInjection } from '../../../di/useInjection';
import { BillingCycle } from '../../../types/billingCycles';
import { DepositoryAccount } from '../../../types/accountsOverview';
import currency from 'currency.js';

export type CreateBtTransactionDialogPayload = {
  userId: string
  billingCycles: BillingCycle[]
  depositoryAccounts: Array<DepositoryAccount>
  // isLocked: boolean
} | null;

export type FromAccountTag = "DepositoryAccount" | "ManualAccount";

export const CreateBtTransactionDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { debtActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [scheduledDate, setScheduledDate] = useState<string>(Date.now().toString());
  const [transactionAmount, setTransactionAmount] = useState<string>("");
  const [fromAccountTag, setFromAccountTag] = useState<FromAccountTag>("ManualAccount");
  const [billingCycleId, setBillingCycleId] = useState<number | undefined>(undefined);
  const [fromAccountContent, setFromAccountContent] = useState<string>("");
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [error, setError] = useState<PostError>(null);

  useEffect(() => {
    setScheduledDate(Date.now().toString());
    setTransactionAmount("");
    setFromAccountTag("ManualAccount");
    setBillingCycleId(undefined);
    setError(null);
    setIsLocked(false);
  }, [payload]);

  const handleScheduledDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setScheduledDate(value);
  }

  const handleTransactionAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTransactionAmount(value);
  }

  const handleFromAccountTagChange = useCallback((e: SelectChangeEvent<FromAccountTag>) => {
    setFromAccountTag(e.target.value as FromAccountTag);
  }, [fromAccountTag]);

  const handleBillingCycleChange = (e: SelectChangeEvent<number>) => {
    setBillingCycleId(e.target.value as number);
  };

  const handleFromAccountContentChange = useCallback((e: SelectChangeEvent<string>) => {
    setFromAccountContent(e.target.value);
  }, [fromAccountContent]);

  const handleIsLockedChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    setIsLocked(isSelect);
  }, []);

  const handleSendClick = async () => {
    if (payload === null) return;

    const { userId } = payload;

    const fromAccount = fromAccountTag === "DepositoryAccount" ? {
      tag: "DepositoryAccount",
      contents: parseInt(fromAccountContent),
    } : { tag: "ManualAccount" }

    const sendPayload = {
      userId,
      billingCycleId,
      amount: currency(transactionAmount).intValue,
      fromAccount,
      scheduledDate: scheduledDate,
      comment: "updated by test admin",
      isLocked,
    }

    const error = await debtActions.createPBTransaction(sendPayload);

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("created");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title="Create transaction"
      onSend={handleSendClick}
      actionTitle="Create">

      <TextField
        fullWidth
        type="date"
        label="Transaction date"
        value={scheduledDate}
        onChange={handleScheduledDateChange}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="number"
        label="Transaction amount (Dollars)"
        value={transactionAmount}
        onChange={handleTransactionAmountChange}
        variant="outlined"
      />

      <FormControl fullWidth>
        <InputLabel id="method-select-label">Method</InputLabel>
        <Select
          autoFocus={false}
          onFocus={() => { }}
          labelId="method-select-label"
          id="demo-simple-select"
          value={fromAccountTag}
          label="Method"
          onChange={handleFromAccountTagChange}>
          <MenuItem key={"DepositoryAccount"} value={"DepositoryAccount"}>DepositoryAccount</MenuItem>
          <MenuItem key={"ManualAccount"} value={"ManualAccount"}>ManualAccount</MenuItem>
        </Select>
      </FormControl>

      {
        fromAccountTag === "DepositoryAccount" &&
        <FormControl fullWidth>
          <InputLabel id="account-id-select-label">From account id</InputLabel>
          <Select
            autoFocus={false}
            onFocus={() => { }}
            labelId="account-id-select-label"
            id="demo-simple-select"
            value={fromAccountContent}
            label="Account id"
            onChange={handleFromAccountContentChange}>
            {payload?.depositoryAccounts.map(({ depositoryId, bankName, mask }) =>
              <MenuItem key={depositoryId} value={depositoryId.toString()}>{bankName} : *{mask} - {depositoryId}</MenuItem>
            )}
          </Select>
        </FormControl>
      }

      <FormControl fullWidth>
        <InputLabel id="method-select-label">Billing Cycle</InputLabel>
        <Select
          autoFocus={false}
          onFocus={() => { }}
          labelId="method-select-label"
          id="demo-simple-select"
          value={billingCycleId}
          label="Billing Cycle"
          onChange={handleBillingCycleChange}>
          {
            payload?.billingCycles.map(({ single_bill_cycle_id, active_from, active_to }) =>
              <MenuItem value={single_bill_cycle_id}>{single_bill_cycle_id}: {active_from} - {active_to}</MenuItem>
            )
          }
        </Select>
      </FormControl>

      <FormControlLabel
        control={<Checkbox checked={isLocked} onChange={handleIsLockedChange} />}
        label="is locked" />

      {
        error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>
      }

    </SimpleDialog>
  );
}

type Props = {
  payload: CreateBtTransactionDialogPayload;
  hideDialog: () => void;
}