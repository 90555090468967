import * as React from 'react';
import { classNames } from '../../../../kendo-react-common';
import { getItemValue } from './utils';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var preventDefault = function (e) { return e.preventDefault(); };
/**
 * @hidden
 */
var ListDefaultItem = /** @class */ (function (_super) {
    __extends(ListDefaultItem, _super);
    function ListDefaultItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ListDefaultItem.prototype.render = function () {
        var _a = this.props, selected = _a.selected, defaultItem = _a.defaultItem, textField = _a.textField;
        return (React.createElement("div", { onClick: this.props.onClick, onMouseDown: preventDefault, style: { position: 'unset' }, className: classNames('k-list-optionlabel', {
                'k-selected': selected
            }) }, getItemValue(defaultItem, textField) || ''));
    };
    return ListDefaultItem;
}(React.Component));
export default ListDefaultItem;
