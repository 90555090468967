import { zipWith } from 'lodash';
import { makeObservable, observable, action } from 'mobx';
import { SortDescriptor } from '../lib/kendo-data-query/dist/npm/sort-descriptor';

export type Column = {
  show?: boolean
  field: string
  title: string
  width: string | number | undefined
  orderIndex?: number
  format?: string
  isCents?: boolean
  sorts?: SortDescriptor[]
  complex?: string[]
  complexJoinString?: string
  showTotal?: boolean
  calcTotal?: string
}

type ResizeEvent = {
  newWidth?: number,
  targetColumnId?:
  string, columns: { id?: string }[]
}

type ReorderEvent = { orderIndex?: number }[]
export class ColumnModel {
  constructor(
    public resource: string,
    public columns: Column[],
  ) {
    makeObservable(this, {
      columns: observable,
      getFromCache: action,
      updateShow: action,
      updateColumns: action,
      reorder: action,
    });

    this.getFromCache();
  }

  public get columnPersistId() {
    return `column.${this.resource}`;
  }

  getFromCache() {
    const persistedColumns = localStorage.getItem(this.columnPersistId);
    if (persistedColumns !== null) {
      this.columns = JSON.parse(persistedColumns);
    }
  }

  updateShow(field: string, state: boolean) {
    const columnIndex = this.columns.map((c) => c.field).indexOf(field);

    if (columnIndex >= 0) {
      this.columns[columnIndex].show = state;
      this.persistColumns();
    }
  }

  updateColumns(newColumns: Column[]) {
    this.columns = newColumns;
    this.persistColumns();
  }

  resize(event: ResizeEvent
  ) {
    const columnIndex = event.columns.map((c) => c.id).indexOf(event.targetColumnId ?? "");

    const showedColumns = this.columns.filter((c) => c.show);
    const hiddenColumns = this.columns.filter((c) => !c.show);

    showedColumns[columnIndex].width = `${event.newWidth}px`;

    this.columns = [...showedColumns, ...hiddenColumns]

    this.persistColumns();
  }

  reorder(eventColumns: ReorderEvent) {
    const showedColumns = this.columns.filter((c) => c.show);
    const hiddenColumns = this.columns.filter((c) => !c.show);

    const newShowedColumns = zipWith(
      eventColumns,
      showedColumns,
      (currentColumn, initColumn) => ({
        ...initColumn,
        orderIndex: currentColumn.orderIndex
      })
    );

    this.columns = [...newShowedColumns, ...hiddenColumns]
    this.persistColumns();
  }

  private persistColumns() {
    localStorage.setItem(this.columnPersistId, JSON.stringify(this.columns));
  }
}