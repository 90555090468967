import { FC, useEffect, useState } from 'react';
import {
  Alert,
  TextField,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { PostError } from '../../../network/AdminRequest';
import { useInjection } from '../../../di/useInjection';

export const AddACHAccountDialog: FC<Props> = ({
  isShow,
  hideDialog,
}) => {
  const { underwritingActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [accountNumber, setAccountNumber] = useState<string>("");
  const [routingNumber, setRoutingNumber] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [plaidAccountId, setPlaidAccountId] = useState<string>("");
  const [error, setError] = useState<PostError>(null);

  useEffect(() => {
    setAccountNumber("")
    setUserId("")
    setPlaidAccountId("")
    setRoutingNumber("")
    setError(null);
  }, [isShow]);

  const handleUpdateUserId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserId(e.target.value);
  };

  const handleUpdatePlaidAccountId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlaidAccountId(e.target.value);
  };

  const handleUpdateAccountNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountNumber(e.target.value);
  };

  const handleUpdateRoutingNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoutingNumber(e.target.value);
  };

  const handleSendClick = async () => {
    try {
      const error = await underwritingActions.updateACHAccountAndRoutingNumber({
        accountNumber,
        routingNumber,
        plaidAccountId,
        userId: parseInt(userId),
      });

      setError(error);

      if (error === null) {
        hideDialog();
        refresh();
        notify("updated");
      }
    } catch (e) {
      if (e instanceof Error) {
        notify(e.message);
      } else {
        notify("Something went wrong");
      }
    }

  }


  return (
    <SimpleDialog
      isShow={isShow}
      onClose={hideDialog}
      title="Update account and routing number"
      onSend={handleSendClick}
      actionTitle="Update">

      <TextField
        fullWidth
        type="text"
        label="user id"
        value={userId}
        onChange={handleUpdateUserId}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="text"
        label="plaid account id"
        value={plaidAccountId}
        onChange={handleUpdatePlaidAccountId}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="text"
        label="account number"
        value={accountNumber}
        onChange={handleUpdateAccountNumber}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="text"
        label="routing number"
        value={routingNumber}
        onChange={handleUpdateRoutingNumber}
        variant="outlined"
      />

      {
        error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>
      }

    </SimpleDialog>
  );
}

type Props = {
  isShow: boolean;
  hideDialog: () => void;
}