import { reduce } from "lodash";

export const getFiltersFromURI = (): { [index:string]: any} => {
    const hash = decodeURIComponent(window.location.hash)
    if (hash.length === 0) return {};

    const uri = hash.split('?')[1];
    if (uri === undefined || uri.length === 0) return {};

    const filterKeys = ['filter'];
    const filtersArray = uri
      .split('&')
      .map((v) => v.split('='))
      .filter(([key]) => filterKeys.includes(key));

    const { filter } = reduce(filtersArray, (acc: {[index:string]: any}, [key, value]) => {
      acc[key] = JSON.parse(value); 
      return acc;
    }, {});

    return filter;
}


export const wait = (time: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  })
}
