import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { ResourceVertical } from '../../common/ResourceVertical';

export const resource = "admin/generic-views/twilio";

const tableColumns = [
  { field: "user_id", width: "80px", title: "user_id", show: true },
  { field: "names", width: "80px", title: "names", show: true },
  { field: "phone", width: "150px", title: "phone", show: true },
  { field: "alternate_phones", width: "150px", title: "alternate_phones", show: true },
  { field: "current_addresses", width: "300px", title: "current_addresses", show: true },
  { field: "historical_addresses", width: "300px", title: "historical_addresses", show: true },
];

export const TwilioCollection: React.FC<Props> = ({
  initialFilters,
}) => {
  return (
    <Box sx={{ position: "relative" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`Twilio: ${initialFilters?.user_id.eq}`}
        </h4>
      </Box>

      <ResourceVertical
        initialFilters={initialFilters}
        resource={resource}
        // filters={filters}
        disableUrlFilterListener={false}
        columnModel={tableColumns}
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
}