import React, { useMemo, } from 'react';
import { Box } from '@mui/material';
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { DateInput, FilterButton, SelectInput, TextInput } from 'react-admin';
import { DollarInput } from '../../common/DollarInput';
import { RowActions } from './widget/RowActions';
import { RowClickEvent } from '../../common/Table';

export const resource = "admin/generic-views/consolidated_report_history";

const tableColumns = [
  { field: "id", width: "80px", title: "id", show: true },
  { field: "user_id", width: "80px", title: "uid", show: true },
  { field: "created_at", width: "100px", title: "cr_at", show: true, format: "yyyy-MM-dd" },
  { field: "updated_at", width: "100px", title: "upd_at", show: true, format: "yyyy-MM-dd" },
  { field: "underwriting_id", width: "100px", title: "uw_id", show: true },

  { field: "equifax_1_24_hist", width: "170px", title: "eq_1_24_hist", show: true },
  { field: "equifax_account_user_type", width: "140px", title: "eq_acc_u_type", show: true },
  { field: "equifax_actual_payment_amount", width: "160px", title: "eq_payment_amount", show: true, isCents: true },
  { field: "equifax_attention_hist", width: "150px", title: "eq_attention_hist", show: true },
  { field: "equifax_balance", width: "100px", title: "eq_balance", show: true, isCents: true },
  { field: "equifax_duplicates", width: "150px", title: "eq_duplicates", show: true },
  { field: "equifax_is_revolving", width: "150px", title: "eq_is_revolving", show: true },
  { field: "equifax_limit", width: "120px", title: "eq_limit", show: true, isCents: true },
  { field: "equifax_monthly_payment_amount", width: "200px", title: "eq_month_payment_amount", show: true, isCents: true },
  { field: "equifax_name", width: "150px", title: "eq_name", show: true },
  { field: "equifax_number", width: "140px", title: "eq_number", show: true },
  { field: "equifax_open_closed", width: "150px", title: "eq_open_closed", show: true },
  { field: "equifax_open_dt", width: "120px", title: "eq_open_dt", show: true, format: "yyyy-MM-dd" },
  { field: "equifax_open_high", width: "140px", title: "eq_open_high", show: true },
  { field: "equifax_report_id", width: "120px", title: "eq_rep_id", show: true },
  { field: "equifax_reviewed_months", width: "160px", title: "eq_rev-d_months", show: true },
  { field: "equifax_trade_id", width: "160px", title: "eq_trade_id", show: true },
  { field: "equifax_type", width: "160px", title: "eq_type", show: true },

  { field: "experian_1_24_hist", width: "160px", title: "ex_1_24_hist", show: true },
  { field: "experian_account_user_type", width: "160px", title: "ex_acc_u_type", show: true },
  { field: "experian_actual_payment_amount", width: "160px", title: "ex_payment_amount", show: true, isCents: true },
  { field: "experian_attention_hist", width: "150px", title: "ex_attention_hist", show: true },
  { field: "experian_balance", width: "100px", title: "ex_balance", show: true, isCents: true },
  { field: "experian_duplicates", width: "150px", title: "ex_duplicates", show: true },
  { field: "experian_is_revolving", width: "150px", title: "ex_is_revolving", show: true },
  { field: "experian_limit", width: "120px", title: "ex_limit", show: true, isCents: true },
  { field: "experian_monthly_payment_amount", width: "200px", title: "ex_month_payment_amount", show: true, isCents: true },
  { field: "experian_name", width: "150px", title: "ex_name", show: true },
  { field: "experian_number", width: "140px", title: "ex_number", show: true },
  { field: "experian_open_closed", width: "150px", title: "ex_open_closed", show: true },
  { field: "experian_open_dt", width: "120px", title: "ex_open_dt", show: true, format: "yyyy-MM-dd" },
  { field: "experian_open_high", width: "140px", title: "ex_open_high", show: true },
  { field: "experian_report_id", width: "120px", title: "ex_rep_id", show: true },
  { field: "experian_reviewed_months", width: "160px", title: "ex_rev-d_months", show: true },
  { field: "experian_trade_id", width: "160px", title: "ex_trade_id", show: true },
  { field: "experian_type", width: "160px", title: "ex_type", show: true },


  { field: "parsed", width: "900px", title: "parsed", show: true },

  // { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="id" source="id.eq" alwaysOn />,

  <DateInput label="created_at gt" source="created_at.gt" />,
  <DateInput label="created_at lt" source="created_at.lt" />,

  <DateInput label="updated_at gt" source="updated_at.gt" />,
  <DateInput label="updated_at lt" source="updated_at.lt" />,



  <TextInput label="underwriting_id" source="underwriting_id.like" />,

  <TextInput label="equifax_account_user_type" source="equifax_account_user_type.eq" />,

  <TextInput label="equifax_1_24_hist" source="equifax_1_24_hist.eq" />,
  <TextInput label="equifax_attention_hist" source="equifax_attention_hist.like" />,

  <DollarInput label="equifax_actual_payment_amount gt (dollar)" source="equifax_actual_payment_amount.gt" />,
  <DollarInput label="equifax_actual_payment_amount lt (dollar)" source="equifax_actual_payment_amount.lt" />,

  <DollarInput label="equifax_balance gt (dollar)" source="equifax_balance.gt" />,
  <DollarInput label="equifax_balance lt (dollar)" source="equifax_balance.lt" />,

  <SelectInput source="equifax_is_revolving" choices={[
    { id: 'I', name: 'I' },
    { id: 'R', name: 'R' },
  ]} />,

  <TextInput label="equifax_limit" source="equifax_limit.like" />,

  <DollarInput label="equifax_limit gt (dollar)" source="equifax_limit.gt" />,
  <DollarInput label="equifax_limit lt (dollar)" source="equifax_limit.lt" />,

  <TextInput label="equifax_name" source="equifax_name.like" />,
  <TextInput label="equifax_number" source="equifax_number.like" />,

  <TextInput label="equifax_open_closed" source="equifax_open_closed.like" />,

  <DateInput label="equifax_open_dt gt" source="equifax_open_dt.gt" />,
  <DateInput label="equifax_open_dt lt" source="equifax_open_dt.lt" />,

  <TextInput label="equifax_open_high" source="equifax_open_high.like" />,
  <TextInput label="equifax_report_id" source="equifax_report_id.like" />,
  <TextInput label="equifax_reviewed_months" source="equifax_reviewed_months.like" />,
  <TextInput label="equifax_trade_id" source="equifax_trade_id.like" />,
  <TextInput label="equifax_type" source="equifax_type.like" />,

  <DollarInput label="experian_actual_payment_amount gt (dollar)" source="experian_actual_payment_amount.gt" />,
  <DollarInput label="experian_actual_payment_amount lt (dollar)" source="experian_actual_payment_amount.lt" />,

  <DollarInput label="experian_balance gt (dollar)" source="experian_balance.gt" />,
  <DollarInput label="experian_balance lt (dollar)" source="experian_balance.lt" />,

  <SelectInput source="experian_is_revolving" choices={[
    { id: 'I', name: 'I' },
    { id: 'R', name: 'R' },
  ]} />,

  <TextInput label="experian_limit" source="experian_limit.like" />,

  <DollarInput label="experian_limit gt (dollar)" source="experian_limit.gt" />,
  <DollarInput label="experian_limit lt (dollar)" source="experian_limit.lt" />,

  <TextInput label="experian_name" source="experian_name.like" />,
  <TextInput label="experian_number" source="experian_number.like" />,

  <TextInput label="experian_open_closed" source="experian_open_closed.like" />,

  <DateInput label="experian_open_dt gt" source="experian_open_dt.gt" />,
  <DateInput label="experian_open_dt lt" source="experian_open_dt.lt" />,

  <TextInput label="experian_open_high" source="experian_open_high.like" />,
  <TextInput label="experian_report_id" source="experian_report_id.like" />,
  <TextInput label="experian_reviewed_months" source="experian_reviewed_months.like" />,
  <TextInput label="experian_trade_id" source="experian_trade_id.like" />,
  <TextInput label="experian_type" source="experian_type.like" />,


];

export const ConsolidatedReportHistoryCollection: React.FC<Props> = (props) => {
  const {
    initialFilters,
    rowPerPageOptions = [25, 50, 100],
    disableUrlFilterListener = false,
    onRowClick = (_) => { },
    title = "Consolidated report history collection"
  } = props;

  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, []);

  const handleRowClick = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    const { user_id: userId } = dataItem;

    if (userId !== undefined) {
      onRowClick(userId);
    } else {
      throw Error('no user_id in row click event');
    }
  }

  const customValueFormat = {
    "parsed": (data?: any) => {
      return JSON.stringify(data);
    },
  }

  return (
    <Box sx={{ position: "relative", paddingTop: "60px" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px"
      }}>
        <h4>
          {title}
        </h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        disableUrlFilterListener={disableUrlFilterListener}
        columnModel={columnModel}
        rowPerPageOptions={rowPerPageOptions}
        filters={filters}
        rowActionBuilder={rowActionBuilder}
        onRowClick={handleRowClick}
        customValueFormat={customValueFormat}
        tableActions={
          <>
            <FilterButton {...props} />
          </>
        }
      />

    </Box>
  )
}

type Props = {
  title?: string
  initialFilters?: { [index: string]: any }
  onRowClick?: (userId: string) => void
  rowPerPageOptions?: number[]
  disableUrlFilterListener?: boolean
}