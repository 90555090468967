
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useInjection } from '../../../di/useInjection';
import { PostError } from '../../../network/AdminRequest';
import { Button, useNotify, useRefresh } from 'react-admin';
import currency from 'currency.js';

export type CreateManualPbTnxDialogPayload = {
  userId: number
} | undefined;

export const CreateManualPbTnxDialogButton: FC<{ userId: number }> = ({ userId }) => {

  const [dialogPayload, setDialogPayload] = useState<CreateManualPbTnxDialogPayload | undefined>();

  const handleDialogOpen = () => {
    setDialogPayload({
      userId,
    });
  }

  return (
    <>
      <CreateManualPbTnxDialog payload={dialogPayload} hideDialog={() => setDialogPayload(undefined)} />
      <Button label={"Create PB transaction"} onClick={handleDialogOpen} />
    </>
  );
};

export const CreateManualPbTnxDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { repaymentsActions } = useInjection();
  const notify = useNotify();
  const refresh = useRefresh();

  const [isTrackInLedger, setTrackInLedger] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>("");
  const [comment, setComment] = useState<string>("");

  const [error, setError] = useState<PostError>(null);

  useEffect(() => {
    if (payload !== undefined) {
      setComment("");
      setAmount("")
      setTrackInLedger(false);
      setError(null);
    }
  }, [payload]);

  const handleSendClick = async () => {
    if (payload === undefined) return;

    const { userId } = payload;

    const amountCents = currency(amount).intValue;

    const error = await repaymentsActions.tnxCreateManual({
      userId,
      comment,
      amount: amountCents,
      trackInLedger: isTrackInLedger,
    });

    if (error === null) {
      notify("Done");
      hideDialog();
      refresh();
    } else {
      setError(error);
    }
  }

  const handleTrackInLedgerChange = (e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    e.preventDefault();
    setTrackInLedger(isSelect);
  }

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setComment(value);
  }

  const handleAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAmount(value);
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Create manual pb transaction"
      onSend={handleSendClick}
      actionTitle="Create">

      <TextField
        fullWidth
        id="amount"
        label="amount (Dollars)"
        type="text"
        defaultValue={amount}
        value={amount}
        InputLabelProps={{ shrink: true }}
        onChange={handleAccountNumberChange}
      />

      <TextField
        fullWidth
        id="comment"
        label="comment"
        type="text"
        defaultValue={comment}
        value={comment}
        InputLabelProps={{ shrink: true }}
        onChange={handleCommentChange}
      />

      <FormControlLabel
        control={<Checkbox checked={isTrackInLedger} onChange={handleTrackInLedgerChange} />}
        label="trackInLedger" />

      {error !== null && <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload: CreateManualPbTnxDialogPayload
  hideDialog: () => void
}