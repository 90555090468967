
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import {
  StartUnderwritingPayload,
  UnderwritingBTProductTypePayloadContent,
} from '../../../network/UnderwritingActions';

export type StartUnderwritingDialogPayload = {
  userId: number
} | null;

export const StartUnderwritingDialog: FC<Props> = ({
  payload,
  hideDialog,
  onSend,
}) => {
  const [forceEquifaxReportReload, setForceEquifaxReportReload] = useState<boolean>(false);
  const [forceExperianReportReload, setForceExperianReportReload] = useState<boolean>(false);
  const [underwritingBTProductTypePayloadContent, setUnderwritingBTProductTypePayloadContent] =
    useState<UnderwritingBTProductTypePayloadContent>("BalanceTransfer");

  useEffect(() => {
    setForceEquifaxReportReload(false);
    setForceExperianReportReload(false);
    setUnderwritingBTProductTypePayloadContent("BalanceTransfer");
  }, [payload]);

  const handleSendClick = async () => {
    if (payload === null) return;

    const { userId } = payload;

    const underwritingProduct = {
      tag: "BalanceTransferProduct",
      contents: underwritingBTProductTypePayloadContent,
    };

    onSend({
      userId: parseInt(userId.toString()),
      forceExperianReportReload,
      underwritingProduct,
      withMonitoring: true,
      isRejectionLetterEnabled: false,
    });

    hideDialog();
  }

  const handleForceEquifaxReportReload = (e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    e.preventDefault();
    setForceEquifaxReportReload(isSelect);
  }

  const handleForceExperianReportReload = (e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    e.preventDefault();
    setForceExperianReportReload(isSelect);
  }

  const handleChangeProductTypeBTPayloadContent = useCallback((e: SelectChangeEvent<UnderwritingBTProductTypePayloadContent>) => {
    setUnderwritingBTProductTypePayloadContent(e.target.value as UnderwritingBTProductTypePayloadContent);
  }, [underwritingBTProductTypePayloadContent]);

  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title="Start underwriting"
      onSend={handleSendClick}
      actionTitle="Run">

      <FormControl fullWidth>
        <InputLabel id="account-id-select-label1">Underwriting content</InputLabel>
        <Select
          autoFocus={false}
          onFocus={() => { }}
          labelId="account-id-select-label1"
          id="demo-simple-select1"
          value={underwritingBTProductTypePayloadContent}
          label="underwriting content"
          onChange={handleChangeProductTypeBTPayloadContent}>
          <MenuItem key={"BalanceTransfer"} value={"BalanceTransfer"}>BalanceTransfer</MenuItem>
          <MenuItem key={"BalanceTransferAfterOffer"} value={"BalanceTransferAfterOffer"}>BalanceTransferAfterOffer</MenuItem>
          <MenuItem key={"BalanceTransferOffer"} value={"BalanceTransferOffer"}>BalanceTransferOffer</MenuItem>
        </Select>
      </FormControl>

      <FormControlLabel
        control={<Checkbox checked={forceEquifaxReportReload} onChange={handleForceEquifaxReportReload} />}
        label="forceEquifaxReportReload" />

      <FormControlLabel
        control={<Checkbox checked={forceExperianReportReload} onChange={handleForceExperianReportReload} />}
        label="forceExperianReportReload" />

    </SimpleDialog>
  );
}

type Props = {
  payload: StartUnderwritingDialogPayload;
  hideDialog: () => void;
  onSend: (payload: StartUnderwritingPayload) => Promise<void>
}