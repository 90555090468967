import { addMonths, cloneDate, createDate, isEqual, getDate, lastDayOfMonth } from '../../../../../kendo-date-math';
import { Mask } from './mask';
import { dateSymbolMap } from './../utils';
/**
 * @hidden
 */
var KendoDate = /** @class */ (function () {
    function KendoDate(intlProvider, formatPlaceholder, format) {
        this.year = true;
        this.month = true;
        this.date = true;
        this.hours = true;
        this.minutes = true;
        this.seconds = true;
        this.milliseconds = true;
        this.leadingZero = null;
        this.typedMonthPart = '';
        this.knownParts = 'adHhmMsEy';
        this.symbols = {
            'E': 'E',
            'H': 'H',
            'M': 'M',
            'a': 'a',
            'd': 'd',
            'h': 'h',
            'm': 'm',
            's': 's',
            'y': 'y'
        };
        this._value = getDate(new Date());
        this.intlProvider = intlProvider;
        this.formatPlaceholder = formatPlaceholder;
        this.format = format;
        this.monthNames = this.allFormatedMonths();
    }
    Object.defineProperty(KendoDate.prototype, "intl", {
        get: function () {
            return this.intlProvider();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(KendoDate.prototype, "value", {
        get: function () {
            return this._value;
        },
        enumerable: false,
        configurable: true
    });
    KendoDate.prototype.setValue = function (value) {
        if (!value) {
            this._value = getDate(new Date());
            this.modifyExisting(false);
        }
        else if (!isEqual(value, this._value)) {
            this._value = cloneDate(value);
            this.modifyExisting(true);
        }
    };
    KendoDate.prototype.hasValue = function () {
        var _this = this;
        var pred = function (a, p) { return a || p.type !== 'literal' && p.type !== 'dayperiod' && _this.getExisting(p.pattern[0]); };
        return this.intl.splitDateFormat(this.format).reduce(pred, false);
    };
    KendoDate.prototype.getDateObject = function () {
        for (var i = 0; i < this.knownParts.length; i++) {
            if (!this.getExisting(this.knownParts[i])) {
                return null;
            }
        }
        return cloneDate(this.value);
    };
    KendoDate.prototype.getTextAndFormat = function () {
        return this.merge(this.intl.formatDate(this.value, this.format), this.dateFormatString(this.value, this.format));
    };
    KendoDate.prototype.modifyExisting = function (value) {
        var sampleFormat = this.dateFormatString(this.value, this.format).symbols;
        for (var i = 0; i < sampleFormat.length; i++) {
            this.setExisting(sampleFormat[i], value);
        }
    };
    KendoDate.prototype.getExisting = function (symbol) {
        switch (symbol) {
            case 'y': return this.year;
            case 'M':
            case 'L': return this.month;
            case 'd': return this.date;
            case 'E': return this.date && this.month && this.year;
            case 'h':
            case 'H': return this.hours;
            case 'm': return this.minutes;
            case 's': return this.seconds;
            default: return true;
        }
    };
    KendoDate.prototype.setExisting = function (symbol, value) {
        switch (symbol) {
            case 'y':
                // allow 2/29 dates
                this.year = value;
                if (value === false) {
                    this._value.setFullYear(2000);
                }
                break;
            case 'M':
                // make sure you can type 31 in the day part
                this.month = value;
                if (value === false) {
                    this._value.setMonth(0);
                }
                break;
            case 'd':
                this.date = value;
                break;
            case 'h':
            case 'H':
                this.hours = value;
                break;
            case 'm':
                this.minutes = value;
                break;
            case 's':
                this.seconds = value;
                break;
            default: return;
        }
    };
    KendoDate.prototype.modifyPart = function (symbol, offset) {
        var newValue = cloneDate(this.value);
        switch (symbol) {
            case 'y':
                newValue.setFullYear(newValue.getFullYear() + offset);
                break;
            case 'M':
                newValue = addMonths(this.value, offset);
                break;
            case 'd':
            case 'E':
                newValue.setDate(newValue.getDate() + offset);
                break;
            case 'h':
            case 'H':
                newValue.setHours(newValue.getHours() + offset);
                break;
            case 'm':
                newValue.setMinutes(newValue.getMinutes() + offset);
                break;
            case 's':
                newValue.setSeconds(newValue.getSeconds() + offset);
                break;
            case 'a':
                newValue.setHours(newValue.getHours() + (12 * offset));
                break;
            default: break;
        }
        this.setExisting(symbol, true);
        this._value = newValue;
    };
    KendoDate.prototype.parsePart = function (symbol, currentChar) {
        var _a;
        this.resetLeadingZero();
        if (!currentChar) {
            this.setExisting(symbol, false);
            return { value: null };
        }
        var baseDate = this.intl.formatDate(this.value, this.format);
        var dateParts = this.dateFormatString(this.value, this.format);
        var baseFormat = dateParts.symbols;
        var replaced = false;
        var prefix = '';
        var current = '';
        var suffix = '';
        for (var i = 0; i < baseDate.length; i++) {
            if (baseFormat[i] === symbol) {
                current += this.getExisting(symbol) ? baseDate[i] : '0';
                replaced = true;
            }
            else if (!replaced) {
                prefix += baseDate[i];
            }
            else {
                suffix += baseDate[i];
            }
        }
        var parsedDate = null;
        var month = this.matchMonth(currentChar);
        while (current.length > 0 && current.charAt(0) === '0') {
            current = current.slice(1);
        }
        if (current.length >= 4) {
            current = '';
        }
        for (var i = 0; i < 2; i++) {
            var middle = current + currentChar;
            var middleNumber = parseInt(middle, 10);
            parsedDate = this.intl.parseDate(prefix + middle + suffix, this.format);
            if (!parsedDate && !isNaN(middleNumber) && !isNaN(parseInt(currentChar, 10))) {
                if (symbol === 'M' && !month) {
                    var monthNumber = middleNumber - 1;
                    if (monthNumber > -1 && monthNumber < 12) {
                        parsedDate = cloneDate(this.value);
                        parsedDate.setMonth(monthNumber);
                        if (parsedDate.getMonth() !== monthNumber) {
                            parsedDate = lastDayOfMonth(addMonths(parsedDate, -1));
                        }
                    }
                }
                if (symbol === 'y') {
                    parsedDate = createDate(parseInt(middle, 10), this.month ? this.value.getMonth() : 0, this.date ? this.value.getDate() : 1, this.hours ? this.value.getHours() : 0, this.minutes ? this.value.getMinutes() : 0, this.seconds ? this.value.getSeconds() : 0, this.milliseconds ? this.value.getMilliseconds() : 0);
                    if (this.date && parsedDate.getDate() !== this.value.getDate()) {
                        parsedDate = lastDayOfMonth(addMonths(parsedDate, -1));
                    }
                }
            }
            if (parsedDate) {
                this._value = parsedDate;
                this.setExisting(symbol, true);
                return { value: this.value };
            }
            current = '';
        }
        if (month) {
            parsedDate = this.intl.parseDate(prefix + month + suffix, this.format);
            if (parsedDate) {
                this._value = parsedDate;
                this.setExisting(symbol, true);
                return { value: this.value };
            }
        }
        if (currentChar === '0') {
            this.leadingZero = !this.isAbbrMonth(dateParts.partMap, symbol) ? (_a = {}, _a[symbol] = true, _a) : null;
            this.setExisting(symbol, false);
        }
        return { value: null };
    };
    KendoDate.prototype.symbolMap = function (symbol) {
        return this.intl.splitDateFormat(this.format).reduce(dateSymbolMap, {})[symbol];
    };
    KendoDate.prototype.resetLeadingZero = function () {
        var hasLeadingZero = this.leadingZero !== null;
        this.leadingZero = null;
        return hasLeadingZero;
    };
    KendoDate.prototype.isAbbrMonth = function (parts, symbol) {
        var pattern = this.partPattern(parts, symbol);
        return pattern.type === 'month' && pattern.names;
    };
    KendoDate.prototype.partPattern = function (parts, symbol) {
        return parts.filter(function (part) { return part.pattern.indexOf(symbol) !== -1; })[0];
    };
    KendoDate.prototype.matchMonth = function (typedChar) {
        this.typedMonthPart += typedChar.toLowerCase();
        if (this.monthNames.length === 0) {
            return '';
        }
        while (this.typedMonthPart.length > 0) {
            for (var i = 0; i < this.monthNames.length; i++) {
                if (this.monthNames[i].toLowerCase().indexOf(this.typedMonthPart) === 0) {
                    return this.monthNames[i];
                }
            }
            var monthAsNum = parseInt(this.typedMonthPart, 10);
            /* ensure they exact match */
            if (monthAsNum >= 1 && monthAsNum <= 12 && monthAsNum.toString() === this.typedMonthPart) {
                return this.monthNames[monthAsNum - 1];
            }
            this.typedMonthPart = this.typedMonthPart.substring(1, this.typedMonthPart.length);
        }
        return '';
    };
    KendoDate.prototype.allFormatedMonths = function () {
        var dateFormatParts = this.intl.splitDateFormat(this.format);
        for (var i = 0; i < dateFormatParts.length; i++) {
            if (dateFormatParts[i].type === 'month' && dateFormatParts[i].names) {
                return this.intl.dateFormatNames(dateFormatParts[i].names);
            }
        }
        return [];
    };
    KendoDate.prototype.dateFormatString = function (date, format) {
        var dateFormatParts = this.intl.splitDateFormat(format);
        var parts = [];
        var partMap = [];
        for (var i = 0; i < dateFormatParts.length; i++) {
            var partLength = this.intl.formatDate(date, { pattern: dateFormatParts[i].pattern }).length;
            while (partLength > 0) {
                parts.push(this.symbols[dateFormatParts[i].pattern[0]] || '_');
                partMap.push(dateFormatParts[i]);
                partLength--;
            }
        }
        var returnValue = new Mask();
        returnValue.symbols = parts.join('');
        returnValue.partMap = partMap;
        return returnValue;
    };
    KendoDate.prototype.merge = function (text, mask) {
        // Important: right to left.
        var resultText = '';
        var resultFormat = '';
        var format = mask.symbols;
        for (var r = format.length - 1; r >= 0; r--) {
            if (this.knownParts.indexOf(format[r]) === -1 || this.getExisting(format[r])) {
                resultText = text[r] + resultText;
                resultFormat = format[r] + resultFormat;
            }
            else {
                var currentSymbol = format[r];
                while (r >= 0 && currentSymbol === format[r]) {
                    r--;
                }
                r++;
                if (this.leadingZero && this.leadingZero[currentSymbol]) {
                    resultText = '0' + resultText;
                }
                else {
                    resultText = this.dateFieldName(mask.partMap[r]) + resultText;
                }
                while (resultFormat.length < resultText.length) {
                    resultFormat = format[r] + resultFormat;
                }
            }
        }
        return { text: resultText, format: resultFormat };
    };
    KendoDate.prototype.dateFieldName = function (part) {
        var formatPlaceholder = (this.formatPlaceholder || 'wide');
        if (formatPlaceholder[part.type]) {
            return formatPlaceholder[part.type];
        }
        if (formatPlaceholder === 'formatPattern') {
            return part.pattern;
        }
        return this.intl.dateFieldName(Object.assign(part, { nameType: formatPlaceholder }));
    };
    return KendoDate;
}());
export { KendoDate };
