import React, { FC, useCallback, useEffect, useState } from 'react';
import { Alert, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';

export type PaymentPromiseUpdateDialogPayload = {
  id: number
} | undefined;

export const PaymentPromiseUpdateDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { collectionActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [comment, setComment] = useState<string>("");
  const [isFulfilled, setIsFulfilled] = useState<boolean>(false);
  const [isProcess, setIsProcess] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    setComment("");
    setIsFulfilled(false);
    setError(null);
    setIsProcess(false);
  }, [payload]);

  const onCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setComment(value);
  }

  const onIsFulfilledChange = (e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    setIsFulfilled(isSelect);
  };

  const handleSendClick = async () => {
    if (payload === undefined || isProcess) return

    const id = parseInt(payload.toString());

    setIsProcess(true);

    const error = await collectionActions.updatePaymentPromise({
      id,
      comment,
      isFulfilled,
    });

    setIsProcess(false);

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Updated");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Update promise payment"
      actionTitle={isProcess ? 'Updating...' : 'Update'}
      onSend={handleSendClick}>

      <TextField
        fullWidth
        type="text"
        label="Comment"
        value={comment}
        onChange={onCommentChange}
        variant="outlined"
      />

      <FormControlLabel
        control={<Checkbox checked={isFulfilled} onChange={onIsFulfilledChange} />}
        label="is fulfilled" />

      {error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload?: PaymentPromiseUpdateDialogPayload
  hideDialog: () => void
}