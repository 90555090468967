import { getScrollbarWidth } from '../../../../kendo-react-common';
import * as React from 'react';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * @hidden
 */
var Footer = /** @class */ (function (_super) {
    __extends(Footer, _super);
    function Footer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.footerWrap = null;
        _this.table = null;
        _this.scrollbarWidth = getScrollbarWidth();
        return _this;
    }
    Footer.prototype.componentDidMount = function () {
        this.setState({});
    };
    /**
     *
     * @param scrollLeft - Scrolls to the left (in pixels).
     */
    Footer.prototype.setScrollLeft = function (scrollLeft) {
        if (this.footerWrap) {
            this.footerWrap.scrollLeft = scrollLeft;
        }
    };
    /**
     *
     * @param width - Scrolls the width (in pixels).
     */
    Footer.prototype.setWidth = function (width) {
        if (this.table) {
            this.table.style.width = width + 'px';
        }
    };
    Footer.prototype.render = function () {
        var _this = this;
        if (!this.props.staticHeaders) {
            return (React.createElement("tfoot", { className: "k-grid-footer" }, this.props.row));
        }
        return (React.createElement("div", { className: "k-grid-footer", role: "presentation" },
            React.createElement("div", { ref: function (div) { _this.footerWrap = div; }, className: "k-grid-footer-wrap", style: this.scrollbarWidth ? {} : { borderWidth: 0 }, role: "presentation" },
                React.createElement("table", { ref: function (table) { _this.table = table; }, role: "presentation" },
                    React.createElement("colgroup", { ref: function (e) { _this.props.columnResize.colGroupFooter = e; }, role: "presentation" }, this.props.cols),
                    React.createElement("tfoot", { role: "presentation" }, this.props.row)))));
    };
    return Footer;
}(React.Component));
export { Footer };
