export default [
  {
    "model": "4129",
    "attributes": {
      "6000": "Monthly Scheduled Payment (FFF)",
      "6001": "Monthly Scheduled Payment (AMS 4.0)",
      "6002": "Major Derogatory Amount",
      "6003": "Collection Amount",
      "6004": "Auto Monthly Current Debt Payment",
      "6005": "Bankcard Monthly Current Debt Payment",
      "6006": "Home Equity Line of Credit Monthly Current Debt Payment",
      "6007": "Home Equity Loan Monthly Current Debt Payment",
      "6008": "Other Installment Monthly Current Debt Payment",
      "6009": "First Mortgage Monthly Current Debt Payment",
      "6010": "Other Revolving Monthly Current Debt Payment",
      "6011": "Auto Scheduled Monthly Payment",
      "6012": "Bankcard Scheduled Monthly Payment",
      "6013": "Child/Family Support Scheduled Payment Amount",
      "6014": "Home Equity Line of Credit Scheduled Monthly Payment",
      "6015": "Home Equity Loan Scheduled Monthly Payment",
      "6016": "Other Installment Scheduled Monthly Payment",
      "6017": "First Mortgage Scheduled Monthly Payment",
      "6018": "Other Revolving Scheduled Monthly Payment",
      "6019": "Other Revolving Open to Buy",
      "6020": "Other Installment Open to Buy",
      "6021": "Auto Open to Buy",
      "6022": "Bankcard Open to Buy",
      "6023": "First Mortgage Open to Buy",
      "6024": "First Mortgage Balance",
      "6025": "First Mortgage High Credit/Credit Limit",
      "6026": "Home Equity Loan Open to Buy",
      "6027": "Home Equity Line of Credit Open to Buy",
      "6028": "Personal Monthly Income",
      "6029": "Equity in Home",
      "6030": "Scheduled Monthly Payment (R&D Lab)",
      "6031": "Monthly Current Debt Payment ",
      "6032": "Monthly Disposable Income w/Scheduled Payment (R&D Lab)",
      "6033": "Monthly Disposable Income w/Current Debt ",
      "6034": "Monthly Disposable Income w/PIM & SCHED_PAYMT_AMT",
      "6035": "Monthly Disposable Income w/PIM & SCH_PMT_AMT",
      "6036": "Debt to Income Ratio w/Scheduled Payment (R&D Lab)",
      "6037": "Debt to Income Ratio w/Current Debt (R&D Lab)",
      "6038": "Debt to Income Ratio w/Monthly Scheduled Payment (FFF)",
      "6039": "Debt to Income Ratio w/Monthly Schedule Payment (AMS 4.0)",
      "6040": "Home Equity High Credit/Credit Limit",
      "6045": "Other Revolving Balance",
      "6046": "Other Revolving Credit Limit High Credit",
      "6047": "Other Revolving High Credit",
      "6048": "Other Installment Balance",
      "6049": "Other Installment Credit Limit High Credit",
      "6050": "Other Installment High Credit",
      "6051": "Auto Balance",
      "6052": "Auto Credit Limit High Credit",
      "6053": "Auto High Credit",
      "6054": "Bankcard Balance",
      "6055": "Bankcard  Credit Limit High Credit",
      "6056": "Bankcard  High Credit",
      "6057": "First Mortgage  High Credit",
      "6058": "Home Equity Loan Balance",
      "6059": "Home Equity Loan  Credit Limit High Credit",
      "6060": "Home Equity Loan  High Credit",
      "6061": "Home Equity Line of Credit Balance",
      "6062": "Home Equity Line of Credit Credit Limit High Credit",
      "6063": "Home Equity Line of Credit High Credit",
      "6064": "Home Equity Balance",
      "6065": "Home Equity Open to Buy",
      "6066": "Home Equity High Credit"
    }
  },
  {
    "model": "5127",
    "attributes": {
      "3000": "Number Inquiries w/in 3 Months",
      "3001": "Number Inquiries w/in 12 Months",
      "3002": "Number Inquiries w/in 24 Months",
      "3008": "Number Non-Utility Inquiries w/in 1 Month",
      "3009": "Number Non-Utility Inquiries w/in 3 Months",
      "3010": "Number Non-Utility Inquiries w/in 12 Months",
      "3011": "Number Non-Utility Inquiries w/in 24 Months",
      "3012": "Percent Inquiries w/in 1 Month to Inquiries w/in 12 Months",
      "3013": "Percent Inquiries w/in 1 Month to Inquiries w/in 24 Months",
      "3014": "Percent Utility Inquiries w/in 3 Months to Inquiries w/in 12 Months",
      "3015": "Percent Utility Inquiries w/in 3 Months to Inquiries w/in 24 Months",
      "3016": "Percent Utility Inquiries w/in 12 Months to Inquiries w/in 24 Months",
      "3017": "Percent Utility Inquiries w/in 1 Month to Inquiries w/in 12 Months",
      "3018": "Percent Utility Inquiries w/in 1 Month to Inquiries w/in 24 Months",
      "3019": "Percent Non-Utility Inquiries w/in 3 Months to Inquiries w/in 12 Months",
      "3020": "Percent Non-Utility Inquiries w/in 3 Months to Inquiries w/in 24 Months",
      "3021": "Percent Non-Utility Inquiries w/in 12 Months to Inquiries w/in 24 Months",
      "3022": "Percent Non-Utility Inquiries w/in 1 Month to Inquiries w/in 12 Months",
      "3023": "Percent Non-Utility Inquiries w/in 1 Month to Inquiries w/in 24 Months",
      "3024": "Number Inquiries w/in 6 Months",
      "3025": "Number Utility Inquiries w/in 6 Months",
      "3026": "Number Non-Utility Inquiries w/in 6 Months",
      "3027": "Number Inquiries w/in 1 Month",
      "3028": "Number Utility Inquiries w/in 1 Month",
      "3029": "Number Utility Inquiries w/in 3 Months",
      "3030": "Number Utility Inquiries w/in 12 Months",
      "3031": "Number Utility Inquiries w/in 24 Months",
      "3100": "Number Trades",
      "3101": "Number of Auto Trades",
      "3102": "Number Bankcard Trades",
      "3104": "Number Department Store Trades",
      "3105": "Number Installment Trades",
      "3109": "Number Revolving Trades",
      "3111": "Age Oldest Trade",
      "3113": "Age Oldest Bankcard Trade",
      "3116": "Age Oldest Installment Trade",
      "3117": "Age Oldest Mortgage Trade",
      "3119": "Age Oldest Retail Trade",
      "3120": "Age Oldest Revolving Trade",
      "3122": "Age Newest Trade",
      "3123": "Age Newest Auto Trade",
      "3124": "Age Newest Bankcard Trade",
      "3126": "Age Newest Department Store Trades",
      "3127": "Age Newest Installment Trade",
      "3131": "Age Newest Retail Trade",
      "3132": "Age Newest Sales Finance Trade",
      "3133": "Number Trades Opened w/in 6 Months",
      "3134": "Number Revolving Trades Opened w/in 6 Months",
      "3135": "Number Trades Opened w/in 12 Months",
      "3136": "Number Installment Trades Opened w/in 12 Months",
      "3137": "Number Open Trades",
      "3138": "Number Open Auto Trades",
      "3139": "Number Open Bankcard Trades",
      "3140": "Number Open Credit Union Trades",
      "3141": "Number Open Department Store Trades",
      "3142": "Number Open Installment Trades",
      "3143": "Number Open Mortgage Trades",
      "3144": "Number Open Personal Finance and Student Loan Trades",
      "3145": "Number Open Retail Trades",
      "3146": "Number Open Revolving Trades",
      "3147": "Number Open Sales Finance Trades",
      "3148": "Number Trades w/Update w/in 3 Months w/ Balance > $0",
      "3150": "Number Bankcard Trades w/Update w/in 3 Months w/ Balance > $0",
      "3152": "Number Department Store Trades w/Update w/in 3 Months w/ Balance > $0",
      "3153": "Number Installment Trades w/Update w/in 3 Months w/ Balance > $0",
      "3157": "Number Revolving Trades w/Update w/in 3 Months w/ Balance > $0",
      "3158": "Number Retail Trades w/Update w/in 3 Months w/ Balance > $0",
      "3159": "Total Balance Open Trades w/Update w/in 3 Months",
      "3160": "Total Balance Open Auto Trades w/Update w/in 3 Months",
      "3161": "Total Balance Open Bankcard Trades w/Update w/in 3 Months",
      "3162": "Total Balance Open Credit Union Trades w/Update w/in 3 Months",
      "3163": "Total Balance Open Department Store Trades w/Update w/in 3 Months",
      "3164": "Total Balance Open Installment Trades w/Update w/in 3 Months",
      "3165": "Total Balance Open Mortgage Trades w/Update w/in 3 Months",
      "3166": "Total Balance Open Personal Finance and Student Loan Trades w/Update w/in 3 Months",
      "3167": "Total Balance Open Retail Trades w/Update w/in 3 Months",
      "3168": "Total Balance Open Revolving Trades w/Update w/in 3 Months",
      "3169": "Total Balance Open Sales Finance Trades w/Update w/in 3 Months",
      "3174": "Total Balance Open Student Loan Trades w/Update w/in 3 Months",
      "3175": "Number Student Loan Trades",
      "3176": "Number Defaulted Student Loan Trades",
      "3177": "Number Mortgage Trades",
      "3178": "Number Retail Trades",
      "3181": "Total Balance Closed Trades w/Update w/in 3 Months",
      "3183": "Total Balance Closed Bankcard Trades w/Update w/in 3 Months",
      "3184": "Total Balance Closed Credit Union Trades w/Update w/in 3 Months",
      "3185": "Total Balance Closed Department Store Trades w/Update w/in 3 Months",
      "3188": "Total Balance Closed Personal Finance and Student Loan Trades w/Update w/in 3 Months",
      "3189": "Total Balance Closed Retail Trades w/Update w/in 3 Months",
      "3190": "Total Balance Closed Revolving Trades w/Update w/in 3 Months",
      "3191": "Total Balance Closed Sales Finance Trades w/Update w/in 3 Months",
      "3192": "Number Trades Opened w/in 3 Months",
      "3193": "Number Installment Trades Opened w/in 6 Months",
      "3194": "Number Revolving Trades Opened w/in 12 Months",
      "3195": "Age Newest Mortgage Trade",
      "3202": "Total Loan Amount Open Student Loan Trades w/Update w/in 3 Months",
      "3203": "Total Loan Amount Open Auto Trades w/Update w/in 3 Months",
      "3204": "Total High Credit Open Bankcard Trades w/Update w/in 3 Months",
      "3205": "Total High Credit Open Department Store Trades w/Update w/in 3 Months",
      "3206": "Total Loan Amount Open Installment Trades w/Update w/in 3 Months",
      "3207": "Total Loan Amount Open Mortgage Trades w/Update w/in 3 Months",
      "3208": "Total High Credit Open Revolving Trades w/Update w/in 3 Months",
      "3215": "Number Trades w/ Past Due Amount > $0",
      "3217": "Number Bankcard Trades w/ Past Due Amount > $0",
      "3219": "Number Department Store Trades w/ Past Due Amount > $0",
      "3220": "Total High Credit Open Retail Trades w/Update w/in 3 Months",
      "3223": "Number Retail Trades w/ Past Due Amount > $0",
      "3224": "Number Revolving Trades w/ Past Due Amount > $0",
      "3228": "Total Past Due Amount  Bankcard Trades w/Update w/in 3 Months",
      "3231": "Total Past Due Amount Installment Trades w/Update w/in 3 Months",
      "3234": "Total Past Due Amount Retail Trades w/Update w/in 3 Months",
      "3235": "Total Past Due Amount Revolving Trades w/Update w/in 3 Months",
      "3236": "Total Past Due Amount Trades w/Update w/in 3 Months",
      "3237": "Total Past Due Amount",
      "3239": "Total Past Due Amount Bankcard Trades",
      "3242": "Total Past Due Amount Installment Trades",
      "3245": "Total Past Due Amount Retail Trades",
      "3246": "Total Past Due Amount Revolving Trades",
      "3257": "Number 30 Days Past Due Occurrences w/in 6 Months Revolving Trades",
      "3266": "Number 30 Days Past Due Occurrences w/in 12 Months Revolving Trades",
      "3268": "Number 30 Days Past Due Occurrences w/in 24 Months",
      "3270": "Number 30 Days Past Due Occurrences w/in 24 Months Bankcard Trades",
      "3272": "Number 30 Days Past Due Occurrences w/in 24 Months Department Store Trades",
      "3273": "Number 30 Days Past Due Occurrences w/in 24 Months Installment Trades",
      "3276": "Number 30 Days Past Due Occurrences w/in 24 Months Retail Trades",
      "3277": "Number 30 Days Past Due Occurrences w/in 24 Months Revolving Trades",
      "3285": "Number 60 Days Past Due Occurrences w/in 12 Months Retail Trades",
      "3286": "Number 60 Days Past Due Occurrences w/in 12 Months Revolving Trades",
      "3288": "Number 60 Days Past Due Occurrences w/in 24 Months",
      "3290": "Number 60 Days Past Due Occurrences w/in 24 Months Bankcard Trades",
      "3292": "Number 60 Days Past Due Occurrences w/in 24 Months Department Store Trades",
      "3293": "Number 60 Days Past Due Occurrences w/in 24 Months Installment Trades",
      "3296": "Number 60 Days Past Due Occurrences w/in 24 Months Retail Trades",
      "3297": "Number 60 Days Past Due Occurrences w/in 24 Months Revolving Trades",
      "3307": "Number 90 Days Past Due Occurrences w/in 24 Months",
      "3309": "Number 90 Days Past Due Occurrences w/in 24 Months Bankcard Trades",
      "3311": "Number 90 Days Past Due Occurrences w/in 24 Months Department Store Trades",
      "3312": "Number 90 Days Past Due Occurrences w/in 24 Months Installment Trades",
      "3315": "Number 90 Days Past Due Occurrences w/in 24 Months Retail Trades",
      "3316": "Number 90 Days Past Due Occurrences w/in 24 Months Revolving Trades",
      "3318": "Number 120-180 or More Days Past Due Occurrences w/in 24 Months",
      "3320": "Number 120-180 or More Days Past Due Occurrences w/in 24 Months Bankcard Trades",
      "3322": "Number 120-180 or More Days Past Due Occurrences w/in 24 Months Department Store Trades",
      "3323": "Number 120-180 or More Days Past Due Occurrences w/in 24 Months Installment Trades",
      "3326": "Number 120-180 or More Days Past Due Occurrences w/in 24 Months Retail Trades",
      "3327": "Number 120-180 or More Days Past Due Occurrences w/in 24 Months Revolving Trades",
      "3329": "Number Trades Satisfactory w/in 3 Months",
      "3330": "Number Bankcard Trades Satisfactory w/in 3 Months",
      "3332": "Number Department Store Trades Satisfactory w/in 3 Months",
      "3333": "Number Installment Trades Satisfactory w/in 3 Months",
      "3335": "Number Retail Trades Satisfactory w/in 3 Months",
      "3336": "Number Revolving Trades Satisfactory w/in 3 Months",
      "3338": "Number Trades Satisfactory w/in 6 Months",
      "3340": "Number Bankcard Trades Satisfactory w/in 6 Months",
      "3347": "Number Revolving Trades Satisfactory w/in 6 Months",
      "3348": "Number Retail Trades Satisfactory w/in 6 Months",
      "3349": "Number 60-180 or More Days Past Due Occurrences w/in 24 Months",
      "3350": "Number 60-180 or More Days Past Due Occurrences w/in 24 Months Bankcard Trades",
      "3351": "Number 60-180 or More Days Past Due Occurrences w/in 24 Months Department Store Trades",
      "3352": "Number 60-180 or More Days Past Due Occurrences w/in 24 Months Installment Trades",
      "3353": "Number 60-180 or More Days Past Due Occurrences w/in 24 Months Retail Trades",
      "3354": "Number 60-180 or More Days Past Due Occurrences w/in 24 Months Revolving Trades",
      "3355": "Number 90-180 or More Days Past Due Occurrences w/in 24 Months",
      "3356": "Number 90-180 or More Days Past Due Occurrences w/in 24 Months Bankcard Trades",
      "3357": "Number 90-180 or More Days Past Due Occurrences w/in 24 Months Department Store Trades",
      "3358": "Number 90-180 or More Days Past Due Occurrences w/in 24 Months Installment Trades",
      "3359": "Number 90-180 or More Days Past Due Occurrences w/in 24 Months Retail Trades",
      "3360": "Number 90-180 or More Days Past Due Occurrences w/in 24 Months Revolving Trades",
      "3361": "Number 60-180 or More Days Past Due Occurrences w/in 12 Months",
      "3362": "Number 60-180 or More Days Past Due Occurrences w/in 12 Months Bankcard Trades",
      "3363": "Number 60-180 or More Days Past Due Occurrences w/in 12 Months Department Store Trades",
      "3364": "Number 60-180 or More Days Past Due Occurrences w/in 12 Months Installment Trades",
      "3365": "Number 60-180 or More Days Past Due Occurrences w/in 12 Months Retail Trades",
      "3366": "Number 60-180 or More Days Past Due Occurrences w/in 12 Months Revolving Trades",
      "3367": "Number 90-180 or More Days Past Due Occurrences w/in 12 Months",
      "3368": "Number Trades Always Satisfactory",
      "3369": "Number 90-180 or More Days Past Due Occurrences w/in 12 Months Bankcard Trades",
      "3370": "Number Bankcard Trades Always Satisfactory",
      "3371": "Number 90-180 or More Days Past Due Occurrences w/in 12 Months Department Store Trades",
      "3372": "Number 90-180 or More Days Past Due Occurrences w/in 12 Months Installment Trades",
      "3373": "Number 90-180 or More Days Past Due Occurrences w/in 12 Months Retail Trades",
      "3374": "Number 90-180 or More Days Past Due Occurrences w/in 12 Months Revolving Trades",
      "3376": "Number Revolving Trades Always Satisfactory",
      "3377": "Number Retail Trades Always Satisfactory",
      "3379": "Number Trades Worst Rating 30 Days Past Due w/in 3 Months",
      "3380": "Number Bankcard Trades Worst Rating 30 Days Past Due w/in 3 Months",
      "3382": "Number Department Store Trades Worst Rating 30 Days Past Due w/in 3 Months",
      "3383": "Number Installment Trades Worst Rating 30 Days Past Due w/in 3 Months",
      "3385": "Number Retail Trades Worst Rating 30 Days Past Due w/in 3 Months",
      "3386": "Number Revolving Trades Worst Rating 30 Days Past Due w/in 3 Months",
      "3387": "Number Sales Finance Trades Worst Rating 30 Days Past Due w/in 3 Months",
      "3388": "Number Trades Worst Rating 60 Days Past Due w/in 3 Months",
      "3389": "Number Bankcard Trades Worst Rating 60 Days Past Due w/in 3 Months",
      "3391": "Number Department Store Trades Worst Rating 60 Days Past Due w/in 3 Months",
      "3392": "Number Installment Trades Worst Rating 60 Days Past Due w/in 3 Months",
      "3394": "Number Retail Trades Worst Rating 60 Days Past Due w/in 3 Months",
      "3395": "Number Revolving Trades Worst Rating 60 Days Past Due w/in 3 Months",
      "3396": "Number Sales Finance Trades Worst Rating 60 Days Past Due w/in 3 Months",
      "3397": "Number Trades Worst Rating 90 Days Past Due w/in 3 Months",
      "3398": "Number Bankcard Trades Worst Rating 90 Days Past Due w/in 3 Months",
      "3400": "Number Department Store Trades Worst Rating 90 Days Past Due w/in 3 Months",
      "3401": "Number Installment Trades Worst Rating 90 Days Past Due w/in 3 Months",
      "3403": "Number Retail Trades Worst Rating 90 Days Past Due w/in 3 Months",
      "3404": "Number Revolving Trades Worst Rating 90 Days Past Due w/in 3 Months",
      "3405": "Number Sales Finance Trades Worst Rating 90 Days Past Due w/in 3 Months",
      "3406": "Number Trades Worst Rating 120-180 or More Days Past Due w/in 3 Months",
      "3407": "Number Bankcard Trades Worst Rating 120-180 or More Days Past Due w/in 3 Months",
      "3409": "Number Department Store Trades Worst Rating 120-180 or More Days Past Due w/in 3 Months",
      "3410": "Number Installment Trades Worst Rating 120-180 or More Days Past Due w/in 3 Months",
      "3412": "Number Retail Trades Worst Rating 120-180 or More Days Past Due w/in 3 Months",
      "3413": "Number Revolving Trades Worst Rating 120-180 or More Days Past Due w/in 3 Months",
      "3414": "Number Sales Finance Trades Worst Rating 120-180 or More Days Past Due w/in 3 Months",
      "3415": "Number Trades Worst Rating 30 Days Past Due w/in 6 Months",
      "3417": "Number Bankcard Trades Worst Rating 30 Days Past Due w/in 6 Months",
      "3419": "Number Department Store Trades Worst Rating 30 Days Past Due w/in 6 Months",
      "3420": "Number Installment Trades Worst Rating 30 Days Past Due w/in 6 Months",
      "3422": "Number Personal Finance and Student Loan Trades Worst Rating 30 Days Past Due w/in 6 Months",
      "3423": "Number Retail Trades Worst Rating 30 Days Past Due w/in 6 Months",
      "3424": "Number Revolving Trades Worst Rating 30 Days Past Due w/in 6 Months",
      "3425": "Number Sales Finance Trades Worst Rating 30 Days Past Due w/in 6 Months",
      "3426": "Number Trades Worst Rating 60 Days Past Due w/in 6 Months",
      "3428": "Number Bankcard Trades Worst Rating 60 Days Past Due w/in 6 Months",
      "3430": "Number Department Store Trades Worst Rating 60 Days Past Due w/in 6 Months",
      "3431": "Number Installment Trades Worst Rating 60 Days Past Due w/in 6 Months",
      "3433": "Number Personal Finance and Student Loan Trades Worst Rating 60 Days Past Due w/in 6 Months",
      "3434": "Number Retail Trades Worst Rating 60 Days Past Due w/in 6 Months",
      "3435": "Number Revolving Trades Worst Rating 60 Days Past Due w/in 6 Months",
      "3436": "Number Sales Finance Trades Worst Rating 60 Days Past Due w/in 6 Months",
      "3437": "Number Trades Worst Rating 90 Days Past Due w/in 6 Months",
      "3439": "Number Bankcard Trades Worst Rating 90 Days Past Due w/in 6 Months",
      "3441": "Number Department Store Trades Worst Rating 90 Days Past Due w/in 6 Months",
      "3442": "Number Installment Trades Worst Rating 90 Days Past Due w/in 6 Months",
      "3444": "Number Personal Finance and Student Loan Trades Worst Rating 90 Days Past Due w/in 6 Months",
      "3445": "Number Retail Trades Worst Rating 90 Days Past Due w/in 6 Months",
      "3446": "Number Revolving Trades Worst Rating 90 Days Past Due w/in 6 Months",
      "3447": "Number Sales Finance Trades Worst Rating 90 Days Past Due w/in 6 Months",
      "3448": "Number Trades Worst Rating 120-180 or More Days Past Due w/in 6 Months",
      "3450": "Number Bankcard Trades Worst Rating 120-180 or More Days Past Due w/in 6 Months",
      "3452": "Number Department Store Trades Worst Rating 120-180 or More Days Past Due w/in 6 Months",
      "3453": "Number Installment Trades Worst Rating 120-180 or More Days Past Due w/in 6 Months",
      "3455": "Number Personal Finance and Student Loan Trades Worst Rating 120-180 or More Days Past Due w/in 6 Months",
      "3456": "Number Retail Trades Worst Rating 120-180 or More Days Past Due w/in 6 Months",
      "3457": "Number Revolving Trades Worst Rating 120-180 or More Days Past Due w/in 6 Months",
      "3458": "Number Sales Finance Trades Worst Rating 120-180 or More Days Past Due w/in 6 Months",
      "3471": "Number of trades with status satisfactory reported within one month",
      "3472": "Number of trades with status satisfactory last reported",
      "3473": "Number of trades with\\xa0status 30-180 DPD\\xa0reported within one month",
      "3474": "Number of trades with\\xa0status 30-180 DPD last reported",
      "3475": "Number of trades with\\xa0status 60-180 DPD\\xa0reported within one month",
      "3476": "Number of trades with\\xa0status 60-180 DPD last reported",
      "3477": "Number of trades with\\xa0status 90-180 DPD\\xa0reported within one month",
      "3478": "Number of trades with status 90-180 DPD last reported",
      "3479": "Worst status rating reported within one month",
      "3480": "Worst status rating last reported",
      "3535": "Number Trades Worst Rating Ever 30 Days Past Due",
      "3536": "Number Auto Trades Worst Rating Ever 30 Days Past Due",
      "3537": "Number Bankcard Trades Worst Rating Ever 30 Days Past Due",
      "3539": "Number Department Store Trades Worst Rating Ever 30 Days Past Due",
      "3540": "Number Installment Trades Worst Rating Ever 30 Days Past Due",
      "3544": "Number Revolving Trades Worst Rating Ever 30 Days Past Due",
      "3545": "Number Retail Trades Worst Rating Ever 30 Days Past Due",
      "3546": "Number Trades Worst Rating Ever 60 Days Past Due",
      "3547": "Number Auto Trades Worst Rating Ever 60 Days Past Due",
      "3548": "Number Bankcard Trades Worst Rating Ever 60 Days Past Due",
      "3550": "Number Department Store Trades Worst Rating Ever 60 Days Past Due",
      "3551": "Number Installment Trades Worst Rating Ever 60 Days Past Due",
      "3555": "Number Revolving Trades Worst Rating Ever 60 Days Past Due",
      "3556": "Number Retail Trades Worst Rating Ever 60 Days Past Due",
      "3557": "Number Trades Worst Rating Ever 90 Days Past Due",
      "3558": "Number Auto Trades Worst Rating Ever 90 Days Past Due",
      "3559": "Number Bankcard Trades Worst Rating Ever 90 Days Past Due",
      "3561": "Number Department Store Trades Worst Rating Ever 90 Days Past Due",
      "3562": "Number Installment Trades Worst Rating Ever 90 Days Past Due",
      "3566": "Number Revolving Trades Worst Rating Ever 90 Days Past Due",
      "3567": "Number Retail Trades Worst Rating Ever 90 Days Past Due",
      "3568": "Number Trades Worst Rating Ever 120-180 or More Days Past Due",
      "3569": "Number Auto Trades Worst Rating Ever 120-180 or More Days Past Due",
      "3570": "Number Bankcard Trades Worst Rating Ever 120-180 or More Days Past Due",
      "3572": "Number Department Store Trades Worst Rating Ever 120-180 or More Days Past Due",
      "3573": "Number Installment Trades Worst Rating Ever120-180 or More Days Past Due",
      "3577": "Number Revolving Trades Worst Rating Ever120-180 or More Days Past Due",
      "3578": "Number Retail Trades Worst Rating Ever120-180 or More Days Past Due",
      "3579": "Worst Rating w/in 3 Months Auto Trades",
      "3580": "Worst Rating w/in 3 Months Mortgage Trades",
      "3581": "Number Trades w/ Major Derogatory Reported w/in 6 Months",
      "3583": "Number Bankcard Trades w/ Major Derogatory Reported w/in 6 Months",
      "3585": "Number Department Store Trades w/ Major Derogatory Reported w/in 6 Months",
      "3586": "Number Installment Trades w/ Major Derogatory Reported w/in 6 Months",
      "3589": "Number Retail Trades w/ Major Derogatory Reported w/in 6 Months",
      "3590": "Number Revolving Trades w/ Major Derogatory Reported w/in 6 Months",
      "3592": "Number Trades w/ Major Derogatory Event w/in 24 Months",
      "3593": "Number Auto Trades w/ Major Derogatory Event w/in 24 Months",
      "3594": "Number Bankcard Trades w/ Major Derogatory Event w/in 24 Months",
      "3596": "Number Department Store Trades w/ Major Derogatory Event w/in 24 Months",
      "3597": "Number Installment Trades w/ Major Derogatory Event w/in 24 Months",
      "3598": "Number Mortgage Trades w/ Major Derogatory Event w/in 24 Months",
      "3600": "Number Retail Trades w/ Major Derogatory Event w/in 24 Months",
      "3601": "Number Revolving Trades w/ Major Derogatory Event w/in 24 Months",
      "3602": "Number Sales Finance Trades w/ Major Derogatory Event w/in 24 Months",
      "3603": "Number Trades Major Derogatory",
      "3605": "Number Bankcard Trades Major Derogatory",
      "3607": "Number Department Store Trades Major Derogatory",
      "3608": "Number Installment Trades Major Derogatory",
      "3609": "Number Auto Trades Major Derogatory",
      "3610": "Number Mortgage Trades Major Derogatory",
      "3611": "Number Retail Trades Major Derogatory",
      "3612": "Number Revolving Trades Major Derogatory",
      "3614": "Number Trades w/ Unpaid Major Derogatory Event w/in 24 Months",
      "3616": "Number Bankcard Trades w/ Unpaid Major Derogatory Event w/in 24 Months",
      "3618": "Number Department Store Trades w/ Unpaid Major Derogatory Event w/in 24 Months",
      "3619": "Number Installment Trades w/ Unpaid Major Derogatory Event w/in 24 Months",
      "3622": "Number Retail Trades w/ Unpaid Major Derogatory Event w/in 24 Months",
      "3623": "Number Revolving Trades w/ Unpaid Major Derogatory Event w/in 24 Months",
      "3624": "Number Sales Finance Trades w/ Unpaid Major Derogatory Event w/in 24 Months",
      "3625": "Number Trades Unpaid Major Derogatory",
      "3627": "Number Bankcard Trades Unpaid Major Derogatory",
      "3629": "Number Department Store Trades Unpaid Major Derogatory",
      "3630": "Number Installment Trades Unpaid Major Derogatory",
      "3633": "Number Retail Trades Unpaid Major Derogatory",
      "3634": "Number Revolving Trades Unpaid Major Derogatory",
      "3723": "Number Open Retail Trades w/ Update w/in 3 Months w/ Balance >= 50% High Credit",
      "3724": "Number Open Revolving Trades w/ Update w/in 3 Months w/ Balance >= 50% High Credit",
      "3725": "Number Open Auto Trades w/ Update w/in 3 Months w/ Balance >= 75% Loan Amount",
      "3726": "Number Open Bankcard Trades w/ Update w/in 3 Months w/ Balance >= 75% High Credit",
      "3727": "Number Open Department Store Trades w/ Update w/in 3 Months w/ Balance >= 75% High Credit",
      "3728": "Number Open Installment Trades w/ Update w/in 3 Months w/ Balance >= 75% Loan Amount",
      "3729": "Number Open Mortgage Trades w/ Update w/in 3 Months w/ Balance >= 75% Loan Amount",
      "3743": "Months on File",
      "3744": "Number Retail Trades Reported w/in 3 Months",
      "3745": "Number Retail Trades Reported w/in 6 Months",
      "3746": "Subject’s Age",
      "3747": "Number Trades Reported w/in 3 Months",
      "3748": "Number Bankcard Trades Reported w/in 3 Months",
      "3749": "Number Department Store Trades Reported w/in 3 Months",
      "3750": "Number Installment Trades Reported w/in 3 Months",
      "3751": "Number Revolving Trades Reported w/in 3 Months",
      "3752": "Number Trades Reported w/in 6 Months",
      "3753": "Number Bankcard Trades Reported w/in 6 Months",
      "3754": "Number Revolving Trades Reported w/in 6 Months",
      "3755": "Age Newest Date Last Activity Trades Paid as Agreed",
      "3756": "Age Newest Date Last Activity Bankcard Trades Paid as Agreed",
      "3757": "Age Newest Date Last Activity Installment Trades Paid as Agreed",
      "3758": "Age Newest Date Last Activity Revolving Trades Paid as Agreed",
      "3759": "Age Newest Date Last Activity Trades Other Than Paid as Agreed",
      "3760": "Age Newest Date Last Activity Bankcard Trades Other Than Paid as Agreed",
      "3761": "Age Newest Date Last Activity Installment Trades Other Than Paid as Agreed",
      "3762": "Age Newest Date Last Activity Revolving Trades Other Than Paid as Agreed",
      "3794": "Number Unpaid 3rd Party Collections w/in 12 Months",
      "3795": "Number Unpaid 3rd Party Collections w/in 24 Months",
      "3796": "Number Unpaid 3rd Party Collections",
      "3797": "Total Collection Amount Unpaid 3rd Party Collections w/in 12 Months",
      "3798": "Total Collection Amount Unpaid 3rd Party Collections w/in 24 Months",
      "3799": "Total Collection Amount Unpaid 3rd Party Collections",
      "3800": "Dismissed Bankruptcy Public Record w/in 24 Months Flag",
      "3801": "Dismissed Bankruptcy Public Record Flag",
      "3802": "Discharged Bankruptcy Public Record w/in 24 Months Flag",
      "3803": "Discharged Bankruptcy Public Record Flag",
      "3804": "Non-Dismissed, Non-Discharged Bankruptcy Public Record w/in 24 Months Flag",
      "3805": "Non-Dismissed, Non-Discharged Bankruptcy Public Record Flag",
      "3806": "Foreclosure w/in 12 Months Flag",
      "3807": "Number Public Record Tax Liens, Suits and Judgments and 3rd Party Collection Items excluding Debt Buyer",
      "3808": "Total Balance Defaulted Student Loan Trades w/Update w/in 3 Months",
      "3809": "Percent Balance to High Credit Open Retail Trades w/Update w/in 3 Months",
      "3810": "Percent Retail Trades to Revolving Trades",
      "3811": "Percent Retail Trades to Trades",
      "3812": "Age Newest Tax Lien Public Record Item",
      "3813": "Age Newest Judgment Public Record Item",
      "3814": "Number of Tax Lien Pub Rec Item",
      "3815": "Number of Judgment Pub Rec Item",
      "3825": "Percent Bankcard Trades to Trades",
      "3826": "Percent Department Store Trades to Trades",
      "3827": "Percent Installment Trades to Trades",
      "3828": "Percent Revolving Trades to Trades",
      "3829": "Percent Bankcard Trades to Revolving Trades",
      "3830": "Percent Open Department Store Trades to Open Retail Trades",
      "3831": "Percent Open Bankcard Trades to Open Revolving Trades",
      "3832": "Percent Open Trades to Trades",
      "3833": "Percent Open Bankcard Trades to Bankcard Trades",
      "3834": "Percent Open Department Store Trades to Department Store Trades",
      "3835": "Percent Open Installment Trades to Installment Trades",
      "3836": "Percent Open Revolving Trades to Revolving Trades",
      "3837": "Percent Open Bankcard Trades to Open Trades",
      "3838": "Percent Open Credit Union Trades to Open Trades",
      "3839": "Percent Open Department Store Trades to Open Trades",
      "3840": "Percent Open Installment Trades to Open Trades",
      "3841": "Percent Open Retail Trades to Open Trades",
      "3842": "Percent Open Revolving Trades to Open Trades",
      "3843": "Percent Trades Opened w/in 6 Months to Trades",
      "3844": "Percent Trades Opened w/in 12 Months to Trades",
      "3845": "Percent Revolving Trades Opened w/in 6 Months to Revolving Trades",
      "3846": "Total Balance Trades w/Update w/in 3 Months",
      "3847": "Total Balance Bankcard Trades w/Update w/in 3 Months",
      "3848": "Total Balance Credit Union Trades w/Update w/in 3 Months",
      "3849": "Total Balance Department Store Trades w/Update w/in 3 Months",
      "3850": "Total Balance Personal Finance and Student Loan Trades w/Update w/in 3 Months",
      "3851": "Total Balance Retail Trades w/Update w/in 3 Months",
      "3852": "Total Balance Revolving Trades w/Update w/in 3 Months",
      "3853": "Total Balance Sales Finance Trades w/Update w/in 3 Months",
      "3854": "Percent Balance to High Credit Open Bankcard Trades w/Update w/in 3 Months",
      "3855": "Percent Balance to High Credit Open Department Store Trades w/Update w/in 3 Months",
      "3856": "Percent Balance to High Credit Open Revolving Trades w/Update w/in 3 Months",
      "3857": "Percent Balance to Total Loan Amount Open Auto Trades w/Update w/in 3 Months",
      "3858": "Percent Balance to Total Loan Amount Open Installment Trades w/Update w/in 3 Months",
      "3859": "Percent Balance to Total Loan Amount Open Mortgage Trades w/Update w/in 3 Months",
      "3860": "Percent Total Past Due Amount to Total Balance Bankcard Trades w/Update w/in 3 Months",
      "3861": "Percent Total Past Due Amount to Total Balance Retail Trades w/Update w/in 3 Months",
      "3862": "Percent Total Past Due Amount to Total Balance Revolving Trades w/Update w/in 3 Months",
      "3863": "Percent Total Past Due Amount to Total Balance Trades w/Update w/in 3 Months",
      "3864": "Percent Trades Always Satisfactory to Trades",
      "3865": "Percent Trades Satisfactory w/in 3 Months to Trades Reported w/in 3 Months",
      "3866": "Percent Bankcard Trades Satisfactory w/in 3 Months to Bankcard Trades Reported w/in 3 Months",
      "3867": "Percent Department Store Trades Satisfactory w/in 3 Months to Department Store Trades Reported w/in 3 Months",
      "3868": "Percent Installment Trades Satisfactory w/in 3 Months to Installment Trades Reported w/in 3 Months",
      "3869": "Percent Revolving Trades Satisfactory w/in 3 Months to Revolving Trades Reported w/in 3 Months",
      "3870": "Number Trades Worst Rating 60 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3871": "Number Trades Worst Rating 90 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3872": "Number Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3873": "Number Bankcard Trades Worst Rating 60 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3874": "Number Bankcard Trades Worst Rating 90 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3875": "Number Bankcard Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3876": "Number Department Store Trades Worst Rating 60 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3877": "Number Department Store Trades Worst Rating 90 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3878": "Number Department Store Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3879": "Number Installment Trades Worst Rating 60 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3880": "Number Installment Trades Worst Rating 90 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3881": "Number Installment Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3882": "Number Retail Trades Worst Rating 60 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3883": "Number Retail Trades Worst Rating 90 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3884": "Number Retail Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3885": "Number Revolving Trades Worst Rating 60 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3886": "Number Revolving Trades Worst Rating 90 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3887": "Number Revolving Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months",
      "3888": "Number Trades Worst Rating No Worse Than 59 Days Past Due w/in 3 Months",
      "3889": "Number Bankcard Trades Worst Rating No Worse Than 59 Days Past Due w/in 3 Months",
      "3890": "Number Department Store Trades Worst Rating No Worse Than 59 Days Past Due w/in 3 Months",
      "3891": "Number Installment Trades Worst Rating No Worse Than 59 Days Past Due w/in 3 Months",
      "3892": "Number Retail Trades Worst Rating No Worse Than 59 Days Past Due w/in 3 Months",
      "3893": "Number Revolving Trades Worst Rating No Worse Than 59 Days Past Due w/in 3 Months",
      "3894": "Percent Trades Worst Rating 60 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months to Trades Reported w/in 3 Months",
      "3895": "Percent Trades Worst Rating 90 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months to Trades Reported w/in 3 Months",
      "3896": "Percent Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months to Trades Reported w/in 3 Months",
      "3897": "Percent Bankcard Trades Worst Rating 60 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months to Bankcard Trades Reported w/in 3 Months",
      "3898": "Percent Bankcard Trades Worst Rating 90 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months to Bankcard Trades Reported w/in 3 Months",
      "3899": "Percent Bankcard Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months to Bankcard Trades Reported w/in 3 Months",
      "3900": "Percent Satisfactory Bankcard Trades to Total Bankcard Trades",
      "3901": "Percent Satisfactory Revolving Trades to Total Revolving Trades",
      "3902": "Bankruptcy w/in 24 Months Flag",
      "3903": "Bankruptcy Flag",
      "3904": "Foreclosure w/in 24 Months Flag",
      "3905": "Foreclosure Flag",
      "3907": "Number 3rd Party Collections w/in 12 Months",
      "3908": "Number 3rd Party Collections w/in 24 Months",
      "3909": "Number 3rd Party Collections",
      "3911": "Total Collection Amount 3rd Party Collections w/in 12 Months",
      "3912": "Total Collection Amount 3rd Party Collections w/in 24 Months",
      "3913": "Total Collection Amount 3rd Party Collections",
      "3914": "Percent Revolving Trades Worst Rating 60 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months to Revolving Trades Reported w/in 3 Months",
      "3915": "Percent Revolving Trades Worst Rating 90 Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months to Revolving Trades Reported w/in 3 Months",
      "3916": "Percent Revolving Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 3 Months or Major Derogatory Event w/in 24 Months to Revolving Trades Reported w/in 3 Months",
      "3917": "Number Trades Worst Rating 60 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3918": "Number Trades Worst Rating 90 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3919": "Number Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3920": "Number Bankcard Trades Worst Rating 60 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3921": "Number Bankcard Trades Worst Rating 90 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3922": "Number Bankcard Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3923": "Number Department Store Trades Worst Rating 60 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3924": "Number Department Store Trades Worst Rating 90 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3925": "Number Department Store Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3926": "Number Installment Trades Worst Rating 60 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3927": "Number Installment Trades Worst Rating 90 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3928": "Number Installment Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3929": "Number Retail Trades Worst Rating 60 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3930": "Number Retail Trades Worst Rating 90 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3931": "Number Retail Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3932": "Number Revolving Trades Worst Rating 60 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3933": "Number Revolving Trades Worst Rating 90 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3934": "Number Revolving Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months",
      "3935": "Number Trades Worst Rating No Worse Than 59 Days Past Due w/in 6 Months",
      "3936": "Number Bankcard Trades Worst Rating No Worse Than 59 Days Past Due w/in 6 Months",
      "3937": "Number Revolving Trades Worst Rating No Worse Than 59 Days Past Due w/in 6 Months",
      "3938": "Percent Trades Satisfactory w/in 6 Months to Trades Reported w/in 6 Months",
      "3939": "Percent Bankcard Trades Satisfactory w/in 6 Months to Bankcard Trades Reported w/in 6 Months",
      "3940": "Percent Revolving Trades Satisfactory w/in 6 Months to Revolving Trades Reported w/in 6 Months",
      "3941": "Percent Trades Worst Rating 60 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months to Trades Reported w/in 6 Months",
      "3942": "Percent Trades Worst Rating 90 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months to Trades Reported w/in 6 Months",
      "3943": "Percent Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months to Trades Reported w/in 6 Months",
      "3944": "Percent Bankcard Trades Worst Rating 60 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months to Bankcard Trades Reported w/in 6 Months",
      "3945": "Percent Bankcard Trades Worst Rating 90 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months to Bankcard Trades Reported w/in 6 Months",
      "3946": "Percent Bankcard Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months to Bankcard Trades Reported w/in 6 Months",
      "3947": "Percent Revolving Trades Worst Rating 60 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months to Revolving Trades Reported w/in 6 Months",
      "3948": "Percent Revolving Trades Worst Rating 90 Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months to Revolving Trades Reported w/in 6 Months",
      "3949": "Percent Revolving Trades Worst Rating 120-180 or More Days Past Due or Worse w/in 6 Months or Major Derogatory Event w/in 24 Months to Revolving Trades Reported w/in 6 Months",
      "3950": "Number Trades Worst Rating Ever 60 Days Past Due or Worse",
      "3951": "Number Trades Worst Rating Ever 90 Days Past Due or Worse",
      "3952": "Number Trades Worst Rating Ever 120-180 or More Days Past Due or Worse",
      "3953": "Number Bankcard Trades Worst Rating Ever 60 Days Past Due or Worse",
      "3954": "Number Bankcard Trades Worst Rating Ever 90 Days Past Due or Worse",
      "3955": "Number Bankcard Trades Worst Rating Ever 120-180 or More Days Past Due or Worse",
      "3956": "Number Department Store Trades Worst Rating Ever 60 Days Past Due or Worse",
      "3957": "Number Department Store Trades Worst Rating Ever 90 Days Past Due or Worse",
      "3958": "Number Department Store Trades Worst Rating Ever 120-180 or More Days Past Due or Worse",
      "3959": "Number Installment Trades Worst Rating Ever 60 Days Past Due or Worse",
      "3960": "Number Installment Trades Worst Rating Ever 90 Days Past Due or Worse",
      "3961": "Number Installment Trades Worst Rating Ever 120-180 or More Days Past Due or Worse",
      "3962": "Number Revolving Trades Worst Rating Ever 60 Days Past Due or Worse",
      "3963": "Number Revolving Trades Worst Rating Ever 90 Days Past Due or Worse",
      "3964": "Number Revolving Trades Worst Rating Ever 120-180 or More Days Past Due or Worse",
      "3965": "Number Trades Worst Rating Ever No Worse Than 59 Days Past Due",
      "3966": "Number Bankcard Trades Worst Rating Ever No Worse Than 59 Days Past Due",
      "3967": "Number Revolving Trades Worst Rating Ever No Worse Than 59 Days Past Due",
      "3968": "Percent Trades Worst Rating Ever 60 Days Past Due or Worse to Trades",
      "3969": "Percent Trades Worst Rating Ever 90 Days Past Due or Worse to Trades",
      "3970": "Percent Trades Worst Rating Ever 120-180 or More Days Past Due or Worse to Trades",
      "3971": "Percent Trades Major Derogatory to Trades",
      "3972": "Percent Trades Unpaid Major Derogatory to Trades",
      "3973": "Percent Bankcard Trades Worst Rating Ever 60 Days Past Due or Worse to Bankcard Trades",
      "3974": "Percent Bankcard Trades Worst Rating Ever 90 Days Past Due or Worse to Bankcard Trades",
      "3975": "Percent Bankcard Trades Worst Rating Ever 120-180 or More Days Past Due or Worse to Bankcard Trades",
      "3976": "Percent Bankcard Trades Major Derogatory to Bankcard Trades",
      "3977": "Percent Bankcard Trades Unpaid Major Derogatory to Bankcard Trades",
      "3978": "Percent Revolving Trades Worst Rating Ever 60 Days Past Due or Worse to Revolving Trades",
      "3979": "Percent Revolving Trades Worst Rating Ever 90 Days Past Due or Worse to Revolving Trades",
      "3980": "Percent Revolving Trades Worst Rating Ever 120-180 or More Days Past Due or Worse to Revolving Trades",
      "3981": "Percent Revolving Trades Major Derogatory to Revolving Trades",
      "3982": "Percent Revolving Trades Unpaid Major Derogatory to Revolving Trades",
      "3983": "Percent Trades w/Major Derogatory Event w/in 24 Months to Trades",
      "3984": "Percent Bankcard Trades w/Major Derogatory Event w/in 24 Months to Bankcard Trades",
      "3985": "Percent Department Store Trades w/Major Derogatory Event w/in 24 Months to Department Store Trades",
      "3986": "Percent Installment Trades w/Major Derogatory Event w/in 24 Months to Installment Trades",
      "3987": "Percent Revolving Trades w/Major Derogatory Event w/in 24 Months to Revolving Trades",
      "3988": "Percent Trades w/Unpaid Major Derogatory Event w/in 24 Months to Trades",
      "3989": "Percent Bankcard Trades w/Unpaid Major Derogatory Event w/in 24 Months to Bankcard Trades",
      "3990": "Percent Department Store Trades w/Unpaid Major Derogatory Event w/in 24 Months to Department Store Trades",
      "3991": "Percent Installment Trades w/Unpaid Major Derogatory Event w/in 24 Months to Installment Trades",
      "3992": "Percent Revolving Trades w/Unpaid Major Derogatory Event w/in 24 Months to Revolving Trades",
      "3993": "Percent Inquiries w/in 3 Months to Inquiries w/in 12 Months",
      "3994": "Percent Inquiries w/in 3 Months to Inquiries w/in 24 Months",
      "3995": "Percent Inquiries w/in 12 Months to Inquiries w/in 24 Months"
    }
  },
  {
    "model": "5149",
    "attributes": {
      "6001": "Percent of missing open bankcard balances in the last 12 months",
      "6002": "Percent of missing open bankcard scheduled payment amounts in the last 3 months",
      "6003": "Percent of missing open bankcard scheduled payment amounts in the last 6 months",
      "6004": "Percent of missing open bankcard scheduled payment amounts in the last 24 months",
      "6005": "Percent of missing open revolving scheduled payment amounts in the last 3 months",
      "6006": "Percent of missing open revolving scheduled payment amounts in the last 6 months",
      "6007": "Percent of missing open revolving scheduled payment amounts in the last 12 months",
      "6008": "Percent of missing open revolving scheduled payment amounts in the last 24 months",
      "6009": "Percent of missing open retail scheduled payment amounts in the last 12 months",
      "6010": "Percent of missing open retail scheduled payment amounts in the last 24 months",
      "6011": "Percent of missing open bankcard actual payment amounts in the last 6 months",
      "6012": "Percent of missing open revolving actual payment amounts in the last 3 months",
      "6013": "Percent of missing open revolving actual payment amounts in the last 6 months",
      "6014": "Percent of missing open revolving actual payment amounts in the last 24 months",
      "6015": "Percent of missing open retail actual payment amounts in the last 3 months",
      "6016": "Percent of missing open bankcard credit limits in the last 3 months",
      "6017": "Percent of missing open bankcard credit limits in the last 6 months",
      "6018": "Percent of missing open bankcard credit limits in the last 9 months",
      "6019": "Percent of missing open revolving credit limits in the last 6 months",
      "6020": "Percent of missing open revolving credit limits in the last 12 months",
      "6021": "Percent of missing open revolving credit limits in the last 24 months",
      "6022": "Percent of missing open retail credit limits in the last 6 months",
      "6023": "Percent of missing open retail credit limits in the last 24 months",
      "6024": "Percent of missing open bankcard past due amounts in the last 3 months",
      "6025": "Percent of missing open bankcard past due amounts in the last 9 months",
      "6026": "Percent of missing open bankcard past due amounts in the last 12 months",
      "6027": "Percent of missing open bankcard past due amounts in the last 24 months",
      "6028": "Percent of missing open revolving past due amounts in the last 3 months",
      "6029": "Percent of missing open revolving past due amounts in the last 6 months",
      "6030": "Percent of missing open revolving past due amounts in the last 12 months",
      "6031": "Percent of missing open revolving past due amounts in the last 24 months",
      "6032": "Percent of missing open retail past due amounts in the last 3 months",
      "6033": "Percent of missing open retail past due amounts in the last 24 months",
      "6034": "Percent of missing open auto past due amounts in the last 9 months",
      "6035": "Percent of missing open auto past due amounts in the last 24 months",
      "6036": "Percent of missing open mortgage past due amounts in the last 9 months",
      "6037": "Percent of missing open mortgage past due amounts in the last 12 months",
      "6038": "Percent of missing open mortgage past due amounts in the last 24 months",
      "6039": "Percent of missing open bankcard utilizations in the last 24 months",
      "6040": "Percent of missing open revolving utilizations in the last 3 months",
      "6041": "Percent of missing open revolving utilizations in the last 6 months",
      "6042": "Percent of missing open revolving utilizations in the last 24 months",
      "6043": "Percent of missing open retail utilizations in the last 24 months",
      "6044": "Percent of missing open bankcard over payment amounts in the last 6 months",
      "6045": "Percent of missing open bankcard over payment amounts in the last 24 months",
      "6046": "Percent of missing open revolving over payment amounts in the last 9 months",
      "6047": "Percent of missing open retail over payment amounts in the last 24 months",
      "6048": "Percent of missing open bankcard percent payment amounts in the last 3 months",
      "6049": "Percent of missing open bankcard percent payment amounts in the last 9 months",
      "6050": "Percent of missing open revolving percent payment amounts in the last 3 months",
      "6051": "Percent of missing open retail percent payment amounts in the last 3 months",
      "6052": "Percent of missing open bankcard available credits in the last 6 months",
      "6053": "Percent of missing open bankcard available credits in the last 12 months",
      "6054": "Percent of missing open revolving available credits in the last 3 months",
      "6055": "Percent of missing open revolving available credits in the last 6 months",
      "6056": "Maximum slope on revolving balances in the last 24 months",
      "6057": "Maximum slope on retail balances in the last 9 months",
      "6058": "Maximum slope on bankcard scheduled payment amounts in the last 12 months",
      "6059": "Maximum slope on retail scheduled payment amounts in the last 6 months",
      "6060": "Maximum slope on auto actual payment amounts in the last 12 months",
      "6061": "Maximum slope on bankcard credit limits in the last 24 months",
      "6062": "Maximum slope on revolving credit limits in the last 12 months",
      "6063": "Maximum slope on retail credit limits in the last 24 months",
      "6064": "Maximum slope on revolving past due amounts in the last 6 months",
      "6065": "Maximum slope on revolving past due amounts in the last 12 months",
      "6066": "Maximum slope on revolving past due amounts in the last 24 months",
      "6067": "Maximum slope on mortgage past due amounts in the last 24 months",
      "6068": "Maximum slope on bankcard utilizations in the last 24 months",
      "6069": "Maximum slope on retail utilizations in the last 24 months",
      "6070": "Maximum slope on revolving percent payment amounts in the last 12 months",
      "6071": "Maximum slope on retail available credits in the last 6 months",
      "6072": "Minimum slope on bankcard balances in the last 24 months",
      "6073": "Minimum slope on revolving balances in the last 6 months",
      "6074": "Minimum slope on revolving balances in the last 12 months",
      "6075": "Minimum slope on mortgage balances in the last 12 months",
      "6076": "Minimum slope on retail scheduled payment amounts in the last 24 months",
      "6077": "Minimum slope on bankcard credit limits in the last 24 months",
      "6078": "Minimum slope on retail credit limits in the last 24 months",
      "6079": "Minimum slope on revolving past due amounts in the last 9 months",
      "6080": "Minimum slope on revolving past due amounts in the last 12 months",
      "6081": "Minimum slope on retail past due amounts in the last 24 months",
      "6082": "Minimum slope on mortgage past due amounts in the last 12 months",
      "6083": "Minimum slope on bankcard utilizations in the last 12 months",
      "6084": "Minimum slope on bankcard utilizations in the last 24 months",
      "6085": "Minimum slope on retail utilizations in the last 12 months",
      "6086": "Minimum slope on retail over payment amounts in the last 24 months",
      "6087": "Minimum slope on auto over payment amounts in the last 12 months",
      "6088": "Average slope on retail balances in the last 9 months",
      "6089": "Average slope on retail balances in the last 12 months",
      "6090": "Average slope on retail actual payment amounts in the last 24 months",
      "6091": "Average slope on retail credit limits in the last 24 months",
      "6092": "Average slope on mortgage past due amounts in the last 9 months",
      "6093": "Average slope on bankcard utilizations in the last 12 months",
      "6094": "Average slope on bankcard over payment amounts in the last 6 months",
      "6095": "Average slope on revolving available credits in the last 24 months",
      "6096": "Average slope on retail available credits in the last 24 months",
      "6097": "Maximum difference on bankcard balances in the last 6 months",
      "6098": "Maximum difference on bankcard balances in the last 24 months",
      "6099": "Maximum difference on retail balances in the last 3 months",
      "6100": "Maximum difference on retail balances in the last 9 months",
      "6101": "Maximum difference on mortgage balances in the last 24 months",
      "6102": "Maximum difference on revolving scheduled payment amounts in the last 3 months",
      "6103": "Maximum difference on retail scheduled payment amounts in the last 12 months",
      "6104": "Maximum difference on bankcard credit limits in the last 9 months",
      "6105": "Maximum difference on revolving credit limits in the last 6 months",
      "6106": "Maximum difference on bankcard utilization in the last 24 months",
      "6107": "Maximum difference on bankcard available credit in the last 3 months",
      "6108": "Minimum difference on bankcard balances in the last 24 months",
      "6109": "Minimum difference on retail balances in the last 6 months",
      "6110": "Minimum difference on retail balances in the last 24 months",
      "6111": "Minimum difference on auto balances in the last 24 months",
      "6112": "Minimum difference on revolving scheduled payment amounts in the last 24 months",
      "6113": "Minimum difference on retail scheduled payment amounts in the last 24 months",
      "6114": "Minimum difference on bankcard utilizations in the last 3 months",
      "6115": "Minimum difference on bankcard utilizations in the last 6 months",
      "6116": "Minimum difference on bankcard utilizations in the last 9 months",
      "6117": "Minimum difference on bankcard utilizations in the last 12 months",
      "6118": "Minimum difference on bankcard utilizations in the last 24 months",
      "6119": "Minimum difference on revolving utilizations in the last 12 months",
      "6120": "Minimum difference on revolving percent payment amounts in the last 3 months",
      "6121": "Minimum difference on bankcard available credits in the last 3 months",
      "6122": "Minimum difference on bankcard available credits in the last 24 months",
      "6123": "Average difference on bankcard balances in the last 6 months",
      "6124": "Average difference on revolving balances in the last 24 months",
      "6125": "Average difference on retail balances in the last 9 months",
      "6126": "Average difference on mortgage balances in the last 24 months",
      "6127": "Average difference on retail scheduled payment amounts in the last 9 months",
      "6128": "Average difference on retail actual payment amounts in the last 9 months",
      "6129": "Average difference on retail credit limits in the last 9 months",
      "6130": "Average difference on bankcard utilizations in the last 6 months",
      "6131": "Average difference on revolving utilizations in the last 9 months",
      "6132": "Average difference on bankcard available credits in the last 9 months",
      "6133": "Maximum percent difference on mortgage balances in the last 24 months",
      "6134": "Maximum percent difference on bankcard scheduled payment amounts in the last 24 months",
      "6135": "Maximum percent difference on revolving scheduled payment amounts in the last 3 months",
      "6136": "Maximum percent difference on revolving scheduled payment amounts in the last 12 months",
      "6137": "Maximum percent difference on retail scheduled payment amounts in the last 12 months",
      "6138": "Maximum percent difference on revolving actual payment amounts in the last 12 months",
      "6139": "Maximum percent difference on retail actual payment amounts in the last 12 months",
      "6140": "Maximum percent difference on retail actual payment amounts in the last 24 months",
      "6141": "Maximum percent difference on revolving credit limits in the last 12 months",
      "6142": "Maximum percent difference on retail credit limits in the last 24 months",
      "6143": "Maximum percent difference on mortgage past due amounts in the last 3 months",
      "6144": "Maximum percent difference on bankcard utilizations in the last 6 months",
      "6145": "Maximum percent difference on bankcard utilizations in the last 12 months",
      "6146": "Maximum percent difference on revolving utilizations in the last 3 months",
      "6147": "Maximum percent difference on revolving utilizations in the last 6 months",
      "6148": "Maximum percent difference on revolving utilizations in the last 9 months",
      "6149": "Maximum percent difference on revolving utilizations in the last 12 months",
      "6150": "Maximum percent difference on auto over payment amounts in the last 3 months",
      "6151": "Minimum percent difference on revolving balances in the last 3 months",
      "6152": "Minimum percent difference on revolving balances in the last 6 months",
      "6153": "Minimum percent difference on auto balances in the last 12 months",
      "6154": "Minimum percent difference on auto balances in the last 24 months",
      "6155": "Minimum percent difference on bankcard scheduled payment amounts in the last 24 months",
      "6156": "Minimum percent difference on revolving scheduled payment amounts in the last 3 months",
      "6157": "Minimum percent difference on retail scheduled payment amounts in the last 3 months",
      "6158": "Minimum percent difference on revolving credit limits in the last 9 months",
      "6159": "Minimum percent difference on revolving credit limits in the last 24 months",
      "6160": "Minimum percent difference on auto past due amounts in the last 12 months",
      "6161": "Minimum percent difference on revolving utilizations in the last 3 months",
      "6162": "Minimum percent difference on revolving utilizations in the last 6 months",
      "6163": "Minimum percent difference on auto over payment amounts in the last 24 months",
      "6164": "Average percent difference on bankcard balances in the last 9 months",
      "6165": "Average percent difference on mortgage balances in the last 9 months",
      "6166": "Average percent difference on mortgage balances in the last 24 months",
      "6167": "Average percent difference on revolving scheduled payment amounts in the last 3 months",
      "6168": "Average percent difference on bankcard actual payment amounts in the last 9 months",
      "6169": "Average percent difference on revolving actual payment amounts in the last 9 months",
      "6170": "Average percent difference on bankcard past due amounts in the last 3 months",
      "6171": "Average percent difference on bankcard utilizations in the last 3 months",
      "6172": "Average percent difference on revolving utilizations in the last 3 months",
      "6173": "Average percent difference on retail utilizations in the last 6 months",
      "6174": "Average percent difference on retail utilizations in the last 24 months",
      "6175": "Average percent difference on retail available credits in the last 3 months",
      "6176": "Maximum scheduled payment amount on revolving accounts in the last 24 months",
      "6177": "Maximum credit limit on retail accounts in the last 24 months",
      "6178": "Maximum past due amount on bankcard accounts in the last 24 months",
      "6179": "Maximum utilization on bankcard accounts in the last 6 months",
      "6180": "Maximum utilization on bankcard accounts in the last 9 months",
      "6181": "Maximum utilization on bankcard accounts in the last 12 months",
      "6182": "Maximum utilization on bankcard accounts in the last 24 months",
      "6183": "Maximum utilization on revolving accounts in the last 9 months",
      "6184": "Maximum available credit on bankcard accounts in the last 24 months",
      "6185": "Maximum available credit on revolving accounts in the last 24 months",
      "6186": "Maximum available credit on retail accounts in the last 24 months",
      "6187": "Minimum of maximum tradeline balance on bankcard accounts in the last 3 months",
      "6188": "Minimum of maximum tradeline balance on bankcard accounts in the last 6 months",
      "6189": "Minimum of maximum tradeline balance on bankcard accounts in the last 9 months",
      "6190": "Minimum of maximum tradeline balance on bankcard accounts in the last 24 months",
      "6191": "Minimum of maximum tradeline scheduled payment amount on bankcard accounts in the last 12 months",
      "6192": "Minimum of maximum tradeline scheduled payment amount on revolving accounts in the last 9 months",
      "6193": "Minimum of maximum tradeline scheduled payment amount on revolving accounts in the last 24 months",
      "6194": "Minimum of maximum tradeline scheduled payment amount on retail accounts in the last 6 months",
      "6195": "Minimum of maximum tradeline actual payment amount on retail accounts in the last 24 months",
      "6196": "Minimum of maximum tradeline credit limit on bankcard accounts in the last 24 months",
      "6197": "Minimum of maximum tradeline credit limit on revolving accounts in the last 24 months",
      "6198": "Minimum of maximum tradeline past due amount on bankcard accounts in the last 12 months",
      "6199": "Minimum of maximum tradeline utilization on bankcard accounts in the last 3 months",
      "6200": "Minimum of maximum tradeline utilization on bankcard accounts in the last 9 months",
      "6201": "Minimum of maximum tradeline utilization on bankcard accounts in the last 24 months",
      "6202": "Minimum of maximum tradeline utilization on revolving accounts in the last 24 months",
      "6203": "Minimum of maximum tradeline over payment amount on revolving accounts in the last 12 months",
      "6204": "Minimum of maximum tradeline over payment amount on mortgage accounts in the last 24 months",
      "6205": "Minimum of maximum tradeline percent payment amount on bankcard accounts in the last 24 months",
      "6206": "Minimum of maximum tradeline available credit on bankcard accounts in the last 9 months",
      "6207": "Average of maximum tradeline balance on bankcard accounts in the last 24 months",
      "6208": "Average of maximum tradeline balance on retail accounts in the last 24 months",
      "6209": "Average of maximum tradeline scheduled payment amount on revolving accounts in the last 9 months",
      "6210": "Average of maximum tradeline actual payment amount on bankcard accounts in the last 24 months",
      "6211": "Average of maximum tradeline actual payment amount on retail accounts in the last 24 months",
      "6212": "Average of maximum tradeline credit limit on bankcard accounts in the last 24 months",
      "6213": "Average of maximum tradeline credit limit on retail accounts in the last 12 months",
      "6214": "Average of maximum tradeline past due amount on bankcard accounts in the last 12 months",
      "6215": "Average of maximum tradeline past due amount on revolving accounts in the last 12 months",
      "6216": "Average of maximum tradeline past due amount on auto accounts in the last 6 months",
      "6217": "Average of maximum tradeline past due amount on auto accounts in the last 24 months",
      "6218": "Average of maximum tradeline utilization on bankcard accounts in the last 6 months",
      "6219": "Average of maximum tradeline utilization on bankcard accounts in the last 9 months",
      "6220": "Average of maximum tradeline utilization on bankcard accounts in the last 24 months",
      "6221": "Average of maximum tradeline over payment amount on bankcard accounts in the last 24 months",
      "6222": "Average of maximum tradeline percent payment amount on revolving accounts in the last 12 months",
      "6223": "Average of maximum tradeline percent payment amount on retail accounts in the last 24 months",
      "6224": "Average of maximum tradeline available credit on bankcard accounts in the last 3 months",
      "6225": "Average of maximum tradeline available credit on bankcard accounts in the last 12 months",
      "6226": "Average of maximum tradeline available credit on revolving accounts in the last 6 months",
      "6227": "Average of maximum tradeline available credit on revolving accounts in the last 24 months",
      "6228": "Maximum of minimum tradeline balance on revolving accounts in the last 9 months",
      "6229": "Maximum of minimum tradeline past due amount on bankcard accounts in the last 9 months",
      "6230": "Maximum of minimum tradeline past due amount on bankcard accounts in the last 24 months",
      "6231": "Maximum of minimum tradeline past due amount on mortgage accounts in the last 24 months",
      "6232": "Maximum of minimum tradeline utilization on bankcard accounts in the last 3 months",
      "6233": "Maximum of minimum tradeline utilization on bankcard accounts in the last 9 months",
      "6234": "Maximum of minimum tradeline utilization on bankcard accounts in the last 12 months",
      "6235": "Maximum of minimum tradeline utilization on revolving accounts in the last 6 months",
      "6236": "Maximum of minimum tradeline percent payment amount on retail accounts in the last 3 months",
      "6237": "Maximum of minimum tradeline available credit on bankcard accounts in the last 12 months",
      "6238": "Minimum balance on bankcard accounts in the last 24 months",
      "6239": "Minimum balance on revolving accounts in the last 24 months",
      "6240": "Minimum balance on retail accounts in the last 6 months",
      "6241": "Minimum balance on mortgage accounts in the last 6 months",
      "6242": "Minimum scheduled payment amount on revolving accounts in the last 9 months",
      "6243": "Minimum scheduled payment amount on revolving accounts in the last 24 months",
      "6244": "Minimum credit limit on revolving accounts in the last 12 months",
      "6245": "Minimum past due amount on bankcard accounts in the last 24 months",
      "6246": "Minimum past due amount on revolving accounts in the last 24 months",
      "6247": "Minimum past due amount on auto accounts in the last 12 months",
      "6248": "Minimum utilization on retail accounts in the last 24 months",
      "6249": "Minimum available credit on bankcard accounts in the last 9 months",
      "6250": "Average of minimum tradeline scheduled payment amount on retail accounts in the last 24 months",
      "6251": "Average of minimum tradeline actual payment amount on bankcard accounts in the last 6 months",
      "6252": "Average of minimum tradeline actual payment amount on revolving accounts in the last 6 months",
      "6253": "Average of minimum tradeline credit limit on bankcard accounts in the last 24 months",
      "6254": "Average of minimum tradeline credit limit on revolving accounts in the last 24 months",
      "6255": "Average of minimum tradeline credit limit on retail accounts in the last 24 months",
      "6256": "Average of minimum tradeline past due amount on bankcard accounts in the last 9 months",
      "6257": "Average of minimum tradeline utilization on bankcard accounts in the last 3 months",
      "6258": "Average of minimum tradeline utilization on bankcard accounts in the last 6 months",
      "6259": "Average of minimum tradeline utilization on bankcard accounts in the last 9 months",
      "6260": "Average of minimum tradeline utilization on bankcard accounts in the last 24 months",
      "6261": "Average of minimum tradeline utilization on retail accounts in the last 12 months",
      "6262": "Average of minimum tradeline percent payment amount on retail accounts in the last 3 months",
      "6263": "Average of minimum tradeline available credit on bankcard accounts in the last 12 months",
      "6264": "Average of minimum tradeline available credit on revolving accounts in the last 24 months",
      "6265": "Maximum of average tradeline balance on retail accounts in the last 24 months",
      "6266": "Maximum of average tradeline actual payment amount on bankcard accounts in the last 12 months",
      "6267": "Maximum of average tradeline credit limit on revolving accounts in the last 12 months",
      "6268": "Maximum of average tradeline past due amount on bankcard accounts in the last 3 months",
      "6269": "Maximum of average tradeline utilization on bankcard accounts in the last 3 months",
      "6270": "Maximum of average tradeline utilization on bankcard accounts in the last 12 months",
      "6271": "Maximum of average tradeline utilization on bankcard accounts in the last 24 months",
      "6272": "Maximum of average tradeline percent payment amount on bankcard accounts in the last 12 months",
      "6273": "Maximum of average tradeline percent payment amount on retail accounts in the last 24 months",
      "6274": "Maximum of average tradeline available credit on retail accounts in the last 3 months",
      "6275": "Minimum of average tradeline balance on bankcard accounts in the last 12 months",
      "6276": "Minimum of average tradeline balance on revolving accounts in the last 24 months",
      "6277": "Minimum of average tradeline scheduled payment amount on revolving accounts in the last 12 months",
      "6278": "Minimum of average tradeline credit limit on bankcard accounts in the last 3 months",
      "6279": "Minimum of average tradeline credit limit on bankcard accounts in the last 9 months",
      "6280": "Minimum of average tradeline credit limit on bankcard accounts in the last 24 months",
      "6281": "Minimum of average tradeline credit limit on retail accounts in the last 12 months",
      "6282": "Minimum of average tradeline past due amount on bankcard accounts in the last 9 months",
      "6283": "Minimum of average tradeline past due amount on retail accounts in the last 12 months",
      "6284": "Minimum of average tradeline utilization on bankcard accounts in the last 6 months",
      "6285": "Minimum of average tradeline utilization on bankcard accounts in the last 24 months",
      "6286": "Minimum of average tradeline utilization on revolving accounts in the last 24 months",
      "6287": "Minimum of average tradeline over payment amount on retail accounts in the last 12 months",
      "6288": "Minimum of average tradeline percent payment amount on retail accounts in the last 24 months",
      "6289": "Minimum of average tradeline available credit on bankcard accounts in the last 6 months",
      "6290": "Minimum of average tradeline available credit on bankcard accounts in the last 9 months",
      "6291": "Minimum of average tradeline available credit on bankcard accounts in the last 24 months",
      "6292": "Minimum of average tradeline available credit on revolving accounts in the last 24 months",
      "6293": "Minimum of average tradeline available credit on retail accounts in the last 12 months",
      "6294": "Minimum of average tradeline available credit on retail accounts in the last 24 months",
      "6295": "Average balance on retail accounts in the last 12 months",
      "6296": "Average scheduled payment amount on retail accounts in the last 24 months",
      "6297": "Average actual payment amount on bankcard accounts in the last 12 months",
      "6298": "Average actual payment amount on bankcard accounts in the last 24 months",
      "6299": "Average credit limit on retail accounts in the last 9 months",
      "6300": "Average credit limit on retail accounts in the last 12 months",
      "6301": "Average credit limit on retail accounts in the last 24 months",
      "6302": "Average past due amount on auto accounts in the last 24 months",
      "6303": "Average past due amount on mortgage accounts in the last 24 months",
      "6304": "Average utilization on bankcard accounts in the last 6 months",
      "6305": "Average utilization on bankcard accounts in the last 9 months",
      "6306": "Average utilization on bankcard accounts in the last 12 months",
      "6307": "Average utilization on bankcard accounts in the last 24 months",
      "6308": "Average utilization on revolving accounts in the last 3 months",
      "6309": "Average utilization on revolving accounts in the last 24 months",
      "6310": "Average over payment amount on bankcard accounts in the last 12 months",
      "6311": "Average over payment amount on bankcard accounts in the last 24 months",
      "6312": "Average percent payment amount on bankcard accounts in the last 9 months",
      "6313": "Average percent payment amount on bankcard accounts in the last 24 months",
      "6314": "Average percent payment amount on revolving accounts in the last 24 months",
      "6315": "Average percent payment amount on retail accounts in the last 24 months",
      "6316": "Average available credit on bankcard accounts in the last 6 months",
      "6317": "Average available credit on bankcard accounts in the last 24 months",
      "6318": "Average available credit on revolving accounts in the last 24 months",
      "6319": "Maximum of total tradeline actual payment amount on bankcard accounts in the last 6 months",
      "6320": "Maximum of total tradeline actual payment amount on retail accounts in the last 3 months",
      "6321": "Maximum of total tradeline over payment amount on bankcard accounts in the last 9 months",
      "6322": "Maximum of total tradeline over payment amount on bankcard accounts in the last 12 months",
      "6323": "Maximum of total tradeline over payment amount on bankcard accounts in the last 24 months",
      "6324": "Minimum of total tradeline scheduled payment amount on bankcard accounts in the last 12 months",
      "6325": "Minimum of total tradeline scheduled payment amount on retail accounts in the last 9 months",
      "6326": "Minimum of total tradeline actual payment amount on retail accounts in the last 24 months",
      "6327": "Average of total tradeline scheduled payment amount on revolving accounts in the last 6 months",
      "6328": "Average of total tradeline actual payment amount on bankcard accounts in the last 12 months",
      "6329": "Average of total tradeline actual payment amount on bankcard accounts in the last 24 months",
      "6330": "Average of total tradeline actual payment amount on revolving accounts in the last 9 months",
      "6331": "Average of total tradeline actual payment amount on revolving accounts in the last 12 months",
      "6332": "Average of total tradeline actual payment amount on revolving accounts in the last 24 months",
      "6333": "Average of total tradeline actual payment amount on retail accounts in the last 3 months",
      "6334": "Average of total tradeline over payment amount on bankcard accounts in the last 9 months",
      "6335": "Slope of maximum snapshot percent payment amount on mortgage accounts in the last 12 months",
      "6336": "Slope of minimum snapshot balance on revolving accounts in the last 9 months",
      "6337": "Slope of minimum snapshot scheduled payment amount on bankcard accounts in the last 6 months",
      "6338": "Slope of minimum snapshot scheduled payment amount on bankcard accounts in the last 12 months",
      "6339": "Slope of minimum snapshot past due amount on mortgage accounts in the last 9 months",
      "6340": "Slope of minimum snapshot available credit on bankcard accounts in the last 6 months",
      "6341": "Slope of minimum snapshot available credit on revolving accounts in the last 6 months",
      "6342": "Slope of total snapshot scheduled payment amount on bankcard accounts in the last 24 months",
      "6343": "Slope of total snapshot actual payment amount on auto accounts in the last 6 months",
      "6344": "Slope of total snapshot credit limit on retail accounts in the last 6 months",
      "6345": "Slope of total snapshot credit limit on retail accounts in the last 12 months",
      "6346": "Slope of total snapshot over payment amount on retail accounts in the last 12 months",
      "6347": "Slope of average snapshot balance on revolving accounts in the last 3 months",
      "6348": "Slope of average snapshot actual payment amount on retail accounts in the last 3 months",
      "6349": "Slope of average snapshot utilization on bankcard accounts in the last 24 months",
      "6350": "Slope of average snapshot available credit on retail accounts in the last 24 months",
      "6351": "Difference of maximum snapshot scheduled payment amount on bankcard accounts in the last 6 months",
      "6352": "Difference of maximum snapshot credit limit on bankcard accounts in the last 24 months",
      "6353": "Difference of maximum snapshot credit limit on retail accounts in the last 6 months",
      "6354": "Difference of minimum snapshot balance on revolving accounts in the last 12 months",
      "6355": "Difference of minimum snapshot balance on retail accounts in the last 12 months",
      "6356": "Difference of minimum snapshot balance on auto accounts in the last 6 months",
      "6357": "Difference of minimum snapshot balance on mortgage accounts in the last 6 months",
      "6358": "Difference of minimum snapshot scheduled payment amount on bankcard accounts in the last 24 months",
      "6359": "Difference of minimum snapshot actual payment amount on revolving accounts in the last 6 months",
      "6360": "Difference of minimum snapshot past due amount on revolving accounts in the last 9 months",
      "6361": "Difference of minimum snapshot past due amount on mortgage accounts in the last 9 months",
      "6362": "Difference of minimum snapshot over payment amount on bankcard accounts in the last 6 months",
      "6363": "Difference of total snapshot balance on bankcard accounts in the last 24 months",
      "6364": "Difference of total snapshot scheduled payment amount on bankcard accounts in the last 24 months",
      "6365": "Difference of total snapshot actual payment amount on revolving accounts in the last 3 months",
      "6366": "Difference of total snapshot credit limit on retail accounts in the last 24 months",
      "6367": "Difference of total snapshot past due amount on revolving accounts in the last 12 months",
      "6368": "Difference of total snapshot available credit on retail accounts in the last 24 months",
      "6369": "Difference of average snapshot credit limit on revolving accounts in the last 24 months",
      "6370": "Difference of average snapshot utilization on bankcard accounts in the last 9 months",
      "6371": "Difference of average snapshot utilization on retail accounts in the last 12 months",
      "6372": "Difference of average snapshot over payment amount on mortgage accounts in the last 12 months",
      "6373": "Difference of average snapshot percent payment amount on bankcard accounts in the last 3 months",
      "6374": "Difference of average snapshot percent payment amount on revolving accounts in the last 9 months",
      "6375": "Difference of average snapshot available credit on retail accounts in the last 12 months",
      "6376": "Percent difference of maximum snapshot balance on bankcard accounts in the last 24 months",
      "6377": "Percent difference of maximum snapshot balance on revolving accounts in the last 12 months",
      "6378": "Percent difference of maximum snapshot balance on auto accounts in the last 24 months",
      "6379": "Percent difference of maximum snapshot scheduled payment amount on bankcard accounts in the last 24 months",
      "6380": "Percent difference of maximum snapshot scheduled payment amount on revolving accounts in the last 3 months",
      "6381": "Percent difference of maximum snapshot actual payment amount on bankcard accounts in the last 12 months",
      "6382": "Percent difference of maximum snapshot actual payment amount on revolving accounts in the last 9 months",
      "6383": "Percent difference of maximum snapshot utilization on bankcard accounts in the last 3 months",
      "6384": "Percent difference of maximum snapshot utilization on revolving accounts in the last 3 months",
      "6385": "Percent difference of maximum snapshot utilization on revolving accounts in the last 24 months",
      "6386": "Percent difference of minimum snapshot balance on bankcard accounts in the last 6 months",
      "6387": "Percent difference of minimum snapshot balance on auto accounts in the last 3 months",
      "6388": "Percent difference of minimum snapshot utilization on retail accounts in the last 24 months",
      "6389": "Percent difference of minimum snapshot over payment amount on mortgage accounts in the last 3 months",
      "6390": "Percent difference of total snapshot balance on auto accounts in the last 24 months",
      "6391": "Percent difference of total snapshot scheduled payment amount on revolving accounts in the last 24 months",
      "6392": "Percent difference of total snapshot scheduled payment amount on retail accounts in the last 24 months",
      "6393": "Percent difference of total snapshot credit limit on revolving accounts in the last 6 months",
      "6394": "Percent difference of total snapshot credit limit on revolving accounts in the last 24 months",
      "6395": "Percent difference of total snapshot credit limit on retail accounts in the last 6 months",
      "6396": "Percent difference of average snapshot credit limit on bankcard accounts in the last 6 months",
      "6397": "Percent difference of average snapshot credit limit on revolving accounts in the last 6 months",
      "6398": "Percent difference of average snapshot utilization on bankcard accounts in the last 24 months",
      "6399": "Percent difference of average snapshot utilization on revolving accounts in the last 3 months",
      "6400": "Percent difference of average snapshot utilization on retail accounts in the last 6 months",
      "6401": "Percent difference of average snapshot over payment amount on retail accounts in the last 24 months",
      "6402": "Percent difference of average snapshot available credit on bankcard accounts in the last 12 months",
      "6403": "Percent difference of average snapshot available credit on revolving accounts in the last 24 months",
      "6404": "Minimum of maximum snapshot balance on revolving accounts in the last 9 months",
      "6405": "Minimum of maximum snapshot past due amount on mortgage accounts in the last 24 months",
      "6406": "Minimum of maximum snapshot utilization on bankcard accounts in the last 6 months",
      "6407": "Minimum of maximum snapshot utilization on bankcard accounts in the last 24 months",
      "6408": "Minimum of maximum snapshot utilization on revolving accounts in the last 24 months",
      "6409": "Minimum of maximum snapshot over payment amount on revolving accounts in the last 9 months",
      "6410": "Minimum of maximum snapshot over payment amount on auto accounts in the last 24 months",
      "6411": "Minimum of maximum snapshot available credit on revolving accounts in the last 24 months",
      "6412": "Average of maximum snapshot scheduled payment amount on revolving accounts in the last 9 months",
      "6413": "Average of maximum snapshot actual payment amount on revolving accounts in the last 24 months",
      "6414": "Average of maximum snapshot past due amount on retail accounts in the last 3 months",
      "6415": "Average of maximum snapshot past due amount on mortgage accounts in the last 24 months",
      "6416": "Average of maximum snapshot utilization on revolving accounts in the last 24 months",
      "6417": "Average of maximum snapshot percent payment amount on revolving accounts in the last 24 months",
      "6418": "Maximum of minimum snapshot scheduled payment amount on bankcard accounts in the last 3 months",
      "6419": "Maximum of minimum snapshot scheduled payment amount on bankcard accounts in the last 12 months",
      "6420": "Maximum of minimum snapshot actual payment amount on mortgage accounts in the last 3 months",
      "6421": "Maximum of minimum snapshot past due amount on revolving accounts in the last 3 months",
      "6422": "Maximum of minimum snapshot utilization on bankcard accounts in the last 6 months",
      "6423": "Maximum of minimum snapshot available credit on bankcard accounts in the last 6 months",
      "6424": "Maximum of minimum snapshot available credit on bankcard accounts in the last 12 months",
      "6425": "Average of minimum snapshot scheduled payment amount on bankcard accounts in the last 3 months",
      "6426": "Average of minimum snapshot scheduled payment amount on bankcard accounts in the last 6 months",
      "6427": "Average of minimum snapshot scheduled payment amount on bankcard accounts in the last 9 months",
      "6428": "Average of minimum snapshot scheduled payment amount on retail accounts in the last 9 months",
      "6429": "Average of minimum snapshot credit limit on bankcard accounts in the last 24 months",
      "6430": "Average of minimum snapshot utilization on bankcard accounts in the last 6 months",
      "6431": "Average of minimum snapshot utilization on bankcard accounts in the last 9 months",
      "6432": "Average of minimum snapshot utilization on bankcard accounts in the last 24 months",
      "6433": "Average of minimum snapshot available credit on revolving accounts in the last 24 months",
      "6434": "Maximum of total snapshot credit limit on bankcard accounts in the last 9 months",
      "6435": "Maximum of total snapshot credit limit on bankcard accounts in the last 24 months",
      "6436": "Maximum of total snapshot credit limit on retail accounts in the last 6 months",
      "6437": "Maximum of total snapshot past due amount on bankcard accounts in the last 12 months",
      "6438": "Maximum of total snapshot past due amount on bankcard accounts in the last 24 months",
      "6439": "Maximum of total snapshot past due amount on revolving accounts in the last 3 months",
      "6440": "Maximum of total snapshot past due amount on retail accounts in the last 9 months",
      "6441": "Maximum of total snapshot past due amount on retail accounts in the last 12 months",
      "6442": "Maximum of total snapshot past due amount on auto accounts in the last 24 months",
      "6443": "Maximum of total snapshot over payment amount on bankcard accounts in the last 6 months",
      "6444": "Maximum of total snapshot over payment amount on auto accounts in the last 12 months",
      "6445": "Maximum of total snapshot available credit on bankcard accounts in the last 9 months",
      "6446": "Maximum of total snapshot available credit on revolving accounts in the last 9 months",
      "6447": "Maximum of total snapshot available credit on retail accounts in the last 3 months",
      "6448": "Maximum of total snapshot available credit on retail accounts in the last 6 months",
      "6449": "Minimum of total snapshot scheduled payment amount on revolving accounts in the last 3 months",
      "6450": "Minimum of total snapshot actual payment amount on bankcard accounts in the last 6 months",
      "6451": "Minimum of total snapshot actual payment amount on revolving accounts in the last 3 months",
      "6452": "Minimum of total snapshot actual payment amount on retail accounts in the last 3 months",
      "6453": "Minimum of total snapshot credit limit on bankcard accounts in the last 6 months",
      "6454": "Minimum of total snapshot credit limit on bankcard accounts in the last 24 months",
      "6455": "Minimum of total snapshot past due amount on bankcard accounts in the last 24 months",
      "6456": "Minimum of total snapshot over payment amount on bankcard accounts in the last 9 months",
      "6457": "Average of total snapshot balance on auto accounts in the last 6 months",
      "6458": "Average of total snapshot balance on mortgage accounts in the last 12 months",
      "6459": "Average of total snapshot balance on mortgage accounts in the last 24 months",
      "6460": "Average of total snapshot scheduled payment amount on revolving accounts in the last 24 months",
      "6461": "Average of total snapshot actual payment amount on bankcard accounts in the last 24 months",
      "6462": "Average of total snapshot actual payment amount on revolving accounts in the last 6 months",
      "6463": "Average of total snapshot actual payment amount on retail accounts in the last 3 months",
      "6464": "Average of total snapshot credit limit on bankcard accounts in the last 24 months",
      "6465": "Average of total snapshot past due amount on bankcard accounts in the last 12 months",
      "6466": "Average of total snapshot past due amount on bankcard accounts in the last 24 months",
      "6467": "Average of total snapshot past due amount on revolving accounts in the last 3 months",
      "6468": "Average of total snapshot past due amount on revolving accounts in the last 12 months",
      "6469": "Average of total snapshot past due amount on auto accounts in the last 6 months",
      "6470": "Average of total snapshot past due amount on auto accounts in the last 9 months",
      "6471": "Average of total snapshot over payment amount on retail accounts in the last 12 months",
      "6472": "Average of total snapshot available credit on bankcard accounts in the last 12 months",
      "6473": "Average of total snapshot available credit on retail accounts in the last 3 months",
      "6474": "Average of total snapshot available credit on retail accounts in the last 24 months",
      "6475": "Total actual payment amount on bankcard accounts in the last 6 months",
      "6476": "Total actual payment amount on revolving accounts in the last 6 months",
      "6477": "Total actual payment amount on revolving accounts in the last 12 months",
      "6478": "Total actual payment amount on revolving accounts in the last 24 months",
      "6479": "Total actual payment amount on retail accounts in the last 3 months",
      "6480": "Total actual payment amount on retail accounts in the last 6 months",
      "6481": "Total over payment amount on bankcard accounts in the last 12 months",
      "6482": "Total over payment amount on retail accounts in the last 24 months",
      "6483": "Maximum of average snapshot actual payment amount on revolving accounts in the last 24 months",
      "6484": "Maximum of average snapshot utilization on bankcard accounts in the last 3 months",
      "6485": "Maximum of average snapshot utilization on bankcard accounts in the last 6 months",
      "6486": "Maximum of average snapshot utilization on bankcard accounts in the last 9 months",
      "6487": "Maximum of average snapshot utilization on bankcard accounts in the last 12 months",
      "6488": "Maximum of average snapshot utilization on bankcard accounts in the last 24 months",
      "6489": "Maximum of average snapshot utilization on revolving accounts in the last 6 months",
      "6490": "Maximum of average snapshot over payment amount on bankcard accounts in the last 24 months",
      "6491": "Maximum of average snapshot available credit on revolving accounts in the last 3 months",
      "6492": "Minimum of average snapshot actual payment amount on revolving accounts in the last 9 months",
      "6493": "Minimum of average snapshot actual payment amount on retail accounts in the last 9 months",
      "6494": "Minimum of average snapshot credit limit on bankcard accounts in the last 24 months",
      "6495": "Minimum of average snapshot credit limit on revolving accounts in the last 24 months",
      "6496": "Minimum of average snapshot credit limit on retail accounts in the last 12 months",
      "6497": "Minimum of average snapshot credit limit on retail accounts in the last 24 months",
      "6498": "Minimum of average snapshot utilization on revolving accounts in the last 3 months",
      "6499": "Minimum of average snapshot utilization on revolving accounts in the last 24 months",
      "6500": "Minimum of average snapshot available credit on revolving accounts in the last 24 months"
    }
  }
] as {
  model: string
  attributes: {
    [index: string]: string
  }
}[];