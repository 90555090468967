
import React, { FC, useEffect, useState } from 'react';
import { Alert, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';

export type CreateBankAccountDialogPayload = {
  userId: number
  accounts: any[]
} | undefined;

export const CreateBankAccountDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { clientActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [account, setAccount] = useState<string>("");

  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    setAccount("");
    setError(null);
  }, [payload]);

  const handleSendClick = async () => {
    if (payload === undefined) return

    const { userId } = payload;

    const currentAccount = payload.accounts.filter((item) => item.account_id === account);

    if (currentAccount.length === 0) {
      notify('No account selected');
      return;
    }

    const itemId = currentAccount[0].item_id;

    const error = await clientActions.saveChosenPrimaryAccount(
      userId,
      itemId,
      account,
    );

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("created");
    }
  }

  const handleAccountChange = (e: SelectChangeEvent<string>) => {
    setAccount(e.target.value as string);
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Create bank account"
      actionTitle='Create'
      onSend={handleSendClick}>

      <FormControl fullWidth>
        <InputLabel id="account-id-select-label">From account id</InputLabel>
        <Select
          autoFocus={false}
          onFocus={() => { }}
          labelId="account-id-select-label"
          id="demo-simple-select"
          value={account}
          label="Account id"
          onChange={handleAccountChange}>
          {payload?.accounts.map(({ mask, account_id, bank }: { mask?: string, account_id: string, bank: string }) =>
            <MenuItem key={account_id} value={account_id}>{bank} : *{mask} - {account_id}</MenuItem>
          )}
        </Select>
      </FormControl>

      {error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload?: CreateBankAccountDialogPayload
  hideDialog: () => void
}