import currency from "currency.js";
import { TextInput } from "react-admin";

export const DollarInput: React.FC<{ label: string, source: string }> = ({ label, source }) => {
  return <TextInput
    label={label}
    source={source}
    format={(value: any) => {
      return currency(value, { fromCents: true }).dollars();
    }}
    parse={(value) => currency(value).intValue}
  />;
}
