import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { Resource } from "../../common/Resource";
import { ColumnModel } from "../../model/ColumnModel";
import { DateInput, FilterButton, SelectInput, TextInput } from "react-admin";
import { DollarInput } from "../../common/DollarInput";

export const resource = "admin/generic-views/transactions_overview";

const tableColumns = [
  { field: "id", width: "70px", title: "id", show: true },
  { field: "subscription_id", width: "100px", title: "subscription id", show: true },
  { field: "payment_date", width: "200px", title: "payment date", show: true, format: 'yyyy-MM-dd' },
  { field: "amount", width: "100px", title: "amount", show: true, isCents: true },
  { field: "source_account_id", width: "100px", title: "source account id", show: true },
  { field: "status", width: "100px", title: "status", show: true },
  { field: "error", width: "100px", title: "error", show: true },
]

const filters = [
  <TextInput label="id" source="id.eq" />,
  <TextInput label="subscription id" source="subscription_id.eq" alwaysOn />,
  <TextInput label="source account id" source="source_account_id.eq" alwaysOn />,

  <DollarInput label="amount gt (dollar)" source="amount.gt" />,
  <DollarInput label="amount lt (dollar)" source="amount.lt" />,

  <DateInput label="payment date gt" source="payment_date.gt" alwaysOn />,
  <DateInput label="payment date date lt" source="payment_date.lt" />,

  <SelectInput source="status" choices={[
    { id: 'PROCESSING', name: 'PROCESSING' },
    { id: 'INITED', name: 'INITED' },
    { id: 'DECLINED', name: 'DECLINED' },
    { id: 'CLEARED', name: 'CLEARED' },
  ]} alwaysOn />,

];


export const TransactionsOverviewCollection: React.FC<Props> = (props) => {
  const columnModel = useMemo(() => {
    console.log(resource, 'call just once');
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (

    <Box sx={{ position: "relative", padding: "40px 0px 0px 0px" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "10px",
      }}>
        <h4>
          {`Transaction Overview`}
        </h4>
      </Box>

      <Resource
        resource={resource}
        // initialFilters={initialFilters}
        columnModel={columnModel}
        filters={filters}
        tableActions={
          <>
            <FilterButton {...props} />
          </>
        }
      />

    </Box>
  );
}

type Props = {
  initialFilters?: { [index: string]: any }
}