import { FC, useState } from "react";
import { Button } from "react-admin";
import { GetIncomeInfoDrawer } from "./GetIncomeInfoDrawer";

export const GetIncomeInfoButton: FC<Props> = ({
  userId,
}) => {
  const [getIncomeDialogPayload, setIncomeDialogPayload] = useState<{ userId: number } | undefined>(undefined);

  const onClick = async () => {
    const payload = {
      userId: parseInt(userId.toString()),
    };

    setIncomeDialogPayload(payload);
  }

  return <div>
    <GetIncomeInfoDrawer
      payload={getIncomeDialogPayload}
      onClose={() => setIncomeDialogPayload(undefined)}
    />
    <Button
      label="Get income info"
      onClick={onClick} />
  </div>

}

type Props = {
  userId: string;
}