import { fetchUtils } from 'react-admin'

export const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  const token = localStorage.getItem('token')

  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
    })
  }

  ;(options.headers as Headers).set('Authorization', `Bearer ${token}`)
  return fetchUtils.fetchJson(url, options)
}

export const headRequest = (url: string) => {
  const token = localStorage.getItem('token')

  return fetch(url, {
    method: 'HEAD',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }
  });
}