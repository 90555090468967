
import * as React from 'react';
import { classNames } from '../../../../../kendo-react-common';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/**
 * @hidden
 */
var compareProps = function (x, y) {
    var xProps = Object.getOwnPropertyNames(x);
    var yProps = Object.getOwnPropertyNames(y);
    if (xProps.length !== yProps.length) {
        return false;
    }
    for (var i = 0; i < xProps.length; i++) {
        var propName = xProps[i];
        if (x[propName] !== y[propName]) {
            return false;
        }
    }
    return true;
};
/**
 * The `CalendarCell` component is internally used for rendering the items in the current view. Also be used as a custom `cell` of the [Calendar]({% slug api_dateinputs_calendarprops %}#toc-cell) and the [MultiViewCalendar]({% slug api_dateinputs_multiviewcalendarprops %}#toc-cell).
 *
 * * [Customizing the cells inside the Calendar view]({% slug custom_rendering_calendar %}#toc-cells-inside-the-view)
 * * [Customizing the cells inside the MultiViewCalendar view]({% slug custom_rendering_multiviewcalendar %}#toc-cells-inside-the-view)
 */
var CalendarCell = /** @class */ (function (_super) {
    __extends(CalendarCell, _super);
    function CalendarCell() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // tslint:enable:max-line-length
        _this.handleClick = function (event) {
            var _a = _this.props, onClick = _a.onClick, value = _a.value;
            if (onClick) {
                onClick.call(undefined, value, event);
            }
        };
        _this.handleMouseEnter = function () {
            var _a = _this.props, onMouseEnter = _a.onMouseEnter, value = _a.value;
            if (onMouseEnter) {
                onMouseEnter.call(undefined, value);
            }
        };
        _this.handleMouseLeave = function () {
            var _a = _this.props, onMouseLeave = _a.onMouseLeave, value = _a.value;
            if (onMouseLeave) {
                onMouseLeave.call(undefined, value);
            }
        };
        return _this;
    }
    // Manually checking if the component needs an update
    // due to date object being compared by instance
    // and new Date object is created
    // every time and fails the shallow compare of the React.PureComponent.
    /**
     * @hidden
     */
    CalendarCell.prototype.shouldComponentUpdate = function (nextProps) {
        var _a = this.props, value = _a.value, props = __rest(_a, ["value"]);
        var newValue = nextProps.value, newProps = __rest(nextProps, ["value"]);
        var valueEqual = !(value && newValue) || value.getTime() === newValue.getTime();
        return !(valueEqual && compareProps(props, newProps));
    };
    /* eslint-disable max-len */
    /**
     * @return
     * Returns a `<td />` element with a `<span className="k-link" />` inside to apply the styles. The text inside is the [`formattedValue`]({% slug api_dateinputs_calendarcellprops %}#toc-formattedvalue) of the `cell`.
     */
    CalendarCell.prototype.render = function () {
        var _a = this.props, className = _a.className, formattedValue = _a.formattedValue, isWeekend = _a.isWeekend, isFocused = _a.isFocused, isInRange = _a.isInRange, isSelected = _a.isSelected, isRangeStart = _a.isRangeStart, isRangeMid = _a.isRangeMid, isRangeEnd = _a.isRangeEnd, isRangeSplitStart = _a.isRangeSplitStart, isRangeSplitEnd = _a.isRangeSplitEnd, isToday = _a.isToday, isDisabled = _a.isDisabled, view = _a.view, value = _a.value, other = __rest(_a, ["className", "formattedValue", "isWeekend", "isFocused", "isInRange", "isSelected", "isRangeStart", "isRangeMid", "isRangeEnd", "isRangeSplitStart", "isRangeSplitEnd", "isToday", "isDisabled", "view", "value"]);
        var isEndActive = this.props.activeRangeEnd === 'end' && isRangeEnd;
        var isStartActive = this.props.activeRangeEnd === 'start' && isRangeStart;
        var rootClassName = classNames('k-calendar-td', {
            'k-range-end': isRangeEnd,
            'k-range-mid': isRangeMid,
            'k-range-split-end': isRangeSplitEnd,
            'k-range-split-start': isRangeSplitStart,
            'k-range-start': isRangeStart,
            'k-active': isStartActive || isEndActive,
            'k-state-pending-focus': isFocused,
            'k-selected': isSelected || isRangeStart || isRangeEnd,
            'k-today': isToday,
            'k-weekend': isWeekend,
            'k-disabled': isDisabled
        }, className);
        return (React.createElement("td", __assign({}, other, { className: rootClassName, onClick: this.handleClick, onMouseEnter: this.handleMouseEnter, onMouseLeave: this.handleMouseLeave }),
            React.createElement("span", { className: "k-link" }, this.props.children)));
    };
    return CalendarCell;
}(React.Component));
export { CalendarCell };
