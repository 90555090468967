import { ReactElement } from 'react'
import { Box, Typography } from '@material-ui/core'
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid'

export const CustomToolbar = (props: {
  title: string
  action?: ReactElement
}) => {
  const { title, action } = props

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      padding={2}
      alignItems="flex-start"
      paddingTop={0}
    >
      <Box display="flex" flexGrow={1}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
      <Box flexShrink={1}>{action}</Box>
    </Box>
  )
}
