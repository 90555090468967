import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { Button } from 'react-admin';
import { BottomDrawer } from '../../../common/BottomDrawer';
import { Resource } from "../../../common/Resource";
import { ColumnModel } from "../../../model/ColumnModel";

export const resource = "admin/generic-views/plaid_link_events";

export type PlaidLinkEventsPayload = {
  userId: string
}

const tableColumns = [
  { field: "created_at", width: "150px", title: "created at", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "link_action", width: "200px", title: "link action", show: true, },
  { field: "metadata", width: "800px", title: "metadata", show: true, },
]

export const PlaidLinkEventsDrawer: React.FC<Props> = (props) => {
  const {
    payload,
    onClose,
  } = props;

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <BottomDrawer
      isShow={payload !== undefined}
      onClose={onClose}
      title="Plaid tokens">

      {
        payload !== undefined && (
          <Box>
            <Resource
              resource={resource}
              initialFilters={{ user_id: { eq: payload.userId } }}
              columnModel={columnModel}
              tableActions={
                <>
                  <Button label="close" onClick={onClose} />
                </>
              }
            />
          </Box>
        )
      }

    </BottomDrawer>
  );
}

type Props = {
  payload: PlaidLinkEventsPayload | undefined
  onClose: () => void
}