import { AuthProvider } from 'react-admin'
import { config } from '../config';

const authProvider: AuthProvider = {
  // called when the user attempts to log in
  login: async ({ username: login, password }) => {
    const url = `${config.apiUrl}/admin/sign-in`;

    const response = await fetch(url, {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login,
        password
      }),
    })

    const { success, error, result } = await response.json();

    if (!response.ok) {
      return Promise.reject("fail");
    }

    console.log(success, error, result);

    if (success) {
      localStorage.setItem("username", login);
      localStorage.setItem("token", result.token);
      return;
    } else {
      return Promise.reject(error.message ?? "Something went wrong");
    }
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('username')
    localStorage.removeItem('token')
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('username')
      localStorage.removeItem('token')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('username')
      ? Promise.resolve()
      : Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
}

export default authProvider
