import { FC, useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { Button, useNotify, useRefresh } from 'react-admin';
import { PlacidResponse, PostError } from '../../../network/AdminRequest';
import { useInjection } from '../../../di/useInjection';
import { BottomDrawer } from '../../../common/BottomDrawer';
import { centsToDollars } from '../../../helpers';

type Flow = "BillPayOffer" | "BillPayContract" | "BalanceTransferOffer" | "BalanceTransferContract";

export const GetIncomeInfoDrawer: FC<Props> = ({
  payload,
  onClose,
}) => {
  const { underwritingActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [flow, setFlow] = useState<Flow>("BalanceTransferOffer");
  const [response, setResponse] = useState<PlacidResponse | null>(null);

  useEffect(() => {
    setFlow("BalanceTransferOffer")
    setResponse(null);
  }, [payload]);

  const handleFlowChange = useCallback((e: SelectChangeEvent<Flow>) => {
    setFlow(e.target.value as Flow);
  }, [flow]);

  const handleGetClick = async () => {
    if (payload === null || payload == undefined) return;

    const userId = payload.userId;

    if (userId == null || userId == undefined) {
      notify("No userId");
      return;
    }

    const response = await underwritingActions.getIncomeInfo(
      userId,
      flow,
    );

    setResponse(response);
  }

  return (
    <BottomDrawer
      isShow={payload !== undefined}
      onClose={onClose}
      title="Credit cards">

      {
        payload !== undefined && (
          <Box minHeight="300px" paddingX="20px">

            <Box display="flex" alignItems="center">
              <FormControl fullWidth>
                <InputLabel id="method-select-label">flow</InputLabel>
                <Select
                  autoFocus={false}
                  onFocus={() => { }}
                  labelId="method-select-label"
                  id="demo-simple-select"
                  value={flow}
                  label="Primary status"
                  onChange={handleFlowChange}>
                  <MenuItem key={"BillPayOffer"} value={"BillPayOffer"}>BillPayOffer</MenuItem>
                  <MenuItem key={"BillPayContract"} value={"BillPayContract"}>BillPayContract</MenuItem>
                  <MenuItem key={"BalanceTransferOffer"} value={"BalanceTransferOffer"}>BalanceTransferOffer</MenuItem>
                  <MenuItem key={"BalanceTransferContract"} value={"BalanceTransferContract"}>BalanceTransferContract</MenuItem>
                </Select>
              </FormControl>

              <Box marginLeft="10px">
                <Button label='Get' onClick={handleGetClick} />
              </Box>
            </Box>

            {
              response !== null && response.error != null &&
              <Alert color={"error"} severity="error">{response.error.message}</Alert>
            }

            {
              response !== null && response.result != null &&
              <IncomeInfoRender data={response.result} />
            }
          </Box>
        )
      }

    </BottomDrawer >
  );
}

type Props = {
  payload: { userId: number } | undefined
  onClose: () => void
}

type AccountReviewStatus = {
  "accountId": number,
  "approvedIncome": number | null,
  "decision": string
}

type Verifications = {
  "isCompleted": boolean,
  "title": string,
  "verificationType": string
}

type IncomeInfoResponse = {
  "accountReviewStatus": AccountReviewStatus[],
  "argyleEmployments": any[]
  "checkingAccounts": any[]
  "estimatedLimit": number | null
  "minimumRequiredIncome": number | null
  "minimumRequiredPreTaxIncome": number | null
  "verifications": Verifications[]
};

const IncomeInfoRender: FC<{ data: IncomeInfoResponse }> = ({ data }) => {
  return (
    <Box>
      <h3>Result</h3>

      <Alert color='info' severity='info'>
        <h5>
          Estimated limit: {data.estimatedLimit == null ? "unknown" : centsToDollars(data.estimatedLimit ?? -1)}
        </h5>

        <h5>
          Minimum Required Income: {data.minimumRequiredIncome == null ? "unknown" : centsToDollars(data.minimumRequiredIncome ?? -1)}
        </h5>

        <h5>
          Minimum Required Pre Tax Income: {data.minimumRequiredPreTaxIncome == null ? "unknown" : centsToDollars(data.minimumRequiredPreTaxIncome ?? -1)}
        </h5>

        <Box marginTop="20px">
          <h4>Verifications</h4>
          {data.verifications.map((v) => (
            <pre>
              {JSON.stringify(v, null, 2)}
            </pre>
          ))}
        </Box>

        <Box marginTop="20px">
          <h4>Accounts review status</h4>
          {data.accountReviewStatus.map((a) => (
            <pre>
              {JSON.stringify(a, null, 2)}
            </pre>
          ))}
        </Box>

        <Box marginTop="20px">
          <h4>Checking accounts</h4>
          {data.checkingAccounts.map((ch) => (
            <pre>
              {JSON.stringify(ch, null, 2)}
            </pre>
          ))}
        </Box>

        <Box marginTop="20px">
          <h4>Argyle Employments</h4>
          {data.argyleEmployments.map((ch) => (
            <pre>
              {JSON.stringify(ch, null, 2)}
            </pre>
          ))}
        </Box>
      </Alert >
    </Box >
  )
}


