import * as React from 'react';
import { Draggable } from '../../../../kendo-react-common';
import { closestTagName, getColumnIndex, getOffset, getRowIndex, getSelectionOptions, relativeContextElement } from './utils';
import { TABLE_PREVENT_SELECTION_ELEMENT } from './constants';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/** @hidden */
export var TableSelection = function (props) {
    var onRelease = props.onRelease, childRef = props.childRef;
    var _a = getSelectionOptions(props.selectable), selectionEnabled = _a.enabled, selectionDrag = _a.drag, selectionMode = _a.mode, selectionCell = _a.cell;
    var draggableRef = React.useRef(null);
    var overlayElementRef = React.useRef(null);
    var dragStartRef = React.useRef({ clientX: 0, clientY: 0 });
    var _b = React.useState(null), overlaySettings = _b[0], setOverlaySettings = _b[1];
    var offsetParentRef = React.useRef();
    var isDraggedRef = React.useRef(false);
    var isPreventedRef = React.useRef(false);
    var onPressHandler = React.useCallback(function (eventData) {
        var event = eventData.event;
        isPreventedRef.current = event.originalEvent.target.hasAttribute(TABLE_PREVENT_SELECTION_ELEMENT);
        if (isPreventedRef.current || !draggableRef.current) {
            return;
        }
        offsetParentRef.current = relativeContextElement(draggableRef.current.element);
        dragStartRef.current = {
            clientY: event.clientY,
            clientX: event.clientX
        };
    }, []);
    var onDragHandler = React.useCallback(function (eventData) {
        var event = eventData.event;
        var _a = dragStartRef.current, clientX = _a.clientX, clientY = _a.clientY;
        if (isPreventedRef.current) {
            return;
        }
        if (!selectionDrag || selectionMode === 'single') {
            return;
        }
        if (Math.abs(clientY - event.clientY) > 5 || Math.abs(clientX - event.clientX) > 5) {
            isDraggedRef.current = true;
        }
        if (isDraggedRef.current) {
            var offset = getOffset(offsetParentRef.current);
            setOverlaySettings({
                top: Math.min(clientY, event.clientY) - offset.top,
                left: Math.min(clientX, event.clientX) - offset.left,
                width: Math.abs(event.clientX - clientX),
                height: Math.abs(event.clientY - clientY)
            });
        }
    }, [setOverlaySettings, selectionDrag, selectionMode]);
    var onReleaseHandler = React.useCallback(function (eventData) {
        var event = eventData.event;
        var _a = dragStartRef.current, clientX = _a.clientX, clientY = _a.clientY;
        if (isPreventedRef.current || !draggableRef.current) {
            return;
        }
        var currentDocument = draggableRef.current.element && draggableRef.current.element.ownerDocument;
        if (!currentDocument) {
            return;
        }
        if (!isDraggedRef.current) {
            var startElement = currentDocument.elementFromPoint(clientX, clientY);
            if (!startElement) {
                return;
            }
            var tdElement = closestTagName(startElement, 'TD');
            var trElement = closestTagName(startElement, 'TR');
            var colIndex = getColumnIndex(tdElement);
            var rowIndex = getRowIndex(trElement);
            if (tdElement && trElement && rowIndex !== undefined && colIndex !== undefined) {
                onRelease({
                    nativeEvent: event.originalEvent,
                    startRowIndex: rowIndex,
                    startColIndex: colIndex,
                    endRowIndex: rowIndex,
                    endColIndex: colIndex,
                    altKey: event.altKey,
                    shiftKey: event.shiftKey,
                    ctrlKey: event.ctrlKey,
                    metaKey: event.metaKey,
                    mode: selectionMode,
                    cell: selectionCell,
                    isDrag: false
                });
            }
        }
        else {
            var top_1 = Math.min(clientY, event.clientY);
            var left = Math.min(clientX, event.clientX);
            var bottom = Math.max(clientY, event.clientY);
            var right = Math.max(clientX, event.clientX);
            var overlayElement = overlayElementRef.current;
            if (!overlayElement) {
                return;
            }
            overlayElement.style.visibility = 'hidden';
            var startElement = currentDocument.elementFromPoint(left, top_1);
            var endElement = currentDocument.elementFromPoint(right, bottom);
            overlayElement.style.visibility = '';
            if (!startElement || !endElement) {
                return;
            }
            var startTdElement = closestTagName(startElement, 'TD');
            var startTrElement = closestTagName(startTdElement, 'TR');
            var endTdElement = closestTagName(endElement, 'TD');
            var endTrElement = closestTagName(endTdElement, 'TR');
            var startColIndex = getColumnIndex(startTdElement);
            var startRowIndex = getRowIndex(startTrElement);
            var endColIndex = getColumnIndex(endTdElement);
            var endRowIndex = getRowIndex(endTrElement);
            if (startColIndex !== undefined &&
                startRowIndex !== undefined &&
                endColIndex !== undefined &&
                endRowIndex !== undefined) {
                onRelease({
                    nativeEvent: event.originalEvent,
                    startRowIndex: startRowIndex,
                    startColIndex: startColIndex,
                    endRowIndex: endRowIndex,
                    endColIndex: endColIndex,
                    altKey: event.altKey,
                    shiftKey: event.shiftKey,
                    ctrlKey: event.ctrlKey,
                    metaKey: event.metaKey,
                    mode: selectionMode,
                    cell: selectionCell,
                    isDrag: true
                });
            }
        }
        setOverlaySettings(null);
        isDraggedRef.current = false;
        dragStartRef.current = { clientX: 0, clientY: 0 };
    }, [setOverlaySettings, selectionMode, selectionCell, onRelease]);
    if (!selectionEnabled) {
        return (React.cloneElement(React.Children.only(props.children), { ref: childRef }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Draggable, { onPress: onPressHandler, onDrag: onDragHandler, onRelease: onReleaseHandler, ref: draggableRef, childRef: childRef }, props.children),
        overlaySettings &&
            React.createElement("div", { ref: overlayElementRef, style: __assign(__assign({}, overlaySettings), { position: 'fixed' }), className: 'k-marquee k-marquee-color' })));
};
