import * as React from 'react';
import { GroupingIndicator } from '../drag/GroupingIndicator';
import { registerForLocalization, provideLocalizationService as intl } from '../../../../kendo-react-intl';
import { messages, groupPanelEmpty as messageKey } from '../messages';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * @hidden
 */
var GroupPanel = /** @class */ (function (_super) {
    __extends(GroupPanel, _super);
    function GroupPanel() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onGroupRemove = function (event, index) {
            var newGroups = _this.props.group.slice();
            newGroups.splice(index, 1);
            _this.props.groupChange(newGroups, event);
        };
        _this.onGroupSortChange = function (event, index, groupDesc, dir) {
            var group = Object.assign({}, groupDesc, { dir: dir });
            var newGroups = _this.props.group.slice();
            newGroups.splice(index, 1, group);
            _this.props.groupChange(newGroups, event);
        };
        return _this;
    }
    GroupPanel.prototype.render = function () {
        var _this = this;
        var groupsProp = this.props.group || [];
        var groups = groupsProp.map(function (groupDesc, index) { return (React.createElement(GroupingIndicator, { key: index, dir: groupDesc.dir || 'asc', title: _this.props.resolveTitle(groupDesc.field), onRemove: function (event) {
                _this.onGroupRemove(event, index);
            }, onSortChange: function (event, dir) {
                _this.onGroupSortChange(event, index, groupDesc, dir);
            }, onPress: _this.props.pressHandler, onDrag: _this.props.dragHandler, onRelease: _this.props.releaseHandler })); });
        return (React.createElement("div", { ref: this.props.refCallback, className: "k-grouping-header k-grouping-header-flex" },
            groups,
            React.createElement("div", { className: "k-indicator-container" },
                !groups.length && intl(this).toLanguageString(messageKey, messages[messageKey]),
                "\u00A0")));
    };
    return GroupPanel;
}(React.Component));
export { GroupPanel };
registerForLocalization(GroupPanel);
