import { MembershipOverviewCollection } from "../../collections/membership-overview/MembershipOverview";
import { TransactionsOverviewCollection } from "../../collections/transactions-overview/TransactionsOverview";

export const MembershipPaymentsDashboard = () => {

  return (
    <section className='dashboard'>
      <h1>Membership payments and statuses</h1>

      <MembershipOverviewCollection />
      <TransactionsOverviewCollection />
    </section>
  );
}
