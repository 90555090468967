import React from 'react';
import { Box } from "@mui/material";
import { ClientCollection } from "../../collections/client-collection/ClientCollection";
import { RawAccountsCollection } from "../../collections/raw-accounts-collection/RawAccountsCollection";
import { BankAccountsCollection } from '../../collections/bank-accounts-collection/BankAccountsCollection';
import { CreditCardsCollection } from '../../collections/credit-cards-collection/CreditCardsCollection';
import { PlaidTokensCollection } from '../../collections/plaid-tokens-collection/PlaidTokensCollection';
import { PlaidLinkEventsCollection } from '../../collections/plaid-link-events-collection/PlaidLinkEventsCollection';
import { StatementsCollection } from '../../collections/statements-collection/StatementsCollection';
import { UnderwritingCollection } from '../../collections/underwriting-collection/UnderwritingCollection';
import { MessagesCollection } from '../../collections/messages-collection/MessagesCollection';
import { ProductsOverviewCollection } from '../../collections/product-overview-collection/ProductsOverviewCollection';
import { CyclesCollection } from '../../collections/cycles-collection/CyclesCollection';
import { ManualRawAccountsCollection } from '../../collections/manual-raw-accounts-collection/ManualRawAccountsCollection';
import { ConsolidatedReportHistoryCollection } from '../../collections/consoledated-report-history/ConsolidatedReportHistoryCollection';
import { RepaymentsCollection } from '../../collections/repayments-collection/RepaymentsCollection';

const ClientDashboard: React.FC<Props> = ({ userId }) => {
  return (
    <section className='dashboard'>
      <h1>Client Page</h1>

      <Box sx={{ display: "flex", maxWidth: "100%" }}>
        <Box sx={{ marginRight: "20px" }}>
          <ClientCollection
            userId={userId} />
        </Box>

        {userId !== undefined &&
          <Box sx={{ marginRight: "20px" }}>
            <ProductsOverviewCollection
              userId={userId}
            />
          </Box>
        }
      </Box>

      <RawAccountsCollection
        initialFilters={{ user_id: { eq: userId } }}
        onDrawerOpenChange={(isHistoricalPlaidOpen: boolean): void => {
        }}
      />

      <BankAccountsCollection
        initialFilters={{ user_id: { eq: userId } }}
      />

      <CreditCardsCollection
        initialFilters={{ user_id: { eq: userId } }}
      />

      <PlaidTokensCollection
        initialFilters={{ user_id: { eq: userId } }}
      />

      <PlaidLinkEventsCollection
        initialFilters={{ user_id: { eq: userId } }}
      />


      <RepaymentsCollection
        initialFilters={{
          "user_id": { "eq": userId }
        }}
        title={`SB payments for user ${userId}`}
      />

      <StatementsCollection
        initialFilters={{ user_id: { eq: userId } }}
      />

      <CyclesCollection
        initialFilters={{ user_id: { eq: userId } }}
      />

      <UnderwritingCollection
        initialFilters={{ "user_id": { eq: userId } }}
        disableUrlFilterListener={true}
      />

      {/* 
      <SeeminglyDiedTokenCollection
        initialFilters={{ "user_id": { eq: userId } }} />
        */}


      <ManualRawAccountsCollection
        initialFilters={{ "user_id": { eq: userId } }}
      />


      <MessagesCollection
        initialFilters={{ "user_id": { eq: userId } }}
      />

      <ConsolidatedReportHistoryCollection
        initialFilters={{ "user_id": { eq: userId } }}
      />

    </section >
  );
}

export default ClientDashboard;

type Props = {
  userId?: string
}