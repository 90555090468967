import React from 'react';
import { Box } from "@mui/material";
import { SubscriptionsGroupPlansCollection } from '../../collections/subscriptions-group-plans/SubscriptionsGroupPlans';

const SubscriptionsDashboard: React.FC<Props> = ({ userId }) => {
  return (
    <section className='dashboard'>
      <h1>Subscriptions</h1>

      <Box sx={{ display: "flex", maxWidth: "100%" }}>
        <Box sx={{ marginRight: "20px" }}>
          <SubscriptionsGroupPlansCollection />
        </Box>
      </Box>

    </section >
  );
}

export default SubscriptionsDashboard;

type Props = {
  userId?: string
}