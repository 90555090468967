import React, { useState } from "react";
import { Button } from "react-admin"
import { PaymentPromiseNewDialog, PaymentPromiseNewDialogPayload } from "./PaymentPromiseNewDialog";

export const PaymentPromiseDialogButton: React.FC<Props> = ({
  userId
}) => {
  const [paymentPromiseNewPayload, setPromiseNewPayload] = useState<PaymentPromiseNewDialogPayload | undefined>(undefined);

  const onClickHandle = React.useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    console.log(userId);

    setPromiseNewPayload({ userId: parseInt(userId.toString()) });
  }, [userId]);

  return (<>
    <PaymentPromiseNewDialog
      payload={paymentPromiseNewPayload}
      hideDialog={() => { setPromiseNewPayload(undefined) }} />

    <Button label="create payment promise" onClick={onClickHandle} />
  </>
  )
}

type Props = {
  userId: string
}
