import * as React from 'react';
import { Draggable } from '../../../../kendo-react-common';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * @hidden
 */
var ColumnDraggable = /** @class */ (function (_super) {
    __extends(ColumnDraggable, _super);
    function ColumnDraggable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.draggable = null;
        /**
         * @hidden
         */
        _this.onPress = function (data) {
            var element = _this.draggable && _this.draggable.element;
            if (element && _this.props.pressHandler) {
                _this.props.pressHandler(data.event, element);
            }
        };
        /**
         * @hidden
         */
        _this.onDrag = function (data) {
            var element = _this.draggable && _this.draggable.element;
            if (element && _this.props.dragHandler) {
                _this.props.dragHandler(data.event, element);
            }
        };
        /**
         * @hidden
         */
        _this.onRelease = function (data) {
            var element = _this.draggable && _this.draggable.element;
            if (element && _this.props.releaseHandler) {
                _this.props.releaseHandler(data.event);
            }
        };
        return _this;
    }
    ColumnDraggable.prototype.render = function () {
        var _this = this;
        return (React.createElement(Draggable, { onPress: this.onPress, onDrag: this.onDrag, onRelease: this.onRelease, hint: this.props.dragClue, autoScroll: { boundaryElementRef: this.props.headerRef, direction: { horizontal: true, vertical: false } }, scrollContainer: this.props.containerRef || undefined, ref: function (component) { _this.draggable = component; } },
            React.createElement("tr", { style: { touchAction: 'none' }, role: "row", "aria-rowindex": this.props.ariaRowIndex }, this.props.children)));
    };
    return ColumnDraggable;
}(React.PureComponent));
export { ColumnDraggable };
