import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  RowActions,
} from "./widget/RowActions";
import { Box } from '@mui/material';
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';

export const resource = "admin/generic-views/plaid_tokens";

const tableColumns = [
  { field: "created_at", width: "120px", title: "created at", show: true, format: "yyyy-MM-dd" },
  { field: "access_token", width: "400px", title: "access_token", show: true },
  { field: "item_id", width: "120px", title: "item_id", show: true },
  { field: "bank_id", width: "120px", title: "bank_id", show: true },
  { field: "can_be_duplicated", width: "120px", title: "can_be_duplicated", show: true },
  { field: "status", width: "120px", title: "status", show: true },
  { field: "sys_period", width: "120px", title: "sys_period", show: true },

  { field: "actions", width: "140px", title: "actions", show: true },
]

export const PlaidTokensCollection: React.FC<Props> = ({ initialFilters, onRowClick }) => {
  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Box sx={{ position: "relative" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`Plaid tokens: ${initialFilters?.user_id.eq}`}
        </h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        disableUrlFilterListener={false}
        rowActionBuilder={rowActionBuilder}
        columnModel={columnModel}
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
}