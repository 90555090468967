import { Box, Divider, List, ListItem, ListItemText } from "@mui/material";
import currency from "currency.js";
import React, { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import { useInjection } from "../../di/useInjection";
import { ProductOverview } from "../../types/productOverview";

const subtitleStyles = {
  fontSize: "14px",
  marginTop: "14px",
  marginLeft: "16px",
  marginBottom: "4px",
  fontWeight: "bold",
  textDecoration: "underline",
}

const ListItemRow = ({ title, value }: { title: string, value: string }) => {
  return (
    <ListItem>
      <ListItemText sx={{ margin: 0 }}>
        <strong style={{ fontSize: "12px", lineHeight: 1 }}>{title}</strong>
        : <span style={{ fontSize: "12px", lineHeight: 1 }}>{value}</span>
      </ListItemText>
    </ListItem>
  );
}

export const ProductsOverviewCollection: React.FC<Props> = ({
  userId,
}) => {
  const [productsOverview, setProductsOverview] = useState<ProductOverview | undefined>();

  const { clientActions } = useInjection();
  const notify = useNotify();

  useEffect(() => {
    if (userId === undefined) {
      notify("Products overview: user id not provided");
      return;
    }

    clientActions.fetchProductsOverview(parseInt(userId))
      .then((response) => {
        console.log(response);
        if (typeof response === "string") {
          notify(response);
        } else {
          setProductsOverview(response);
        }
      })
      .catch((e) => notify(e.message ?? "Can't load products overview"));
  }, [userId]);

  if (productsOverview === undefined) {
    return <Box>Loading...</Box>
  }

  if (productsOverview.minimumPayment === undefined) {
    return <Box>No data</Box>
  }

  const btBalance = currency(productsOverview.balanceTransfer?.balance ?? "unknown", { fromCents: true }).format();
  const btTotalPaid = currency(productsOverview.balanceTransfer?.totalPaid ?? "unknown", { fromCents: true }).format();
  const btTotalRepaid = currency(productsOverview.balanceTransfer?.totalRepaid ?? "unknown", { fromCents: true }).format();
  const btLimit = currency(productsOverview.balanceTransfer?.limit ?? "unknown", { fromCents: true }).format();

  const bpLimit = currency(productsOverview.billPay?.limit ?? "unknown", { fromCents: true }).format();
  const bpTotalPaid = currency(productsOverview.billPay?.totalPaid ?? "unknown", { fromCents: true }).format();
  const bpTotalRepaid = currency(productsOverview.billPay?.totalRepaid ?? "unknown", { fromCents: true }).format();

  const savingsBalance = currency(productsOverview.savings?.balance ?? "unknown", { fromCents: true }).format();
  const savingsEarned = currency(productsOverview.savings?.earned ?? "unknown", { fromCents: true }).format();

  return (
    <Box sx={{
      position: "relative",
      marginTop: "30px",
      maxWidth: "400px",
      width: "100%",
    }}>

      <Box sx={{
        position: "relative",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "5px",
      }}>
        <h4>
          Products overview
        </h4>
      </Box>

      <Box sx={{
        background: "white",
      }}>

        <List dense={true}>
          <ListItemRow title={"minimumPayment"} value={`${currency(productsOverview.minimumPayment, { fromCents: true }).format()}`} />

          {
            productsOverview.balanceTransfer !== null &&
            <>
              <Divider variant="inset" component="li" sx={{ margin: 0 }} />
              <h3 style={subtitleStyles}>Balance transfer</h3>
              <ListItemRow title={"apr"} value={`${productsOverview.balanceTransfer?.apr}%`} />
              <ListItemRow title={"initialApr"} value={`${productsOverview.balanceTransfer?.initialApr}%`} />
              <ListItemRow title={"limit"} value={btLimit} />
              <ListItemRow title={"balance"} value={btBalance} />
              <ListItemRow title={"totalPaid"} value={btTotalPaid} />
              <ListItemRow title={"totalRepaid"} value={btTotalRepaid} />
              <ListItemRow title={"dueDate"} value={`${productsOverview.balanceTransfer?.dueDate}`} />
              <ListItemRow title={"autopayDay"} value={`${productsOverview.balanceTransfer?.autopayDay}`} />
            </>
          }

          {
            productsOverview.billPay !== null &&
            <>
              <Divider variant="inset" component="li" sx={{ margin: 0 }} />
              <h3 style={subtitleStyles}>Bill pay</h3>
              <ListItemRow title={"limit"} value={bpLimit} />
              <ListItemRow title={"totalPaid"} value={bpTotalPaid} />
              <ListItemRow title={"totalRepaid"} value={bpTotalRepaid} />
              <ListItemRow title={"dueDate"} value={`${productsOverview.billPay?.dueDate}`} />
              <ListItemRow title={"autopayDay"} value={`${productsOverview.billPay?.autopayDay}`} />
            </>
          }

          {
            productsOverview.savings !== null &&
            <>
              <Divider variant="inset" component="li" sx={{ margin: 0 }} />
              <h3 style={subtitleStyles}>Savings</h3>
              <ListItemRow title={"apy"} value={`${productsOverview.savings?.apy}`} />
              <ListItemRow title={"balance"} value={savingsBalance} />
              <ListItemRow title={"earned"} value={savingsEarned} />
            </>
          }

        </List>

      </Box>

    </Box>
  )

}

type Props = {
  userId: string
}


