import { wait } from "../common/helpers";
import { Product } from "../types/common";
import { JSONObject } from "../types/json";
import { AdminRequest, PlacidResponse, PostError } from "./AdminRequest";

export type ManualReviewTag = "Approve" | "Reject";

export type UpdateManualReviewPayload = {
  userId: number
  accounts: {
    accountId: number,
    decision: any,
    // { tag: "Approve", contents: number } | { tag: "Reject" }
  }[]
}

export class ManualReviewActions {
  constructor(private adminRequest: AdminRequest) { };

  async updateManualReview(payload: UpdateManualReviewPayload): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/account/primary-account/mark-primary-account-review-viewed',
          JSON.stringify(payload),
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  _checkPlacidError(placidResponse: PlacidResponse): PostError {
    if (placidResponse.success) {
      return null
    } else {
      return placidResponse.error.message ?? "Something went wrong";
    }
  }
}
