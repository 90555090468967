export class Constants {
  static CREDIT_LINES = 'admin/customer/list'

  static PERSONAL_INFO_UPDATE = 'admin/account/update-personal-info'

  static CC_CREATE = 'admin/cc-txn/add'
  static CC_CANCEL = 'admin/cc-txn/cancel'
  static CC_ADJUST = 'admin/cc-txn/adjust'

  static PB_CREATE = 'admin/pb-txn/add'
  static PB_CANCEL = 'admin/pb-txn/cancel'
  static PB_ADJUST = 'admin/pb-txn/adjust'

  static UNDERWRITING_UPDATE = 'admin/underwriting-decision/update'
}
