import React, { useCallback, useEffect, useMemo } from 'react';
import {
  DateInput,
  FilterButton,
  NumberInput,
  SelectInput,
  TextInput,
  useNotify,
} from "react-admin"
import {
  RowActions,
} from "./widget/RowActions";
// import { RowClickEvent } from "../../common/Table";
import { ColumnActions } from "../../common/ColumnActions";
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { RowClickEvent } from '../../common/Table';
import { LinkToUnderwritingUser } from './widget/LinkToUnderwritingUser';

export const resource = "admin/generic-views/waiting_users";

const tableColumns = [
  { field: "id", width: "120px", title: "user_id", show: true },
  { field: "first_name", width: "150px", title: "fist name", show: true },
  { field: "last_name", width: "150px", title: "last name", show: true },
  { field: "decision", width: "200px", title: "decision", show: true },
  { field: "number_of_checking_accounts", width: "80px", title: "number_of_checking_accounts", show: true },
  { field: "fraud_flag", width: "80px", title: "fraud_flag", show: true },
  { field: "fico_score", width: "150px", title: "fico_score", show: true },
  { field: "product", width: "150px", title: "product", show: true },
  { field: "ssn_matching", width: "150px", title: "ssn_matching", show: true },
  { field: "first_decision_date", width: "300px", title: "first_decision_date", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "last_decision_date", width: "300px", title: "last_decision_date", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "has_freeze_code", width: "300px", title: "has_freeze_code", show: true },

  // { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="user_id" source="id.eq" alwaysOn />,

  <TextInput label="first_name" source="first_name.like" />,
  <TextInput label="last_name" source="last_name.like" />,

  <NumberInput label="fico_score gt" source="fico_score.gt" />,
  <NumberInput label="fico_score lt" source="fico_score.lt" />,

  <DateInput label="first decision date gt" source="first_decision_date.gt" />,
  <DateInput label="first decision date lt" source="first_decision_date.lt" />,

  <DateInput label="last decision gt" source="last_decision_date.gt" />,
  <DateInput label="last decision lt" source="last_decision_date.lt" />,

  <SelectInput source="decision" choices={[
    { id: 'Pending', name: 'Pending' },
    { id: 'RequestManual', name: 'RequestManual' },
    { id: 'Reject', name: 'Reject' },
    { id: 'Approved', name: 'Approved' },
  ]} />,
];


export const WaitingUsersCollection: React.FC<Props> = (props) => {
  // const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />
  const notify = useNotify();

  const handleRowClick = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    const { id } = dataItem;

    if (id === undefined) {
      notify("Error: user id not provided");
      return;
    }

    const uri = `/dash/underwriting-user/${id}/`;

    if (nativeEvent.shiftKey || nativeEvent.metaKey) {
      window.open(uri, '_blank', 'toolbar=yes, location=yes, status=yes, menubar=yes, scrollbars=yes');
    } else {
      window.history.pushState('', '', uri);
    }
  }

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Resource
      resource={resource}
      filters={filters}
      disableUrlFilterListener={false}
      // rowActionBuilder={rowActionBuilder}
      onRowClick={handleRowClick}
      columnModel={columnModel}
      tableActions={
        <>
          <FilterButton {...props} />
        </>
      }
      rowPerPageOptions={[25, 50, 100]}
      noDataContent={<LinkToUnderwritingUser />}
      idLink={(data: { [index: string]: any }) => {
        const id = data["id"];
        return `/dash/underwriting-user/${id}/`;
      }}
    />
  )
}

type Props = {
  initialFilters?: { [index: string]: string }
  onRowClick?: (userId: number) => void
}