import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import OpenIcon from '@mui/icons-material/OpenWith';
import ResetIcon from '@mui/icons-material/ResetTv';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export const RowActions: React.FC<Props> = ({
  record,
  onUpdateCardDialogOpen,
}) => {
  const { clientActions } = useInjection();
  const refresh = useRefresh();
  const notify = useNotify();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(null);
  };

  const handleOpenCard = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const { user_id, id: accountId } = record;

    const error = await clientActions.openCreditCard(user_id, accountId);

    if (error === null) {
      notify("opened");
      refresh();
    } else {
      notify(error);
    }

    setAnchorEl(null);
  }

  const handleResetCardNumber = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const { user_id, id: accountId } = record;

    const error = await clientActions.openCreditCard(user_id, accountId);

    if (error === null) {
      notify("dropped");
      refresh();
    } else {
      notify(error);
    }

    setAnchorEl(null);
  }

  const handleCloseCard = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const { user_id, id: accountId } = record;

    const error = await clientActions.closeCreditCard(user_id, accountId);

    if (error === null) {
      notify("closed");
      refresh();
    } else {
      notify(error);
    }

    setAnchorEl(null);
  }

  const handleUpdateCard = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    onUpdateCardDialogOpen(record);
    setAnchorEl(null);
  }

  return (
    <div>
      <Button
        size='small'
        color='inherit'
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Actions
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >

        <MenuItem onClick={handleCloseCard} disableRipple>
          <CloseIcon />
          Close
        </MenuItem>

        <MenuItem onClick={handleOpenCard} disableRipple>
          <OpenIcon />
          Open
        </MenuItem>

        <MenuItem onClick={handleUpdateCard} disableRipple>
          <UpdateIcon />
          Update card data
        </MenuItem>

        <MenuItem onClick={handleResetCardNumber} disableRipple>
          <ResetIcon />
          Drop number
        </MenuItem>

      </StyledMenu>
    </div>
  );
}

type Props = {
  record: { [index: string]: any }
  onUpdateCardDialogOpen: (record: { [index: string]: any }) => void
}