import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AdjustIcon from '@mui/icons-material/Adjust';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import { AdjustDialog, AdjustDialogPayload } from './AdjustDialog';

const ALLOWED_STATUSES_FOR_CANCEL_MFI_ONLY = ['Pending'];

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export const RowActions: React.FC<Props> = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { repaymentsActions } = useInjection();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [adjustDialogPayload, setAdjustDialogPayload] = React.useState<AdjustDialogPayload>();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(null);
  };

  const handleAdjustClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      user_id: userId,
      id: transactionId,
      amount,
      scheduled_date: scheduledDate,
    } = record;

    setAdjustDialogPayload({
      userId,
      amount,
      scheduledDate: scheduledDate.toString(),
      transactionId,
    });

    setAnchorEl(null);
  }

  const handleCancelClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const { user_id: userId, id: transactionId } = record;

    const userName = localStorage.getItem("username");

    const error = await repaymentsActions.pbTnxCancel({
      userId,
      transactionId,
      comment: `Canceled from admin panel by ${userName}`,
    });

    if (error === null) {
      notify("Canceled");
      refresh();
    } else {
      notify(error);
    }

    setAnchorEl(null);
  }

  const handleCancelMfiOnlyClick = async (e: React.MouseEvent) => {
    e.preventDefault();

    const { payments_provider_transaction_id: mfiTransactionId } = record;

    if (mfiTransactionId === null) {
      notify("no mfi transaction id");
      return;
    }

    setAnchorEl(null);

    const error = await repaymentsActions.cancelMfiOnly(mfiTransactionId);

    if (error === null) {
      notify("Done");
      refresh();
    } else {
      notify(error);
    }
  }

  const handleSendManuallyClick = async (e: React.MouseEvent, force: boolean) => {
    e.preventDefault();

    const { id: transactionId, user_id: userId } = record;

    const error = await repaymentsActions.tnxSendManually({
      transactionId,
      force,
      userId,
    });

    if (error === null) {
      notify("Done");
      refresh();
    } else {
      notify(error);
    }

    setAnchorEl(null);
  }

  return (
    <div>
      <Button
        size='small'
        color='inherit'
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Actions
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >

        <MenuItem onClick={handleAdjustClick} disableRipple>
          <AdjustIcon />
          Adjust
        </MenuItem>

        <MenuItem onClick={handleCancelClick} disableRipple>
          <CloseIcon />
          Cancel
        </MenuItem>

        {
          ALLOWED_STATUSES_FOR_CANCEL_MFI_ONLY.includes(record.current_status) &&
          <MenuItem onClick={handleCancelMfiOnlyClick} disableRipple>
            <CloseIcon />
            Cancel (MFI Only)
          </MenuItem>
        }

        {
          record.bank_account !== null &&
          <MenuItem onClick={(e) => handleSendManuallyClick(e, false)} disableRipple>
            <CloseIcon />
            Send manually (not force)
          </MenuItem>
        }

        {
          record.bank_account !== null &&
          <MenuItem onClick={(e) => handleSendManuallyClick(e, true)} disableRipple>
            <CloseIcon />
            Send manually (force)
          </MenuItem>
        }

      </StyledMenu>

      <AdjustDialog
        payload={adjustDialogPayload}
        hideDialog={() => setAdjustDialogPayload(undefined)}
      />
    </div>
  );
}

type Props = {
  record: { [index: string]: any }
}