import { useState } from 'react'
import 'date-fns'
import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core'

import { ICCPaymentAddProps } from '../types'

const useStyles = makeStyles({
  field: {
    paddingBottom: 15,
  },
  actions: {
    justifyContent: 'flex-end',
    marginRight: 10,
  },
})

const schema = yup
  .object({
    cardId: yup.number().min(0).required(),
    minPaymentAmount: yup.number().min(0).required(),
    extraPaymentAmount: yup.number().min(0).required(),
    comment: yup.string().required(),
  })
  .required()

export const CCPaymentAdd = (props: {
  id: string
  isOpen: boolean
  setOpen: (v: boolean) => void
  handleAdd: (v: ICCPaymentAddProps) => void
  isLoading: boolean
}) => {
  const { id, isOpen, setOpen, isLoading, handleAdd } = props

  const classes = useStyles()

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const [scheduledDate, setScheduledDate] = useState<Date | null>(null)

  const buildData = (): ICCPaymentAddProps => {
    const data: ICCPaymentAddProps = {
      userId: parseInt(id),
      cardId: parseInt(watch('cardId')),
      minPaymentAmount: parseInt(watch('minPaymentAmount')),
      extraPaymentAmount: parseInt(watch('extraPaymentAmount')),
      scheduledDate: format(scheduledDate!, 'yyyy-MM-dd'),
      comment: watch('comment'),
    }

    return data
  }

  const handleClose = () => {
    clearErrors()
    setOpen(false)
    setScheduledDate(null)
    setValue('cardId', '')
    setValue('minPaymentAmount', '')
    setValue('extraPaymentAmount', '')
    setValue('comment', '')
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent>
          <TextField
            error={errors.cardId !== undefined}
            helperText={errors.cardId?.message}
            className={classes.field}
            label="Card number"
            type="number"
            fullWidth
            {...register('cardId')}
          />
          <TextField
            error={errors.minPaymentAmount !== undefined}
            helperText={errors.minPaymentAmount?.message}
            className={classes.field}
            label="Min payment amount"
            type="number"
            fullWidth
            {...register('minPaymentAmount')}
          />
          <TextField
            error={errors.extraPaymentAmount !== undefined}
            helperText={errors.extraPaymentAmount?.message}
            className={classes.field}
            label="extraPaymentAmount"
            type="number"
            fullWidth
            {...register('extraPaymentAmount')}
          />
          <TextField
            error={errors.comment !== undefined}
            helperText={errors.comment?.message}
            label="comment"
            type="text"
            fullWidth
            {...register('comment')}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Scheduled date"
              format="yyyy/MM/dd"
              value={scheduledDate}
              onChange={setScheduledDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={handleClose} color="primary" variant="outlined" disabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(() => handleAdd(buildData()))}
            color="primary"
            variant="contained"
            style={{ marginLeft: 15 }}
            disabled={isLoading || scheduledDate === null}
            startIcon={isLoading && <CircularProgress size={14} color='inherit' />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
