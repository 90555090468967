import { Datagrid, TextField, FieldProps, NumberInput, DatagridConfigurable } from 'react-admin'
import { List } from 'react-admin';
import { CustomPagination } from './CustomPagination'
import { makeStyles } from '@material-ui/core'

import { IUserProps } from '../types'

const userFilters = [<NumberInput source="userId" label="Search by userId" alwaysOn />]

const useStyles = makeStyles({
  thead: {
    backgroundColor: '#ccc',
  },
})

export const CreditLineList = (props: FieldProps<IUserProps>) => {
  return (
    <List
      title="PLACID ADMIN"
      filters={userFilters}
      bulkActionButtons={false}
      pagination={CustomPagination({ perPage: [50] })}
      perPage={50}
      sort={{ field: 'userId', order: 'ASC' }}
      {...props}>

      <DatagridConfigurable  
          sx={{
              '& .RaDatagrid-rowOdd': {
                  backgroundColor: '#eee',
              },
          }}
          rowClick="show">
        <TextField source="userId" />
        <TextField source="userName" />
        <TextField textAlign="right" source="pbBalance" />
        <TextField textAlign="right" source="pbLimit" />
        <TextField textAlign="right" source="placidCreditLineId" />
        <TextField textAlign="right" source="btBalance" />
        <TextField textAlign="right" source="btLimit" />
      </DatagridConfigurable>

    </List>
  )
}
