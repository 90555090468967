import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { resource } from '../clients-collection/ClientsCollection';
import { Button, useNotify } from 'react-admin';
import { useInjection } from '../../di/useInjection';
import { UpdateAddressDialog, UpdateAddressDialogPayload } from './widget/UpdateAddressDialog';
import { UpdateEmailDialog, UpdateEmailDialogPayload } from './widget/UpdateEmailDialog';
import { UpdateNameDialog, UpdateNameDialogPayload } from './widget/UpdateNamelDialog';
import { UpdateDOBDialog, UpdateDOBDialogPayload } from './widget/UpdateDOBDialog';
import { UpdateIncomeDialog, UpdateIncomeDialogPayload } from './widget/UpdateIncomeDialog';
import { ResourceVertical } from '../../common/ResourceVertical';
import { CancelMembershipDialog, CancelMembershipDialogPayload } from './widget/CancelMembershipDialog';
import { RefundPFMSubscriptionDialog, RefundPFMSubscriptionDialogPayload } from './widget/RefundPFMSubsctiptionDialog';
import { ForceVefiffDialog, ForceVeriffDialogPayload } from '../underwriting-collection/widget/ForceVeriffDialog';

export enum dialogs {
  address,
  email,
  name,
  dob,
  income,
  membershipSubscription,
  refundPFMSubscription,
  forceVeriff,
}

const _getMixpanelLink = (userId: string): string => {
  return `https://mixpanel.com/project/2451292/view/2994464/app/users#list/chosenColumns:!((label:Name,propertyDefaultType:string,resourceType:user,type:string,value:'$name'),(label:Email,propertyDefaultType:string,resourceType:user,type:string,value:'$email'),(label:'Updated%20at',propertyDefaultType:datetime,resourceType:user,type:datetime,value:'$last_seen'),(label:'Country%20Code',propertyDefaultType:string,resourceType:user,type:string,value:'$country_code'),(label:Region,propertyDefaultType:string,resourceType:user,type:string,value:'$region'),(label:City,propertyDefaultType:string,resourceType:user,type:string,value:'$city')),columnWidths:(),filterByCohort:(groups:!((behavioralFiltersOperator:and,event:(label:'All%20Users',resourceType:cohort,value:'$all_users'),filters:!((filterOperator:'is%20equal%20to',filterValue:${userId},propertyDefaultType:number,propertyName:placid_id,propertyObjectKey:!n,propertyType:number,resourceType:user)),filtersOperator:and,groupingOperator:!n,property:!n,type:cohort_group)),id:!n,name:'',unsavedId:!n),sortOrder:descending,sortProperty:(label:'Updated%20at',propertyDefaultType:datetime,resourceType:user,type:datetime,value:'$last_seen'),sortPropertyType:datetime`;
}

export const tableColumns = [
  { field: "id", width: "80px", title: "id", show: true },
  { field: "created_at", width: "180px", title: "created_at", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "status", width: "150px", title: "status", show: true },
  { field: "first_name", width: "200px", title: "first name", show: true },
  { field: "last_name", width: "200px", title: "last name", show: true },
  { field: "name", width: "200px", title: "name", show: true, complex: ['first_name', 'last_name',], complexJoinString: " " },
  { field: "email", width: "150px", title: "email", show: true },
  { field: "last_seen", width: "100px", title: "last_seen", show: true, format: "yyyy-MM-dd" },
  { field: "date_of_birth", width: "100px", title: "date_of_birth", show: true, format: "yyyy-MM-dd" },
  { field: "line_1", width: "150px", title: "line_1", show: false },
  { field: "zip", width: "150px", title: "zip", show: false },
  { field: "state", width: "150px", title: "state", show: false },
  { field: "city", width: "150px", title: "city", show: false },
  { field: "user_address", width: "200px", title: "address", show: true, complex: ['line_1', 'city', 'zip', 'state',] },
  { field: "ip", width: "150px", title: "ip", show: true },
  { field: "bill_pay_limit", width: "150px", title: "bill_pay_limit", show: true, isCents: true },
  { field: "balance_transfer_limit", width: "100px", title: "balance_transfer_limit", show: true, isCents: true },
  { field: "bill_pay_balance", width: "150px", title: "bill_pay_balance", show: true, isCents: true },
  { field: "balance_transfer_balance", width: "100px", title: "balance_transfer_balance", show: true, isCents: true },
  { field: "phone", width: "100px", title: "phone", show: true },
  { field: "due_date", width: "100px", title: "due_date", show: true },
  { field: "debt_collection", width: "100px", title: "debt collection", show: true },
  { field: "statement_date", width: "120px", title: "statement date", show: true },
  { field: "products", width: "120px", title: "products", show: true },

  // { field: "actions", width: "140px", title: "actions", show: true },
]

export const ClientCollection: React.FC<Props> = ({ userId, onRowClick }) => {
  const { adminRequest, membershipActions } = useInjection();
  const notify = useNotify();

  const [client, setClient] = useState<any | undefined>(undefined);

  const [updateAddressDialogPayload, setUpdateAddressDialogPayload] = useState<UpdateAddressDialogPayload | undefined>(undefined);
  const [updateEmailDialogPayload, setUpdateEmailDialogPayload] = useState<UpdateEmailDialogPayload | undefined>(undefined);
  const [updateNameDialogPayload, setUpdateNameDialogPayload] = useState<UpdateNameDialogPayload | undefined>(undefined);
  const [updateDOBDialogPayload, setUpdateDOBDialogPayload] = useState<UpdateDOBDialogPayload | undefined>(undefined);
  const [updateIncomeDialogPayload, setUpdateIncomeDialogPayload] = useState<UpdateIncomeDialogPayload | undefined>(undefined);
  const [membershipSubscriptionPayload, setMembershipSubscriptionPayload] = useState<CancelMembershipDialogPayload | undefined>(undefined);
  const [refundPFMSubscription, setRefundPFMSubscription] = useState<RefundPFMSubscriptionDialogPayload | undefined>(undefined);
  const [forceVeriffDialogPayload, setForceVeriffDialogPayload] = useState<ForceVeriffDialogPayload>(null);

  useEffect(() => {
    adminRequest.getView<any>(`/admin/generic-views/clients?id=eq.${userId}`)
      .then((response) => {
        if (response.length > 0) {
          const [client] = response;
          setClient(client);
        }
      });
  }, []);

  const openDialogHandler = ({ dialog, record }: { dialog: dialogs, record: { [index: string]: any } }) => {
    switch (dialog) {
      case dialogs.address:
        return setUpdateAddressDialogPayload({ record });
      case dialogs.email:
        return setUpdateEmailDialogPayload({ id: record.id!, email: record.email });
      case dialogs.name:
        return setUpdateNameDialogPayload({
          id: record.id!,
          first_name: record.first_name,
          last_name: record.last_name
        });
      case dialogs.dob:
        return setUpdateDOBDialogPayload({
          id: record.id!,
          dateOfBirth: record.date_of_birth,
        });
      case dialogs.membershipSubscription:
        return setMembershipSubscriptionPayload({
          userId: record.id!,
        });
      case dialogs.refundPFMSubscription:
        return setRefundPFMSubscription({
          userId: record.id!,
        });
      case dialogs.forceVeriff:
        return setForceVeriffDialogPayload({
          userId: record.id!,
        });
      // case dialogs.income:
      //   return setUpdateIncomeDialogPayload({
      //     id: record.id!,
      //     average: 0,
      //     regular: 0,
      //   });
    }
  }

  const goToMixpanel = (userId: string) => {
    console.log(userId);
    window.open(_getMixpanelLink(userId), "_blank")?.focus();
  }

  if (userId === undefined) {
    return <Box sx={{ hight: "200px", display: "flex" }}>
      No data
    </Box>
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ position: "relative" }}>

        <UpdateAddressDialog
          payload={updateAddressDialogPayload}
          hideDialog={() => setUpdateAddressDialogPayload(undefined)}
        />

        <UpdateEmailDialog
          payload={updateEmailDialogPayload}
          hideDialog={() => setUpdateEmailDialogPayload(undefined)}
        />

        <UpdateNameDialog
          payload={updateNameDialogPayload}
          hideDialog={() => setUpdateNameDialogPayload(undefined)}
        />

        <UpdateDOBDialog
          payload={updateDOBDialogPayload}
          hideDialog={() => setUpdateDOBDialogPayload(undefined)}
        />

        <UpdateIncomeDialog
          payload={updateIncomeDialogPayload}
          hideDialog={() => setUpdateIncomeDialogPayload(undefined)}
        />

        <CancelMembershipDialog
          payload={membershipSubscriptionPayload}
          hideDialog={() => setMembershipSubscriptionPayload(undefined)}
        />

        <RefundPFMSubscriptionDialog
          payload={refundPFMSubscription}
          hideDialog={() => setRefundPFMSubscription(undefined)}
        />

        <ForceVefiffDialog
          payload={forceVeriffDialogPayload}
          hideDialog={() => setForceVeriffDialogPayload(null)}
        />

        <Box sx={{
          position: "absolute",
          background: "#2a41b0",
          color: "#fff",
          padding: "0 20px",
          borderRadius: "10px 10px 0 0",
          top: "30px",
        }}>
          <h4>
            {`User: ${userId}`}
          </h4>
        </Box>

        <ResourceVertical
          initialFilters={{ id: { eq: userId } }}
          resource={resource}
          disableUrlFilterListener={false}
          columnModel={tableColumns}
        />

      </Box>

      {client !== undefined
        && (
          <Box sx={{ marginTop: "60px", display: "flex", flexDirection: "column" }}>
            <h2>Actions</h2>
            <Button label="Mixpanel" onClick={() => goToMixpanel(client.id)} />
            <Button label="Update address" onClick={() => openDialogHandler({ dialog: dialogs.address, record: client })} />
            <Button label="Update email" onClick={() => openDialogHandler({ dialog: dialogs.email, record: client })} />
            <Button label="Update name" onClick={() => openDialogHandler({ dialog: dialogs.name, record: client })} />
            <Button label="Update dob" onClick={() => openDialogHandler({ dialog: dialogs.dob, record: client })} />
            <Button label="Cancel membership" onClick={() => openDialogHandler({ dialog: dialogs.membershipSubscription, record: client })} />
            <Button label="Refund PFM subscription" onClick={() => openDialogHandler({ dialog: dialogs.refundPFMSubscription, record: client })} />
            <Button label="Force veriff" onClick={() => openDialogHandler({ dialog: dialogs.forceVeriff, record: client })} />
            {/* <Button label="Update income" onClick={() => openDialogHandler({ dialog: dialogs.income, record: client })} /> */}
          </Box>
        )
      }

    </Box>
  )
}

type Props = {
  userId?: string | undefined
  onRowClick?: (underwritingId: string) => void
}