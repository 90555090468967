import React from 'react';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import { Column, ColumnModel } from '../../model/ColumnModel';
import currency from 'currency.js';
import { format } from 'date-fns';

const formatValue = (
  {
    field,
    isCents,
    format: formatPattern,
    complex, complexJoinString,
  }: Column,
  row: any,
  specialFormat?: SpecialFormatValue
): any => {
  const value = row[field];

  if (
    specialFormat !== undefined
    && specialFormat[field] !== undefined
    && value !== null
    && value !== undefined
  ) {
    return specialFormat[field](row[field]);
  }

  if (complex !== undefined) {
    const complexValue = complex
      .map((fieldName) => row[fieldName])
      .join(complexJoinString ?? ', ');

    return complexValue;
  }

  if (isCents && value !== null && value !== undefined) {
    return currency(value, { fromCents: true }).format();
  }

  if (formatPattern && value !== null && value !== undefined) {
    return format(new Date(value), formatPattern);
  }

  return value;
}


export const VerticalRow: React.FC<Props> = (props) => {
  const {
    columnModel,
    specialFormat,
    row,
  } = props;

  return (
    <List dense={true}>
      {
        columnModel
          .filter(({ show }: Column) => show)
          .map((column) => {
            return (
              <ListItem key={column.field}>
                <ListItemText sx={{ margin: 0 }}>
                  <strong style={{ fontSize: "12px", lineHeight: 1 }}>{column.title}</strong>
                  : <span style={{ fontSize: "12px", lineHeight: 1 }}>{formatValue(column, row, specialFormat)}</span>
                </ListItemText>
              </ListItem>
            )
          })
      }
    </List>
  );
}

export type SpecialFormatValue = { [index: string]: (value: any) => string }

type Props = {
  columnModel: Column[]
  specialFormat?: SpecialFormatValue
  row: any[]
}