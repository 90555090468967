

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Popup } from '../../../../kendo-react-popup';
import { getDate, cloneDate } from '../../../../kendo-date-math';
import { classNames, guid, Keys, AsyncFocusBlur, createPropsContext, withPropsContext, validatePackage, kendoThemeMaps } from '../../../../kendo-react-common';
import { packageMetadata } from '../package-metadata';
import { DateInput } from '../dateinput/DateInput';
import { Calendar } from '../calendar/components/Calendar';
import { MIN_DATE, MAX_DATE, setTime } from '../utils';
import { isInDateRange } from '../utils';
import { messages, toggleCalendar } from '../messages';
import { registerForLocalization, provideLocalizationService } from '../../../../kendo-react-intl';
import { ToggleButton } from './ToggleButton';
import { PickerWrap } from '../common/PickerWrap';
import { PickerFloatingLabel } from '../hooks/usePickerFloatingLabel';
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/** @hidden */
var DatePickerWithoutContext = /** @class */ (function (_super) {
    __extends(DatePickerWithoutContext, _super);
    function DatePickerWithoutContext(props) {
        var _this = _super.call(this, props) || this;
        _this._popupId = guid();
        _this._element = null;
        _this._dateInput = React.createRef();
        _this._calendar = null;
        _this.shouldFocusDateInput = false;
        _this.prevShow = false;
        /**
         * @hidden
         */
        _this.focus = function () {
            if (_this.dateInput) {
                _this.dateInput.focus();
            }
        };
        _this.setCalendarRef = function (calendar) {
            _this._calendar = calendar;
        };
        _this.nextValue = function (nextProps, nextState) {
            return nextProps.value !== undefined
                ? nextProps.value
                : nextState.value;
        };
        _this.nextShow = function (nextProps, nextState) {
            return nextProps.show !== undefined
                ? nextProps.show
                : nextState.show;
        };
        _this.handleInputValueChange = function (event) {
            _this.handleValueChange(event.value, event);
        };
        _this.handleCalendarValueChange = function (event) {
            var value = _this.mergeTime(event.value);
            _this.handleValueChange(value, event);
        };
        _this.handleValueChange = function (value, event) {
            _this.setState({
                value: cloneDate(value || undefined),
                show: false
            });
            _this.valueDuringOnChange = value;
            _this.showDuringOnChange = false;
            _this.shouldFocusDateInput = true;
            var onChange = _this.props.onChange;
            if (onChange) {
                onChange.call(undefined, {
                    syntheticEvent: event.syntheticEvent,
                    nativeEvent: event.nativeEvent,
                    value: _this.value,
                    show: _this.show,
                    target: _this
                });
            }
            _this.valueDuringOnChange = undefined;
            _this.showDuringOnChange = undefined;
        };
        _this.handleFocus = function () {
            _this.setState({ focused: true });
        };
        _this.handleBlur = function () {
            _this.setState({ focused: false });
            _this.setShow(false);
        };
        _this.handleIconClick = function () {
            if (_this.props.disabled) {
                return;
            }
            _this.shouldFocusDateInput = true;
            _this.setShow(!_this.show);
        };
        _this.handleIconMouseDown = function (event) {
            event.preventDefault();
        };
        _this.handleKeyDown = function (event) {
            var altKey = event.altKey, keyCode = event.keyCode;
            if (keyCode === Keys.esc) {
                _this.shouldFocusDateInput = true;
                _this.setShow(false);
                return;
            }
            if (altKey && (keyCode === Keys.up || keyCode === Keys.down)) {
                event.preventDefault();
                event.stopPropagation();
                _this.shouldFocusDateInput = keyCode === Keys.up;
                _this.setShow(keyCode === Keys.down);
            }
        };
        validatePackage(packageMetadata);
        _this.state = {
            value: _this.props.defaultValue || DatePickerWithoutContext.defaultProps.defaultValue,
            show: _this.props.defaultShow || DatePickerWithoutContext.defaultProps.defaultShow,
            focused: false
        };
        return _this;
    }
    Object.defineProperty(DatePickerWithoutContext.prototype, "element", {
        /**
         * Gets the wrapping element of the DatePickerWithoutContext.
         */
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "dateInput", {
        /**
         * Gets the DateInput component inside the DatePicker component.
         */
        get: function () {
            return this._dateInput.current;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "calendar", {
        /**
         * Gets the Calendar component inside the DatePicker component.
         */
        get: function () {
            return this._calendar;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "value", {
        /**
         * Gets the value of the DatePickerWithoutContext.
         */
        get: function () {
            var value = this.valueDuringOnChange !== undefined
                ? this.valueDuringOnChange
                : this.props.value !== undefined
                    ? this.props.value
                    : this.state.value;
            return (value !== null) ? cloneDate(value) : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "show", {
        /**
         * Gets the popup state of the DatePickerWithoutContext.
         */
        get: function () {
            return this.showDuringOnChange !== undefined
                ? this.showDuringOnChange
                : this.props.show !== undefined
                    ? this.props.show
                    : this.state.show;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "name", {
        /**
         * Gets the `name` property of the DatePickerWithoutContext.
         */
        get: function () {
            return this.props.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "min", {
        get: function () {
            return this.props.min !== undefined
                ? this.props.min
                : DatePickerWithoutContext.defaultProps.min;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "max", {
        get: function () {
            return this.props.max !== undefined
                ? this.props.max
                : DatePickerWithoutContext.defaultProps.max;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "dateInputComp", {
        get: function () {
            return this.props.dateInput || DatePickerWithoutContext.defaultProps.dateInput;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "toggleButtonComp", {
        get: function () {
            return this.props.toggleButton || DatePickerWithoutContext.defaultProps.toggleButton;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "calendarComp", {
        get: function () {
            return this.props.calendar || DatePickerWithoutContext.defaultProps.calendar;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "popupComp", {
        get: function () {
            return this.props.popup || DatePickerWithoutContext.defaultProps.popup;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "pickerWrapComp", {
        get: function () {
            return this.props.pickerWrap || DatePickerWithoutContext.defaultProps.pickerWrap;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "validity", {
        /**
         * Represents the validity state into which the DatePicker is set.
         */
        get: function () {
            var inRange = isInDateRange(this.value, this.min, this.max);
            var customError = this.props.validationMessage !== undefined;
            var isValid = (!this.required || this.value !== null) && inRange;
            var valid = this.props.valid !== undefined ? this.props.valid : isValid;
            return {
                customError: customError,
                rangeOverflow: (this.value && this.max.getTime() < this.value.getTime()) || false,
                rangeUnderflow: (this.value && this.value.getTime() < this.min.getTime()) || false,
                valid: valid,
                valueMissing: this.value === null
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "validityStyles", {
        /**
         * @hidden
         */
        get: function () {
            return this.props.validityStyles !== undefined
                ? this.props.validityStyles
                : DatePickerWithoutContext.defaultProps.validityStyles;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DatePickerWithoutContext.prototype, "required", {
        /**
         * @hidden
         */
        get: function () {
            return this.props.required !== undefined
                ? this.props.required
                : DatePickerWithoutContext.defaultProps.required;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    DatePickerWithoutContext.prototype.componentDidMount = function () {
        if (this.show) {
            // If defaultShow is true during the initial render, the popup is not aligned.
            this.forceUpdate();
        }
    };
    /**
     * @hidden
     */
    DatePickerWithoutContext.prototype.componentDidUpdate = function () {
        if (this._calendar && this._calendar.element && this.show && !this.prevShow) {
            this._calendar.element.focus({ preventScroll: true });
        }
        if (this.dateInput
            && this.dateInput.element
            && !this.show
            && this.shouldFocusDateInput) {
            this.dateInput.element.focus({ preventScroll: true });
        }
        this.prevShow = this.show;
        this.shouldFocusDateInput = false;
    };
    /**
     * @hidden
     */
    DatePickerWithoutContext.prototype.componentWillUnmount = function () {
        clearTimeout(this.nextTickId);
    };
    /**
     * @hidden
     */
    DatePickerWithoutContext.prototype.render = function () {
        var _this = this;
        var _a = this.props, _b = _a.size, size = _b === void 0 ? DatePickerWithoutContext.defaultProps.size : _b, _c = _a.rounded, rounded = _c === void 0 ? DatePickerWithoutContext.defaultProps.rounded : _c, _d = _a.fillMode, fillMode = _d === void 0 ? DatePickerWithoutContext.defaultProps.fillMode : _d, disabled = _a.disabled, tabIndex = _a.tabIndex, title = _a.title, id = _a.id, format = _a.format, formatPlaceholder = _a.formatPlaceholder, min = _a.min, max = _a.max, weekNumber = _a.weekNumber, focusedDate = _a.focusedDate, className = _a.className, width = _a.width, name = _a.name, validationMessage = _a.validationMessage, required = _a.required, validityStyles = _a.validityStyles, ariaLabelledBy = _a.ariaLabelledBy, ariaDescribedBy = _a.ariaDescribedBy, ariaLabel = _a.ariaLabel;
        var _e = this.props.popupSettings, popupClass = _e.popupClass, otherPopupSettings = __rest(_e, ["popupClass"]);
        var show = this.show;
        var value = this.value;
        var sanitizedValue = value && getDate(value);
        var isValid = !this.validityStyles || this.validity.valid;
        var popupClassNames = classNames('k-calendar-container k-group k-reset', popupClass);
        var dateInputProps = {
            disabled: disabled,
            format: format,
            formatPlaceholder: formatPlaceholder,
            id: id,
            ariaLabelledBy: ariaLabelledBy,
            ariaDescribedBy: ariaDescribedBy,
            ariaLabel: ariaLabel,
            max: max,
            min: min,
            name: name,
            onChange: this.handleInputValueChange,
            required: required,
            tabIndex: !show ? tabIndex : -1,
            title: title,
            valid: this.validity.valid,
            validationMessage: validationMessage,
            validityStyles: validityStyles,
            value: value,
            label: undefined,
            placeholder: !this.state.focused ? this.props.placeholder : null,
            ariaExpanded: this.show,
            size: null,
            fillMode: null,
            rounded: null
        };
        var popupProps = __assign({ show: show, anchor: this._element, className: popupClassNames, id: this._popupId, anchorAlign: {
                horizontal: 'left',
                vertical: 'bottom'
            }, popupAlign: {
                horizontal: 'left',
                vertical: 'top'
            } }, otherPopupSettings);
        var calendarProps = {
            disabled: disabled,
            value: sanitizedValue,
            min: min,
            max: max,
            weekNumber: weekNumber,
            focusedDate: focusedDate,
            onChange: this.handleCalendarValueChange
        };
        var toggleButtonTitle = provideLocalizationService(this)
            .toLanguageString(toggleCalendar, messages[toggleCalendar]);
        var datepicker = (React.createElement(AsyncFocusBlur, { onFocus: this.handleFocus, onBlur: this.handleBlur, onSyncBlur: this.props.onBlur, onSyncFocus: this.props.onFocus }, function (_a) {
            var _b;
            var onFocus = _a.onFocus, onBlur = _a.onBlur;
            return (React.createElement("span", { ref: function (span) { _this._element = span; }, className: classNames('k-input', 'k-datepicker', (_b = {},
                    _b["k-input-".concat(kendoThemeMaps.sizeMap[size] || size)] = size,
                    _b["k-rounded-".concat(kendoThemeMaps.roundedMap[rounded] || rounded)] = rounded,
                    _b["k-input-".concat(fillMode)] = fillMode,
                    _b['k-invalid'] = !isValid,
                    _b['k-required'] = _this.required,
                    _b['k-disabled'] = _this.props.disabled,
                    _b), className), onKeyDown: _this.handleKeyDown, style: { width: width }, onFocus: onFocus, onBlur: onBlur },
                React.createElement(_this.dateInputComp, __assign({ _ref: _this._dateInput, ariaRole: 'combobox', ariaExpanded: _this.show, ariaHasPopup: true, ariaControls: _this._popupId }, dateInputProps)),
                React.createElement(_this.toggleButtonComp, { type: "button", icon: "calendar", title: toggleButtonTitle, className: "k-input-button", rounded: null, onClick: _this.handleIconClick, "aria-label": toggleButtonTitle, onMouseDown: _this.handleIconMouseDown }),
                React.createElement(_this.popupComp, __assign({}, popupProps),
                    React.createElement(_this.calendarComp, __assign({ _ref: _this.setCalendarRef }, calendarProps)))));
        }));
        return this.props.label
            ? (React.createElement(PickerFloatingLabel, { dateInput: this._dateInput, label: this.props.label, editorId: id, editorValid: isValid, editorDisabled: this.props.disabled, children: datepicker, style: { width: this.props.width } }))
            : datepicker;
    };
    DatePickerWithoutContext.prototype.setShow = function (show) {
        if (this.show === show) {
            return;
        }
        this.setState({ show: show });
    };
    DatePickerWithoutContext.prototype.mergeTime = function (value) {
        return this.value && value ? setTime(value, this.value) : value;
    };
    DatePickerWithoutContext.prototype.nextTick = function (f) {
        // XXX: use window.setTimeout due to async focus/blur events in IE, and missing relatedTarget prop.
        // XXX: https://github.com/facebook/react/issues/3751
        // Handles multiple focus events happening at the same time.
        clearTimeout(this.nextTickId);
        this.nextTickId = window.setTimeout(function () { return f(); });
    };
    /**
     * @hidden
     */
    DatePickerWithoutContext.displayName = 'DatePicker';
    /**
     * @hidden
     */
    DatePickerWithoutContext.propTypes = {
        className: PropTypes.string,
        defaultShow: PropTypes.bool,
        defaultValue: PropTypes.instanceOf(Date),
        disabled: PropTypes.bool,
        focusedDate: PropTypes.instanceOf(Date),
        format: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                skeleton: PropTypes.string,
                pattern: PropTypes.string,
                date: PropTypes.oneOf(['short', 'medium', 'long', 'full']),
                time: PropTypes.oneOf(['short', 'medium', 'long', 'full']),
                datetime: PropTypes.oneOf(['short', 'medium', 'long', 'full']),
                era: PropTypes.oneOf(['narrow', 'short', 'long']),
                year: PropTypes.oneOf(['numeric', '2-digit']),
                month: PropTypes.oneOf(['numeric', '2-digit', 'narrow', 'short', 'long']),
                day: PropTypes.oneOf(['numeric', '2-digit']),
                weekday: PropTypes.oneOf(['narrow', 'short', 'long']),
                hour: PropTypes.oneOf(['numeric', '2-digit']),
                hour12: PropTypes.bool,
                minute: PropTypes.oneOf(['numeric', '2-digit']),
                second: PropTypes.oneOf(['numeric', '2-digit']),
                timeZoneName: PropTypes.oneOf(['short', 'long'])
            })
        ]),
        formatPlaceholder: PropTypes.oneOfType([
            PropTypes.oneOf(['wide', 'narrow', 'short', 'formatPattern']),
            PropTypes.shape({
                year: PropTypes.string,
                month: PropTypes.string,
                day: PropTypes.string,
                hour: PropTypes.string,
                minute: PropTypes.string,
                second: PropTypes.string
            })
        ]),
        id: PropTypes.string,
        ariaLabelledBy: PropTypes.string,
        ariaDescribedBy: PropTypes.string,
        ariaLabel: PropTypes.string,
        min: PropTypes.instanceOf(Date),
        max: PropTypes.instanceOf(Date),
        name: PropTypes.string,
        popupSettings: PropTypes.shape({
            animate: PropTypes.bool,
            appendTo: PropTypes.any,
            popupClass: PropTypes.string
        }),
        show: PropTypes.bool,
        tabIndex: PropTypes.number,
        title: PropTypes.string,
        value: PropTypes.instanceOf(Date),
        weekNumber: PropTypes.bool,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        validationMessage: PropTypes.string,
        required: PropTypes.bool,
        validate: PropTypes.bool,
        valid: PropTypes.bool,
        size: PropTypes.oneOf([null, 'small', 'medium', 'large']),
        rounded: PropTypes.oneOf([null, 'small', 'medium', 'large', 'full']),
        fillMode: PropTypes.oneOf([null, 'solid', 'flat', 'outline'])
    };
    /**
     * @hidden
     */
    DatePickerWithoutContext.defaultProps = {
        defaultShow: false,
        defaultValue: null,
        dateInput: DateInput,
        calendar: Calendar,
        toggleButton: ToggleButton,
        popup: Popup,
        pickerWrap: PickerWrap,
        disabled: false,
        format: 'd',
        max: MAX_DATE,
        min: MIN_DATE,
        popupSettings: {},
        tabIndex: 0,
        weekNumber: false,
        required: false,
        validityStyles: true,
        size: 'medium',
        rounded: 'medium',
        fillMode: 'solid'
    };
    return DatePickerWithoutContext;
}(React.Component));
export { DatePickerWithoutContext };
/**
 * Represents the PropsContext of the `DatePicker` component.
 * Used for global configuration of all `DatePicker` instances.
 *
 * For more information, refer to the [DateInputs Props Context]({% slug props-context_dateinputs %}) article.
 */
export var DatePickerPropsContext = createPropsContext();
/* eslint-disable @typescript-eslint/no-redeclare */
/**
 * Represents the KendoReact DatePicker Component.
 *
 * Accepts properties of type [DatePickerProps]({% slug api_dateinputs_datepickerprops %}).
 * Obtaining the `ref` returns an object of type [DatePickerHandle]({% slug api_dateinputs_datepickerhandle %}).
 */
export var DatePicker = withPropsContext(DatePickerPropsContext, DatePickerWithoutContext);
DatePicker.displayName = 'KendoReactDatePicker';
registerForLocalization(DatePickerWithoutContext);
