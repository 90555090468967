import * as React from 'react';
import { useAsyncFocusBlur } from '../hooks/useAsyncFocusBlur';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * An utility High-order Component for asynchronous focus/blur handling.
 *
 * By default, the `onFocus` and `onBlur` callbacks are called every time a child components receives/loses focus.
 * Use this utility HOC for scenarios where you need to know if the parent has received focus for the first time, or completely lost focus.
 *
 * @returns
 */
export var AsyncFocusBlur = function (_a) {
    var children = _a.children, onFocus = _a.onFocus, onBlur = _a.onBlur, onSyncFocus = _a.onSyncFocus, onSyncBlur = _a.onSyncBlur;
    var args = useAsyncFocusBlur({ onFocus: onFocus, onBlur: onBlur, onSyncFocus: onSyncFocus, onSyncBlur: onSyncBlur });
    return React.createElement(React.Fragment, null, children.call(undefined, __assign({}, args)));
};
