import { AdminRequest, PlacidResponse, PostError } from "./AdminRequest";

export type ManualAccountPayload = {
  balanceLimit: number
  mask: string
  nameAccount: string
  type: ManualAccountPayloadType
  userId: number
}

export type ManualAccountPayloadType = "checking" | "savings" | "prepaid"

export type CreateMfiAccountManualPayload = {
  accountName: string,
  accountNumber: string,
  manualRawAccountId: number
  override: boolean
  routingNumber: string
  userId: number
}

export class DepositoryActions {
  constructor(private adminRequest: AdminRequest) { };

  async createManualAccount(
    payload: ManualAccountPayload,
  ): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          "/admin/depository-accounts/create-manual-account",
          JSON.stringify(payload),
        )
      )
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Update statement dd request failed";
    }
  }

  async createMfiManualAccount(
    payload: CreateMfiAccountManualPayload,
  ): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          "/admin/depository-accounts/create-mfi-account-manual",
          JSON.stringify(payload),
        )
      )
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Update statement dd request failed";
    }
  }

  _checkPlacidError(placidResponse: PlacidResponse): PostError {
    if (placidResponse.success) {
      return null
    } else {
      return placidResponse.error.message ?? "Something went wrong";
    }
  }
}
