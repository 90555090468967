
import React, { FC, useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import currency from 'currency.js';

export type UpdateCreditCardDataDialogPayload = {
  id: number
  userId: number
  apr: number
  limit: number
} | undefined;

export const UpdateCreditCardDataDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { clientActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [apr, setApr] = useState<string>("");
  const [limit, setLimit] = useState<string>("");

  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    if (payload !== undefined) {
      setApr(payload?.apr?.toString() ?? "")
      setLimit(currency(payload?.limit, { fromCents: true }).value.toString())
    }

    setError(null);
  }, [payload]);

  const onLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLimit(value);
  }

  const onAprChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setApr(value);
  }

  const handleSendClick = async () => {
    if (payload === undefined) return

    const { userId, id } = payload;

    const newApr = parseFloat(apr);
    const newLimit = currency(limit).intValue;

    const error = await clientActions.updateCreditCardData({
      userId,
      cardId: id,
      apr: newApr,
      limit: newLimit,
    });

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Updated");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Update card data"
      actionTitle='Update'
      onSend={handleSendClick}>

      <TextField
        fullWidth
        type="text"
        label="apr"
        value={apr}
        onChange={onAprChange}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="text"
        label="limit (dollars)"
        value={limit}
        onChange={onLimitChange}
        variant="outlined"
      />

      {error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload?: UpdateCreditCardDataDialogPayload
  hideDialog: () => void
}