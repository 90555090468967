import { useTableKeyboardNavigation } from '../../../../kendo-react-data-tools';
import * as React from 'react';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/** @hidden */
export var GridDetailCell = function (props) {
    var colSpan = props.colSpan, ariaColIndex = props.ariaColIndex, dataItem = props.dataItem, dataIndex = props.dataIndex, id = props.id;
    var navigationAttributes = useTableKeyboardNavigation(id);
    return (React.createElement("td", __assign({ className: "k-detail-cell", colSpan: colSpan, "aria-colindex": ariaColIndex, role: 'gridcell' }, navigationAttributes),
        React.createElement(props.detail, { dataItem: dataItem, dataIndex: dataIndex })));
};
