import { AdminRequest, PlacidResponse, PostError } from "./AdminRequest";

export class AccessControlActions {
  constructor(private adminRequest: AdminRequest) { };

  async actionApprove(actionId: number): Promise<PostError> {
    return this._checkPlacidError(
      await this.adminRequest.post(
        "/admin/access-control/action/batch",
        JSON.stringify([{
          actionId: actionId,
          updateStatus: "APPROVED"
        }]),
      ),
    );
  }

  async actionReject(actionId: number): Promise<PostError> {
    return this._checkPlacidError(
      await this.adminRequest.post(
        "/admin/access-control/action/batch",
        JSON.stringify([{
          actionId: actionId,
          updateStatus: "DENIED"
        }]),
      ),
    );
  }

  _checkPlacidError(placidResponse: PlacidResponse): PostError {
    if (placidResponse.success) {
      return null
    } else {
      return placidResponse.error.message ?? "Something went wrong";
    }
  }
}
