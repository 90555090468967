import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { EquifaxReportsCollection } from "../../collections/equifax-reports-collection/EquifaxReportsCollection";
import { RawAccountsCollection } from "../../collections/raw-accounts-collection/RawAccountsCollection";
import { TwilioCollection } from "../../collections/twilio-collection/TwilioCollection";
import { UnderwritingCollection } from "../../collections/underwriting-collection/UnderwritingCollection";
import { UnderwritingPlaidIdentitiesCollection } from "../../collections/underwriting-plaid-identities-collection/UnderwritingPlaidIdentitiesCollection";
import { UnderwritingUsersCollection } from "../../collections/underwriting-users-collection/UnderwritingUserCollection";
import { PlaidIdentityDrawer, PlaidIdentityDrawerPayload } from "../../collections/underwriting-users-collection/widget/PlaidIdentityDrawer";
import { UnderwritingDecisionChecksCollection } from "../../collections/underwrtiting-decision-checks-collection/UnderwritingDecisionChecksCollection";
import { AddressMap, AddressMapPayload, CoordinatesMapPayload } from "../../common/AddressMap";
import { useInjection } from "../../di/useInjection";
import { ExperianReportsCollection } from "../../collections/experian-reports-collection/ExperianReportsCollection";
import { SeeminglyDiedTokenCollection } from "../../collections/seemingly-died-tokens-collection/SeeminglyDiedTokensCollection";
import { ConsolidatedReportHistoryCollection } from "../../collections/consoledated-report-history/ConsolidatedReportHistoryCollection";
import { LimitLayout } from "../../collections/client-collection/widget/LimitLayout";

type UnderwritingUserItem = {
  address: string,
  city: string,
  state: string,
  zip: string,
  ip: string
  latitude: number | null
  longitude: number | null
}


export const UnderwritingUserDashboard: React.FC<Props> = ({ userId }) => {
  const [underwritingId, setUnderwritingId] = useState<string | undefined>(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [addressMapPayload, setAddressMapPayload] = useState<AddressMapPayload | undefined>(undefined);
  const [coordinatesMapPayload, setCoordinatesMapPayload] = useState<CoordinatesMapPayload | undefined>(undefined);
  const [plaidIdentityDrawerPayload, setPlaidIdentityDrawerPayload] = useState<PlaidIdentityDrawerPayload | undefined>(undefined);

  const { adminRequest } = useInjection();

  const onDrawerOpenChange = (isHistoricalPlaidOpen: boolean) => {
    window.location.hash = "";
    setIsDrawerOpen(isHistoricalPlaidOpen);
  }

  useEffect(() => {
    adminRequest.getView<UnderwritingUserItem[]>(`/admin/generic-views/underwriting_user?id=eq.${userId}`)
      .then((response) => {
        if (response?.length > 0) {
          const [{ address, city, state, zip, latitude, longitude }] = response;
          setAddressMapPayload({ address: `${address}, ${city}, ${state} ${zip}, USA` });
          if (latitude !== null && longitude !== null && latitude !== undefined && longitude !== undefined) {
            setCoordinatesMapPayload({ longitude, latitude });
          }
        }
      });
  }, []);

  if (userId === undefined || userId === "undefined") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "400px" }}>
        No user id provided
      </Box>
    )
  }

  return (
    <section className='dashboard'>
      <h1>Underwriting</h1>

      {userId !== null &&
        <>

          <Box sx={{ display: "flex" }}>
            <UnderwritingUsersCollection
              initialFilters={{ "id": { eq: userId } }} />
            <UnderwritingPlaidIdentitiesCollection
              initialFilters={{ "user_id": { eq: userId } }} />
            <TwilioCollection
              initialFilters={{ "user_id": { eq: userId } }} />

            {
              (addressMapPayload !== undefined || coordinatesMapPayload !== undefined) &&
              <AddressMap address={addressMapPayload} addressFromIp={coordinatesMapPayload} />
            }
          </Box>

          {/* 
          <AddressMap
            address={{ address: `32700 HWY6 #293, Edwards, CO, 81632, USA` }}
            addressFromIp={{
              latitude: 39.6382,
              longitude: -106.6206,
            }}
          /> 
          */}

          <RawAccountsCollection
            initialFilters={{ "user_id": { eq: userId } }}
            onDrawerOpenChange={onDrawerOpenChange}
            resource="admin/generic-views/raw_accounts_depository"
            title="Raw accounts depository"
          />

          <RawAccountsCollection
            initialFilters={{ "user_id": { eq: userId } }}
            onDrawerOpenChange={onDrawerOpenChange}
            resource="admin/generic-views/raw_accounts_non_depository"
            title="Raw accounts non depository"
          />

          <UnderwritingCollection
            initialFilters={{ "user_id": { eq: userId } }}
            disableUrlFilterListener={isDrawerOpen}
            onRowClick={setUnderwritingId}
          />

          {/* <SeeminglyDiedTokenCollection
            initialFilters={{ "user_id": { eq: userId } }} /> */}

          <EquifaxReportsCollection initialFilters={{ "user_id": { eq: userId } }} />
          <ExperianReportsCollection userId={userId} />

          <ConsolidatedReportHistoryCollection
            initialFilters={{ "user_id": { eq: userId } }}
          />
        </>
      }

      {
        underwritingId &&
        <UnderwritingDecisionChecksCollection
          underwritingId={underwritingId}
          onPlaidIdentitySelect={(plaidIdentity: string) => setPlaidIdentityDrawerPayload({ id: plaidIdentity })} />
      }

      <PlaidIdentityDrawer
        payload={plaidIdentityDrawerPayload}
        onClose={() => setPlaidIdentityDrawerPayload(undefined)} />

    </section>
  );
}

type Props = {
  userId?: string
}
