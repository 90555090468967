import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { Resource } from "../../common/Resource";
import { ColumnModel } from "../../model/ColumnModel";

export const resource = "admin/generic-views/messages";

const tableColumns = [
  { field: "created_at", width: "200px", title: "created at", show: true, format: 'yyyy-MM-dd' },
  { field: "subject", width: "200px", title: "subject", show: true },
  { field: "kind", width: "80px", title: "kind", show: true },
  { field: "body", width: "400px", title: "body", show: true },
]

export const MessagesCollection: React.FC<Props> = ({ initialFilters }) => {
  const columnModel = useMemo(() => {
    console.log(resource, 'call just once');
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Box sx={{ position: "relative" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`messages: ${initialFilters?.user_id.eq}`}
        </h4>
      </Box>

      <Resource
        resource={resource}
        initialFilters={initialFilters}
        columnModel={columnModel}
      />

    </Box>
  );
}

type Props = {
  initialFilters?: { [index: string]: any }
}