import React, { useMemo, useState } from 'react';
import {
  RowActions,
} from "./widget/RowActions";
import { ColumnModel } from '../../model/ColumnModel';
import { RowClickEvent } from '../../common/Table';
import { Box } from '@mui/material';
import { Resource } from '../../common/Resource';
import { PDFDisplay } from '../../common/PDFDisplay';

export const resource = "admin/generic-views/equifax_reports";

export enum PdfPlace {
  left,
  right
}

const tableColumns = [
  { field: "created_at", width: "200px", title: "created_at", show: true, format: "yyy-MM-dd" },
  { field: "actions", width: "150px", title: "action", show: true },
];

export const EquifaxReportsCollection: React.FC<Props> = ({
  initialFilters,
  onRowClick,
}) => {
  const [leftPdfSrc, setLeftPdfSrc] = useState<string | undefined>();
  const [rightPdfSrc, setRightPdfSrc] = useState<string | undefined>();

  const handlePdfOpen = (pdfPlace: PdfPlace, src: string) => {
    switch (pdfPlace) {
      case PdfPlace.left:
        setLeftPdfSrc(src);
        break;
      case PdfPlace.right:
        setRightPdfSrc(src);
        break;
    }
  }

  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions
    onPdfOpen={handlePdfOpen}
    record={record} />
  const handleRowClick = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    if (onRowClick === undefined) return;

    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    const { id } = dataItem;

    if (id === undefined) {
      throw Error('no user_id in row click event');
    }

    onRowClick(id);
  }

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, []);

  return (
    <Box sx={{ position: "relative" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`Equifax reports: ${initialFilters?.user_id?.eq}`}
        </h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        // filters={filters}
        rowActionBuilder={rowActionBuilder}
        onRowClick={handleRowClick}
        columnModel={columnModel}
      />

      <Box sx={{ width: "100%" }}>
        {leftPdfSrc !== undefined && <PDFDisplay src={leftPdfSrc} />}
        {rightPdfSrc !== undefined && <PDFDisplay src={rightPdfSrc} />}
      </Box>

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
}