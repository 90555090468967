import {
  Show,
  SimpleShowLayout,
  ShowProps,
  useShowController,
} from 'react-admin'
import CircularProgress from '@material-ui/core/CircularProgress'

import { CreditLineOverview } from './CreditLineOverview'
import { ICreditLineProps } from '../types'

export const CreditLineShow = (props: ShowProps) => {
  const { record } = useShowController(props)

  if (!record?.overviewUserInfo)
    return <CircularProgress style={{ margin: 'auto' }} />

  return (
    <>
      <Show {...props}>
        <SimpleShowLayout>
          <CreditLineOverview user={record as ICreditLineProps} />
        </SimpleShowLayout>
      </Show>
    </>
  )
}
