import { useState } from "react";
import { BankAccountsCollection } from "../../collections/bank-accounts-collection/BankAccountsCollection";
import { ChargesCollection } from "../../collections/charges-collection/ChargesCollection";
import { RepaymentsCollection } from "../../collections/repayments-collection/RepaymentsCollection";
import { UserInfoCollection } from "../../collections/user-info-collection/UserInfoCollection";

const SBPaymentsDashboard = () => {
  const [userId, setUserId] = useState<string | undefined>();

  return (
    <section className='dashboard'>
      <h1>SB payments</h1>

      <RepaymentsCollection onRowClick={setUserId} />

      {
        userId !== undefined &&

        <>
          <RepaymentsCollection
            initialFilters={{
              "user_id": { "eq": userId }
            }}
            title={`SB payments for user ${userId}`}
          />
          <ChargesCollection initialFilters={{ "user_id": { "eq": userId } }} />
          <BankAccountsCollection
            initialFilters={{
              "supports_outgoing_payments": { "eq": "true" },
              "user_id": { "eq": userId }
            }}
          />
          <UserInfoCollection initialFilters={{ "user_id": { "eq": userId } }} />
        </>
      }


    </section >
  );
}

export default SBPaymentsDashboard;
