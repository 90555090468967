import React, { useMemo, useState, } from 'react';
import { Box } from '@mui/material';
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { Button, DateInput, FilterButton, SelectInput, TextInput, useNotify } from 'react-admin';
import { DollarInput } from '../../common/DollarInput';
import { RowActions } from './widget/RowActions';
import { ManualRawAccountDialogPayload, ManualRawAccountsDialog } from './widget/ManualRawAccountDialog';

export const resource = "admin/generic-views/manual_raw_accounts";

const tableColumns = [
  { field: "id", width: "150px", title: "id", show: true },
  { field: "user_id", width: "150px", title: "user_id", show: true },
  { field: "created_at", width: "150px", title: "created_at", show: true, format: "yyyy-MM-dd" },
  { field: "status", width: "150px", title: "status", show: true },
  { field: "type", width: "150px", title: "type", show: true },
  { field: "name_account", width: "150px", title: "name account", show: true },
  { field: "mask", width: "150px", title: "mask", show: true },
  { field: "balance_limit", width: "150px", title: " balance limit", show: true, isCents: true },
  { field: "ach_numbers_saved", width: "150px", title: "ach numbers saved ", show: true, isCents: true },
  { field: "mfi_account_id", width: "150px", title: " mfi account id", show: true },
  { field: "mfi_account_status", width: "150px", title: " mfi account status", show: true },
  { field: "mfi_account_error", width: "150px", title: " mfi account error", show: true },

  { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="id" source="id.like" alwaysOn />,
  <TextInput label="mask" source="mask.like" alwaysOn />,
  <TextInput label="name account" source="name_account.like" />,
  <TextInput label="mfi_account_id" source="mfi_account_id.like" />,

  <DateInput label="created_at gt" source="created_at.gt" alwaysOn />,
  <DateInput label="created_at lt" source="created_at.lt" />,

  <SelectInput source="status" choices={[
    { id: 'Failure', name: 'Failure' },
    { id: 'Pending', name: 'Pending' },
    { id: 'Canceled', name: 'Canceled' },
    { id: 'Sent', name: 'Sent' },
    { id: 'Scheduled', name: 'Scheduled' },
  ]} />,

  <SelectInput source="type" choices={[
    { id: 'true', name: 'Expired' },
    { id: 'false', name: 'Not expired' },
  ]} />,

  <SelectInput source="mfi_account_status" choices={[
    { id: 'true', name: 'Expired' },
    { id: 'false', name: 'Not expired' },
  ]} />,

  <SelectInput source="ach_numbers_saved" choices={[
    { id: 'true', name: 'Saved' },
    { id: 'false', name: 'Not saved' },
  ]} />,

  <DollarInput label="balance_limit gt (dollar)" source="balance_limit.gt" />,
  <DollarInput label="balance_limit lt (dollar)" source="balance_limit.lt" />,
];

export const ManualRawAccountsCollection: React.FC<Props> = (props) => {
  const {
    initialFilters,
  } = props;
  const [manualRawAccountDialogPayload, setManualRawAccountDialogPayload] = useState<ManualRawAccountDialogPayload>();

  const notify = useNotify();

  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, []);

  return (
    <Box sx={{ position: "relative", paddingTop: "60px" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px"
      }}>
        <h4>
          {`Manual raw accounts: ${initialFilters?.user_id.eq}`}
        </h4>
      </Box>

      <ManualRawAccountsDialog
        payload={manualRawAccountDialogPayload}
        hideDialog={() => setManualRawAccountDialogPayload(undefined)}
      />

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        columnModel={columnModel}
        filters={filters}
        rowActionBuilder={rowActionBuilder}
        tableActions={
          <>
            <Button
              label="Add manual account"
              onClick={() => {
                const userId = initialFilters?.user_id.eq;

                if (userId === undefined) {
                  notify("No user id");
                  return;
                }

                setManualRawAccountDialogPayload({ userId });
              }} />
            <FilterButton {...props} />
          </>
        }
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
}