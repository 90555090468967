import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { CircularProgress, makeStyles } from '@material-ui/core'
import {
  FormControlLabel,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from '@material-ui/core'

import { IUserInfoProps, IUserInfoUpdateProps } from '../types'

const useStyles = makeStyles({
  field: {
    paddingBottom: 15,
  },
  actions: {
    justifyContent: 'flex-end',
    marginRight: 10,
  },
})

const schema = yup
  .object({
    phone: yup
      .string()
      .matches(/^\d{11}$/)
      .required(),
    state: yup
      .string()
      .matches(/^[A-Z]{2,3}$/i)
      .required(),
    city: yup.string().required(),
    email: yup.string().email(),
  })
  .required()

export const UserInfoEdit = (props: {
  userInfo: IUserInfoProps
  id: string
  isOpen: boolean
  setOpen: (v: boolean) => void
  isLoading: boolean
  handleEdit: (v: IUserInfoUpdateProps) => void
}) => {
  const { userInfo, id, isOpen, setOpen, isLoading, handleEdit } = props

  const classes = useStyles()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const [isVerified, setIsVerified] = useState(true)
  
  useEffect(()=> {
    setDefaultValues()
  }, [userInfo])

  const handleClose = () => {
    clearErrors()
    setOpen(false)
    setDefaultValues()
  }

  const setDefaultValues = () => {
    setValue('phone', userInfo.userInfoPhone)
    setValue('email', userInfo.userInfoEmail)
    setValue('city', userInfo.userInfoCity)
    setValue('state', userInfo.userInfoState)
    // setIsVerified(false)
  }

  const isChanged = (): boolean => {
    return (
      watch('email') !== userInfo.userInfoEmail ||
      watch('phone') !== userInfo.userInfoPhone ||
      watch('city') !== userInfo.userInfoCity ||
      watch('state') !== userInfo.userInfoState
    )
  }

  const isNotValid = (): boolean => {
    return !!errors.phone
  }

  const buildData = (): IUserInfoUpdateProps => {
    const data: IUserInfoUpdateProps = {
      userId: parseInt(id),
      phone: watch('phone'),
      address: {
        city: watch('city'),
        state: watch('state').toUpperCase(),
      },
    }

    if (watch('email') !== userInfo.userInfoEmail) {
      data.email = {
        value: watch('email'),
        isVerified: isVerified,
      }
    }
    return data
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogContent>
          <TextField
            {...register('phone')}
            error={errors.phone !== undefined}
            helperText={errors.phone?.message}
            fullWidth
            id="phone"
            label="Phone number"
            className={classes.field}
            variant="outlined"
          />
          <TextField
            {...register('state')}
            error={errors.state !== undefined}
            helperText={errors.state?.message}
            fullWidth
            id="state"
            label="State"
            className={classes.field}
            variant="outlined"
            inputProps={{ style: { textTransform: 'uppercase' } }}
          />
          <TextField
            {...register('city')}
            error={errors.city !== undefined}
            helperText={errors.city?.message}
            fullWidth
            id="city"
            label="City"
            className={classes.field}
            variant="outlined"
          />
          <TextField
            {...register('email')}
            error={errors.email !== undefined}
            helperText={errors.email?.message}
            fullWidth
            id="email"
            type="email"
            label="Email"
            variant="outlined"
          />
          {/* {watch('email') && watch('email') !== userInfo.userInfoEmail && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isVerified}
                  onChange={(v) => setIsVerified(v.target.checked)}
                  name="checked"
                  color="primary"
                />
              }
              label="Is email verified"
            />
          )} */}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={handleClose} color="primary" variant="outlined" disabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(() => handleEdit(buildData()))}
            color="primary"
            disabled={isLoading || !isChanged() || isNotValid()}
            variant="contained"
            startIcon={isLoading && <CircularProgress size={14} color='inherit' />}
            style={{ marginLeft: 15 }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      </>
  )
}
