import { AdminRequest, PlacidResponse, PostError } from "./AdminRequest";

export type PaymentPromiseNewPayload = {
  userId: number
  comment: string
  promisedToPayAt: string
}

export type PaymentPromiseUpdatePayload = {
  id: number
  comment: string
  isFulfilled: boolean
}

export class CollectionActions {
  constructor(private adminRequest: AdminRequest) { };

  async createPaymentPromise(payload: PaymentPromiseNewPayload): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/collection/payment_promise/new',
          JSON.stringify(payload)
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async updatePaymentPromise(payload: PaymentPromiseUpdatePayload): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/collection/payment_promise/update',
          JSON.stringify(payload)
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  _checkPlacidError(placidResponse: PlacidResponse): PostError {
    if (placidResponse.success) {
      return null
    } else {
      return placidResponse.error.message ?? "Something went wrong";
    }
  }
}
