
import React, { FC, useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import { format } from 'date-fns';

export type UpdateDDDialogPayload = {
  userId: number
  billingCycleId: number
  currentDD: Date
} | undefined;

export const UpdateDDDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { clientActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [dd, setDD] = useState<string>("");

  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    console.log("SHOW DD", payload);
    if (payload !== undefined) {
      setDD(payload?.currentDD.toString() ?? "");
    }

    setError(null);
  }, [payload]);

  const onDDChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDD(value);
  }

  const handleSendClick = async () => {
    if (payload === undefined) return
    const { userId, billingCycleId } = payload;

    const dueDate = format(new Date(dd), "yyyy-MM-dd");

    const error = await clientActions.updateStatementDD({
      userId,
      billingCycleId,
      dueDate,
    });

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Updated");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Update due data"
      actionTitle='Update'
      onSend={handleSendClick}>

      <TextField
        fullWidth
        id="dob"
        label="dob"
        type="date"
        defaultValue={dd}
        value={dd}
        InputLabelProps={{ shrink: true }}
        onChange={onDDChange}
      />

      {
        error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>
      }

    </SimpleDialog>
  );
}

type Props = {
  payload?: UpdateDDDialogPayload
  hideDialog: () => void
}