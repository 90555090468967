import { AdminRequest, PlacidResponse, PostError } from "./AdminRequest";

export class MembershipActions {
  constructor(private adminRequest: AdminRequest) { };

  async refundPfmSubscription(userId: number, setupOnly: boolean): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/refunds/refund-pfm-subscription',
          JSON.stringify({
            userId,
            setupOnly,
          }),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request refund pfm subscription failed";
    }
  }
  async cancelSubscription(userId: number, immediately: boolean): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/subscription/cancel',
          JSON.stringify({
            userId,
            immediately,
          }),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request cancel membership subscription failed";
    }
  }

  _checkPlacidError(placidResponse: PlacidResponse): PostError {
    if (placidResponse.success) {
      return null
    } else {
      return placidResponse.error.message ?? "Something went wrong";
    }
  }
}
