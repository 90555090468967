
import { AdminRequest, PlacidResponse, PostError } from "./AdminRequest";

export class HistoricalPlaidTransactionActions {
  constructor(private adminRequest: AdminRequest) { };

  async update(userId: number): Promise<PostError> {
    return this._checkPlacidError(
      await this.adminRequest.post(
        "/admin/update-plaid-historical-transactions",
        JSON.stringify({
          userId,
        }),
      ),
    );
  }

  _checkPlacidError(placidResponse: PlacidResponse): PostError {
    if (placidResponse.success) {
      return null
    } else {
      return placidResponse.error.message ?? "Something went wrong";
    }
  }
}
