import { Box } from "@mui/material";
import React, { useMemo } from "react";
import {
  DateInput,
  FilterButton,
  NumberInput,
  TextInput,
} from "react-admin"
import { Button } from 'react-admin';
import { BottomDrawer } from '../../../common/BottomDrawer';
import { DollarInput } from "../../../common/DollarInput";
import { Resource } from "../../../common/Resource";
import { ColumnModel } from "../../../model/ColumnModel";

export const resource = "admin/generic-views/underwriting_decision_history";

export type UWDecisionHistoryDrawerPayload = {
  decisionId: number
}

const tableColumns = [
  { field: "id", width: "180px", title: "id", show: false },
  { field: "created_at", width: "180px", title: "created at", show: true, format: "yyyy-MM-dd" },
  { field: "user_id", width: "180px", title: "user id", show: false },
  { field: "product", width: "120px", title: "product", show: true },
  { field: "decision", width: "120px", title: "decision", show: true },
  { field: "is_monitoring", width: "120px", title: "is monitoring", show: true },
  { field: "product_id", width: "120px", title: "product id", show: true },
  { field: "comment", width: "120px", title: "comment", show: true },
  { field: "sys_period", width: "300px", title: "sys period", show: true },
]

const filters = [
  <TextInput label="product" source="product.eq" />,
  <TextInput label="decision" source="decision.eq" />,

  <DateInput label="created_at gt" source="created_at.gt" />,
  <DateInput label="created_at lt" source="created_at.lt" />,
]

export const UWDecisionHistoryDrawer: React.FC<Props> = (props) => {
  const {
    payload,
    onClose,
    disableUrlFilerListener = true,
  } = props;

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, []);

  if (payload !== undefined && payload?.decisionId === undefined) {
    return (
      <Box sx={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        no history
      </Box>
    )
  }

  return (
    <BottomDrawer
      isShow={payload !== undefined}
      onClose={onClose}
      title={`UW decision history: ${payload?.decisionId}`}>

      {
        payload !== undefined && (
          <Box>
            <Resource
              resource={resource}
              initialFilters={{ id: { eq: payload.decisionId } }}
              filters={filters}
              columnModel={columnModel}
              disableUrlFilterListener={disableUrlFilerListener}
              tableActions={
                <>
                  <Button label="close" onClick={onClose} />
                  <FilterButton {...props} />
                </>
              }
            />
          </Box>
        )
      }

    </BottomDrawer>
  );
}

type Props = {
  payload: UWDecisionHistoryDrawerPayload | undefined
  onClose: () => void
  disableUrlFilerListener?: boolean
}