
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { registerForLocalization } from '../../../../../kendo-react-intl';
import { classNames } from '../../../../../kendo-react-common';
import { CalendarHeaderTitle } from './CalendarHeaderTitle';
import { MIN_DATE, MAX_DATE } from '../../defaults';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * @hidden
 */
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getTitle = function () {
            if (!_this.props.currentDate) {
                return '';
            }
            var take = _this.rangeLength - 1;
            var title = _this.props.service.title(_this.props.currentDate);
            var nextDate = _this.props.service.addToDate(_this.props.currentDate, take);
            if (take < 1 || !_this.props.service.isInRange(nextDate, _this.min, _this.max)) {
                return title;
            }
            return "".concat(title, " - ").concat(_this.props.service.title(nextDate));
        };
        _this.handleTitleClick = function (event) {
            if (!_this.canMoveUp) {
                return;
            }
            _this.props.bus.moveUp(_this.props.activeView, event);
        };
        return _this;
    }
    Object.defineProperty(Header.prototype, "min", {
        get: function () {
            return this.props.min !== undefined
                ? this.props.min
                : Header.defaultProps.min;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Header.prototype, "max", {
        get: function () {
            return this.props.max !== undefined
                ? this.props.max
                : Header.defaultProps.max;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Header.prototype, "rangeLength", {
        get: function () {
            return this.props.rangeLength !== undefined ? this.props.rangeLength : Header.defaultProps.rangeLength;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Header.prototype, "canMoveUp", {
        get: function () {
            return this.props.bus.canMoveUp(this.props.activeView);
        },
        enumerable: false,
        configurable: true
    });
    Header.prototype.render = function () {
        var title = this.getTitle();
        var titleClassNames = classNames('k-calendar-title');
        var headerTitleProps = {
            children: title,
            value: title,
            view: this.props.activeView,
            className: titleClassNames,
            onClick: this.handleTitleClick,
            disabled: !this.canMoveUp
        };
        var headerTitle = this.props.headerTitle
            ? React.createElement(this.props.headerTitle, __assign({}, headerTitleProps), title)
            : React.createElement(CalendarHeaderTitle, __assign({}, headerTitleProps), title);
        return (React.createElement("div", { className: "k-calendar-header k-hstack" },
            headerTitle,
            React.createElement("span", { className: "k-spacer" }),
            React.createElement("span", { className: "k-calendar-nav k-hstack" }, this.props.commands)));
    };
    Header.propTypes = {
        activeView: PropTypes.number.isRequired,
        currentDate: PropTypes.instanceOf(Date).isRequired,
        max: PropTypes.instanceOf(Date).isRequired,
        min: PropTypes.instanceOf(Date).isRequired,
        rangeLength: PropTypes.number
    };
    Header.defaultProps = {
        rangeLength: 1,
        min: MIN_DATE,
        max: MAX_DATE
    };
    return Header;
}(React.Component));
export { Header };
registerForLocalization(Header);
