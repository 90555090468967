import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  RowActions,
} from "./widget/RowActions";
import { Box } from '@mui/material';
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { UpdateCycleDialog, UpdateCycleDialogPayload } from './widget/UpdateCycleDialog';
import { UpdateDueDateDialog, UpdateDueDateDialogPayload } from './widget/UpdateDueDateDialog';

export const resource = "admin/generic-views/cycles";

const tableColumns = [
  { field: "id", width: "120px", title: "id", show: true },
  { field: "created_at", width: "120px", title: "created at", show: true, format: "yyyy-MM-dd" },
  { field: "user_id", width: "120px", title: "user id", show: true },
  { field: "active_from", width: "120px", title: "active from", show: true, format: "yyyy-MM-dd" },
  { field: "active_to", width: "120px", title: "active to", show: true, format: "yyyy-MM-dd" },
  { field: "extra_amount", width: "120px", title: "extra_amount", show: true, isCents: true },
  { field: "balance_transfer_status", width: "120px", title: "balance transfer status", show: true },
  { field: "single_bill_status", width: "120px", title: "single bill status", show: true },
  { field: "is_subscription_charged", width: "120px", title: "is subscription charged", show: true },
  { field: "sys_period", width: "120px", title: "sys_period", show: true },
  { field: "minimum_payment", width: "120px", title: "minimum_payment", show: true, isCents: true },
  { field: "subscription_amount", width: "120px", title: "subscription_amount", show: true, isCents: true },
  { field: "bt_minimum_payment", width: "120px", title: "bt_minimum_payment", show: true, isCents: true },
  { field: "placid_credit_line_extra_amount", width: "120px", title: "placid_credit_line_extra_amount", show: true, isCents: true },
  { field: "due_date", width: "120px", title: "due date", show: true, format: "yyyy-MM-dd" },
  { field: "premium_payment", width: "120px", title: "premium_payment", show: true, isCents: true },

  { field: "actions", width: "120px", title: "actions", show: true },
]

export enum Dialog {
  ddDialog,
  cycleDialog,
}

export const CyclesCollection: React.FC<Props> = ({
  initialFilters,
  onRowClick,
}) => {
  const [updateCycleDialogPayload, setUpdateCycleDialogPayload] = useState<UpdateCycleDialogPayload>();
  const [updateDueDateDialogPayload, setUpdateDueDateDialogPayload] = useState<UpdateDueDateDialogPayload>();

  const rowActionBuilder = (record: { [index: string]: any }) => {
    return <RowActions
      record={record}
      onAction={(dialog, record) => {
        const {
          user_id: userId,
          id: billingCycleId,
          due_date: dueDate,
        } = record;

        switch (dialog) {
          case Dialog.cycleDialog:
            setUpdateCycleDialogPayload({ userId });
            break;
          case Dialog.ddDialog:
            setUpdateDueDateDialogPayload({
              userId,
              billingCycleId,
              dueDate
            });
            break
        }
      }}
    />;
  }

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Box sx={{ position: "relative" }}>

      <UpdateCycleDialog
        payload={updateCycleDialogPayload}
        hideDialog={() => setUpdateCycleDialogPayload(undefined)}
      />

      <UpdateDueDateDialog
        payload={updateDueDateDialogPayload}
        hideDialog={() => setUpdateDueDateDialogPayload(undefined)}
      />

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`Cycles: ${initialFilters?.user_id.eq}`}
        </h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        disableUrlFilterListener={false}
        rowActionBuilder={rowActionBuilder}
        columnModel={columnModel}
        rowPerPageOptions={[25, 50, 100]}
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
}