import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  RowActions,
} from "./widget/RowActions";
import { Box } from '@mui/material';
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { UpdateDDDialog, UpdateDDDialogPayload } from './widget/UpdateDDDialog';

export const resource = "admin/generic-views/statements";

const tableColumns = [
  { field: "created_at", width: "120px", title: "created at", show: true, format: "yyyy-MM-dd" },
  { field: "single_bill_cycle_id", width: "120px", title: "single_bill_cycle_id", show: true, },
  { field: "bt_balance", width: "120px", title: "bt_balance", show: true, isCents: true },
  { field: "total_minimum_payment", width: "120px", title: "total_minimum_payment", show: true, isCents: true },
  { field: "pb_minimum_payment", width: "120px", title: "pb_minimum_payment", show: true, isCents: true },
  { field: "subscription_payment", width: "120px", title: "subscription_payment", show: true, isCents: true },
  { field: "bt_principal_payment", width: "120px", title: "bt_principal_payment", show: true, isCents: true },
  { field: "bt_interest_payment", width: "120px", title: "bt_interest_payment", show: true, isCents: true },
  { field: "late_fee_payment", width: "120px", title: "late_fee_payment", show: true, isCents: true },
  { field: "payment_due_date", width: "120px", title: "payment_due_date", show: true, format: "yyyy-MM-dd" },
  { field: "total_unpaid_amount", width: "120px", title: "total_unpaid_amount", show: true, isCents: true },
  { field: "pb_minimum_unpaid_amount", width: "120px", title: "pb_minimum_unpaid_amount", show: true, isCents: true },
  { field: "subscription_unpaid_amount", width: "120px", title: "subscription_unpaid_amount", show: true, isCents: true },
  { field: "bt_interest_unpaid_amount", width: "120px", title: "bt_interest_unpaid_amount", show: true, isCents: true },
  { field: "bt_principal_unpaid_amount", width: "120px", title: "bt_principal_unpaid_amount", show: true, isCents: true },
  { field: "late_fee_unpaid_amount", width: "120px", title: "late_fee_unpaid_amount", show: true, isCents: true },
  { field: "sys_period", width: "120px", title: "sys_period", show: true },
  { field: "deferred_interest", width: "120px", title: "deferred_interest", show: true, isCents: true },

  { field: "actions", width: "120px", title: "actions", show: true },
]

export enum Dialog {
  ddDialog,
  cycleDialog,
}

export const StatementsCollection: React.FC<Props> = ({
  initialFilters,
  onRowClick,
}) => {
  const [updateDDDialogPayload, setUpdateDDDialogPayload] = useState<UpdateDDDialogPayload>();

  const rowActionBuilder = (record: { [index: string]: any }) => {
    return <RowActions
      record={record}
      onAction={(dialog, record) => {
        const {
          user_id: userId,
          single_bill_cycle_id: billingCycleId,
          payment_due_date: currentDD,
        } = record

        switch (dialog) {
          case Dialog.ddDialog:
            setUpdateDDDialogPayload({ userId, billingCycleId, currentDD })
            break;
        }
      }}
    />;
  }

  useEffect(() => {
    console.log(updateDDDialogPayload);
  }, [updateDDDialogPayload]);

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Box sx={{ position: "relative" }}>

      <UpdateDDDialog
        payload={updateDDDialogPayload}
        hideDialog={() => setUpdateDDDialogPayload(undefined)}
      />

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`Statements: ${initialFilters?.user_id.eq}`}
        </h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        disableUrlFilterListener={false}
        rowActionBuilder={rowActionBuilder}
        columnModel={columnModel}
        rowPerPageOptions={[25, 50, 100]}
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
}