
import React, { FC, useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';

export type UpdateNameDialogPayload = {
  first_name: string
  last_name: string
  id: number
} | null;

export const UpdateNameDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { clientActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    if (payload !== undefined) {
      setFirstName(payload?.first_name ?? "")
      setLastName(payload?.last_name ?? "")
    }

    setError(null);
  }, [payload]);

  const onFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFirstName(value);
  }

  const onLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLastName(value);
  }

  const handleSendClick = async () => {
    if (payload === null) return;

    const { id } = payload ?? {};

    if (id === undefined) {
      notify("no id provided");
      return;
    }

    if (!window.confirm(`Update name?`)) {
      return;
    }

    const error = await clientActions.updatePersonalInfo({
      userId: id,
      payload: {
        firstName,
        lastName,
        income: {},
        address: {},
      }
    });

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Updated");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Update name"
      actionTitle='Update'
      onSend={handleSendClick}>

      <TextField
        fullWidth
        type="text"
        label="fist name"
        value={firstName}
        onChange={onFirstNameChange}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="text"
        label="last name"
        value={lastName}
        onChange={onLastNameChange}
        variant="outlined"
      />

      {error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload?: UpdateNameDialogPayload;
  hideDialog: () => void;
}