import React, { FC, useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';

export type PaymentPromiseNewDialogPayload = {
  userId: number
} | undefined;

export const PaymentPromiseNewDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { collectionActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [comment, setComment] = useState<string>("");
  const [payAt, setPayAt] = useState<string>("");
  const [isProcess, setIsProcess] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    setComment("");
    setPayAt("");
    setIsProcess(false);
    setError(null);
  }, [payload]);

  const onPayAtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPayAt(value);
  }

  const onCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setComment(value);
  }

  const handleSendClick = async () => {
    if (payload === undefined || isProcess) return

    const { userId } = payload;

    setIsProcess(true);

    const error = await collectionActions.createPaymentPromise({
      userId,
      comment,
      promisedToPayAt: payAt,
    });

    setIsProcess(false);

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Created");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="New promise payment"
      actionTitle={isProcess ? 'Sending...' : 'Create'}
      onSend={handleSendClick}>

      <TextField
        fullWidth
        type="date"
        label="Transaction date"
        value={payAt}
        onChange={onPayAtChange}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="text"
        label="Comment"
        value={comment}
        onChange={onCommentChange}
        variant="outlined"
      />

      {error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload?: PaymentPromiseNewDialogPayload
  hideDialog: () => void
}