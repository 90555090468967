import React, { FC, useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import currency from 'currency.js';

export type UpdateBTTnxAmountDialogPayload = {
  userId: number
  accountId: number
  amount: number
} | undefined;

export const UpdateBTTnxAmountDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { btActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [amount, setAmount] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    if (payload !== undefined) {
      setAmount(currency(payload?.amount ?? 0, { fromCents: true }).format());
    }

    setError(null);
  }, [payload]);

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAmount(value);
  }

  const handleSendClick = async () => {
    if (payload === undefined) return

    const { userId, accountId } = payload;

    const payment = currency(amount).intValue

    const error = await btActions.btDisburse({
      userId,
      accountId,
      payment,
    });

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Updated");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Update bt tnx amount"
      actionTitle='Update'
      onSend={handleSendClick}>

      <TextField
        fullWidth
        type="text"
        label="amount (dollars)"
        value={amount}
        onChange={onAmountChange}
        variant="outlined"
      />

      {
        error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>
      }

    </SimpleDialog>
  );
}

type Props = {
  payload?: UpdateBTTnxAmountDialogPayload
  hideDialog: () => void
}