import * as React from 'react';
import { classNames } from '../../../../kendo-react-common';
/**
 * @hidden
 * @deprecated
 */
export var PickerWrap = React.forwardRef(function (props, ref) {
    var _ref = props._ref;
    var element = React.useRef(null);
    React.useImperativeHandle(ref, function () { return element.current; });
    React.useImperativeHandle(_ref, function () { return element.current; });
    var className = React.useMemo(function () { return classNames(props.className, 'k-picker-wrap'); }, [props.className]);
    return (React.createElement("span", { ref: element, id: props.id, style: props.style, className: className, tabIndex: props.tabIndex }, props.children));
});
