import * as React from 'react';
import { getIndex } from '../../../../kendo-react-data-tools';
/**
 * @hidden
 */
var CommonDragLogic = /** @class */ (function () {
    function CommonDragLogic(columnReorder, groupReorder, columnToGroup) {
        var _this = this;
        this.refDragElementClue = React.createRef();
        this.refDropElementClue = React.createRef();
        this.columns = [];
        this.reorderable = false;
        this.groupable = false;
        this.startColumn = -1;
        this.startGroup = -1;
        this.currentColumn = -1;
        this.currentGroup = -1;
        this.groupPanelDivElement = null;
        this.refGroupPanelDiv = function (e) {
            _this.groupPanelDivElement = e;
        };
        this.pressHandler = function (event, element) {
            var startColumn = _this.getColumnIndex(event, element);
            _this.startGroup = _this.getGroupIndex(event);
            if (startColumn >= 0) {
                var col = _this.columns[startColumn];
                if (col.reorderable && _this.reorderable || col.groupable && _this.groupable) {
                    _this.startColumn = startColumn;
                }
            }
        };
        this.dragHandler = function (event, element) {
            if (!event.isTouch) {
                event.originalEvent.preventDefault();
            }
            event.originalEvent.stopPropagation();
            if (_this.startColumn === -1 && _this.startGroup === -1) {
                return;
            }
            _this.currentColumn = _this.getColumnIndex(event, element);
            _this.currentGroup = _this.getGroupIndex(event);
            if (_this.groupPanelDivElement && _this.startGroup >= 0) {
                _this.currentGroup = Math.min(_this.currentGroup, _this.groupPanelDivElement.children.length - 2);
            }
            var invalidIndex = !_this.isValid();
            if (invalidIndex) {
                _this.currentColumn = -1;
                _this.currentGroup = -1;
            }
            var targetElement = (_this.currentColumn >= 0) ?
                element.children[_this.columns[_this.currentColumn].index] :
                _this.groupPanelDivElement && _this.groupPanelDivElement.children[_this.currentGroup];
            _this.updateDragElementClue(event, element, targetElement, invalidIndex);
            _this.updateDropElementClue(event, element, targetElement, invalidIndex);
        };
        this.releaseHandler = function (event) {
            var prevColumnIndex = _this.startColumn;
            var nextColumnIndex = _this.currentColumn;
            var prevGroupIndex = _this.startGroup;
            var nextGroupIndex = _this.currentGroup;
            event.originalEvent.preventDefault();
            if (_this.dropElementClue) {
                _this.dropElementClue.setState({ visible: false });
            }
            if (_this.dragElementClue) {
                _this.dragElementClue.setState({ visible: false });
            }
            var isValid = _this.isValid();
            _this.startColumn = _this.startGroup = _this.currentColumn = _this.currentGroup = -1;
            if (!isValid) {
                return;
            }
            if (prevColumnIndex >= 0 && nextColumnIndex >= 0) {
                _this.columnReorder(prevColumnIndex, nextColumnIndex, event.originalEvent);
            }
            else if (prevGroupIndex >= 0 && nextGroupIndex >= 0) {
                _this.groupReorder(prevGroupIndex, nextGroupIndex, event.originalEvent);
            }
            else if (prevColumnIndex >= 0 && nextGroupIndex >= 0) {
                _this.columnToGroup(prevColumnIndex, nextGroupIndex, event.originalEvent);
            }
        };
        this.columnReorder = columnReorder;
        this.groupReorder = groupReorder;
        this.columnToGroup = columnToGroup;
    }
    Object.defineProperty(CommonDragLogic.prototype, "dragClueRef", {
        get: function () {
            return this.refDragElementClue;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CommonDragLogic.prototype, "dropClueRef", {
        get: function () {
            return this.refDropElementClue;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CommonDragLogic.prototype, "dragElementClue", {
        get: function () {
            return this.refDragElementClue.current;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CommonDragLogic.prototype, "dropElementClue", {
        get: function () {
            return this.refDropElementClue.current;
        },
        enumerable: false,
        configurable: true
    });
    CommonDragLogic.prototype.getColumnIndex = function (event, parent) {
        if (!parent || (parent.parentElement === this.groupPanelDivElement)) {
            return -1;
        }
        var index = getIndex(event, parent);
        if (index === -1) {
            return -1;
        }
        var _loop_1 = function (i) {
            if (parent.parentNode.children[i] === parent) {
                return { value: this_1.columns.findIndex(function (c) { return ((c.index === index) && (c.depth === i)); }) };
            }
        };
        var this_1 = this;
        for (var i = 0; i < parent.parentNode.children.length; i++) {
            var state_1 = _loop_1(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return -1;
    };
    CommonDragLogic.prototype.getGroupIndex = function (event) {
        return getIndex(event, this.groupPanelDivElement);
    };
    CommonDragLogic.prototype.isValid = function () {
        if (this.startGroup >= 0) {
            // group can be moved into group only
            return this.currentGroup >= 0 && this.currentGroup !== this.startGroup;
        }
        if (this.startColumn === -1) {
            return false;
        }
        if (this.currentGroup >= 0) {
            // column to group is possible
            return this.columns[this.startColumn].groupable === true && this.groupable === true;
        }
        // reorder is possible
        return this.reorderable === true &&
            this.currentColumn >= 0 &&
            this.currentColumn !== this.startColumn &&
            this.columns[this.startColumn].reorderable === true &&
            this.columns[this.currentColumn].parentIndex === this.columns[this.startColumn].parentIndex;
    };
    CommonDragLogic.prototype.updateDragElementClue = function (event, element, targetElement, invalidIndex) {
        if (!this.dragElementClue) {
            return;
        }
        var innerText = this.startColumn >= 0 ?
            element.children[this.columns[this.startColumn].index].innerText :
            element.innerText;
        this.dragElementClue.setState({
            visible: true,
            top: (event.pageY + 10),
            left: event.pageX,
            innerText: innerText,
            status: (invalidIndex || !targetElement) ? 'k-i-cancel' : 'k-i-plus'
        });
    };
    CommonDragLogic.prototype.updateDropElementClue = function (event, element, targetElement, invalidIndex) {
        if (!this.dropElementClue) {
            return;
        }
        if (invalidIndex || !targetElement) {
            this.dropElementClue.setState({ visible: false });
            return;
        }
        var rect = targetElement.getBoundingClientRect();
        var left = rect.left + event.pageX - event.clientX - 6;
        if (this.currentColumn > this.startColumn || this.currentGroup > this.startGroup && this.startGroup !== -1) {
            left += rect.width;
        }
        var top = rect.top + event.pageY - event.clientY;
        this.dropElementClue.setState({
            visible: true,
            top: top,
            left: left,
            height: (this.currentColumn >= 0) ? element.clientHeight : rect.height
        });
    };
    return CommonDragLogic;
}());
export { CommonDragLogic };
