import { WaitingUsersCollection } from "../../collections/waiting-users-collection/WaitingUsersCollection";
import { UnderwritingMonitoringForm } from "../../components/UnderwritingMonitoringForm";

export const UnderwritingMonitoringDashboard = () => {

  return (
    <section className='dashboard'>
      <h1>Underwriting Monitoring</h1>

      <UnderwritingMonitoringForm />

    </section>
  );
}
