import { Box } from '@mui/material'
import React from 'react'

export type CoordinatesMapPayload = {
  latitude: number
  longitude: number
}

export type AddressMapPayload = {
  address: string
}

const isCoord = (obj: any): obj is CoordinatesMapPayload => obj.latitude !== undefined;

const _stringifyAddress = (payload: AddressMapPayload | CoordinatesMapPayload) => {
  if (isCoord(payload)) {
    const { latitude, longitude } = payload;
    return encodeURIComponent(`${latitude},${longitude}`);
  } else {
    const { address } = payload as AddressMapPayload;
    return encodeURIComponent(address + ", USA");
  }
}

const _Map = (title: string, url: string) =>
  <Box>
    <h5>{title}</h5>
    <iframe
      width="500"
      height="500"
      src={url}
    />
  </Box>


const buildMapUrl = (origin: string, destination?: string): string => {
  if (destination === undefined) {
    return `https://maps.google.com/maps?q=${origin},&t=&z=16&ie=UTF8&iwloc=&output=embed`;
  }

  return `https://www.google.com/maps/embed/v1/directions`
    + `?key=AIzaSyA6Mp027f4q5jC8KVir3FYrWTRIxnntxT8`
    + `&origin=${origin}`
    + `&destination=${destination}`;
}

export const AddressMap: React.FC<Props> = ({
  address, addressFromIp,
}) => {
  let url = '';

  if (address === undefined && addressFromIp !== undefined) {
    url = buildMapUrl(_stringifyAddress(addressFromIp));
    return _Map(`IP address: ${addressFromIp?.latitude},${addressFromIp?.latitude}`, url);
  } else if (address !== undefined && addressFromIp === undefined) {
    url = buildMapUrl(_stringifyAddress(address));
    return _Map(address?.address, url);
  } else {
    return _Map(
      `${address?.address} ==> ${addressFromIp?.latitude},${addressFromIp?.longitude}`,
      buildMapUrl(_stringifyAddress(address!), _stringifyAddress(addressFromIp!)),
    );
  }
}

type Props = {
  address: AddressMapPayload | undefined,
  addressFromIp: CoordinatesMapPayload | undefined
}