import { makeStyles, alpha } from '@material-ui/core/styles'
import { format } from 'date-fns'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Box } from '@material-ui/core'

import { IStatementsProps } from '../types'
import { centsToDollars } from '../helpers'
import { CustomToolbar } from './CustomToolbar'

const useStyles = makeStyles({
  root: {
    padding: 15,
    '& .MuiDataGrid-row': {
      maxHeight: 'unset!important',
    },
    '& .MuiDataGrid-cell': {
      whiteSpace: 'normal!important',
      maxHeight: 'unset!important',
      height: 'unset!important',
    },
  },
  table: {
    minWidth: 650,
  },
  cell: {
    minWidth: 80,
  },
})

export const StatementsTable = (props: { statements: IStatementsProps[] }) => {
  const classes = useStyles()

  const columns: GridColDef[] = [
    {
      field: 'statementDate',
      headerName: 'Statement date',
      width: 110,
    },
    {
      field: 'billingCycle',
      headerName: 'Billing cycle',
      width: 120,
      sortable: false,
    },
    {
      field: 'fullBalance',
      headerName: 'Full balance',
      width: 110,
      sortable: false,
    },
    {
      field: 'previousAmount',
      headerName: 'Past due amount from previous period',
      width: 150,
      sortable: false,
    },
    { field: 'dueDate', headerName: 'Due date', width: 120, sortable: false },
    {
      field: 'totalAmount',
      headerName: 'Total due amount',
      width: 110,
      sortable: false,
    },
    {
      field: 'thisPeriodAmount',
      headerName: 'This period due amount',
      width: 110,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 80,
      sortable: false,
    },
  ]

  const rows = props.statements.map((row, index) => ({
    id: index,
    statementDate: format(new Date(row.date), 'yyyy-MM-dd'),
    billingCycle: row.billingCycle,
    fullBalance:
      row.fullBalance !== null
        ? centsToDollars(
            row.fullBalance.pbBalance +
              row.fullBalance.btBalance +
              row.fullBalance.interestBalance +
              row.fullBalance.savingsBalance +
              row.fullBalance.subscriptionBalance
          )
        : '',
    previousAmount: centsToDollars(row.pastDueAmountFromPreviousPeriod),
    dueDate: row.dueDate,
    totalAmount:
      row.totalDueAmount !== null ? centsToDollars(row.totalDueAmount) : '',
    thisPeriodAmount: centsToDollars(row.thisPeriodDueAmount),
    status: row.status,
  }))

  return (
    <Box height={rows.length > 0 ? 400 : '100%'} width="100%">
      <DataGrid
        components={{
          Toolbar: () =>
            CustomToolbar({
              title: 'Statements',
            }),
        }}
        hideFooterPagination={rows.length === 0}
        classes={{ root: classes.root }}
        density="compact"
        disableColumnMenu
        rows={rows}
        columns={columns}
        pageSize={5}
        disableColumnFilter
      />
    </Box>
  )
}
