import { Box } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import {
  FilterButton,
  TextInput,
} from "react-admin"
import { Button } from 'react-admin';
import { BottomDrawer } from '../../../common/BottomDrawer';
import { Resource } from "../../../common/Resource";
import { ColumnModel } from "../../../model/ColumnModel";

export const resource = "admin/generic-views/plaid_tokens";

export type PlaidTokensPayload = {
  userId: string
}

const tableColumns = [
  { field: "created_at", width: "120px", title: "created at", show: true, format: "yyyy-MM-dd" },
  { field: "access_token", width: "400px", title: "access_token", show: true },
  { field: "item_id", width: "120px", title: "item_id", show: true },
  { field: "bank_id", width: "120px", title: "bank_id", show: true },
  { field: "can_be_duplicated", width: "120px", title: "can_be_duplicated", show: true },
  { field: "status", width: "120px", title: "status", show: true },
  { field: "sys_period", width: "120px", title: "sys_period", show: true },

  // { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="name" source="name.eq" alwaysOn />,

  // <TextInput label="bank" source="bank.eq" />,

  // <TextInput label="mask" source="mask.eq" />,

  // <TextInput label="table id" source="table_id.eq" />,

  // <DollarInput label="balance gt (Dollar)" source="balance.gt" />,
  // <DollarInput label="balance lt (Dollar)" source="balance.lt" />,

  // <DollarInput label="available gt (Dollar)" source="available.gt" />,
  // <DollarInput label="available lt (Dollar)" source="available.lt" />,

  // <DollarInput label="limit gt (Dollar)" source="limit.gt" />,
  // <DollarInput label="limit lt (Dollar)" source="limit.lt" />,

  // <TextInput label="type" source="type.eq" />,
  // <TextInput label="subtype" source="subtype.eq" />,

  // <SelectInput source="status" choices={[
  //   { id: "ACTIVE", name: "ACTIVE" },
  //   { id: "DISAPPEARED", name: "DISAPPEARED" },
  //   { id: "CONFIRMED_CLOSE", name: "CONFIRMED_CLOSE" },
  //   { id: "CLOSED_PERMANENTLY", name: "CLOSED_PERMANENTLY" },
  // ]} />,

  // <SelectInput source="token_status" choices={[
  //   { id: "Active", name: "Active" },
  //   { id: "OnHold", name: "OnHold" },
  //   { id: "Revoked", name: "Revoked" },
  // ]} />,

  // <SelectInput source="primary_status" choices={[
  //   { id: 'NotPrimary', name: 'NotPrimary' },
  //   { id: 'PrimarySystemConfirmed', name: 'PrimarySystemConfirmed' },
  //   { id: 'PrimaryAdminConfirmed', name: 'PrimaryAdminConfirmed' },
  // ]} />,

  // <SelectInput source="pending_auth" choices={[
  //   { id: 'true', name: 'Enabled' },
  //   { id: 'false', name: 'Disabled' },
  // ]} />,

  // <SelectInput source="micro_deposits_status" choices={[
  //   { id: 'Verified', name: 'Verified' },
  //   { id: 'Expired', name: 'Expired' },
  // ]} />,

  // <TextInput label="PREVIOUS_PLAID_RAW_ACCOUNT" source="PREVIOUS_PLAID_RAW_ACCOUNT.eq" />,
];

export const PlaidTokensDrawer: React.FC<Props> = (props) => {
  const {
    payload,
    onClose,
  } = props;

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <BottomDrawer
      isShow={payload !== undefined}
      onClose={onClose}
      title="Plaid tokens">

      {
        payload !== undefined && (
          <Box>
            <Resource
              resource={resource}
              initialFilters={{ user_id: { eq: payload.userId } }}
              filters={filters}
              columnModel={columnModel}
              tableActions={
                <>
                  <Button label="close" onClick={onClose} />
                  <FilterButton {...props} />
                </>
              }
            />
          </Box>
        )
      }

    </BottomDrawer>
  );
}

type Props = {
  payload: PlaidTokensPayload | undefined
  onClose: () => void
}