import React, { FC, useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import currency from 'currency.js';
import { format } from 'date-fns';

export type UpdateBTTnxDateDialogPayload = {
  userId: number
  accountId: number
} | undefined;

export const UpdateBTTnxDateDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { btActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [date, setDate] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    if (payload !== undefined) {
      setDate("");
    }

    setError(null);
  }, [payload]);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDate(value);
  }

  const handleSendClick = async () => {
    if (payload === undefined) return

    const { userId, accountId: scheduledBTTxnId } = payload;

    if (date === "") {
      setError("date not provided");
      return;
    }

    const error = await btActions.moveScheduledDate({
      userId,
      scheduledBTTxnId,
      date,
    });

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Updated");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Move scheduled date"
      actionTitle='Move'
      onSend={handleSendClick}>


      <TextField
        fullWidth
        type="date"
        label="Transaction date"
        value={date}
        onChange={handleDateChange}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
      />

      {
        error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>
      }

    </SimpleDialog>
  );
}

type Props = {
  payload?: UpdateBTTnxDateDialogPayload
  hideDialog: () => void
}