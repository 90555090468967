import { useState } from 'react'
import { useRefresh } from 'react-admin'
import { Box, Button, Typography, Grid, Snackbar } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { format } from 'date-fns'

import { IUserInfoProps, IUserInfoUpdateProps } from '../types'
import { UserInfoEdit } from './UserInfoEdit'
import { centsToDollars } from '../helpers'
import { dataProvider } from '../providers/dataProvider'
import { Constants } from '../constants'

const phoneRegex = /^[0-9]([0-9]{3})([0-9]{3})([0-9]{4})$/

export const UserInfo = (props: { userInfo: IUserInfoProps; id: string }) => {
  const { userInfo, id } = props

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const refresh = useRefresh()
  const [error, setError] = useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleEdit = (data: IUserInfoUpdateProps) => {
    setIsLoading(true)
    dataProvider
      .update(Constants.PERSONAL_INFO_UPDATE, {
        id: parseInt(id),
        data: data,
        previousData: { id: parseInt(id) },
      })
      .then((response) => {
        if (response.data.success) {
          refresh()
          handleClickOpen()
        } else {
          setError(response.data.error.message)
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        setError(e.toString())
      })
  }

  return (
    <Box padding={2}>
      <Box display="flex">
        <Box width="100%">
          <Typography variant="h6">User Info</Typography>
        </Box>
        <Box flexShrink={1}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Edit />}
            onClick={handleClickOpen}
          >
            Edit
          </Button>
        </Box>
      </Box>

      <Grid container>
        <Grid item xs={12}>
          <Typography>
            <b>Name: </b>
            {userInfo.userInfoName} {userInfo.userInfoSurname}
          </Typography>
          <Typography>
            <span>
              <b>Email: </b> {userInfo.userInfoEmail}
            </span>
          </Typography>
          <Typography>
            <b>City and state: </b> {[userInfo.userInfoState, userInfo.userInfoCity].filter((v) => v !== null).join(', ')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <b>Status: </b> {userInfo.userInfoStatus}
          </Typography>
          <Typography>
            <b>Registration date: </b>
            {format(new Date(userInfo.userInfoRegistrationDate), 'yyyy-MM-dd')}
          </Typography>
          <Typography>
            <b>Status update: </b>
            {format(new Date(userInfo.userInfoStatusUpdate), 'yyyy-MM-dd')}
          </Typography>

          <Typography>
            <b>Income 3rd party: </b>{' '}
            {userInfo.userInfoIncome3rdParty !== null
              ? centsToDollars(userInfo.userInfoIncome3rdParty)
              : ''}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 10 }}>
          <Typography>
            <b>FICO: </b>{' '}
            {userInfo.userInfoFico !== null
              ? userInfo.userInfoFico
              : ''}
          </Typography>
          <Typography>
            <span>
              <b>DOB: </b> {userInfo.userInfoAge}
            </span>
          </Typography>
          <Typography>
            <b>Phone number: </b>{' '}
            {userInfo.userInfoPhone.replace(phoneRegex, '($1) $2-$3')}
          </Typography>
          <Typography>
            <b>ID: </b> {id}
          </Typography>
        </Grid>
      </Grid>
      <UserInfoEdit
        userInfo={userInfo}
        id={id}
        isOpen={open}
        setOpen={setOpen}
        isLoading={isLoading}
        handleEdit={handleEdit}
      />
      <Snackbar
        open={error.length > 0}
        message={error}
        onClose={() => setError('')}
      />
    </Box>
  )
}
