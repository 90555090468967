import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { Resource } from "../../common/Resource";
import { ColumnModel } from "../../model/ColumnModel";
import { DateInput, FilterButton, SelectInput, TextInput } from "react-admin";
import { RowActions } from "./widget/RowActions";

export const resource = "admin/generic-views/membership_overview";

const tableColumns = [
  { field: "id", width: "50px", title: "id", show: true },
  { field: "user_id", width: "50px", title: "user_id", show: false },
  { field: "trial_start_date", width: "100px", title: "trial start", show: true, format: 'yyyy-MM-dd' },
  { field: "trial_end_date", width: "100px", title: "trial end", show: true, format: 'yyyy-MM-dd' },
  { field: "end_date", width: "100px", title: "end date", show: true, format: 'yyyy-MM-dd' },
  { field: "last_payment_date", width: "100px", title: "last payment date", show: true, format: 'yyyy-MM-dd' },
  { field: "last_payment_status", width: "100px", title: "last payment status", show: true },
  { field: "last_payment_error", width: "100px", title: "last payment error", show: true },
  { field: "membership_status", width: "100px", title: "membership status", show: true },
  { field: "last_cancelation_date", width: "100px", title: "last cancelation date", show: true, format: 'yyyy-MM-dd' },
  { field: "expected_payment_date", width: "100px", title: "exp pay date", show: true, format: 'yyyy-MM-dd' },
  { field: "expected_payment_amount", width: "150px", title: "exp pay amount", show: true, isCents: true },
  { field: "payment_account_type", width: "150px", title: "pay acc type", show: true, },
  { field: "payment_account_id", width: "150px", title: "pay acc id", show: true, },

  { field: "actions", width: "140px", title: "actions", show: true },
];

const filters = [
  <TextInput label="id" source="id.eq" />,
  <TextInput label="payment_account_id" source="payment_account_id.eq" />,
  <TextInput label="user id" source="user_id.eq" alwaysOn />,

  <DateInput label="trial start date gt" source="trial_start_date.gt" alwaysOn />,
  <DateInput label="trial start date lt" source="trial_start_date.lt" />,

  <DateInput label="trial end date gt" source="trial_end_date.gt" />,
  <DateInput label="trial end date lt" source="trial_end_date.lt" alwaysOn />,

  <DateInput label="end date gt" source="end_date.gt" />,
  <DateInput label="end date lt" source="end_date.lt" />,

  <DateInput label="last payment date gt" source="last_payment_date.gt" />,
  <DateInput label="last payment date lt" source="last_payment_date.lt" />,

  <SelectInput source="last_payment_status" choices={[
    { id: 'PROCESSING', name: 'PROCESSING' },
    { id: 'INITED', name: 'INITED' },
    { id: 'DECLINED', name: 'DECLINED' },
    { id: 'CLEARED', name: 'CLEARED' },
  ]} alwaysOn />,

  <SelectInput source="membership_status" choices={[
    { id: 'Active', name: 'Active' },
    { id: 'Canceled', name: 'Canceled' },
  ]} alwaysOn />,

  <SelectInput source="payment_account_type" choices={[
    { id: 'null', name: 'null' },
    { id: 'MethodFI', name: 'MethodFI' },
    { id: 'Stripe', name: 'Stripe' },
  ]} alwaysOn />,

  <DateInput label="last cancelation date gt" source="last_cancelation_date.gt" />,
  <DateInput label="last cancelation date lt" source="last_cancelation_date.lt" />,

  <DateInput label="expected payment date gt" source="expected_payment_date.gt" />,
  <DateInput label="expected payment date lt" source="expected_payment_date.lt" />,
];

export const MembershipOverviewCollection: React.FC<Props> = (props) => {
  const columnModel = useMemo(() => {
    console.log(resource, 'call just once');
    return new ColumnModel(resource, tableColumns);
  }, []);

  const rowActionBuilder = (record: { [index: string]: any }) =>
    <RowActions record={record} />


  return (
    <Box sx={{ position: "relative", padding: "40px 0px 0px 0px" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "10px",
      }}>
        <h4>
          {`Membership Overview`}
        </h4>
      </Box>

      <Resource
        resource={resource}
        // initialFilters={initialFilters}
        columnModel={columnModel}
        filters={filters}
        rowPerPageOptions={[20]}
        tableActions={
          <>
            <FilterButton {...props} />
          </>
        }
        rowActionBuilder={rowActionBuilder}
      />

    </Box>
  );
}

type Props = {
  initialFilters?: { [index: string]: any }
}