import { Box } from '@mui/material';
import React, { useState, FC } from 'react';
import { Button } from 'react-admin';
import { Column } from '../../model/ColumnModel';
import { SpecialFormatValue, VerticalRow } from './VerticalRow';
import { MAX_DISPLAY_DATA_LENGTH } from './VerticalTable';

export const VerticalCarousel: FC<Props> = (props) => {
  const { data, size } = props;
  const [offset, setOffset] = useState<number>(0);

  const dataSlice = data.slice(offset, offset + MAX_DISPLAY_DATA_LENGTH);

  const nextPage = () => {
    setOffset(offset + 1);
  }

  const prevPage = () => {
    setOffset(offset - 1);
  }

  return (
    <Box>
      <Box sx={{ maxWidth: `${size * data.length}px` }}>
        <Box sx={{ display: "flex" }}>
          {
            dataSlice.map((row, index) => <VerticalRow
              key={index + offset}
              row={row}
              {...props}
            />)
          }
        </Box>
      </Box>


      <Box sx={{ marginTop: "20px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button disabled={offset === 0} label="<" onClick={prevPage} />
          <span>{offset + MAX_DISPLAY_DATA_LENGTH}  /  {data.length}</span>
          <Button disabled={offset + MAX_DISPLAY_DATA_LENGTH === data.length} label=">" onClick={nextPage} />
        </Box>
      </Box>
    </Box>

  )
}

type Props = {
  data: any[]
  size: number
  columnModel: Column[]
  specialFormat?: SpecialFormatValue
}