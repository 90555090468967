import { useState } from 'react'
import { useRefresh } from 'react-admin'
import { format } from 'date-fns'
import { makeStyles, alpha } from '@material-ui/core/styles'
import { Button, Box, Snackbar } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import * as React from 'react'
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid'

import { dataProvider } from '../providers/dataProvider'
import { IUnderwritingsProps, IUnderwritingUpdateProps } from '../types'
import { centsToDollars } from '../helpers'
import { UnderwritingEdit } from './UnderwritingEdit'
import { CustomToolbar } from './CustomToolbar'

const useStyles = makeStyles({
  root: {
    padding: 15,
    '& .MuiDataGrid-row': {
      maxHeight: 'unset!important',
    },
    '& .MuiDataGrid-cell': {
      whiteSpace: 'normal!important',
      maxHeight: 'unset!important',
      height: 'unset!important',
    },
  },
})

export const UnderwritingsTable = (props: {
  payments: IUnderwritingsProps[]
  id: string
}) => {
  const classes = useStyles()

  const refresh = useRefresh()

  const [openEdit, setOpenEdit] = useState(false)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([])

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Application date',
      width: 120,
    },
    {
      field: 'decision',
      headerName: 'Decision',
      width: 120,
      sortable: false,
    },
    { field: 'report', headerName: 'Report', width: 150, sortable: false },
    {
      field: 'loanPayment',
      headerName: 'Total loan monthly payments',
      width: 120,
      sortable: false,
    },
    { field: 'fico', headerName: 'FICO', width: 110, sortable: false },
    {
      field: 'dti',
      headerName: 'DTI',
      width: 110,
      sortable: false,
    },
    {
      field: 'details',
      headerName: 'Details',
      width: 180,
      sortable: false,
    },
  ]

  const rows = props.payments.map((row, index) => ({
    id: index,
    date: format(new Date(row.applicationDate), 'yyyy-MM-dd'),
    decision: row.decision,
    report: row.report,
    loanPayment:
      row.totalLoanMonthlyPayments !== null
        ? centsToDollars(row.totalLoanMonthlyPayments)
        : '',
    fico: row.fico,
    dti: row.dti,
    details: row.details.replace(/\\/, ''),
  }))
  const actionButton = () => (
    <Button
      onClick={() => setOpenEdit(true)}
      startIcon={<Edit />}
      variant="contained"
      color="primary"
      disabled={selectionModel.length === 0}
    >
      Edit
    </Button>
  )

  const handleEdit = (data: IUnderwritingUpdateProps) => {
    setIsLoading(true)
    dataProvider
      .update('underwriting-decision/update', {
        id: parseInt(props.id),
        data: data,
        previousData: { id: parseInt(props.id) },
      })
      .then((response) => {
        if (response.data.success) {
          refresh()
          setOpenEdit(false)
        } else {
          setError(response.data.error.message)
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        setError(e.toString())
      })
  }

  return (
    <Box height={rows.length > 0 ? 400 : '100%'} width="100%">
      <DataGrid
        components={{
          Toolbar: () =>
            CustomToolbar({
              title: 'Underwritings',
              action: actionButton(),
            }),
        }}
        hideFooterPagination={rows.length === 0}
        classes={{ root: classes.root }}
        density="compact"
        disableColumnMenu
        rows={rows}
        columns={columns}
        pageSize={5}
        disableColumnFilter
        onPageChange={() => setSelectionModel([])}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(
            newSelectionModel.toString() !== selectionModel.toString()
              ? newSelectionModel
              : []
          )
        }}
        selectionModel={selectionModel}
      />
      {selectionModel.length > 0 && (
        <UnderwritingEdit
          id={props.id}
          isOpen={openEdit}
          setOpen={setOpenEdit}
          underwriting={props.payments[Number(selectionModel[0])]}
          isLoading={isLoading}
          handleEdit={handleEdit}
        />
      )}
      <Snackbar
        open={error.length > 0}
        message={error}
        onClose={() => setError('')}
      />
    </Box>
  )
}
