import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  RowActions,
} from "./widget/RowActions";
import { Box } from '@mui/material';
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';

export const resource = "admin/generic-views/plaid_link_events";

const tableColumns = [
  { field: "created_at", width: "150px", title: "created at", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "link_action", width: "200px", title: "link action", show: true, },
  { field: "metadata", width: "800px", title: "metadata", show: true, },
]

export const PlaidLinkEventsCollection: React.FC<Props> = ({ initialFilters, onRowClick }) => {
  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Box sx={{ position: "relative" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>Plaid link events</h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        disableUrlFilterListener={false}
        rowActionBuilder={rowActionBuilder}
        columnModel={columnModel}
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
}