
import React, { FC, useEffect, useState } from 'react';
import {
  Alert,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useInjection } from '../../../di/useInjection';
import { PostError } from '../../../network/AdminRequest';
import { useNotify, useRefresh } from 'react-admin';

export type CreateMFIAccountManualDialogPayload = {
  userId: number
  manualRawAccountId: number
  nameAccount: string
} | undefined;

export const CreateMFIAccountManualDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { depositoryActions } = useInjection();
  const notify = useNotify();
  const refresh = useRefresh();

  const [accountName, setAccountName] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [routingNumber, setRoutingNumber] = useState<string>("");
  const [override, setOverride] = useState<boolean>(false);

  const [error, setError] = useState<PostError>(null);

  useEffect(() => {
    if (payload !== undefined) {
      setAccountName(payload.nameAccount);
      setRoutingNumber("");
      setAccountNumber("");
      setError(null);
    }
  }, [payload]);

  const handleSendClick = async () => {
    if (payload === undefined) return;

    const { userId, manualRawAccountId } = payload;

    const error = await depositoryActions.createMfiManualAccount({
      userId: parseInt(userId.toString()),
      accountName,
      accountNumber,
      manualRawAccountId,
      override,
      routingNumber,
    });

    if (error === null) {
      notify("Done");
      hideDialog();
      refresh();
    } else {
      setError(error);
      refresh();
    }
  }

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAccountName(value);
  }

  const handleAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAccountNumber(value);
  }

  const handleRoutingNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRoutingNumber(value);
  }

  const handleOverrideChange = (e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    setOverride(isSelect);
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Create MFI account"
      onSend={handleSendClick}
      actionTitle="Create">

      <TextField
        fullWidth
        id="accountName"
        label="Account name"
        type="text"
        defaultValue={accountName}
        value={accountName}
        onChange={handleAccountNameChange}
      />

      <TextField
        fullWidth
        id="accountNumber"
        label="Account number"
        type="text"
        defaultValue={accountNumber}
        value={accountNumber}
        onChange={handleAccountNumberChange}
      />

      <TextField
        fullWidth
        id="routingNumber"
        label="Routing number"
        type="text"
        defaultValue={routingNumber}
        value={routingNumber}
        onChange={handleRoutingNumberChange}
      />

      <FormControlLabel
        control={<Checkbox checked={override} onChange={handleOverrideChange} />}
        label="override" />

      {error !== null && <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload: CreateMFIAccountManualDialogPayload
  hideDialog: () => void
}