
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { provideLocalizationService, registerForLocalization } from '../../../../../kendo-react-intl';
import { classNames } from '../../../../../kendo-react-common';
import { getDate } from '../../../../../kendo-date-math';
import { messages, today } from '../../messages';
import { dateInRange, getToday, isInRange } from '../../utils';
import { MIN_DATE, MAX_DATE } from '../../defaults';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var TodayCommand = /** @class */ (function (_super) {
    __extends(TodayCommand, _super);
    function TodayCommand() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.localization = null;
        _this.handleClick = function (syntheticEvent) {
            if (!_this.todayIsInRange) {
                return;
            }
            if (_this.props.onClick) {
                var event_1 = {
                    syntheticEvent: syntheticEvent,
                    nativeEvent: syntheticEvent.nativeEvent,
                    value: dateInRange(getToday(), _this.min, _this.max),
                    target: _this,
                    isTodayClick: true
                };
                _this.props.onClick.call(undefined, event_1);
            }
        };
        return _this;
    }
    Object.defineProperty(TodayCommand.prototype, "min", {
        get: function () {
            return this.props.min !== undefined
                ? this.props.min
                : TodayCommand.defaultProps.min;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TodayCommand.prototype, "max", {
        get: function () {
            return this.props.max !== undefined
                ? this.props.max
                : TodayCommand.defaultProps.max;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TodayCommand.prototype, "todayIsInRange", {
        get: function () {
            return isInRange(getToday(), getDate(this.min), getDate(this.max));
        },
        enumerable: false,
        configurable: true
    });
    TodayCommand.prototype.render = function () {
        this.localization = provideLocalizationService(this);
        var todayMessage = this.localization.toLanguageString(today, messages[today]);
        var todayClassNames = classNames('k-calendar-nav-today', {
            'k-disabled': this.props.disabled
        });
        return (React.createElement("span", { className: todayClassNames, onClick: this.handleClick, tabIndex: this.props.tabIndex }, todayMessage));
    };
    TodayCommand.propTypes = {
        max: PropTypes.instanceOf(Date).isRequired,
        min: PropTypes.instanceOf(Date).isRequired,
        onClick: PropTypes.func,
        disabled: PropTypes.bool
    };
    TodayCommand.defaultProps = {
        min: MIN_DATE,
        max: MAX_DATE
    };
    return TodayCommand;
}(React.Component));
export { TodayCommand };
registerForLocalization(TodayCommand);
