import * as React from 'react';
import { GridColumnMenuItem } from './GridColumnMenuItem';
import { GridColumnMenuItemGroup } from './GridColumnMenuItemGroup';
import { normalize } from '../interfaces/GridSortSettings';
import { provideLocalizationService, registerForIntl, registerForLocalization } from '../../../../kendo-react-intl';
import { messages, sortAscending, sortDescending } from '../messages';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * @hidden
 */
var ASC_DIR = 'asc';
/**
 * @hidden
 */
var DESC_DIR = 'desc';
/**
 * @hidden
 */
var sortSeqMap = {
    true: {
        'asc': { 'asc': '', 'desc': 'desc' },
        'desc': { 'asc': 'asc', 'desc': '' },
        '': { 'asc': 'asc', 'desc': 'desc' }
    },
    false: {
        'asc': { 'asc': 'asc', 'desc': 'desc' },
        'desc': { 'asc': 'asc', 'desc': 'desc' },
        '': { 'asc': 'asc', 'desc': 'desc' }
    }
};
/**
 * @hidden
 */
var sortIndex = function (field, sort) {
    if (!sort) {
        return -1;
    }
    return sort.findIndex(function (s) { return s.field === field; });
};
/**
 * @hidden
 */
var sortedAsc = function (sortedIndex, sort) {
    return !!(sort && sortedIndex > -1 && sort[sortedIndex].dir === ASC_DIR);
};
/**
 * @hidden
 */
var sortedDesc = function (sortedIndex, sort) {
    return !!(sort && sortedIndex > -1 && sort[sortedIndex].dir === DESC_DIR);
};
/**
 * @example
 * ```jsx-no-run
 * class ColumnMenu extends React.Component {
 *     render() {
 *         return (
 *         <div>
 *             <GridColumnMenuSort {...this.props} />
 *         </div>);
 *     }
 * }
 *
 * class App extends React.PureComponent {
 *     constructor(props) {
 *         super(props);
 *
 *         this.state = this.createAppState({
 *             take: 10,
 *             skip: 0
 *         });
 *     }
 *
 *     createAppState(dataState) {
 *         return {
 *             result: process(products.slice(0), dataState),
 *             dataState: dataState
 *         };
 *     }
 *
 *     dataStateChange = (event) => {
 *         this.setState(this.createAppState(event.dataState));
 *     }
 *
 *     render() {
 *         return (
 *             <div>
 *                 <Grid
 *                     data={this.state.result}
 *                     {...this.state.dataState}
 *                     onDataStateChange={this.dataStateChange}
 *                     sortable={true}
 *                     pageable={true}
 *                 >
 *                     <Column field="ProductID" title="Product ID" columnMenu={ColumnMenu}/>
 *                     <Column field="ProductName" />
 *                 </Grid>
 *                 <br />
 *             </div>
 *         );
 *     }
 * }
 *
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var GridColumnMenuSort = /** @class */ (function (_super) {
    __extends(GridColumnMenuSort, _super);
    function GridColumnMenuSort() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * @hidden
         */
        _this.onAscClick = function (event) {
            _this.onSort(event, ASC_DIR);
            if (_this.props.onCloseMenu) {
                _this.props.onCloseMenu();
            }
        };
        /**
         * @hidden
         */
        _this.onDescClick = function (event) {
            _this.onSort(event, DESC_DIR);
            if (_this.props.onCloseMenu) {
                _this.props.onCloseMenu();
            }
        };
        /**
         * @hidden
         */
        _this.onSort = function (event, selectedDir) {
            event.preventDefault();
            if (!_this.props.onSortChange) {
                return;
            }
            var _a = _this.props, column = _a.column, sortable = _a.sortable, sort = _a.sort;
            var _b = normalize(sortable || false, false), allowUnsort = _b.allowUnsort, mode = _b.mode;
            var oldDescriptor = (sort || []).filter(function (d) { return d.field === column.field; })[0];
            var dir = sortSeqMap[allowUnsort][oldDescriptor && oldDescriptor.dir || ''][selectedDir];
            var newDescriptor = (mode === 'single') ?
                [] : (_this.props.sort || []).filter(function (d) { return d.field !== column.field; });
            if (dir !== '' && column.field) {
                newDescriptor.push({ field: column.field, dir: dir });
            }
            _this.props.onSortChange(newDescriptor, event);
        };
        return _this;
    }
    /**
     * @hidden
     */
    GridColumnMenuSort.prototype.render = function () {
        var _a = this.props, sort = _a.sort, column = _a.column;
        var currentSortIndex = sortIndex(column.field, sort);
        var localizationService = provideLocalizationService(this);
        return (React.createElement(GridColumnMenuItemGroup, null,
            React.createElement(GridColumnMenuItem, { title: localizationService.toLanguageString(sortAscending, messages[sortAscending]), iconClass: 'k-i-sort-asc-sm', selected: sortedAsc(currentSortIndex, sort), onClick: this.onAscClick }),
            React.createElement(GridColumnMenuItem, { title: localizationService.toLanguageString(sortDescending, messages[sortDescending]), iconClass: 'k-i-sort-desc-sm', selected: sortedDesc(currentSortIndex, sort), onClick: this.onDescClick })));
    };
    /* eslint-disable max-len */
    /**
     * Can be used to check if sorting is applied to a specific field ([see example]({% slug column_menu_grid %}#toc-styling-the-column-menu-icon)). Useful for creating active sort indicators.
     */
    // tslint:enable:max-line-length
    GridColumnMenuSort.active = function (field, sort) {
        var currentSortIndex = sortIndex(field, sort);
        return sortedDesc(currentSortIndex, sort) || sortedAsc(currentSortIndex, sort);
    };
    return GridColumnMenuSort;
}(React.Component));
export { GridColumnMenuSort };
registerForIntl(GridColumnMenuSort);
registerForLocalization(GridColumnMenuSort);
