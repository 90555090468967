import * as React from 'react';
import { getNestedValue } from '../utils';
import { NumericTextBox } from '../../../../kendo-react-inputs';
import { DatePicker } from '../../../../kendo-react-dateinputs';
import { guid, classNames } from '../../../../kendo-react-common';
import { useTableKeyboardNavigation } from '../../../../kendo-react-data-tools';
import { GRID_COL_INDEX_ATTRIBUTE } from '../constants';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * @hidden
 */
export var GridEditCell = function (props) {
    var _a, _b, _c, _d, _e;
    var data = getNestedValue(props.field, props.dataItem);
    var defaultRendering = null;
    var editCellClasses = classNames('k-grid-edit-cell', (_a = {},
        _a['k-selected'] = props.isSelected,
        _a), props.className);
    var inputId = React.useMemo(function () { return guid(); }, []);
    var navigationAttributes = useTableKeyboardNavigation(props.id);
    var handleOnChange = React.useCallback(function (syntheticEvent) {
        if (props.onChange) {
            var value = syntheticEvent.target.type === 'checkbox' ?
                syntheticEvent.target.checked :
                syntheticEvent.target.value;
            props.onChange({
                dataItem: props.dataItem,
                dataIndex: props.dataIndex,
                field: props.field,
                syntheticEvent: syntheticEvent,
                value: value
            });
        }
    }, [props.onChange, props.dataItem, props.dataIndex, props.field]);
    var inputChange = React.useCallback(function (e) {
        if (props.onChange) {
            props.onChange({
                dataItem: props.dataItem,
                dataIndex: props.dataIndex,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.value
            });
        }
    }, [props.onChange, props.dataItem, props.dataIndex, props.field]);
    switch (props.editor) {
        case 'numeric':
            defaultRendering = (React.createElement("td", __assign({ colSpan: props.colSpan, style: props.style, className: editCellClasses, "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected }, (_b = {}, _b[GRID_COL_INDEX_ATTRIBUTE] = props.columnIndex, _b), { role: 'gridcell' }, navigationAttributes),
                React.createElement(NumericTextBox, { width: "100%", value: (data === undefined) ? null : data, onChange: inputChange })));
            break;
        case 'date':
            defaultRendering = (React.createElement("td", __assign({ colSpan: props.colSpan, style: props.style, className: editCellClasses, "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected }, (_c = {}, _c[GRID_COL_INDEX_ATTRIBUTE] = props.columnIndex, _c), { role: 'gridcell' }, navigationAttributes),
                React.createElement(DatePicker, { width: "100%", value: data, onChange: inputChange })));
            break;
        case 'boolean':
            defaultRendering = (React.createElement("td", __assign({ colSpan: props.colSpan, style: props.style, className: editCellClasses, "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected }, (_d = {}, _d[GRID_COL_INDEX_ATTRIBUTE] = props.columnIndex, _d), { role: 'gridcell' }, navigationAttributes),
                React.createElement("input", { checked: data || false, id: inputId, type: "checkbox", className: "k-checkbox k-checkbox-md k-rounded-md", onChange: handleOnChange }),
                React.createElement("label", { className: "k-checkbox-label", htmlFor: inputId })));
            break;
        default:
            defaultRendering = (React.createElement("td", __assign({ colSpan: props.colSpan, style: props.style, className: editCellClasses, "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected }, (_e = {}, _e[GRID_COL_INDEX_ATTRIBUTE] = props.columnIndex, _e), { role: 'gridcell' }, navigationAttributes),
                React.createElement("input", { style: { width: '100%' }, className: "k-input", value: data || '', onChange: handleOnChange })));
    }
    return props.render ?
        props.render.call(undefined, defaultRendering, props) :
        defaultRendering;
};
