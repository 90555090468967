import { GridColumn } from '../GridColumn';
import { tableKeyboardNavigationTools as navigationTools } from '../../../../kendo-react-data-tools';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/**
 * @hidden
 */
export function getNestedValue(fieldName, dataItem) {
    var path = (fieldName || '').split('.');
    var data = dataItem;
    path.forEach(function (p) {
        data = data ? data[p] : undefined;
    });
    return data;
}
/**
 * @hidden
 */
export function flatData(output, input, footer, dataIndex, groupDefined, field, level) {
    if (level === void 0) { level = 0; }
    var maxLevel = level;
    for (var i = 0; i < input.length; i++) {
        if (!groupDefined || input[i].value === undefined || input[i].items === undefined) {
            output[output.length] = {
                dataIndex: dataIndex.index,
                dataItem: input[i],
                rowType: 'data',
                level: level,
                expanded: field === undefined || getNestedValue(field, input[i])
            };
            dataIndex.index++;
            continue;
        }
        maxLevel = Math.max(maxLevel, level + 1);
        var expanded = field === undefined ||
            getNestedValue(field, input[i]) === undefined ||
            getNestedValue(field, input[i]);
        // header
        output[output.length] = {
            dataIndex: -1,
            dataItem: input[i],
            level: level,
            rowType: 'groupHeader',
            expanded: expanded
        };
        // children
        if (expanded) {
            maxLevel = Math.max(flatData(output, input[i].items, footer, dataIndex, groupDefined, field, level + 1), maxLevel);
        }
        // footer
        if (footer === 'always' || (expanded && footer === 'visible')) {
            output[output.length] = {
                dataIndex: -1,
                dataItem: input[i],
                rowType: 'groupFooter',
                level: level,
                expanded: expanded
            };
        }
    }
    return maxLevel;
}
var eventKeys = [
    'sortChange',
    'filterChange',
    'groupChange',
    'pageChange',
    'expandChange',
    'selectionChange',
    'headerSelectionChange',
    'rowClick',
    'itemChange',
    'dataStateChange',
    'columnResize',
    'columnReorder'
];
var deprecatedHandlerMessage = function (oldKey, newKey) { return ("The ".concat(oldKey, " event handler property is deprecated, ") +
    "use https://www.telerik.com/kendo-react-ui/components/grid/api/GridProps/#toc-".concat(newKey, " instead")); };
/**
 * @hidden
 */
export function checkPropCompatibility(props) {
    for (var index = 0; index < eventKeys.length; index++) {
        var eventKey = eventKeys[index];
        if (props[eventKey] !== undefined) {
            var newKey = 'on' + eventKey.charAt(0).toUpperCase() + eventKey.slice(1);
            console.warn(deprecatedHandlerMessage(eventKey, newKey));
        }
    }
}
/**
 * @hidden
 */
export function readColumns(elements, oldColumns, idInfo, depth) {
    if (depth === void 0) { depth = 0; }
    var columns = [];
    if (!elements) {
        return [];
    }
    if (elements && elements.length === undefined) {
        elements = [elements];
    }
    elements.forEach(function (columnProps, index) {
        columnProps = columnProps.props ? columnProps.props : columnProps;
        var oldColumn = oldColumns[index] || null;
        var children = readColumns(columnProps.children, oldColumn && oldColumn.children || [], idInfo, depth + 1);
        columns.push(Object.assign({ depth: depth }, GridColumn.defaultProps, (children.length) ? { cell: function () { return null; }, filterCell: function () { return null; } } : {}, oldColumn ? { width: oldColumn.width, orderIndex: oldColumn.orderIndex } : {}, columnProps, {
            id: columnProps.id ? columnProps.id : navigationTools.generateNavigatableId("".concat(idInfo.prevId++), idInfo.idPrefix, 'column'),
            declarationIndex: columns.length,
            children: children,
            rowSpan: 0,
            colSpan: 0,
            isAccessible: true
        }));
    });
    var comparator = function (a, b) {
        return a.orderIndex === b.orderIndex ?
            a.declarationIndex - b.declarationIndex :
            ((a.orderIndex || 0) - (b.orderIndex || 0));
    };
    columns.sort(comparator);
    if (depth === 0) {
        var ret_1 = [];
        var flat_1 = function (cols, pIndex) {
            return cols.forEach(function (c) {
                c.parentIndex = pIndex;
                flat_1(c.children, ret_1.push(c) - 1);
            });
        };
        flat_1(columns, -1);
        return ret_1;
    }
    return columns;
}
/**
 * @hidden
 */
export function autoGenerateColumns(data, group, expandField, idInfo) {
    var propData = [];
    if (Array.isArray(data)) {
        propData = data;
    }
    else if (data) {
        propData = data.data;
    }
    var columns = [];
    if (propData.length > 0) {
        var itemForColumnsGen = propData[0];
        if (group) {
            for (var i = 0; i < group.length; i++) {
                itemForColumnsGen = itemForColumnsGen.items && itemForColumnsGen.items[0];
            }
        }
        var fields = Object.getOwnPropertyNames(itemForColumnsGen);
        fields.forEach(function (field) {
            if (field !== expandField) {
                columns.push(Object.assign({
                    id: navigationTools.generateNavigatableId("".concat(idInfo.prevId++), idInfo.idPrefix, 'column'),
                    declarationIndex: -1,
                    parentIndex: -1,
                    depth: 0,
                    colSpan: 0,
                    rowSpan: 0,
                    index: 0,
                    left: 0,
                    right: 0,
                    rightBorder: false,
                    children: [],
                    ariaColumnIndex: 0,
                    isAccessible: true
                }, GridColumn.defaultProps, { field: field }));
            }
        });
    }
    return columns;
}
var hasParentFooterCell = function (columns, column) {
    var parent = columns[column.parentIndex];
    while (parent) {
        if (parent.footerCell) {
            return true;
        }
        parent = columns[parent.parentIndex];
    }
    return false;
};
/**
 * @hidden
 */
export var footerColumns = function (columns) {
    return columns.filter(function (column) {
        if (hasParentFooterCell(columns, column)) {
            return false;
        }
        return Boolean(column.footerCell) || !(column.children && column.children.length > 0);
    });
};
/**
 * @hidden
 */
export var sanitizeColumns = function (columns) {
    columns.sort(function (a, b) {
        return a.declarationIndex - b.declarationIndex;
    });
    return columns.map(function (column) {
        var declarationIndex = column.declarationIndex, parentIndex = column.parentIndex, depth = column.depth, colSpan = column.colSpan, rowSpan = column.rowSpan, index = column.index, kFirst = column.kFirst, children = column.children, props = __rest(column, ["declarationIndex", "parentIndex", "depth", "colSpan", "rowSpan", "index", "kFirst", "children"]);
        return children.length ? __assign({ children: sanitizeColumns(children) }, props) : props;
    });
};
