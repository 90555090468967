
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as PropTypes from 'prop-types';
import { slide } from './animation';
import { canUseDOM, ZIndexContext, validatePackage, classNames } from '../../../kendo-react-common';
import { AlignPoint, alignElement, domUtils, positionElement, Collision as CollisionEnum } from '../../../kendo-popup-common';
import { throttle, FRAME_DURATION } from './util';
import { packageMetadata } from './package-metadata';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var DEFAULT_POPUP_ZINDEX = 100;
var ZINDEX_POPUP_STEP = 1;
function isEquivalent(a, b) {
    if (a === b) {
        return true;
    }
    if (!!a !== !!b) {
        return false;
    }
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    if (aProps.length !== bProps.length) {
        return false;
    }
    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];
        if (a[propName] !== b[propName]) {
            return false;
        }
    }
    return true;
}
var DEFAULT_OFFSET = {
    left: -1000,
    top: 0
};
var Status;
(function (Status) {
    Status["hiding"] = "hiding";
    Status["hidden"] = "hidden";
    Status["showing"] = "showing";
    Status["shown"] = "shown";
    Status["reposition"] = "reposition";
})(Status || (Status = {}));
var ANIMATION_CONTAINER = 'k-animation-container';
var ANIMATION_CONTAINER_SHOWN = 'k-animation-container-shown';
var ANIMATION_CONTAINER_RELATIVE = 'k-animation-container-relative';
var ANIMATION_CONTAINER_CHILD = 'k-child-animation-container';
var K_POPUP = 'k-popup';
/**
 * @hidden
 */
var PopupWithoutContext = /** @class */ (function (_super) {
    __extends(PopupWithoutContext, _super);
    function PopupWithoutContext(props) {
        var _this = _super.call(this, props) || this;
        /**
         * @hidden
         */
        _this.context = 0;
        /**
         * @hidden
         */
        _this.state = { current: Status.hidden, previous: Status.hidden, props: {} };
        _this._popup = null;
        _this.show = function (popup) {
            _this.setPosition(popup);
            _this.animate(popup.firstChild, 'enter', _this.onOpened);
            _this.setState({ current: Status.shown, previous: _this.state.current });
        };
        _this.setPosition = function (popup) {
            var _a = _this.props, anchorAlign = _a.anchorAlign, popupAlign = _a.popupAlign, collision = _a.collision, offset = _a.offset, anchor = _a.anchor, margin = _a.margin, scale = _a.scale, positionMode = _a.positionMode;
            var _b = popup.style, width = _b.width, height = _b.height;
            // Calculations are wrong if popup does not have set size
            popup.style.width = popup.offsetWidth + 'px';
            popup.style.height = popup.offsetHeight + 'px';
            var alignedOffset = alignElement({
                anchor: anchor,
                anchorAlign: anchorAlign,
                element: popup,
                elementAlign: popupAlign,
                offset: offset,
                margin: margin,
                positionMode: positionMode,
                scale: scale
            });
            var position = positionElement({
                anchor: anchor,
                anchorAlign: anchorAlign,
                element: popup,
                elementAlign: popupAlign,
                collisions: collision,
                currentLocation: alignedOffset,
                margin: _this.props.margin
            });
            popup.style.top = position.offset.top + 'px';
            popup.style.left = position.offset.left + 'px';
            popup.style.width = width;
            popup.style.height = height;
            _this._collisions = {
                fit: position.fit,
                fitted: position.fitted,
                flip: position.flip,
                flipped: position.flipped
            };
            if (_this.props.onPosition) {
                var event_1 = {
                    target: _this,
                    flipped: position.flipped,
                    fitted: position.fitted
                };
                _this.props.onPosition.call(undefined, event_1);
            }
        };
        _this.onOpened = function () {
            var element = _this._popup;
            if (!element) {
                return;
            }
            if (_this.props.show) {
                element.classList.add(ANIMATION_CONTAINER_SHOWN);
            }
            _this.attachRepositionHandlers(element);
            if (_this.props.onOpen) {
                _this.props.onOpen.call(undefined, { target: _this });
            }
        };
        _this.animate = function (element, type, callback) {
            if (!_this.props.popupAlign) {
                return;
            }
            var animationDirection;
            var _a = _this.props.popupAlign, horizontal = _a.horizontal, vertical = _a.vertical;
            if (horizontal === 'left' && vertical === 'center') {
                animationDirection = 'right';
            }
            else if (horizontal === 'right' && vertical === 'center') {
                animationDirection = 'left';
            }
            else if (vertical === 'top') {
                animationDirection = 'down';
            }
            else {
                animationDirection = 'up';
            }
            var flipPositions = {
                down: 'up',
                up: 'down',
                left: 'right',
                right: 'left'
            };
            if (_this._collisions && _this._collisions.flipped) {
                animationDirection = flipPositions[animationDirection];
            }
            slide(element, animationDirection, _this.animationDuration[type], type, callback);
        };
        _this.onClosing = function (popup) {
            if (!_this.props.show) {
                popup.classList.remove(ANIMATION_CONTAINER_SHOWN);
            }
            _this.detachRepositionHandlers();
        };
        _this.onClosed = function () {
            if (_this.state.current === Status.hiding && _this.state.previous === Status.shown) {
                _this.setState({ current: Status.hidden, previous: _this.state.current });
            }
            if (_this.props.onClose) {
                _this.props.onClose.call(undefined, { target: _this });
            }
        };
        _this.getCurrentZIndex = function () {
            return _this.context ? _this.context + ZINDEX_POPUP_STEP : DEFAULT_POPUP_ZINDEX;
        };
        validatePackage(packageMetadata);
        _this.reposition = throttle(_this.reposition.bind(_this), FRAME_DURATION);
        return _this;
    }
    Object.defineProperty(PopupWithoutContext.prototype, "element", {
        /**
         * Represents the Popup DOM element.
         */
        get: function () {
            return this._popup;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    PopupWithoutContext.getDerivedStateFromProps = function (props, state) {
        var show = props.show, anchor = props.anchor, anchorAlign = props.anchorAlign, appendTo = props.appendTo, collision = props.collision, popupAlign = props.popupAlign, className = props.className, popupClass = props.popupClass, style = props.style, offset = props.offset, contentKey = props.contentKey;
        var nextState = __assign(__assign({}, state), { props: {
                show: show,
                anchor: anchor,
                anchorAlign: anchorAlign,
                appendTo: appendTo,
                collision: collision,
                popupAlign: popupAlign,
                className: className,
                popupClass: popupClass,
                style: style,
                offset: offset,
                contentKey: contentKey
            } });
        if (props.show) {
            if (state.current === Status.hidden || state.current === Status.hiding) {
                return __assign(__assign({}, nextState), { current: Status.showing, previous: state.current });
            }
            if (state.current === Status.showing) {
                return __assign(__assign({}, nextState), { current: Status.shown, previous: state.current });
            }
            if (state.current === Status.shown &&
                (!isEquivalent(offset, state.props.offset) ||
                    !isEquivalent(anchorAlign, state.props.anchorAlign) ||
                    !isEquivalent(appendTo, state.props.appendTo) ||
                    !isEquivalent(collision, state.props.collision) ||
                    !isEquivalent(popupAlign, state.props.popupAlign) ||
                    !isEquivalent(style, state.props.style) ||
                    anchor !== state.props.anchor ||
                    popupClass !== state.props.popupClass ||
                    className !== state.props.className)) {
                return __assign(__assign({}, nextState), { current: Status.reposition, previous: state.current });
            }
            return nextState;
        }
        if (state.current === Status.hiding || state.current === Status.hidden) {
            return __assign(__assign({}, nextState), { current: Status.hidden, previous: state.current });
        }
        return __assign(__assign({}, nextState), { current: Status.hiding, previous: state.current });
    };
    /**
     * @hidden
     */
    PopupWithoutContext.prototype.componentDidUpdate = function (prevProps) {
        if (this.state.current === Status.showing && this._popup) {
            this.show(this._popup);
        }
        else if (this.state.current === Status.hiding && this._popup) {
            this.onClosing(this._popup);
            this.animate(this._popup.firstChild, 'exit', this.onClosed);
        }
        else if (this.state.current === Status.reposition && this.state.previous === Status.shown) {
            this.setState({ current: Status.shown, previous: this.state.current });
        }
        else if (this.state.current === Status.shown &&
            prevProps.contentKey !== this.props.contentKey && this._popup) {
            this.setPosition(this._popup);
        }
    };
    /**
     * @hidden
     */
    PopupWithoutContext.prototype.componentDidMount = function () {
        if (this.state.current === Status.showing && this._popup) {
            this.show(this._popup);
        }
    };
    /**
     * @hidden
     */
    PopupWithoutContext.prototype.componentWillUnmount = function () {
        this.detachRepositionHandlers();
    };
    /**
     * @hidden
     */
    PopupWithoutContext.prototype.render = function () {
        var _this = this;
        var _a = this.props, children = _a.children, className = _a.className, popupClass = _a.popupClass, show = _a.show, id = _a.id, positionMode = _a.positionMode;
        var calculatedAppendTo = this.props.appendTo ?
            this.props.appendTo :
            canUseDOM ?
                (this.props.anchor && this.props.anchor.ownerDocument ? this.props.anchor.ownerDocument.body : document.body)
                : undefined;
        if (this.state.current === Status.reposition && this.state.previous === Status.shown && this._popup) {
            this.setPosition(this._popup);
        }
        var style = Object.assign({}, { position: positionMode, top: 0, left: -10000 }, this.props.style || {});
        var closing = this.state.current === Status.hiding;
        if ((show || closing) && calculatedAppendTo) {
            var currentZIndex = this.getCurrentZIndex();
            var popupElement = (React.createElement(ZIndexContext.Provider, { value: currentZIndex },
                React.createElement("div", { className: classNames(ANIMATION_CONTAINER, ANIMATION_CONTAINER_RELATIVE, className), id: id, ref: function (e) { return _this._popup = e; }, style: __assign({ zIndex: currentZIndex }, style) },
                    React.createElement("div", { className: classNames(K_POPUP, popupClass, ANIMATION_CONTAINER_CHILD), style: { transitionDelay: '0ms' } }, children))));
            return this.props.appendTo !== null ?
                ReactDOM.createPortal(popupElement, calculatedAppendTo) :
                popupElement;
        }
        return null;
    };
    Object.defineProperty(PopupWithoutContext.prototype, "animationDuration", {
        get: function () {
            var animate = this.props.animate;
            var enter = 0;
            var exit = 0;
            if (animate) {
                if (animate === true) {
                    enter = exit = 300;
                }
                else {
                    enter = animate.openDuration || 0;
                    exit = animate.closeDuration || 0;
                }
            }
            return { enter: enter, exit: exit };
        },
        enumerable: false,
        configurable: true
    });
    PopupWithoutContext.prototype.attachRepositionHandlers = function (element) {
        var _this = this;
        this.detachRepositionHandlers();
        this._scrollableParents = domUtils.scrollableParents(this.props.anchor || element);
        if (this._scrollableParents) {
            this._scrollableParents.map(function (p) { return p.addEventListener('scroll', _this.reposition); });
        }
        window.addEventListener('resize', this.reposition);
    };
    PopupWithoutContext.prototype.detachRepositionHandlers = function () {
        var _this = this;
        if (this._scrollableParents) {
            this._scrollableParents.map(function (p) { return p.removeEventListener('scroll', _this.reposition); });
            this._scrollableParents = undefined;
        }
        window.removeEventListener('resize', this.reposition);
    };
    PopupWithoutContext.prototype.reposition = function () {
        this.setState({ current: Status.reposition, previous: this.state.current });
    };
    /**
     * @hidden
     */
    PopupWithoutContext.propTypes = {
        anchor: function (props) {
            var anchor = props.anchor;
            if (anchor && typeof anchor.nodeType !== 'number') {
                return new Error('Invalid prop `anchor` supplied to `Kendo React Popup`. Validation failed.');
            }
            return null;
        },
        appendTo: function (props) {
            var element = props.appendTo;
            if (element && typeof element.nodeType !== 'number') {
                return new Error('Invalid prop `appendTo` supplied to `Kendo React Popup`. Validation failed.');
            }
            return null;
        },
        className: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.object
        ]),
        id: PropTypes.string,
        popupClass: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.object
        ]),
        collision: PropTypes.shape({
            horizontal: PropTypes.oneOf([
                CollisionEnum.fit,
                CollisionEnum.flip,
                CollisionEnum.none
            ]),
            vertical: PropTypes.oneOf([
                CollisionEnum.fit,
                CollisionEnum.flip,
                CollisionEnum.none
            ])
        }),
        anchorAlign: PropTypes.shape({
            horizontal: PropTypes.oneOf([
                AlignPoint.left,
                AlignPoint.center,
                AlignPoint.right
            ]),
            vertical: PropTypes.oneOf([
                AlignPoint.top,
                AlignPoint.center,
                AlignPoint.bottom
            ])
        }),
        popupAlign: PropTypes.shape({
            horizontal: PropTypes.oneOf([
                AlignPoint.left,
                AlignPoint.center,
                AlignPoint.right
            ]),
            vertical: PropTypes.oneOf([
                AlignPoint.top,
                AlignPoint.center,
                AlignPoint.bottom
            ])
        }),
        offset: PropTypes.shape({
            left: PropTypes.number,
            top: PropTypes.number
        }),
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.node
        ]),
        show: PropTypes.bool,
        animate: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.shape({
                openDuration: PropTypes.number,
                closeDuration: PropTypes.number
            })
        ]),
        margin: PropTypes.shape({
            horizontal: PropTypes.number,
            vertical: PropTypes.number
        }),
        positionMode: PropTypes.oneOf([
            'fixed',
            'absolute'
        ]),
        scale: PropTypes.number,
        style: PropTypes.object,
        onClose: PropTypes.func,
        onPosition: PropTypes.func,
        onOpen: PropTypes.func
    };
    /**
     * @hidden
     */
    PopupWithoutContext.defaultProps = {
        collision: {
            horizontal: CollisionEnum.fit,
            vertical: CollisionEnum.flip
        },
        anchorAlign: {
            horizontal: AlignPoint.left,
            vertical: AlignPoint.bottom
        },
        popupAlign: {
            horizontal: AlignPoint.left,
            vertical: AlignPoint.top
        },
        offset: DEFAULT_OFFSET,
        animate: true,
        show: false,
        margin: {
            horizontal: 0,
            vertical: 0
        },
        positionMode: 'absolute'
    };
    /**
     * @hidden
     */
    PopupWithoutContext.contextType = ZIndexContext;
    /**
     * @hidden
     */
    PopupWithoutContext.displayName = 'PopupComponent';
    return PopupWithoutContext;
}(React.Component));
export { PopupWithoutContext };
