import { Box } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import {
  FilterButton,
  TextInput,
} from "react-admin"
import { Button } from 'react-admin';
import { BottomDrawer } from '../../../common/BottomDrawer';
import { Resource } from "../../../common/Resource";
import { ColumnModel } from "../../../model/ColumnModel";

export const resource = "admin/generic-views/credit_cards";

export type CreditCardsDrawerPayload = {
  userId: string
}

const tableColumns = [
  { field: "created_at", width: "120px", title: "created at", show: true, format: "yyyy-MM-dd" },
  { field: "name", width: "120px", title: "name", show: true },
  { field: "bank_id", width: "100px", title: "bank id", show: true },
  { field: "methodfi_account_id", width: "150px", title: "methodfi_account_id", show: true, },
  { field: "mask", width: "150px", title: "mask", show: true },
  { field: "plaid_account_id", width: "150px", title: "plaid account id", show: true },
  { field: "is_single_bill_active", width: "150px", title: "is single bill active", show: true },
  { field: "apr", width: "150px", title: "apr", show: true },
  { field: "current_balance", width: "150px", title: "current balance", show: true, isCents: true },
  { field: "statement_balance", width: "150px", title: "statement balance", show: true, isCents: true },
  { field: "current_utilization", width: "150px", title: "current utilization", show: true, },
  { field: "limit", width: "150px", title: "limit", show: true, isCents: true },
  { field: "next_minimum_payment", width: "150px", title: "next minimum payment", show: true, },
  { field: "next_payment_due_date", width: "150px", title: "next payment due date", show: true, },
  { field: "placid_balance", width: "150px", title: "placid_balance", show: true, isCents: true },
  { field: "plaid_token_id", width: "150px", title: "plaid token id", show: true, },
  { field: "is_pay_off_plan_active", width: "150px", title: "is pay off plan active", show: true, },
  { field: "last_statement_issued_at", width: "150px", title: "last_statement_issued_at", show: false, },
  { field: "plaid_purchase_apr", width: "150px", title: "plaid_purchase_apr", show: false, },
  { field: "plaid_cash_apr", width: "150px", title: "plaid_cash_apr", show: false, },
  { field: "plaid_balance_transfer_apr", width: "150px", title: "plaid_balance_transfer_apr", show: false, },
  { field: "plaid_special_apr", width: "150px", title: "plaid_special_apr", show: false, },
  { field: "payment_network", width: "150px", title: "payment_network", show: false, },
  { field: "last_statement_id", width: "150px", title: "last_statement_id", show: false, },
  { field: "estimated_due_date_each_month", width: "150px", title: "estimated_due_date_each_month", show: true, },
  { field: "card_type", width: "150px", title: "card_type", show: true, },
  { field: "sys_period", width: "150px", title: "sys_period", show: true, },
  { field: "comment", width: "150px", title: "comment", show: false, },
  { field: "status", width: "150px", title: "status", show: true, },
  { field: "is_bt_auto_payoff_active", width: "150px", title: "is_bt_auto_payoff_active", show: true, },
  { field: "primary_data_aggregator", width: "150px", title: "primary_data_aggregator", show: true, },
  { field: "payment_provider", width: "150px", title: "payment_provider", show: true, },
  { field: "yodlee_provider_id", width: "150px", title: "yodlee_provider_id", show: true, },
  { field: "yodlee_account_id", width: "150px", title: "yodlee_account_id", show: true, },
  { field: "user_apr", width: "150px", title: "user_apr", show: true, },
  { field: "user_limit", width: "150px", title: "user_limit", show: true, isCents: true },
  { field: "user_label", width: "150px", title: "user_label", show: true, },
  { field: "methodfi_account_status", width: "150px", title: "methodfi_account_status", show: true, },

  // { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="name" source="name.eq" alwaysOn />,

  // <TextInput label="bank" source="bank.eq" />,

  // <TextInput label="mask" source="mask.eq" />,

  // <TextInput label="table id" source="table_id.eq" />,

  // <DollarInput label="balance gt (Dollar)" source="balance.gt" />,
  // <DollarInput label="balance lt (Dollar)" source="balance.lt" />,

  // <DollarInput label="available gt (Dollar)" source="available.gt" />,
  // <DollarInput label="available lt (Dollar)" source="available.lt" />,

  // <DollarInput label="limit gt (Dollar)" source="limit.gt" />,
  // <DollarInput label="limit lt (Dollar)" source="limit.lt" />,

  // <TextInput label="type" source="type.eq" />,
  // <TextInput label="subtype" source="subtype.eq" />,

  // <SelectInput source="status" choices={[
  //   { id: "ACTIVE", name: "ACTIVE" },
  //   { id: "DISAPPEARED", name: "DISAPPEARED" },
  //   { id: "CONFIRMED_CLOSE", name: "CONFIRMED_CLOSE" },
  //   { id: "CLOSED_PERMANENTLY", name: "CLOSED_PERMANENTLY" },
  // ]} />,

  // <SelectInput source="token_status" choices={[
  //   { id: "Active", name: "Active" },
  //   { id: "OnHold", name: "OnHold" },
  //   { id: "Revoked", name: "Revoked" },
  // ]} />,

  // <SelectInput source="primary_status" choices={[
  //   { id: 'NotPrimary', name: 'NotPrimary' },
  //   { id: 'PrimarySystemConfirmed', name: 'PrimarySystemConfirmed' },
  //   { id: 'PrimaryAdminConfirmed', name: 'PrimaryAdminConfirmed' },
  // ]} />,

  // <SelectInput source="pending_auth" choices={[
  //   { id: 'true', name: 'Enabled' },
  //   { id: 'false', name: 'Disabled' },
  // ]} />,

  // <SelectInput source="micro_deposits_status" choices={[
  //   { id: 'Verified', name: 'Verified' },
  //   { id: 'Expired', name: 'Expired' },
  // ]} />,

  // <TextInput label="PREVIOUS_PLAID_RAW_ACCOUNT" source="PREVIOUS_PLAID_RAW_ACCOUNT.eq" />,
];

export const CreditCardsDrawer: React.FC<Props> = (props) => {
  const {
    payload,
    onClose,
  } = props;

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <BottomDrawer
      isShow={payload !== undefined}
      onClose={onClose}
      title="Credit cards">

      {
        payload !== undefined && (
          <Box>
            <Resource
              resource={resource}
              initialFilters={{ user_id: { eq: payload.userId } }}
              filters={filters}
              columnModel={columnModel}
              tableActions={
                <>
                  <Button label="close" onClick={onClose} />
                  <FilterButton {...props} />
                </>
              }
            />
          </Box>
        )
      }

    </BottomDrawer>
  );
}

type Props = {
  payload: CreditCardsDrawerPayload | undefined
  onClose: () => void
}