import { AdminRequest, PlacidResponse, PostError } from "./AdminRequest";

export type UpdatePlaidIdentityPayload = {
  plaidTokenId?: Array<number>
  userId: number
}

export class PlaidIdentityActions {
  constructor(private adminRequest: AdminRequest) { };

  async update(payload: UpdatePlaidIdentityPayload): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/plaid-identity/update',
          JSON.stringify(payload),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Update plaid identity payload failed";
    }
  }

  _checkPlacidError(placidResponse: PlacidResponse): PostError {
    if (placidResponse.success) {
      return null
    } else {
      return placidResponse.error.message ?? "Something went wrong";
    }
  }
}
