
import { cloneDate, isEqual } from '../../../kendo-date-math';
import { getDate } from '../../../kendo-date-math';
import { EMPTY_SELECTIONRANGE } from './calendar/models/SelectionRange';

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * @hidden
 */
export var isEqualRange = function (initial, updated) {
    var _a = initial || EMPTY_SELECTIONRANGE, initialStart = _a.start, initialEnd = _a.end;
    var _b = updated || EMPTY_SELECTIONRANGE, updatedStart = _b.start, updatedEnd = _b.end;
    if (initialStart === null || initialEnd === null || updatedStart === null || updatedEnd === null) {
        return false;
    }
    return isEqual(initialStart, updatedStart) && isEqual(initialEnd, updatedEnd);
};
// Polyfill for PropTypes.null
/**
 * @hidden
 */
export function nullable(subRequirement) {
    var check = function (required, props, key) {
        var rest = [];
        for (var _i = 3; _i < arguments.length; _i++) {
            rest[_i - 3] = arguments[_i];
        }
        if (props[key] === null) {
            return null;
        }
        var sub = required ? subRequirement.isRequired : subRequirement;
        return sub.apply(void 0, __spreadArray([props, key], rest, false));
    };
    var fn = check.bind(null, false);
    fn.isRequired = check.bind(null, true);
    return fn;
}
/**
 * @hidden
 */
export var viewInRange = function (candidate, min, max) {
    if (min === undefined || max === undefined) {
        return candidate;
    }
    return min <= candidate && candidate <= max
        ? candidate
        : candidate < min
            ? min
            : max;
};
/**
 * @hidden
 */
export var MIDNIGHT_DATE = new Date(1980, 0, 1);
/**
 * @hidden
 */
export var MIN_DATE = new Date(1900, 0, 1);
/**
 * @hidden
 */
export var MAX_DATE = new Date(2099, 11, 31);
/**
 * @hidden
 */
export var MIN_TIME = new Date(1980, 0, 1);
/**
 * @hidden
 */
export var MAX_TIME = new Date(1980, 0, 1, 23, 59, 59);
var isSet = function (value) { return value !== null && value !== undefined; };
/**
 * @hidden
 */
export var isValidRange = function (min, max) { return (!isSet(min) || !isSet(max) || min <= max); };
/**
 * @hidden
 */
export var setTime = function (origin, candidate) {
    var date = cloneDate(origin);
    date.setHours(candidate.getHours(), candidate.getMinutes(), candidate.getSeconds(), candidate.getMilliseconds());
    return date;
};
/**
 * @hidden
 */
export var getToday = function () { return getDate(new Date()); };
/**
 * @hidden
 */
export var isInRange = function (candidate, min, max) { return (!candidate || !((min && min > candidate) || (max && max < candidate))); };
/**
 * @hidden
 */
export var isInDateRange = function (candidate, min, max) { return (candidate === null
    || !((min && getDate(min) > getDate(candidate))
        || (max && getDate(max) < getDate(candidate)))); };
/**
 * @hidden
 */
export var isInSelectionRange = function (value, selectionRange) {
    var _a = selectionRange || EMPTY_SELECTIONRANGE, start = _a.start, end = _a.end;
    if (!start || !end) {
        return false;
    }
    return start < value && value < end;
};
/**
 * @hidden
 */
export var range = function (start, end, step) {
    if (step === void 0) { step = 1; }
    var result = [];
    for (var i = start; i < end; i = i + step) {
        result.push(i);
    }
    return result;
};
/**
 * @hidden
 */
export var intersects = function (date, min, max) {
    return min.getTime() <= date.getTime() && date.getTime() <= max.getTime();
};
/**
 * @hidden
 */
export var shiftWeekNames = function (names, offset) { return (names.slice(offset).concat(names.slice(0, offset))); };
/**
 * @hidden
 */
export var dateInRange = function (candidate, min, max) {
    if (!candidate) {
        return candidate;
    }
    if (min && candidate < min) {
        return cloneDate(min);
    }
    if (max && candidate > max) {
        return cloneDate(max);
    }
    return candidate;
};
/**
 * @hidden
 */
export var domContainerFactory = function (type) { return function (children, classes, styles) {
    if (classes === void 0) { classes = ''; }
    if (styles === void 0) { styles = {}; }
    var container = document.createElement(type);
    container.className = classes;
    Object.keys(styles).map(function (key) { return container.style[key] = styles[key]; });
    if (typeof children === 'string') {
        container.innerHTML = children || '';
    }
    else {
        (children || []).forEach(function (child) { return child && container.appendChild(child); });
    }
    return container;
}; };
/**
 * @hidden
 */
export function debounce(func, wait, options) {
    if (options === void 0) { options = {}; }
    var lastArgs;
    var lastThis;
    var maxWait = options.maxWait;
    var result;
    var timerId;
    var lastCallTime;
    var root = window;
    var lastInvokeTime = 0;
    var leading = false;
    var maxing = false;
    var trailing = true;
    // Bypass `requestAnimationFrame` by explicitly setting `wait=0`.
    var useRAF = (!wait && wait !== 0 && typeof root.requestAnimationFrame === 'function');
    if (typeof func !== 'function') {
        throw new TypeError('Expected a function');
    }
    wait = +wait || 0;
    function invokeFunc(time) {
        var args = lastArgs;
        var thisArg = lastThis;
        lastArgs = lastThis = undefined;
        lastInvokeTime = time;
        result = func.apply(thisArg, args);
        return result;
    }
    function startTimer(pendingFunc, tmr) {
        if (useRAF) {
            root.cancelAnimationFrame(timerId);
            return root.requestAnimationFrame(pendingFunc);
        }
        return setTimeout(pendingFunc, tmr);
    }
    function cancelTimer(id) {
        if (useRAF) {
            return root.cancelAnimationFrame(id);
        }
        clearTimeout(id);
    }
    function leadingEdge(time) {
        // Reset any `maxWait` timer.
        lastInvokeTime = time;
        // Start the timer for the trailing edge.
        timerId = startTimer(timerExpired, wait);
        // Invoke the leading edge.
        return leading ? invokeFunc(time) : result;
    }
    function remainingWait(time) {
        var timeSinceLastCall = time - lastCallTime;
        var timeSinceLastInvoke = time - lastInvokeTime;
        var timeWaiting = wait - timeSinceLastCall;
        return maxing
            ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke)
            : timeWaiting;
    }
    function shouldInvoke(time) {
        var timeSinceLastCall = time - lastCallTime;
        var timeSinceLastInvoke = time - lastInvokeTime;
        // Either this is the first call, activity has stopped and we're at the
        // trailing edge, the system time has gone backwards and we're treating
        // it as the trailing edge, or we've hit the `maxWait` limit.
        return (lastCallTime === undefined || (timeSinceLastCall >= wait) ||
            (timeSinceLastCall < 0) || (maxing && timeSinceLastInvoke >= maxWait));
    }
    function timerExpired() {
        var time = Date.now();
        if (shouldInvoke(time)) {
            return trailingEdge(time);
        }
        // Restart the timer.
        timerId = startTimer(timerExpired, remainingWait(time));
    }
    function trailingEdge(time) {
        timerId = undefined;
        // Only invoke if we have `lastArgs` which means `func` has been
        // debounced at least once.
        if (trailing && lastArgs) {
            return invokeFunc(time);
        }
        lastArgs = lastThis = undefined;
        return result;
    }
    function cancel() {
        if (timerId !== undefined) {
            cancelTimer(timerId);
        }
        lastInvokeTime = 0;
        lastArgs = lastCallTime = lastThis = timerId = undefined;
    }
    function flush() {
        return timerId === undefined ? result : trailingEdge(Date.now());
    }
    function pending() {
        return timerId !== undefined;
    }
    function debounced() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var time = Date.now();
        var isInvoking = shouldInvoke(time);
        lastArgs = args;
        /* @ts-ignore */
        lastThis = this;
        lastCallTime = time;
        if (isInvoking) {
            if (timerId === undefined) {
                return leadingEdge(lastCallTime);
            }
            if (maxing) {
                // Handle invocations in a tight loop.
                timerId = startTimer(timerExpired, wait);
                return invokeFunc(lastCallTime);
            }
        }
        if (timerId === undefined) {
            timerId = startTimer(timerExpired, wait);
        }
        return result;
    }
    debounced.cancel = cancel;
    debounced.flush = flush;
    debounced.pending = pending;
    return debounced;
}
/**
 * @hidden
 */
export function throttle(func, wait) {
    return debounce(func, wait, {
        leading: true,
        trailing: true,
        'maxWait': wait
    });
}
