import { useState } from "react";
import { BillPayCollection } from "../../collections/bill-pay-collection/BillPayCollection";
import { BillPaySpotCollection } from "../../collections/bill-pay-spot-collection/BillPaySpotCollection";

import { BtPaymentsCollection } from "../../collections/bt-payments-collection/BtPaymentsCollection";
import { RepaymentsCollection } from "../../collections/repayments-collection/RepaymentsCollection";
import { UnderwritingCollection } from "../../collections/underwriting-collection/UnderwritingCollection";

const BTPaymentsDashboard = () => {
  const [userId, setUserId] = useState<string | undefined>();

  return (
    <section className='dashboard'>
      <h1>BT payments</h1>

      <BtPaymentsCollection onRowClick={setUserId} />

      {userId !== undefined &&
        <>

          <BtPaymentsCollection
            initialFilters={{ user_id: { eq: userId } }}
            disableUrlFilterListener
          />

          <RepaymentsCollection
            initialFilters={{ user_id: { eq: userId } }}
            rowPerPageOptions={[10, 25, 50]}
            disableUrlFilterListener
          />

          <UnderwritingCollection
            initialFilters={{ "user_id": { eq: userId } }}
            disableUrlFilterListener
          />

          {/*
            <BillPayCollection
              initialFilters={{ "user_id": { eq: userId } }}
            />
          */}

          <BillPaySpotCollection
            initialFilters={{ "user_id": { eq: userId } }} />
        </>
      }
    </section >
  );
}

export default BTPaymentsDashboard;
