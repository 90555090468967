import React, { useMemo } from 'react';
import {
  DateInput,
  FilterButton,
  SelectInput,
  TextInput,
} from "react-admin"
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';

export const resource = "admin/generic-views/admin_executions_history";

const tableColumns = [
  { field: "id", width: "120px", title: "user_id", show: true },
  { field: "created_at", width: "120px", title: "created at", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "closed_at", width: "120px", title: "closed at", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "closed_by", width: "120px", title: "closed by", show: true, },
  { field: "execution_decision", width: "120px", title: "execution decision", show: true },
  { field: "status_description", width: "120px", title: "status description", show: true },
  { field: "endpoint", width: "250px", title: "endpoint", show: true },
  { field: "request", width: "300px", title: "request", show: true },
  { field: "response", width: "300px", title: "response", show: false },
]

const filters = [
  <TextInput label="id" source="id.eq" alwaysOn />,

  <TextInput label="admin id" source="admin_id.eq" alwaysOn />,

  <DateInput label="created_at gt" source="create_at.gt" />,
  <DateInput label="crated_at lt" source="created_at.lt" />,

  <SelectInput source="execution_status" choices={[
    { id: 'PENDING', name: 'PENDING' },
    { id: 'RequestManual', name: 'RequestManual' },
    { id: 'Reject', name: 'Reject' },
    { id: 'Approved', name: 'Approved' },
  ]} />,
];


export const AdminExecutionsHistory: React.FC<Props> = (props) => {
  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, []);

  const customValueFormat = {
    "request": (request?: any) => {
      return JSON.stringify(request);
    },
    "response": (response?: any) => {
      return JSON.stringify(response);
    }
  }

  return (
    <Resource
      resource={resource}
      filters={filters}
      disableUrlFilterListener={false}
      columnModel={columnModel}
      tableActions={
        <>
          <FilterButton {...props} />
        </>
      }
      rowPerPageOptions={[25, 50, 100]}
      customValueFormat={customValueFormat}
    />
  )
}

type Props = {
  initialFilters?: { [index: string]: string }
  onRowClick?: (userId: number) => void
}