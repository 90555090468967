import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, useNotify } from "react-admin";
import { useInjection } from "../../di/useInjection";
import { Grid } from "../../lib/kendo-react-grid/dist/es/Grid";
import { GridColumn } from "../../lib/kendo-react-grid/dist/es/GridColumn";
import { GridPageChangeEvent } from "../../lib/kendo-react-grid/dist/es/interfaces/events";
import { GridCellProps } from "../../lib/kendo-react-grid/dist/npm/main";
import { UnderwritingDecisionCheck } from "../../types/underwritingDecisionsChecks";
import attributesConfig from "../../configs/attributes";

const UserLink = ({ userId }: { userId: string }) => {
  return (
    <div>
      <a href={`/dash/underwriting-user/${userId}/`}> user:{userId}</a>
    </div>
  );
}

interface PageState {
  skip: number;
  take: number;
}

export const UnderwritingDecisionChecksCollection: React.FC<Props> = ({
  underwritingId,
  onPlaidIdentitySelect,
}) => {
  const [page, setPage] = React.useState<PageState>({ skip: 0, take: 25 });
  const [underwritingChecksData, setUnderwritingChecksData] = useState<UnderwritingDecisionCheck[] | undefined>();

  const pageChange = (event: GridPageChangeEvent) => {
    setPage(event.page);
  };

  const { underwritingActions } = useInjection();
  const notify = useNotify();

  useEffect(() => {
    underwritingActions.fetchUnderwritingDecisionChecks(underwritingId)
      .then(({ success, result, error }) => {
        if (success) {
          setUnderwritingChecksData(result);
        } else {
          notify(error?.message ?? "Can't load underwriting checks");
        }
      })
      .catch((e) => notify(e.message ?? "Can't load underwriting checks"));
  }, [underwritingId]);

  if (underwritingChecksData === undefined) {
    return <Box>Loading...</Box>
  }

  if (underwritingChecksData.length === 0) {
    return <Box>No data</Box>
  }

  const cellRender = (
    defaultRender: React.ReactElement<HTMLTableCellElement, string | React.JSXElementConstructor<any>> | null,
    { dataItem, field }: GridCellProps,
  ): React.ReactElement<HTMLTableCellElement> | React.ReactElement<HTMLTableCellElement>[] | null => {
    if (field === "meta") {
      return (
        <td key={dataItem.id}>
          {dataItem.meta.map((
            { entityId, entityType }: { entityId: string, entityType: string }
          ) => {
            switch (entityType) {
              case "UserType":
                return <UserLink
                  key={entityId}
                  userId={entityId} />
              case "PlaidIdentityReportType":
                return <Box>
                  <Button
                    label={`${entityType}:${entityId}`}
                    key={entityId}
                    onClick={() => onPlaidIdentitySelect(entityId)}
                  />
                </Box>
              default:
                return <div key={entityId}>{entityType}:{entityId}</div>
            }
          })}
        </td>
      )
    }

    const craItem = (dataItem.meta ?? [])
      .filter(
        (m: { [index: string]: any }) => m.entityType === "EquifaxModelWithAttributeType"
      );

    if (field === "reasonComment" && craItem.length > 0) {
      const [{ entityId: [model, attribute] }] = craItem;

      const modelFound = attributesConfig.filter((m) => m.model === model.toString());

      if (modelFound.length === 0) {
        return (
          <td key={field}>
            Model not found. Model: {model}, attribute: {attribute}
          </td>
        )
      }

      const attributes = modelFound[0].attributes;
      const attributeFound = attributes[attribute.toString()];

      if (attributeFound === undefined) {
        return (
          <td key={field}>
            Attribute not found. Model: {model}, attribute: {attribute}
          </td>
        )
      }

      return (
        <td key={field}>
          {attributeFound}
        </td>
      )
    }

    return defaultRender;
  }

  return (
    <Box sx={{ position: "relative", paddingTop: "60px" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`Decision checks`}
        </h4>
      </Box>

      <Grid
        data={underwritingChecksData.slice(page.skip, page.take + page.skip)}
        resizable
        pageable
        onPageChange={pageChange}
        sortable
        skip={page.skip}
        take={page.take}
        total={underwritingChecksData.length}
        cellRender={cellRender}>

        <GridColumn field="id" width={80} />
        <GridColumn field="decision" width={200} />
        <GridColumn field="reason" width={400} />
        <GridColumn field="reasonComment" width={800} />
        <GridColumn field="meta" width={500} />
      </Grid>

    </Box>
  )
}

type Props = {
  underwritingId: string
  onPlaidIdentitySelect: (plaidIdentityId: string) => void
}