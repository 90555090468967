
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { weekInYear, cloneDate } from '../../../../../kendo-date-math';
import { registerForIntl, provideIntlService } from '../../../../../kendo-react-intl';
import { CalendarCell } from './CalendarCell';
import { CalendarWeekCell } from './CalendarWeekCell';
import { CalendarViewEnum } from '../models';
import { setTime, getToday } from '../../utils';
import { WeekNamesService } from '../services';
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// In case we decide to render content for the next month in the empty cells,
// add k-other-month class to all cells and k-weekend for weekend cells.
// tslint:disable-next-line jsx-use-translation-function
var emptyCell = function (idx) { return (React.createElement("td", { key: idx, className: 'k-calendar-td' }, "\u00A0")); };
/**
 * @hidden
 */
var View = /** @class */ (function (_super) {
    __extends(View, _super);
    function View() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.intl = null;
        _this.weekService = null;
        _this.buildWeekNumber = function (row, idx) {
            var firstDayOfWeek = _this.firstDate(row);
            if (!firstDayOfWeek) {
                return emptyCell("week-cell-".concat(idx));
            }
            var firstDate = _this.firstDate(row);
            var weekCellValue = _this.getWeekNumber(firstDate);
            var uniqueID = "kendo-react-calendar-week-cell-".concat(weekCellValue);
            var cellProps = {
                className: 'k-alt',
                value: weekCellValue,
                firstDate: firstDate
            };
            return _this.props.weekCell
                ? React.createElement(_this.props.weekCell, __assign({}, cellProps, { key: uniqueID }), weekCellValue)
                : React.createElement(CalendarWeekCell, __assign({}, cellProps, { key: uniqueID }), weekCellValue);
        };
        _this.buildRow = function (row) {
            return row.map(function (cell, idx) {
                if (!cell) {
                    return emptyCell(idx);
                }
                var aria = { 'aria-selected': cell.isSelected };
                var uniqueID = "kendo-react-calendar-cell-".concat(cell.value.getTime());
                var cellProps = __assign(__assign(__assign({}, aria), cell), { isDisabled: !cell.isInRange, view: _this.props.activeView, onClick: _this.handleClick, onMouseEnter: _this.handleMouseEnter, onMouseLeave: _this.handleMouseLeave });
                return _this.props.cell
                    ? (React.createElement(_this.props.cell, __assign({}, cellProps, { key: uniqueID }), cell.formattedValue))
                    : (React.createElement(CalendarCell, __assign({}, cellProps, { key: uniqueID }), cell.formattedValue));
            });
        };
        _this.firstDate = function (row) {
            var cell = _this.firstWeekDateContext(row);
            return cell ? cell.value : null;
        };
        _this.firstWeekDateContext = function (rowCtx) {
            if (!_this.weekNumber) {
                return null;
            }
            var idx = 0;
            var ctx = rowCtx[idx];
            while (!ctx && idx < rowCtx.length) {
                ctx = rowCtx[++idx];
            }
            return ctx;
        };
        _this.handleClick = function (value, event) {
            var onChange = _this.props.onChange;
            if (onChange && event) {
                var args = {
                    value: cloneDate(value),
                    target: _this,
                    nativeEvent: event && event.nativeEvent,
                    syntheticEvent: event
                };
                onChange.call(undefined, args);
            }
        };
        _this.handleMouseEnter = function (value) {
            var onCellEnter = _this.props.onCellEnter;
            if (onCellEnter) {
                onCellEnter.call(undefined, cloneDate(value));
            }
        };
        _this.handleMouseLeave = function (value) {
            var onCellLeave = _this.props.onCellLeave;
            if (onCellLeave) {
                onCellLeave.call(undefined, cloneDate(value));
            }
        };
        return _this;
    }
    Object.defineProperty(View.prototype, "min", {
        get: function () {
            return this.props.min;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(View.prototype, "max", {
        get: function () {
            return this.props.max;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(View.prototype, "isHorizontal", {
        get: function () {
            return this.props.direction === 'horizontal';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(View.prototype, "isMonthView", {
        get: function () {
            return this.props.activeView === CalendarViewEnum.month;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(View.prototype, "weekNumber", {
        get: function () {
            return Boolean(this.props.showWeekNumbers && this.props.activeView === CalendarViewEnum.month);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(View.prototype, "selectedDate", {
        get: function () {
            return this.props.selectedDate !== undefined ? this.props.selectedDate : View.defaultProps.selectedDate;
        },
        enumerable: false,
        configurable: true
    });
    View.prototype.render = function () {
        var _this = this;
        this.intl = provideIntlService(this);
        this.weekService = new WeekNamesService(this.intl);
        var weekNames = this.weekService.getWeekNames(this.weekNumber);
        var colSpan = this.props.service.rowLength(this.weekNumber);
        var title = this.props.service.title(this.props.viewDate);
        var time = getToday();
        var viewDate = setTime(this.props.viewDate, time);
        var data = this.props.service.data({
            cellUID: this.props.cellUID,
            min: this.min,
            max: this.max,
            focusedDate: this.props.focusedDate,
            isActiveView: !this.props.bus.canMoveDown(this.props.activeView),
            selectedDate: this.selectedDate,
            selectionRange: this.props.selectionRange,
            viewDate: viewDate
        });
        return (React.createElement(React.Fragment, null,
            (this.isMonthView && this.isHorizontal) && (React.createElement("thead", { role: "rowgroup", className: "k-calendar-thead" },
                React.createElement("tr", { role: "row", className: "k-calendar-tr" }, weekNames.map(function (name, idx) { return (React.createElement("th", { key: idx, className: "k-calendar-th" }, name)); })))),
            React.createElement("tbody", { role: "rowgroup", className: "k-calendar-tbody" },
                !this.isHorizontal && (React.createElement("tr", { role: "presentation", className: "k-calendar-tr" },
                    React.createElement("th", { scope: "col", colSpan: colSpan, className: "k-calendar-caption" }, title))),
                data.map(function (row, idx) {
                    return (React.createElement("tr", { role: "row", className: "k-calendar-tr", key: idx },
                        _this.weekNumber && _this.buildWeekNumber(row, idx),
                        _this.buildRow(row)));
                }))));
    };
    View.prototype.getWeekNumber = function (date) {
        if (!this.weekNumber || !this.intl) {
            return null;
        }
        return weekInYear(date, this.intl.firstDay());
    };
    View.propTypes = {
        activeRangeEnd: PropTypes.oneOf(['start', 'end', null]),
        activeView: PropTypes.number.isRequired,
        cellUID: PropTypes.string.isRequired,
        direction: PropTypes.oneOf(['horizontal', 'vertical']),
        focusedDate: PropTypes.instanceOf(Date).isRequired,
        max: PropTypes.instanceOf(Date).isRequired,
        min: PropTypes.instanceOf(Date).isRequired,
        onChange: PropTypes.func,
        selectedDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.arrayOf(PropTypes.instanceOf(Date))]),
        showWeekNumbers: PropTypes.bool,
        viewDate: PropTypes.instanceOf(Date).isRequired
    };
    View.defaultProps = {
        direction: 'vertical',
        selectedDate: getToday(),
        showWeekNumbers: false
    };
    return View;
}(React.Component));
export { View };
registerForIntl(View);
