
import React, { FC, useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import currency from 'currency.js';

export type UpdateIncomeDialogPayload = {
  regular: number
  average: number
  id: number
} | null;

export const UpdateIncomeDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { clientActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [regular, setRegular] = useState<string>("");
  const [average, setAverage] = useState<string>("");

  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    if (payload !== undefined) {
      // setRegular(payload?.regular ?? "")
      // setAverage(payload?.average ?? "")
    }

    setError(null);
  }, [payload]);

  const onRegularChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRegular(value);
  }

  const onAverageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAverage(value);
  }

  const handleSendClick = async () => {
    if (payload === null) return;

    const { id } = payload ?? {};

    if (id === undefined) {
      notify("no id provided");
      return;
    }

    const error = await clientActions.updatePersonalInfo({
      userId: id,
      payload: {
        income: {
          regular: regular.length > 0 ? currency(regular).intValue : null,
          average: average.length > 0 ? currency(average).intValue : null,
        },
        address: {},
      }
    });

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Updated");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Update name"
      actionTitle='Update'
      onSend={handleSendClick}>

      <TextField
        fullWidth
        type="number"
        label="regular (dollars)"
        value={regular}
        onChange={onRegularChange}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="number"
        label="average (dollars)"
        value={average}
        onChange={onAverageChange}
        variant="outlined"
      />

      {error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload?: UpdateIncomeDialogPayload;
  hideDialog: () => void;
}