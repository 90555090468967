import React from "react";
import {
  Box,
  Drawer,
} from '@mui/material';

export const BottomDrawer: React.FC<Props> = ({
  isShow,
  onClose,
  children,
  title,
}) => {

  return (
    <Box sx={{ "padding": "0 10px" }}>

      <Drawer
        open={isShow}
        anchor={"bottom"}
        onClose={onClose}>
        <Box>

          <Box sx={{
            background: "#2a41b0",
            color: "#fff",
            padding: "0 20px",
            top: "30px",
          }}>
            <h4>
              {title}
            </h4>
          </Box>
          {children}
        </Box>
      </Drawer>

    </Box>
  );
}

type Props = {
  isShow: boolean
  onClose: () => void
  title: string
}