
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, guid, Keys, validatePackage, withPropsContext, createPropsContext, setScrollbarWidth } from '../../../../../kendo-react-common';
import { cloneDate, getDate, isEqualDate } from '../../../../../kendo-date-math';
import { provideIntlService, registerForIntl } from '../../../../../kendo-react-intl';
import { packageMetadata } from '../../package-metadata';
import { ViewList } from './ViewList';
import { Navigation } from './Navigation';
import { MIN_DATE, MAX_DATE } from '../../defaults';
import { CalendarViewEnum } from '../models';
import { getToday, dateInRange, isInRange, viewInRange } from '../../utils';
import { BusViewService, DOMService, ScrollSyncService, NavigationService } from '../services';
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var virtualizationProp = function (x) { return x ? x.virtualization : null; };
var calculateValue = function (min, max, stateValue, propValue) {
    if (min === void 0) { min = CalendarWithoutContext.defaultProps.min; }
    if (max === void 0) { max = CalendarWithoutContext.defaultProps.max; }
    return propValue !== undefined
        ? propValue !== null && isInRange(getDate(propValue), min, max)
            ? propValue
            : null
        : stateValue !== null && isInRange(getDate(stateValue), min, max)
            ? stateValue
            : null;
};
/** @hidden */
var CalendarWithoutContext = /** @class */ (function (_super) {
    __extends(CalendarWithoutContext, _super);
    function CalendarWithoutContext(props) {
        var _this = _super.call(this, props) || this;
        _this.scrollSyncService = null;
        _this.focusedDate = null;
        _this.cellUID = guid();
        _this.id = guid();
        _this.Navigation = null;
        _this.calendarViewList = null;
        _this._element = null;
        _this.intl = null;
        _this.service = null;
        _this.isActive = false;
        _this.didNavigationChange = false;
        /**
         * @hidden
         */
        _this.focus = function () {
            if (_this._element) {
                _this._element.focus();
            }
        };
        _this.shouldScroll = function () { return _this.didNavigationChange; };
        _this.handleScroll = function (event) {
            if (!_this.scrollSyncService) {
                return;
            }
            _this.scrollSyncService.sync(virtualizationProp(_this.Navigation), virtualizationProp(_this.calendarViewList), event);
        };
        _this.handleNavigationChange = function (event) {
            if (_this.props.disabled) {
                return;
            }
            _this.didNavigationChange = true;
            var focusedDate = cloneDate(event.value);
            _this.setState({ focusedDate: focusedDate });
        };
        _this.handleViewChange = function (_a) {
            var view = _a.view;
            if (_this.scrollSyncService) {
                _this.scrollSyncService.configure(view);
            }
            _this.setState({ activeView: view });
        };
        _this.handleDateChange = function (event) {
            var value = cloneDate(event.value);
            var focusedDate = cloneDate(event.value);
            var canNavigateDown = _this.bus.canMoveDown(_this.state.activeView);
            if (_this.props.disabled) {
                return;
            }
            if (canNavigateDown) {
                if (event.isTodayClick) {
                    _this.bus.moveToBottom(_this.state.activeView);
                }
                else {
                    _this.bus.moveDown(_this.state.activeView, event.syntheticEvent);
                    _this.setState({ focusedDate: focusedDate });
                    return;
                }
            }
            _this.setState({ value: value, focusedDate: focusedDate });
            _this.valueDuringOnChange = value;
            var onChange = _this.props.onChange;
            if (onChange) {
                var args = {
                    syntheticEvent: event.syntheticEvent,
                    nativeEvent: event.nativeEvent,
                    value: value,
                    target: _this
                };
                onChange.call(undefined, args);
            }
            _this.valueDuringOnChange = undefined;
        };
        _this.handleFocus = function (event) {
            _this.isActive = true;
            if (!_this.calendarViewList) {
                return;
            }
            _this.calendarViewList.focusActiveDate();
            var onFocus = _this.props.onFocus;
            if (onFocus) {
                onFocus.call(undefined, event);
            }
        };
        _this.handleBlur = function (event) {
            _this.isActive = false;
            if (!_this.calendarViewList) {
                return;
            }
            _this.calendarViewList.blurActiveDate();
            var onBlur = _this.props.onBlur;
            if (onBlur) {
                onBlur.call(undefined, event);
            }
        };
        _this.handleKeyDown = function (event) {
            var keyCode = event.keyCode;
            if (!_this.focusedDate || !_this.service) {
                return;
            }
            if (keyCode === Keys.enter) {
                if (_this.value !== null
                    && isEqualDate(_this.focusedDate, _this.value)) {
                    var viewDate = dateInRange(_this.focusedDate, _this.min, _this.max);
                    virtualizationProp(_this.calendarViewList).scrollToIndex(_this.service.skip(viewDate, _this.min));
                }
                var args = {
                    syntheticEvent: event,
                    nativeEvent: event.nativeEvent,
                    value: _this.focusedDate,
                    target: _this
                };
                _this.handleDateChange(args);
            }
            else {
                var candidate = dateInRange(_this.navigation.move(_this.focusedDate, _this.navigation.action(event), _this.state.activeView, _this.service, event), _this.min, _this.max);
                if (isEqualDate(_this.focusedDate, candidate)) {
                    return;
                }
                _this.setState({ focusedDate: candidate });
            }
            event.preventDefault();
        };
        _this.handleMouseDown = function (event) {
            event.preventDefault();
        };
        _this.handleClick = function (_) {
            if (_this._element) {
                _this._element.focus({ preventScroll: true });
            }
        };
        validatePackage(packageMetadata);
        var value = calculateValue(_this.min, _this.max, _this.props.defaultValue || CalendarWithoutContext.defaultProps.defaultValue, _this.props.value);
        _this.state = {
            value: value,
            activeView: viewInRange(CalendarViewEnum[props.defaultActiveView], _this.bottomView, _this.topView),
            focusedDate: dateInRange(props.focusedDate
                || value
                || getToday(), _this.min, _this.max)
        };
        _this.dom = new DOMService();
        _this.bus = new BusViewService(_this.handleViewChange);
        _this.navigation = new NavigationService(_this.bus);
        _this.oldValue = value;
        return _this;
    }
    Object.defineProperty(CalendarWithoutContext.prototype, "element", {
        /**
         * Gets the wrapping element of the Calendar.
         */
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CalendarWithoutContext.prototype, "value", {
        /**
         * Gets the value of the Calendar.
         */
        get: function () {
            return this.valueDuringOnChange !== undefined
                ? this.valueDuringOnChange
                : this.props.value !== undefined
                    ? this.props.value
                    : this.state.value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CalendarWithoutContext.prototype, "min", {
        get: function () {
            return getDate(this.props.min !== undefined
                ? this.props.min
                : CalendarWithoutContext.defaultProps.min);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CalendarWithoutContext.prototype, "max", {
        get: function () {
            return getDate(this.props.max !== undefined
                ? this.props.max
                : CalendarWithoutContext.defaultProps.max);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CalendarWithoutContext.prototype, "bottomView", {
        get: function () {
            return CalendarViewEnum[this.props.bottomView !== undefined
                ? this.props.bottomView
                : CalendarWithoutContext.defaultProps.bottomView];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CalendarWithoutContext.prototype, "topView", {
        get: function () {
            return CalendarViewEnum[this.props.topView !== undefined
                ? this.props.topView
                : CalendarWithoutContext.defaultProps.topView];
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    CalendarWithoutContext.prototype.componentDidMount = function () {
        var _this = this;
        setScrollbarWidth();
        // Async calculation of height to avoid animation cancellation
        Promise.resolve().then(function () {
            if (!_this._element) {
                return;
            }
            _this.dom.calculateHeights(_this._element);
            _this.scrollSyncService = new ScrollSyncService(_this.dom);
            _this.scrollSyncService.configure(_this.state.activeView);
            _this.forceUpdate();
        });
    };
    /**
     * @hidden
     */
    CalendarWithoutContext.prototype.componentDidUpdate = function (_, prevState) {
        setScrollbarWidth();
        if (prevState.activeView !== this.state.activeView && this.scrollSyncService) {
            this.scrollSyncService.configure(this.state.activeView);
        }
        if (this.calendarViewList) {
            (this.isActive ? this.calendarViewList.focusActiveDate : this.calendarViewList.blurActiveDate)();
        }
        this.didNavigationChange = false;
        this.oldValue = this.value;
    };
    /**
     * @hidden
     */
    CalendarWithoutContext.prototype.render = function () {
        var _this = this;
        if (this.props._ref) {
            this.props._ref(this);
        }
        var didValueChange = this.value !== null && this.oldValue !== null
            ? !isEqualDate(this.value, this.oldValue)
            : this.value !== this.oldValue;
        var activeView = viewInRange(this.state.activeView, CalendarViewEnum[this.props.bottomView !== undefined
            ? this.props.bottomView
            : CalendarWithoutContext.defaultProps.bottomView], CalendarViewEnum[this.props.topView !== undefined
            ? this.props.topView
            : CalendarWithoutContext.defaultProps.topView]);
        var value = calculateValue(this.min, this.max, this.value, this.value);
        var sanitizedValue = value ? getDate(value) : null;
        this.focusedDate = getDate(dateInRange(didValueChange && value !== null
            ? value
            : this.state.focusedDate, this.min, this.max));
        this.intl = provideIntlService(this);
        this.bus.configure(this.bottomView, this.topView);
        this.service = this.bus.service(activeView, this.intl);
        var rootClassNames = classNames('k-widget k-calendar k-calendar-infinite', {
            'k-disabled': this.props.disabled,
            'k-week-number': this.props.weekNumber
        }, this.props.className);
        var calendarBody = [this.props.navigation && (React.createElement(Navigation, { key: 0, ref: function (el) { _this.Navigation = el; }, activeView: this.state.activeView, focusedDate: this.focusedDate, min: this.min, max: this.max, onScroll: this.handleScroll, onChange: this.handleNavigationChange, service: this.service, dom: this.dom, navigationItem: this.props.navigationItem, tabIndex: this.props.tabIndex })), (React.createElement(ViewList, { key: 1, ref: function (el) { _this.calendarViewList = el; }, activeView: this.state.activeView, focusedDate: this.focusedDate, min: this.min, max: this.max, bus: this.bus, shouldScroll: this.shouldScroll, onScroll: this.handleScroll, service: this.service, cell: this.props.cell, weekCell: this.props.weekCell, dom: this.dom, smoothScroll: this.props.smoothScroll, showWeekNumbers: this.props.weekNumber, onChange: this.handleDateChange, value: sanitizedValue, cellUID: this.cellUID, headerTitle: this.props.headerTitle, tabIndex: this.props.tabIndex }))];
        return (React.createElement("div", { ref: function (el) { _this._element = el; }, className: rootClassNames, id: this.props.id || this.id, "aria-labelledby": this.props.ariaLabelledBy, "aria-describedby": this.props.ariaDescribedBy, "aria-disabled": this.props.disabled, tabIndex: !this.props.disabled ? this.props.tabIndex || 0 : undefined, onFocus: this.handleFocus, onBlur: this.handleBlur, onKeyDown: this.handleKeyDown, onMouseDown: this.handleMouseDown, onClick: this.handleClick }, calendarBody));
    };
    /**
     * @hidden
     */
    CalendarWithoutContext.displayName = 'Calendar';
    /**
     * @hidden
     */
    CalendarWithoutContext.propTypes = {
        className: PropTypes.string,
        defaultActiveView: PropTypes.oneOf(['month', 'year', 'decade', 'century']),
        defaultValue: PropTypes.instanceOf(Date),
        disabled: PropTypes.bool,
        focusedDate: PropTypes.instanceOf(Date),
        id: PropTypes.string,
        ariaLabelledBy: PropTypes.string,
        ariaDescribedBy: PropTypes.string,
        max: PropTypes.instanceOf(Date),
        min: PropTypes.instanceOf(Date),
        navigation: PropTypes.bool,
        smoothScroll: PropTypes.bool,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        tabIndex: PropTypes.number,
        value: PropTypes.instanceOf(Date),
        weekNumber: PropTypes.bool,
        topView: function (props, propName, componentName) {
            var prop = props[propName];
            var bottomView = props.bottomView;
            if (prop && bottomView && CalendarViewEnum[prop] < CalendarViewEnum[bottomView]) {
                return new Error("Invalid prop + ".concat(propName, " suplied to ").concat(componentName, ".\n                    ").concat(propName, " can not be smaller than bottomView.\n                    "));
            }
            return null;
        },
        bottomView: function (props, propName, componentName) {
            var prop = props[propName];
            var topView = props.topView;
            if (prop && topView && CalendarViewEnum[prop] > CalendarViewEnum[topView]) {
                return new Error("Invalid prop + ".concat(propName, " suplied to ").concat(componentName, ".\n                    ").concat(propName, " can not be bigger than topView.\n                    "));
            }
            return null;
        }
    };
    /**
     * @hidden
     */
    CalendarWithoutContext.defaultProps = {
        disabled: false,
        min: MIN_DATE,
        max: MAX_DATE,
        navigation: true,
        defaultActiveView: 'month',
        defaultValue: null,
        smoothScroll: true,
        topView: 'century',
        bottomView: 'month'
    };
    return CalendarWithoutContext;
}(React.Component));
export { CalendarWithoutContext };
/**
 * Represents the PropsContext of the `Calendar` component.
 * Used for global configuration of all `Calendar` instances.
 *
 * For more information, refer to the [DateInputs Props Context]({% slug props-context_dateinputs %}) article.
 */
export var CalendarPropsContext = createPropsContext();
/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the component the same as the type */
/**
 * Represents the KendoReact Calendar Component.
 *
 * Accepts properties of type [CalendarProps]({% slug api_dateinputs_calendarprops %}).
 * Obtaining the `ref` returns an object of type [CalendarHandle]({% slug api_dateinputs_calendarhandle %}).
 */
export var Calendar = withPropsContext(CalendarPropsContext, CalendarWithoutContext);
Calendar.displayName = 'KendoReactCalendar';
registerForIntl(CalendarWithoutContext);
