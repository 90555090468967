
import React, { FC, useEffect, useState } from 'react';
import { Alert, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import { UpdateStatementDDPayload } from '../../../network/ClientActions';
import { format } from 'date-fns';

export type UpdateDueDateDialogPayload = {
  userId: number
  billingCycleId: number
  dueDate: string
} | undefined;

export const UpdateDueDateDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { clientActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [dueDate, setDueDate] = useState<string>("");

  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    console.log(payload);
    if (payload !== undefined) {
      setDueDate(payload?.dueDate ?? "");
    }
    setError(null);
  }, [payload]);

  const onChangeDueDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDueDate(value);
  }

  const handleSendClick = async () => {
    if (payload === undefined) return
    const { userId, billingCycleId } = payload;

    const updatePayload: UpdateStatementDDPayload = {
      userId,
      billingCycleId,
      dueDate: format(new Date(dueDate), "yyyy-MM-dd"),
    }

    const error = await clientActions.updateStatementDD(updatePayload);

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Updated");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Update due date"
      actionTitle='Update'
      onSend={handleSendClick}>

      <TextField
        fullWidth
        id="dueDate"
        label="due date"
        type="date"
        value={dueDate}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChangeDueDate}
      />

      {
        error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>
      }

    </SimpleDialog>
  );
}

type Props = {
  payload?: UpdateDueDateDialogPayload
  hideDialog: () => void
}