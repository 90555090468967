import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DateInput,
  FilterButton,
  SelectInput,
  TextInput,
  useNotify,
  useRefresh,
} from "react-admin"
import {
  RowActions,
} from "./widget/RowActions";
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { Box } from '@mui/material';
import { UpdatePrimaryStatusDialog, UpdatePrimaryStatusDialogPayload } from './widget/UpdatePrimaryStatusDialog';
import { RowClickEvent } from '../../common/Table';
import { HistoricalPlaidTransactionTableDrawer, HistoricalTablePayload } from '../user-bank-accounts-collection/widget/HistoricalPlaidTransactionTableDrawer';
import currency from 'currency.js';
import { DollarInput } from '../../common/DollarInput';
import { zipWith } from 'lodash';
import { UpdateManualReviewDialog, UpdateManualReviewDialogPayload } from './filters/UpdateManualReviewDialog';
import { UpdateManualReviewPayload } from '../../network/ManualReviewActions';
import { useInjection } from '../../di/useInjection';
import { UpdatePlaidHistoricalTransactionButton } from './widget/UpdatePlaidHistoricalTransactionButton';
import { GetIncomeInfoButton } from './widget/GetIncomeInfoButton';
import { FinishAccountReviewButton } from './widget/FinishAccountReviewButton';
import { AddACHAccountDialog, AddACHAccountDialogPayload } from './widget/AddACHAccountDialog';

export const resourceDefault = "admin/generic-views/raw_accounts";
const titleDefault = "Raw accounts";

const calcSumOfIncomes = (data: any[]): string => {
  if (data == undefined) {
    return "0";
  }

  const activeRow = data.filter((row) => row["status"] === "ACTIVE");

  const incomes = activeRow
    .map<number[]>((row) => row['income'])
    .reduce((acc, value) => {
      if (value === undefined || value === null) {
        return acc;
      }

      return zipWith(value, acc, (x, y) => x + y);
    },
      [0, 0, 0, 0],
    )
    .map((value) => currency(value, { fromCents: true }).format())
    .join(', ');

  return incomes;
}

const calcSumOfBalance = (data: any[]): string => {
  if (data == undefined) {
    return currency(0, { fromCents: true }).format();
  }

  const items = data.filter((element) => element.subtype === "credit card");
  let sum = 0;
  for (const item of items) {
    sum += item.balance
  }
  return currency(sum ?? 0, { fromCents: true }).format();
}

const calcSumOfAvailable = (data: any[]): string => {
  if (data == undefined) {
    return currency(0, { fromCents: true }).format();
  }

  const items = data.filter((element) => element.subtype === "credit card");
  let sum = 0;
  for (const item of items) {
    sum += item.available
  }
  return currency(sum ?? 0, { fromCents: true }).format();
}


const tableColumns = (isDepository: boolean) => {
  const depositoryFields = isDepository ? [
    { field: "primary_manual_review_requested_at", width: "120px", title: "man rev req at", show: true, format: "yyyy-MM-dd hh:mm" },
    { field: "primary_manual_review_decision", width: "120px", title: "man rev decision", show: true },
    { field: "primary_manual_review_approved_income", width: "120px", title: "man rev apprv income", show: true, isCents: true },
    { field: "avg_plaid_income", width: "120px", title: "avg plaid income", show: true, isCents: true },
    { field: "avg_model_income", width: "120px", title: "avg model income", show: true, isCents: true },
  ] : [];

  return [
    // { field: "user_id", width: "120px", title: "user_id", show: true },
    { field: "raw_account_id", width: "80px", title: "raw_account_id", show: true },
    { field: "item_id", width: "120px", title: "item_id", show: false },
    { field: "account_id", width: "120px", title: "account_id", show: false },
    { field: "bank", width: "120px", title: "bank", show: true },
    { field: "mask", width: "120px", title: "mask", show: true },
    { field: "name", width: "120px", title: "name", show: true },
    { field: "status", width: "120px", title: "status", show: true },
    { field: "balance", width: "120px", title: "balance", show: true, isCents: true, showTotal: true },
    { field: "available", width: "120px", title: "available", show: true, isCents: true, showTotal: true },
    { field: "limit", width: "120px", title: "limit", show: true, isCents: true, showTotal: true },
    { field: "type", width: "120px", title: "type", show: true },
    { field: "subtype", width: "120px", title: "subtype", show: true },
    { field: "primary_status", width: "120px", title: "primary_status", show: true },
    { field: "income", width: "120px", title: "income", show: true, showTotal: true },
    { field: "token_status", width: "120px", title: "token_status", show: true },
    { field: "expired_at(may_expire_at)", width: "120px", title: "expired_at(may_expire_at)", show: true },
    { field: "pending_auth", width: "120px", title: "pending_auth", show: true },
    { field: "micro_deposits_status", width: "120px", title: "micro_deposits_status", show: true },
    { field: "PREVIOUS_PLAID_RAW_ACCOUNT", width: "120px", title: "previous_plaid_raw_account", show: false },
    { field: "flow_of_funds", width: "120px", title: "flow_of_funds", show: true },

    ...depositoryFields,

    ...[
      { field: "actions", width: "140px", title: "actions", show: true },
    ]
  ]
}

const filters = (isDepository: boolean) => {
  const depositoryFilters = isDepository ? [
    <DateInput label="manual review created at gt" source="primary_manual_review_requested_at.gt" />,
    <DateInput label="manual review created at lt" source="primary_manual_review_requested_at.lt" />,

    <SelectInput source="primary_manual_review_decision" choices={[
      { id: 'PENDING', name: 'PENDING' },
      { id: 'REJECT', name: 'REJECT' },
      { id: 'APPROVE', name: 'APPROVE' },
    ]} alwaysOn />,

  ] : [];

  return [
    <TextInput label="name" source="name.eq" alwaysOn />,
    <TextInput label="bank" source="bank.eq" />,
    <TextInput label="mask" source="mask.eq" />,
    <TextInput label="table id" source="table_id.eq" />,

    <DollarInput label="balance gt (Dollar)" source="balance.gt" />,
    <DollarInput label="balance lt (Dollar)" source="balance.lt" />,

    <DollarInput label="available gt (Dollar)" source="available.gt" />,
    <DollarInput label="available lt (Dollar)" source="available.lt" />,

    <DollarInput label="limit gt (Dollar)" source="limit.gt" />,
    <DollarInput label="limit lt (Dollar)" source="limit.lt" />,

    <TextInput label="type" source="type.eq" />,
    <TextInput label="subtype" source="subtype.eq" />,

    <SelectInput source="status" choices={[
      { id: "ACTIVE", name: "ACTIVE" },
      { id: "DISAPPEARED", name: "DISAPPEARED" },
      { id: "CONFIRMED_CLOSE", name: "CONFIRMED_CLOSE" },
      { id: "CLOSED_PERMANENTLY", name: "CLOSED_PERMANENTLY" },
    ]} />,

    <SelectInput source="token_status" choices={[
      { id: "Active", name: "Active" },
      { id: "OnHold", name: "OnHold" },
      { id: "Revoked", name: "Revoked" },
    ]} />,

    <SelectInput source="primary_status" choices={[
      { id: 'NotPrimary', name: 'NotPrimary' },
      { id: 'PrimarySystemConfirmed', name: 'PrimarySystemConfirmed' },
      { id: 'PrimaryAdminConfirmed', name: 'PrimaryAdminConfirmed' },
    ]} />,

    <SelectInput source="pending_auth" choices={[
      { id: 'true', name: 'Enabled' },
      { id: 'false', name: 'Disabled' },
    ]} />,

    <SelectInput source="micro_deposits_status" choices={[
      { id: 'Verified', name: 'Verified' },
      { id: 'Expired', name: 'Expired' },
    ]} />,

    <TextInput label="PREVIOUS_PLAID_RAW_ACCOUNT" source="PREVIOUS_PLAID_RAW_ACCOUNT.eq" />,

    ...depositoryFilters,
  ];
}

const customValueFormat = {
  "income": (incomes?: any[]) => {
    if (incomes === undefined || incomes === null) {
      return '';
    }

    return "[ " + incomes.map((income) => {
      const t = typeof income;
      if (t == "string" || t == "number") {
        return currency(income as currency.Any, { fromCents: true }).format();
      }
      return JSON.stringify(income, null, 2);
    }).join(", ") + " ]";
  },
  "flow_of_funds": (flowOfFunds?: Array<string>) => {
    if (flowOfFunds === undefined) {
      return "";
    }

    const formatted = flowOfFunds?.map(
      (fof) => fof
        .replace(/(\(|\))/g, "")
        .split(',')
        .map((v) => currency(v, { fromCents: true }).format())
        .join(', ')
    )
      .map((v) => `(${v})`)
      .join('\n');


    return formatted;
  }
}

export const RawAccountsCollection: React.FC<Props> = (props) => {
  const {
    initialFilters,
    onDrawerOpenChange,
    resource = resourceDefault,
    title = titleDefault,
  } = props;

  const [
    updatePrimaryStatusDialogPayload,
    setUpdatePrimaryStatusDialogPayload
  ] = useState<UpdatePrimaryStatusDialogPayload>(null)

  const [
    historicalPlaidTransactionDrawerPayload,
    setHistoricalPlaidTransactionDrawerPayload,
  ] = useState<HistoricalTablePayload | undefined>(undefined)

  const [
    updateManualReviewDialogPayload,
    setUpdateManualReviewDialogPayload
  ] = useState<UpdateManualReviewDialogPayload>(null)

  const [
    addAchAccountPayload,
    setAddAchAccountPayload
  ] = useState<AddACHAccountDialogPayload | null>(null)

  const notify = useNotify();
  const refresh = useRefresh();
  const { manualReviewActions } = useInjection();

  useEffect(() => {
    onDrawerOpenChange(historicalPlaidTransactionDrawerPayload !== undefined);
  }, [historicalPlaidTransactionDrawerPayload]);

  const handleUpdateManualReviewStatusClick = (record: { [index: string]: any }) => {
    const { user_id: userId, account_id: accountId } = record;
    setUpdateManualReviewDialogPayload({ userId, accountId });
  }

  const handleShowAddACHAccountDialog = (plaidRawAccountId: string, userId: number) => {
    setAddAchAccountPayload({
      plaidAccountId: plaidRawAccountId,
      userId,
    });
  }

  const rowActionBuilder = (record: { [index: string]: any }) =>
    <RowActions
      record={record}
      showUpdateDialog={setUpdatePrimaryStatusDialogPayload}
      handleUpdateManualReviewStatus={handleUpdateManualReviewStatusClick}
      showAddACHAccountDialog={handleShowAddACHAccountDialog}
    />

  const handleRowClick = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    const { account_id: plaidAccountId } = dataItem;

    if (plaidAccountId !== undefined) {
      setHistoricalPlaidTransactionDrawerPayload({ plaidAccountId });
    } else {
      throw Error('no user_id in row click event');
    }
  }

  const handleUpdateManualReview = async (payload: UpdateManualReviewPayload) => {
    const error = await manualReviewActions.updateManualReview(payload);

    if (error === null) {
      notify("done");
      refresh();
    } else {
      notify(error);
    }
  }

  const isDepository = resource.includes("raw_accounts_depository");


  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns(isDepository));
  }, [])

  return (
    <Box sx={{ position: "relative", paddingTop: "60px" }}>

      <UpdatePrimaryStatusDialog
        hideDialog={() => setUpdatePrimaryStatusDialogPayload(null)}
        payload={updatePrimaryStatusDialogPayload}
      />

      <UpdateManualReviewDialog
        onSend={handleUpdateManualReview}
        hideDialog={() => setUpdateManualReviewDialogPayload(null)}
        payload={updateManualReviewDialogPayload}
      />

      <HistoricalPlaidTransactionTableDrawer
        payload={historicalPlaidTransactionDrawerPayload}
        onClose={() => setHistoricalPlaidTransactionDrawerPayload(undefined)}
        disableUrlFilerListener={historicalPlaidTransactionDrawerPayload === undefined}
      />

      <AddACHAccountDialog
        hideDialog={() => setAddAchAccountPayload(null)}
        payload={addAchAccountPayload}
      />


      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`${title}: ${initialFilters?.user_id.eq}`}
        </h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        filters={filters(isDepository)}
        rowActionBuilder={rowActionBuilder}
        onRowClick={handleRowClick}
        columnModel={columnModel}
        customValueFormat={customValueFormat}
        rowPerPageOptions={[25, 50]}
        totalCalc={{
          'income': calcSumOfIncomes,
          'balance': calcSumOfBalance,
          'available': calcSumOfAvailable,
        }}
        tableActions={
          <>
            <FinishAccountReviewButton userId={initialFilters?.user_id.eq} />
            <GetIncomeInfoButton userId={initialFilters?.user_id.eq} />
            <UpdatePlaidHistoricalTransactionButton userId={initialFilters?.user_id.eq} />
            <FilterButton {...props} />
          </>
        }
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onDrawerOpenChange: (isHistoricalPlaidOpen: boolean) => void
  resource?: string
  title?: string
}
