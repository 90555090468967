import { guid, Keys } from '../../../../kendo-react-common';
import { findId, findNextIdByCellIndex, findNextIdByRowIndex, getBodyElement, getHeaderElement, getNavigatableId, tableKeyboardNavigationTools as navigationTools } from './utils';

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * @hidden
 */
var onConstructor = function (options) {
    var navigatable = options.navigatable, contextStateRef = options.contextStateRef, navigationStateRef = options.navigationStateRef, idPrefix = options.idPrefix;
    // v.2: check if nested navigation
    if (navigatable) {
        contextStateRef.current = {
            activeId: '',
            level: 0
        };
        navigationStateRef.current = {
            activeElementIsFocused: false,
            prevNavigationIndexes: undefined,
            idPrefix: idPrefix || guid(),
            navigationMatrix: [],
            lastHeaderIndex: -1
        };
    }
};
/**
 * @hidden
 */
var onComponentDidMount = function (options) {
    var scope = options.scope, contextStateRef = options.contextStateRef, navigationStateRef = options.navigationStateRef;
    if (contextStateRef.current && navigationStateRef.current && scope) {
        generateMatrix(options);
        var firstId = navigationStateRef.current.navigationMatrix[0][0];
        var firstIdElement = navigationTools.getActiveElement(scope, firstId);
        if (firstId && firstIdElement) {
            contextStateRef.current.activeId = firstId;
            firstIdElement.setAttribute('tabIndex', '0');
        }
    }
};
/**
 * @hidden
 */
var onGetSnapshotBeforeUpdate = function (options) {
    var contextStateRef = options.contextStateRef, navigationStateRef = options.navigationStateRef, currentDocument = options.document;
    if (contextStateRef.current && navigationStateRef.current && currentDocument) {
        var activeElement = currentDocument.activeElement;
        var activeId = navigationTools.getNavigatableId(activeElement);
        if (activeId && activeId === contextStateRef.current.activeId) {
            navigationStateRef.current.activeElementIsFocused = true;
        }
    }
};
/**
 * @hidden
 */
var onComponentDidUpdate = function (options) {
    var scope = options.scope, contextStateRef = options.contextStateRef, navigationStateRef = options.navigationStateRef;
    generateMatrix(options);
    // check if nested navigation
    if (contextStateRef.current && navigationStateRef.current && scope) {
        var activeElement = navigationTools.getActiveElement(scope, contextStateRef.current.activeId);
        if (!activeElement) {
            var firstId = navigationStateRef.current.navigationMatrix[0][0];
            var firstIdElement = navigationTools.getActiveElement(scope, firstId);
            if (firstId && firstIdElement) {
                contextStateRef.current.activeId = firstId;
                firstIdElement.setAttribute('tabIndex', '0');
                if (navigationStateRef.current.activeElementIsFocused) {
                    firstIdElement.focus();
                }
            }
        }
        navigationStateRef.current.activeElementIsFocused = false;
    }
};
/**
 * @hidden
 */
var onFocus = function (event, options) {
    var contextStateRef = options.contextStateRef;
    if (event.isDefaultPrevented()) {
        return;
    }
    if (!contextStateRef.current) {
        return;
    }
    var focusedElement = event.target;
    var activeId = navigationTools.getNavigatableId(focusedElement);
    if (activeId && activeId !== contextStateRef.current.activeId) {
        var scope = navigationTools.getClosestScope(focusedElement);
        if (!scope) {
            return;
        }
        var prevElement = navigationTools.getActiveElement(scope, contextStateRef.current.activeId);
        if (prevElement) {
            prevElement.setAttribute('tabIndex', '-1');
        }
        focusedElement.setAttribute('tabIndex', '0');
        contextStateRef.current.activeId = activeId;
    }
};
/**
 * @hidden
 */
var onKeyDown = function (event, options) {
    var contextStateRef = options.contextStateRef, navigationStateRef = options.navigationStateRef, onNavigationAction = options.onNavigationAction;
    if (event.isDefaultPrevented()) {
        return;
    }
    if (!contextStateRef.current || !navigationStateRef.current) {
        return;
    }
    if (event.keyCode === Keys.esc) {
        // activate navigation
        var elementForFocus = navigationTools.getClosestNavigatableElement(event.target);
        navigationTools.focusElement({ elementForFocus: elementForFocus, event: event, contextStateRef: contextStateRef });
        return;
    }
    var element = event.target;
    var elementId = navigationTools.getNavigatableId(element);
    var dataLevel = navigationTools.getNavigatableLevel(element);
    var scope = navigationTools.getClosestScope(element);
    var matrix = navigationStateRef.current.navigationMatrix;
    if (dataLevel !== undefined && scope) {
        if (event.keyCode === Keys.enter) {
            // activate nested navigation or focus focusable element
            var navigatableElement = navigationTools.getNavigatableElement(element, { level: dataLevel + 1 });
            if (navigatableElement) {
                navigationTools.focusElement({
                    elementForFocus: navigatableElement,
                    event: event,
                    contextStateRef: contextStateRef,
                    prevElement: element
                });
                return;
            }
            else {
                var elementForFocus = navigationTools.getFocusableElements(element)[0];
                navigationTools.focusElement({ elementForFocus: elementForFocus, event: event, contextStateRef: contextStateRef, prevElement: element });
                return;
            }
        }
        if (event.keyCode === Keys.up ||
            event.keyCode === Keys.down ||
            event.keyCode === Keys.left ||
            event.keyCode === Keys.right) {
            var isReverse = event.keyCode === Keys.up || event.keyCode === Keys.left;
            var isVertical = event.keyCode === Keys.up || event.keyCode === Keys.down;
            var currentIdIndexes = void 0;
            if (navigationStateRef.current && navigationStateRef.current.prevNavigationIndexes) {
                var _a = navigationStateRef.current.prevNavigationIndexes, rowIndex = _a[0], cellIndex = _a[1];
                if (matrix[rowIndex][cellIndex] === elementId) {
                    currentIdIndexes = navigationStateRef.current.prevNavigationIndexes;
                }
                else {
                    currentIdIndexes = findId(matrix, elementId);
                }
            }
            else {
                currentIdIndexes = findId(matrix, elementId);
            }
            if (currentIdIndexes) {
                var rowIndex = currentIdIndexes[0], cellIndex = currentIdIndexes[1];
                var _b = isVertical ?
                    findNextIdByRowIndex(rowIndex, cellIndex, elementId, matrix, isReverse) :
                    findNextIdByCellIndex(rowIndex, cellIndex, elementId, matrix, isReverse), idForFocus = _b[0], currentIndexes = _b[1];
                if (idForFocus) {
                    var elementForFocus = navigationTools.getActiveElement(scope, idForFocus);
                    // emit event
                    navigationTools.focusElement({ elementForFocus: elementForFocus, event: event, contextStateRef: contextStateRef, prevElement: element });
                    navigationStateRef.current.prevNavigationIndexes = currentIndexes;
                    if (onNavigationAction) {
                        onNavigationAction({ focusElement: elementForFocus, event: event });
                    }
                }
            }
        }
    }
};
/**
 * @hidden
 */
var generateMatrix = function (options) {
    var navigationStateRef = options.navigationStateRef, scope = options.scope;
    if (!navigationStateRef.current || !scope) {
        return;
    }
    var matrix = [];
    var thead = getHeaderElement(scope);
    var tbody = getBodyElement(scope);
    if (!thead || !tbody) {
        return;
    }
    var headerRows = Array.from(thead.children);
    var bodyRows = Array.from(tbody.children);
    __spreadArray(__spreadArray([], headerRows, true), bodyRows, true).forEach(function (row, rowIndex) {
        Array.from(row.children).forEach(function (cell) {
            var cellId = getNavigatableId(cell);
            if (!cellId) {
                return;
            } // cell is not navigatable
            var rowSpan = cell.rowSpan || 1;
            var cellSpan = cell.colSpan || 1;
            var cellIndex;
            for (var depth = rowIndex, maxDepth = rowIndex + rowSpan; depth < maxDepth; depth++) {
                if (!matrix[depth]) {
                    matrix[depth] = [];
                }
                if (cellIndex === undefined) {
                    var freeSlotIndex = matrix[depth].findIndex(function (mi) { return !mi; });
                    cellIndex = freeSlotIndex > -1 ? freeSlotIndex : matrix[depth].length;
                }
                matrix[depth][cellIndex] = cellId || '';
            }
            for (var depth = cellIndex + 1, maxDepth = cellIndex + cellSpan; depth < maxDepth; depth++) {
                matrix[rowIndex][depth] = cellId || '';
            }
        });
    });
    navigationStateRef.current.navigationMatrix = matrix.filter(function (row) { return !!row; });
    navigationStateRef.current.lastHeaderIndex = headerRows.length - 1;
};
/**
 * @hidden
 */
export var tableKeyboardNavigation = {
    onConstructor: onConstructor,
    onComponentDidMount: onComponentDidMount,
    onGetSnapshotBeforeUpdate: onGetSnapshotBeforeUpdate,
    onComponentDidUpdate: onComponentDidUpdate,
    onFocus: onFocus,
    onKeyDown: onKeyDown,
    generateMatrix: generateMatrix
};
