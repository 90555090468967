import { Box } from "@mui/material";
import { useState } from "react";
import { ClientsCollection } from "../../collections/clients-collection/ClientsCollection";
import { SeeminglyDiedTokenCollection } from "../../collections/seemingly-died-tokens-collection/SeeminglyDiedTokensCollection";
import { PendingManualReviewRequestCollection } from "../../collections/pending_manual_review_requests/PendingManualReviewReqestsCollection";

const ClientsDashboard = () => {
  const [clientItem, setClientItem] = useState<{ [index: string]: any } | undefined | null>(undefined);

  return (
    <section className='dashboard'>
      <h1>Client Page</h1>

      <Box sx={{ display: "flex", maxWidth: "100%" }}>
        <Box sx={{ marginRight: "20px" }}>

          <ClientsCollection
            onSet={setClientItem}
            disableUrlFilterListener={false} />

          <SeeminglyDiedTokenCollection
            initialFilters={{
              "status": "Active",
            }}
          />

          <PendingManualReviewRequestCollection
            initialFilters={{}}
            disableUrlFilterListener={true}
          />
        </Box>

      </Box>

    </section >
  );
}

export default ClientsDashboard;
