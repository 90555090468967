import { Box } from "@mui/material";
import currency from "currency.js";
import React from "react";

const sumCalc = (data: any[], field: string, isCents: boolean): string => {
  if (data == undefined) {
    return currency(0, { fromCents: true }).format()
  }

  const total =
    data
      .map((row) => row[field])
      .reduce((field, acc) => (field ?? 0) + acc, 0);

  if (isCents) {
    return currency(total, { fromCents: true }).format()
  }

  return total
}

export const Total: React.FC<Props> = ({ data, field, isCents = false, totalCalc }) => {
  const calc = () => {
    if (totalCalc !== undefined && totalCalc[field] !== undefined) {
      const calcFunction = totalCalc[field];
      return calcFunction(data);
    }

    return sumCalc(data, field, isCents);
  }

  return (
    <Box sx={{ fontSize: "14px" }}>
      <strong>total {field}: </strong>
      {calc()}
    </Box>
  );
}

type Props = {
  data: any[]
  field: string
  isCents?: boolean
  totalCalc?: { [index: string]: (data: any[]) => any }
}
