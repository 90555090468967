function getDefaultApiUrl() {
  if (process.env.REACT_APP_HOST) return process.env.REACT_APP_HOST
  if (window.location.host === 'admin.placid.dev') return 'https://api.placid.money'
  return 'https://stage.admin-api.placid.dev'
}

const defaults = {
  apiUrl: getDefaultApiUrl(),
}

const localStorageConfigKey = 'APP_CONFIG'

function getConfig(): typeof defaults {
  try {
    const localStorageConfig = localStorage.getItem(localStorageConfigKey)
    if (localStorageConfig === null) {
      localStorage.setItem(localStorageConfigKey, JSON.stringify(defaults))
    }

    return {
      ...defaults,
      ...JSON.parse(localStorageConfig || '{}'),
    }
  } catch {
    return defaults
  }
}

export const config = getConfig()
