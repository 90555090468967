import React from 'react';

export const PDFDisplay: React.FC<Props> = (props) => {
  const { src } = props;

  return (
    <iframe
      title="Equifax report"
      src={src}
      width="50%"
      height="1000px" />
  )
}

type Props = {
  src: string
}