import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { DateInput, FilterButton, SelectInput, TextInput } from 'react-admin';
import { DollarInput } from '../../common/DollarInput';
import { RowClickEvent } from '../../common/Table';
import { RowActions } from './widget/RowActions';
import { sanitizeSpace } from '../../helpers';

export const resource = "admin/generic-views/bt_payments";

const tableColumns = [
  { field: "id", width: "80px", title: "id", show: true },
  { field: "scheduled_date", width: "150px", title: "scheduled_date", show: true, format: "yyyy-MM-dd" },
  { field: "current_status", width: "120px", title: "current_status", show: true },
  { field: "amount", width: "150px", title: "amount", show: true, isCents: true },
  { field: "user_id", width: "120px", title: "user_id", show: true, },
  { field: "card_name", width: "200px", title: "card_name", show: true, },
  { field: "card_mask", width: "120px", title: "card_mask", show: true, },
  { field: "bank_name", width: "200px", title: "bank_name", show: true, },
  { field: "bank_id", width: "80px", title: "bank_id", show: true, },
  { field: "last_seen", width: "200px", title: "last_seen", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "reasons", width: "200px", title: "reasons", show: true },
  { field: "token_id", width: "120px", title: "token_id", show: true },
  { field: "card_token_is_expired", width: "80px", title: "card_token_is_expired", show: true },
  { field: "last_month_income", width: "80px", title: "last_month_income", show: true, isCents: true },

  { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="user_id" source="user_id.eq" alwaysOn />,

  <TextInput label="first name" source="first_name.like" />,
  <TextInput label="last name" source="last_name.like" />,

  <DateInput label="scheduled_date gt" source="scheduled_date.gt" />,
  <DateInput label="scheduled_date lt" source="scheduled_date.lt" />,

  <SelectInput source="current_status" choices={[
    { id: 'OnHold', name: 'OnHold' },
    { id: 'Canceled', name: 'Canceled' },
    { id: 'Sent', name: 'Sent' },
    { id: 'Scheduled', name: 'Scheduled' },
  ]} />,

  <DollarInput label="amount gt (dollar)" source="amount.gt" />,
  <DollarInput label="amount lt (dollar)" source="amount.lt" />,

  <TextInput label="card_name" source="card_name.eq" />,
  <TextInput label="card_mask" source="card_mask.eq" />,
  <TextInput label="bank_name" source="bank_name.eq" />,
  <TextInput label="bank_id" source="bank_id.eq" />,

  <DateInput label="last_seen gt" source="last_seen.gt" />,
  <DateInput label="last_seen lt" source="last_seen.lt" />,

  <TextInput label="token_id" source="token_id.eq" />,

  <SelectInput source="card_token_is_expired" choices={[
    { id: 'true', name: 'Expired' },
    { id: 'false', name: 'Not expired' },
  ]} />,

  <DollarInput label="last_month_income gt (dollar)" source="last_month_income.gt" />,
  <DollarInput label="last_month_income lt (dollar)" source="last_month_income.lt" />,
];

export const BtPaymentsCollection: React.FC<Props> = (props) => {
  const {
    initialFilters,
    disableUrlFilterListener = false,
    onRowClick,
  } = props;

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, []);

  const rowActionBuilder = (record: { [index: string]: any }) => (
    <RowActions record={record} />
  );

  const handleRowClick = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    if (onRowClick === undefined) return;

    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    const { user_id } = dataItem;

    if (user_id === undefined) {
      throw Error('no user_id in row click event');
    }

    onRowClick(sanitizeSpace(user_id));
  }

  return (
    <Box sx={{ position: "relative", paddingTop: "60px" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px"
      }}>
        <h4>
          {`Bt payments`}
        </h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        disableUrlFilterListener={disableUrlFilterListener}
        columnModel={columnModel}
        rowPerPageOptions={[25, 50, 100]}
        onRowClick={handleRowClick}
        filters={filters}
        tableActions={
          <>
            <FilterButton {...props} />
          </>
        }
        rowActionBuilder={rowActionBuilder}
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (userId: string) => void
  disableUrlFilterListener?: boolean
}