import { Box } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Resource } from "../../common/Resource";
import { ColumnModel } from "../../model/ColumnModel";

export const resource = "admin/generic-views/collection_single_bill_cycles";

const tableColumns = [
  { field: "id", width: "120px", title: "id", show: true },
  { field: "user_id", width: "120px", title: "user id", show: false },
  { field: "single_bill_cycle_id", width: "120px", title: "single bill cycle id", show: true },
  { field: "active_from", width: "120px", title: "active from", show: true, format: "yyyy-MM-dd" },
  { field: "active_to", width: "120px", title: "active to", show: true, format: "yyyy-MM-dd" },
  { field: "due_date", width: "120px", title: "due date", show: true, format: "yyyy-MM-dd" },
  { field: "minimum_payment", width: "120px", title: "minimum payment", show: true, isCents: true },
  { field: "extra_amount", width: "120px", title: "extra amount", show: true, isCents: true },
  { field: "balance_transfer_status", width: "150px", title: "balance transfer status", show: true },
]

export const SingleBillCyclesCollection: React.FC<Props> = ({ initialFilters }) => {
  const columnModel = useMemo(() => {
    console.log(resource, 'call just once');
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Box>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0"
      }}>
        <h4>
          {`Single bill cycles collection: ${initialFilters.user_id.eq}`}
        </h4>
      </Box>

      <Resource
        resource={resource}
        initialFilters={initialFilters}
        columnModel={columnModel}
      />

    </Box>
  );
}

type Props = {
  initialFilters: { "user_id": { "eq": string } }
}