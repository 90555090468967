import * as React from 'react';
import * as PropTypes from 'prop-types';
import { dispatchEvent, classNames, getter, guid, setScrollbarWidth, canUseDOM } from '../../../kendo-react-common';
import { tableColumnsVirtualization, Pager, mapColumns, isRtl, tableKeyboardNavigation, TableKeyboardNavigationContext, tableKeyboardNavigationScopeAttributes, tableKeyboardNavigationTools as navigationTools, tableKeyboardNavigationBodyAttributes, getSelectionOptions, TableSelection, DropClue, DragClue } from '../../../kendo-react-data-tools';
import { GridSelectionCell } from './cells/GridSelectionCell';
import { GridHierarchyCell } from './cells/GridHierarchyCell';
import { GridEditCell } from './cells/GridEditCell';
import { Header } from './header/Header';
import { HeaderRow } from './header/HeaderRow';
import { FilterRow } from './header/FilterRow';
import { GroupPanel } from './header/GroupPanel';
import { Footer } from './footer/Footer';
import { VirtualScroll } from './VirtualScroll';
import { ColumnResize } from './drag/ColumnResize';
import { CommonDragLogic } from './drag/CommonDragLogic';
import { getNestedValue, flatData, readColumns, autoGenerateColumns, sanitizeColumns } from './utils/index';
import { GridCell } from './cells/GridCell';
import { GridGroupCell } from './cells/GridGroupCell';
import { GridRow } from './rows/GridRow';
import { GridHeaderSelectionCell } from './header/GridHeaderSelectionCell';
import { GridNoRecords } from './GridNoRecords';
import { operators } from './filterCommon';
import { FooterRow } from './footer/FooterRow';
import { normalize } from './paging/GridPagerSettings';
import { pagerMessagesMap } from './messages';
import { validatePackage } from '../../../kendo-react-common';
import { packageMetadata } from './package-metadata';
import { GridDetailCell } from './cells/GridDetailCell';
import { GridDetailHierarchyCell } from './cells/GridDetailHierarchyCell';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * Represents the [KendoReact Grid component]({% slug overview_grid %}).
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *    constructor(props) {
 *        super(props);
 *        this.state = {
 *            data: [
 *                { 'foo': 'A1', 'bar': 'B1' },
 *                { 'foo': 'A2', 'bar': 'B2' },
 *                { 'foo': 'A3', 'bar': 'B2' }
 *            ]
 *        };
 *    }
 *    render() {
 *        return (
 *            <Grid
 *                data={this.state.data}
 *                reorderable={true}
 *            >
 *                <GridColumn field="foo" />
 *                <GridColumn field="bar" />
 *            </Grid>
 *        );
 *    }
 * }
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var Grid = /** @class */ (function (_super) {
    __extends(Grid, _super);
    function Grid(props) {
        var _this = _super.call(this, props) || this;
        _this._columns = [];
        _this._columnsMap = [[]];
        _this.contextStateRef = { current: undefined };
        _this.navigationStateRef = { current: undefined };
        _this._data = [];
        _this.wrapperScrollTop = 0;
        _this.headerRef = React.createRef();
        _this.headerElementRef = React.createRef();
        _this._element = null;
        _this.tableElement = null;
        _this._footer = null;
        _this.forceUpdateTimeout = undefined;
        _this.isRtl = false;
        _this._gridId = guid();
        /**
         * Method to allow the scroll to be set to a specific row index.
         *
         * @param options - Object, containing the rowIndex to which is going to be scrolled.
         */
        _this.scrollIntoView = function (options) {
            if (!_this.vs.container || _this.props.scrollable === 'none') {
                return;
            }
            var rowIndex = options.rowIndex;
            if (_this.props.scrollable === 'virtual') {
                _this.vs.askedSkip = rowIndex;
                _this.vs.container.scroll(0, Math.round(_this.vs.askedSkip / _this.vs.total * _this.vs.container.scrollHeight));
            }
            else if (_this.element) {
                var selectedRow = rowIndex < 1
                    ? _this.element.querySelector("tbody > tr:nth-child(".concat(1, ")"))
                    : _this.element.querySelector("tbody > tr:nth-child(".concat(rowIndex, ")"));
                if (selectedRow) {
                    selectedRow.scrollIntoView(true);
                }
            }
        };
        /**
         * Method to fit columns according to their content.
         *
         * @param columnIds - Array of column ids to be fitted.
         */
        _this.fitColumns = function (columnIds) {
            _this.columnResize.dblClickHandler(null, columnIds);
        };
        _this.scrollHandler = function (event) {
            if (event.target !== event.currentTarget) {
                return;
            }
            clearTimeout(_this.forceUpdateTimeout);
            var scrollLeft = event.currentTarget.scrollLeft;
            var scrollTop = event.currentTarget.scrollTop;
            if (_this.props.columnVirtualization && (!_this.vs.scrollableVirtual || scrollTop === _this.wrapperScrollTop)) {
                _this.forceUpdateTimeout = window.setTimeout(function () { _this.forceUpdate(); }, 0);
            }
            if (_this._header) {
                _this._header.setScrollLeft(scrollLeft);
            }
            if (_this._footer) {
                _this._footer.setScrollLeft(scrollLeft);
            }
            if (_this.vs) {
                _this.vs.scrollHandler(event);
            }
            dispatchEvent(_this.props.onScroll, event, _this, undefined);
            _this.wrapperScrollTop = scrollTop;
        };
        _this.onKeyDown = function (event) {
            tableKeyboardNavigation.onKeyDown(event, {
                contextStateRef: _this.contextStateRef,
                navigationStateRef: _this.navigationStateRef,
                onNavigationAction: _this.onNavigationAction
            });
            if (_this.props.onKeyDown) {
                var _a = getSelectionOptions(_this.props.selectable), mode = _a.mode, cell = _a.cell;
                var ev = __assign({ dataItems: _this.getLeafDataItems(), mode: mode, cell: cell, componentId: _this._gridId, selectedField: _this.props.selectedField }, _this.getArguments(event));
                _this.props.onKeyDown.call(undefined, ev);
            }
        };
        _this.onFocus = function (event) {
            tableKeyboardNavigation.onFocus(event, {
                contextStateRef: _this.contextStateRef
            });
        };
        _this.onNavigationAction = function (options) {
            if (_this.props.onNavigationAction) {
                var ev = __assign({ focusElement: options.focusElement }, _this.getArguments(options.event));
                _this.props.onNavigationAction.call(undefined, ev);
            }
        };
        _this.rowClick = function (e, item) {
            if (_this.props.onRowClick && e.target.nodeName === 'TD') {
                _this.props.onRowClick.call(undefined, __assign({ dataItem: item.dataItem }, _this.getArguments(e)));
            }
        };
        _this.rowDblClick = function (e, item) {
            if (_this.props.onRowDoubleClick && e.target.nodeName === 'TD') {
                _this.props.onRowDoubleClick.call(undefined, __assign({ dataItem: item.dataItem }, _this.getArguments(e)));
            }
        };
        _this.itemChange = function (event) {
            var itemChange = _this.props.onItemChange;
            if (event.field === _this.props.expandField || _this.props.group && event.field === undefined) {
                var expandChange = _this.props.onExpandChange;
                if (expandChange) {
                    expandChange.call(undefined, __assign(__assign({}, _this.getArguments(event.syntheticEvent)), { dataItem: event.dataItem, dataIndex: event.dataIndex, value: event.value }));
                }
                return;
            }
            if (itemChange) {
                itemChange.call(undefined, __assign(__assign({}, _this.getArguments(event.syntheticEvent)), { dataItem: event.dataItem, field: event.field, value: event.value }));
            }
        };
        _this.onHeaderSelectionChange = function (event) {
            if (_this.props.onHeaderSelectionChange) {
                _this.props.onHeaderSelectionChange.call(undefined, {
                    field: event.field,
                    nativeEvent: event.syntheticEvent && event.syntheticEvent.nativeEvent,
                    syntheticEvent: event.syntheticEvent,
                    target: _this,
                    dataItems: _this.getLeafDataItems(),
                    selectedField: _this.props.selectedField
                });
            }
        };
        _this.selectionRelease = function (event) {
            if (_this.props.onSelectionChange) {
                var selectionEvent = __assign({ syntheticEvent: undefined, target: _this, selectedField: _this.props.selectedField || '', componentId: _this._gridId, dataItems: _this.getLeafDataItems(), dataItem: null }, event);
                _this.props.onSelectionChange.call(undefined, selectionEvent);
            }
        };
        _this.pageChange = function (page, syntheticEvent) {
            _this.raiseDataEvent(_this.props.onPageChange, { page: page }, { skip: page.skip, take: page.take }, syntheticEvent);
        };
        _this.pagerPageChange = function (event) {
            _this.pageChange({ skip: event.skip, take: event.take }, event.syntheticEvent);
        };
        _this.sortChange = function (sort, syntheticEvent) {
            _this.raiseDataEvent(_this.props.onSortChange, { sort: sort }, { sort: sort }, syntheticEvent);
        };
        _this.filterChange = function (filter, syntheticEvent) {
            _this.raiseDataEvent(_this.props.onFilterChange, { filter: filter }, { filter: filter || undefined, skip: 0 }, syntheticEvent);
        };
        _this.groupChange = function (groups, event) {
            var syntheticEvent = event.nativeEvent ?
                event : { nativeEvent: event.nativeEvent || event.originalEvent };
            _this.raiseDataEvent(_this.props.onGroupChange, { group: groups }, { group: groups, skip: 0 }, syntheticEvent);
        };
        _this.onResize = function (index, newWidth, oldWidth, nativeEvent, end, targetId) {
            _this.resetTableWidth();
            if (_this.props.onColumnResize) {
                _this.props.onColumnResize.call(undefined, {
                    columns: _this.columns,
                    nativeEvent: nativeEvent,
                    targetColumnId: targetId,
                    index: index,
                    newWidth: newWidth,
                    oldWidth: oldWidth,
                    end: end,
                    target: _this
                });
            }
        };
        _this.resolveTitle = function (field) {
            var column = _this._columns.find(function (c) { return c.field === field; });
            var title = column && (column.title || column.field);
            return title === undefined ? field : title;
        };
        _this.getLeafDataItems = function () {
            return _this._data.filter(function (item) { return item.rowType === 'data'; }).map(function (item) { return item.dataItem; });
        };
        validatePackage(packageMetadata);
        var groupable = _this.props.groupable === true ||
            (typeof _this.props.groupable === 'object') && _this.props.groupable.enabled !== false;
        _this.vs = new VirtualScroll(groupable || props.rowHeight === undefined || props.rowHeight === 0);
        _this.dragLogic = new CommonDragLogic(_this.columnReorder.bind(_this), _this.groupReorder.bind(_this), _this.columnToGroup.bind(_this));
        _this.columnResize = new ColumnResize(_this.onResize);
        tableKeyboardNavigation.onConstructor({
            navigatable: Boolean(props.navigatable),
            contextStateRef: _this.contextStateRef,
            navigationStateRef: _this.navigationStateRef,
            idPrefix: _this._gridId
        });
        return _this;
    }
    Object.defineProperty(Grid.prototype, "columns", {
        /* eslint-disable max-len */
        /**
         * A getter of the current columns. Gets the current column width or current columns, or any other [`GridColumnProps`]({% slug api_grid_gridcolumnprops %}) for each defined column. Can be used on each Grid instance. To obtain the instance of the rendered Grid, use the `ref` callback. The following example demonstrates how to reorder the columns by dragging their handlers and check the properties afterwards. You can check the result in the browser console.
         *
         * @example
         * ```jsx
         * class App extends React.Component {
         *     constructor(props) {
         *         super(props);
         *         this.state = {
         *             data: [
         *                 { 'foo': 'A1', 'bar': 'B1' },
         *                 { 'foo': 'A2', 'bar': 'B2' },
         *                 { 'foo': 'A3', 'bar': 'B2' }
         *             ]
         *         };
         *         this.grid = null;
         *     }
         *     render() {
         *         return (
         *             <div>
         *                 <Grid
         *                     data={this.state.data}
         *                     reorderable={true}
         *                     ref={(g) => { this.grid = g; }}
         *                 >
         *                     <GridColumn field="foo" />
         *                     <GridColumn field="bar" />
         *                 </Grid>
         *                 <button onClick={() => console.log(JSON.stringify(this.grid.columns))}>
         *                     log current properties into browser console.
         *                 </button>
         *             </div>
         *         );
         *     }
         * }
         * ReactDOM.render(<App />, document.querySelector('my-app'));
         * ```
         */
        // tslint:enable:max-line-length
        get: function () {
            var shuffledColumns = this._columns.filter(function (q) { return q.declarationIndex >= 0 && q.parentIndex === -1; });
            return sanitizeColumns(shuffledColumns);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Grid.prototype, "element", {
        /**
         * Returns the HTML element of the Grid component.
         */
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Grid.prototype, "document", {
        get: function () {
            if (!canUseDOM) {
                return;
            }
            // useful only for user actions
            return (this.element && this.element.ownerDocument) || document;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Grid.prototype, "_header", {
        get: function () {
            return this.headerRef.current;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    Grid.prototype.componentDidMount = function () {
        setScrollbarWidth();
        var rtl = isRtl(this.element);
        this.isRtl = rtl;
        tableKeyboardNavigation.onComponentDidMount({
            scope: this.element || undefined,
            contextStateRef: this.contextStateRef,
            navigationStateRef: this.navigationStateRef
        });
        if (this.isRtl) {
            this.forceUpdate();
        }
    };
    /**
     * @hidden
     */
    Grid.prototype.getSnapshotBeforeUpdate = function () {
        tableKeyboardNavigation.onGetSnapshotBeforeUpdate({
            document: this.document,
            contextStateRef: this.contextStateRef,
            navigationStateRef: this.navigationStateRef
        });
        return null;
    };
    /**
     * @hidden
     */
    Grid.prototype.componentDidUpdate = function () {
        setScrollbarWidth();
        tableKeyboardNavigation.onComponentDidUpdate({
            scope: this.element || undefined,
            contextStateRef: this.contextStateRef,
            navigationStateRef: this.navigationStateRef
        });
    };
    /**
     * @hidden
     */
    Grid.prototype.componentWillUnmount = function () {
        clearTimeout(this.forceUpdateTimeout);
    };
    /**
     * @hidden
     */
    Grid.prototype.render = function () {
        var _this = this;
        var total = this.props.total || 0;
        var idPrefix = navigationTools.getIdPrefix(this.navigationStateRef);
        var propsData = [];
        if (Array.isArray(this.props.data)) {
            propsData = this.props.data;
            total = total || this.props.data.length;
        }
        else if (this.props.data) {
            propsData = this.props.data.data;
            total = total || this.props.data.total;
        }
        var groupable = this.props.groupable === true ||
            (typeof this.props.groupable === 'object') && this.props.groupable.enabled !== false;
        this.columnResize.resizable = this.props.resizable || false;
        this.dragLogic.reorderable = this.props.reorderable || false;
        this.dragLogic.groupable = groupable;
        var tableUserSelect = this.props.selectable && this.props.selectable.drag ? 'none' : undefined;
        if (total !== this.vs.total) {
            this.vs.reset();
            this.vs = new VirtualScroll(groupable || this.props.rowHeight === undefined || this.props.rowHeight === 0);
            this.vs.table = this.tableElement;
        }
        this.vs.fixedScroll = this.props.fixedScroll || false;
        this.vs.PageChange = this.pageChange;
        this.vs.realSkip = this.props.skip || 0;
        this.vs.pageSize = (this.props.take !== undefined ? this.props.take : this.props.pageSize) || 0;
        this.vs.scrollableVirtual = (this.props.scrollable === 'virtual');
        this.vs.total = total;
        this.vs.propsSkip = (this.props.skip || 0) + (this.props.scrollable === 'virtual' ?
            this.vs.topCacheCount + (this.vs.attendedSkip - (this.props.skip || 0)) : 0);
        if (this.props.rowHeight !== undefined && this.props.rowHeight > 0 && !groupable) {
            this.vs.containerHeight = Math.min(1533915, this.props.rowHeight * (total || 0));
        }
        else {
            this.vs.containerHeight = 1533915;
        }
        var groupingFooter = (typeof this.props.groupable === 'object') && this.props.groupable.footer || 'none';
        this._data = [];
        var resolvedGroupsCount = flatData(this._data, propsData, groupingFooter, { index: this.props.skip || 0 }, this.props.group !== undefined, this.props.expandField);
        var children = React.Children.toArray(this.props.children);
        this.initColumns(children.filter(function (child) { return child && child.type &&
            child.type.displayName === 'KendoReactGridColumn'; }), resolvedGroupsCount);
        var toolbar = children.filter(function (child) { return child && child.type &&
            child.type.displayName === 'KendoReactGridToolbar'; });
        var noRecords = children.filter(function (child) { return child && child.type &&
            child.type.displayName === 'KendoReactGridNoRecords'; });
        var leafColumns = this._columns.filter(function (c) { return c.children.length === 0; });
        var groupingPanel = groupable && (React.createElement(GroupPanel, { group: this.props.group || [], groupChange: this.groupChange, pressHandler: this.dragLogic.pressHandler, dragHandler: this.dragLogic.dragHandler, releaseHandler: this.dragLogic.releaseHandler, refCallback: this.dragLogic.refGroupPanelDiv, resolveTitle: this.resolveTitle }));
        var header = (React.createElement(Header, { columnResize: this.columnResize, staticHeaders: this.props.scrollable !== 'none', scrollableDataElement: function () { return _this.vs.container; }, draggable: this.props.reorderable || groupable, ref: this.headerRef, elemRef: this.headerElementRef, headerRow: React.createElement(HeaderRow, { sort: this.props.sort, sortable: this.props.sortable, sortChange: this.sortChange, group: this.props.group || [], groupable: groupable, groupChange: this.groupChange, filter: this.props.filter, filterable: this.props.filterable, filterOperators: this.props.filterOperators || operators, filterChange: this.filterChange, columnMenu: this.props.columnMenu, selectionChange: this.onHeaderSelectionChange, columns: this._columns, columnResize: this.columnResize, pressHandler: this.dragLogic.pressHandler, dragHandler: this.dragLogic.dragHandler, releaseHandler: this.dragLogic.releaseHandler, columnsMap: this._columnsMap, cellRender: this.props.headerCellRender, isRtl: this.isRtl, dragClue: this.dragLogic.dragClueRef, headerRef: this.headerElementRef, containerRef: this.vs.containerRef }), filterRow: this.props.filterable && React.createElement(FilterRow, { columns: this._columns, filter: this.props.filter, filterOperators: this.props.filterOperators || operators, filterChange: this.filterChange, sort: this.props.sort, cellRender: this.props.filterCellRender, isRtl: this.isRtl, ariaRowIndex: this._columnsMap.length + 1 }) || undefined, cols: leafColumns.map(function (column, index) { return (React.createElement("col", { key: index.toString(), width: column.width !== undefined ?
                    Math.floor(parseFloat(column.width.toString())) + 'px' : undefined })); }) }));
        var scrollLeft = this.vs && this.vs.container && this.vs.container.scrollLeft || 0;
        var tableWidth = parseFloat(((this.props.style || {}).width || '').toString());
        var _a = tableColumnsVirtualization({
            enabled: this.props.columnVirtualization,
            columns: leafColumns,
            tableViewPortWidth: tableWidth,
            scrollLeft: scrollLeft
        }), colSpans = _a.colSpans, isColHidden = _a.hiddenColumns;
        var dataRow = function (item, rowId, rowDataIndex) {
            var isInEdit = false;
            var selectedValue = _this.props.selectedField ? getNestedValue(_this.props.selectedField, item.dataItem) : undefined;
            return {
                row: leafColumns.map(function (column, columnIndex) {
                    if (isColHidden[columnIndex]) {
                        return null;
                    }
                    var columnKey = column.id ? column.id : columnIndex;
                    var className = "".concat(column.className ? column.className + ' ' : '')
                        + "".concat(column.locked ? 'k-grid-content-sticky' : '');
                    var style = column.left !== undefined
                        ? !_this.isRtl
                            ? { left: column.left, right: column.right }
                            : { left: column.right, right: column.left }
                        : {};
                    var cellProps = {
                        id: navigationTools.generateNavigatableId("".concat(rowId, "-").concat(String(columnIndex)), idPrefix),
                        colSpan: colSpans[columnIndex],
                        dataItem: item.dataItem,
                        field: column.field,
                        editor: column.editor,
                        format: column.format,
                        className: className,
                        render: _this.props.cellRender,
                        onChange: _this.itemChange,
                        selectionChange: (_this.props.onSelectionChange) ?
                            (function (e) { _this.selectionChange({ event: e, dataItem: item.dataItem, dataIndex: rowDataIndex, columnIndex: columnIndex }); }) :
                            undefined,
                        columnIndex: columnIndex,
                        columnsCount: leafColumns.length,
                        rowType: item.rowType,
                        level: item.level,
                        expanded: item.expanded,
                        dataIndex: item.dataIndex,
                        style: style,
                        ariaColumnIndex: column.ariaColumnIndex,
                        isSelected: Array.isArray(selectedValue) && selectedValue.indexOf(columnIndex) > -1
                    };
                    var currentColumnIsInEdit = false;
                    if (column.editable && _this.props.editField) {
                        var inEdit = getNestedValue(_this.props.editField, item.dataItem);
                        if (inEdit === true || inEdit === column.field) {
                            isInEdit = true;
                            currentColumnIsInEdit = true;
                        }
                    }
                    if (column.cell) {
                        return React.createElement(column.cell, __assign({ key: columnKey }, cellProps));
                    }
                    return currentColumnIsInEdit
                        ? React.createElement(GridEditCell, __assign({ key: columnKey }, cellProps))
                        : React.createElement(GridCell, __assign({ key: columnKey }, cellProps));
                }),
                isInEdit: isInEdit,
                isSelected: typeof selectedValue === 'boolean' && selectedValue
            };
        };
        var hiddenRows = 0;
        if (this.props.scrollable === 'virtual') {
            for (var i = 0; i < this.vs.topCacheCount + this.vs.attendedSkip - (this.props.skip || 0); i++) {
                var item = this._data.shift();
                if (item) {
                    this._data.push(item);
                    hiddenRows++;
                    if (item.rowType === 'groupHeader') {
                        i--;
                    }
                }
                else {
                    break;
                }
            }
        }
        var hidden = function (index) {
            return (index >= _this._data.length - hiddenRows);
        };
        var absoluteDataIndex = this.vs.propsSkip || 0;
        var body = [];
        var rowIndexStart = this._columnsMap.length + (this.props.filterable ? 1 : 0) + 1;
        var currentAriaRowIndex = 0;
        if (this._data.length) {
            var dataIndex_1 = -1;
            var detailRowCount_1 = 0;
            this._data.forEach(function (item, rowIndex) {
                if (item.rowType === 'data') {
                    absoluteDataIndex++;
                    dataIndex_1++;
                }
                var isAlt = absoluteDataIndex % 2 === 0;
                var dataItemKey = _this.props.dataItemKey && getter(_this.props.dataItemKey)(item.dataItem);
                var absoluteIndex = rowIndex + (_this.vs.propsSkip || 0);
                var rowId = dataItemKey ? dataItemKey : 'ai' + absoluteIndex;
                var detailRowId = rowId + '_1';
                var gridRow = dataRow(item, rowId, dataIndex_1);
                currentAriaRowIndex = absoluteIndex + rowIndexStart + detailRowCount_1;
                body.push((React.createElement(GridRow, { key: rowId, dataItem: item.dataItem, isAltRow: isAlt, isInEdit: gridRow.isInEdit, rowType: item.rowType, isHidden: hidden(rowIndex), onClick: function (e) { return _this.rowClick(e, item); }, onDoubleClick: function (e) { return _this.rowDblClick(e, item); }, selectedField: _this.props.selectedField, rowHeight: _this.props.rowHeight, render: _this.props.rowRender, ariaRowIndex: currentAriaRowIndex, dataIndex: dataIndex_1, isSelected: gridRow.isSelected }, gridRow.row)));
                if (_this.props.detail && item.rowType === 'data' && item.expanded) {
                    var detailColspan = (leafColumns.length
                        - (_this.props.expandField ? 1 : 0)
                        - (_this.props.group ? _this.props.group.length : 0)) || 1;
                    detailRowCount_1++;
                    currentAriaRowIndex = absoluteIndex + rowIndexStart + detailRowCount_1;
                    body.push((React.createElement("tr", { key: detailRowId, className: isAlt ? 'k-detail-row k-alt' : 'k-detail-row', style: { visibility: hidden(rowIndex) ? 'hidden' : '' }, role: "row", "aria-rowindex": currentAriaRowIndex },
                        _this.props.group && _this.props.group.map(function (group, idx) {
                            return (React.createElement(GridGroupCell, { id: '', dataIndex: item.dataIndex, field: group.field, dataItem: item.dataItem, key: idx, style: {}, ariaColumnIndex: 1 + idx, isSelected: false }));
                        }),
                        _this.props.expandField &&
                            React.createElement(GridDetailHierarchyCell, { id: navigationTools.generateNavigatableId("".concat(detailRowId, "-dhcell"), idPrefix) }),
                        React.createElement(GridDetailCell, { dataItem: item.dataItem, dataIndex: item.dataIndex, colSpan: detailColspan, ariaColIndex: 2 + (_this.props.group ? _this.props.group.length : 0), detail: _this.props.detail, id: navigationTools.generateNavigatableId("".concat(detailRowId, "-dcell"), idPrefix) }))));
                }
            });
        }
        else {
            body.push((React.createElement("tr", { key: "no-records", className: "k-grid-norecords", "aria-rowindex": rowIndexStart },
                React.createElement("td", { colSpan: leafColumns.length }, noRecords.length ? noRecords : React.createElement(GridNoRecords, null)))));
        }
        var pagerProps = __assign({ className: 'k-grid-pager', onPageChange: this.pagerPageChange, total: total, skip: this.vs.propsSkip || 0, take: (this.props.take !== undefined ? this.props.take : this.props.pageSize) || 10, messagesMap: pagerMessagesMap }, (normalize(this.props.pageable || {})));
        var pager = this.props.pager ? React.createElement(this.props.pager, __assign({}, pagerProps)) : React.createElement(Pager, __assign({}, pagerProps));
        var sorted = function (field) {
            return _this.props.sort && _this.props.sort.filter(function (descriptor) { return descriptor.field === field; }).length > 0;
        };
        var footer = this._columns.some(function (c) { return Boolean(c.footerCell); }) ? (React.createElement(Footer, { columnResize: this.columnResize, staticHeaders: this.props.scrollable !== 'none', ref: function (f) { return _this._footer = f; }, row: React.createElement(FooterRow, { columns: this._columns, isRtl: this.isRtl, ariaRowIndex: currentAriaRowIndex + 1 }), cols: leafColumns.map(function (column, index) { return (React.createElement("col", { key: index.toString(), width: column.width !== undefined ?
                    Math.floor(parseFloat(column.width.toString())) + 'px' : undefined })); }) })) : null;
        var colGroups = (React.createElement("colgroup", { ref: function (c) {
                _this.columnResize.colGroupMain = c;
            } }, leafColumns.map(function (column, index) { return (React.createElement("col", { key: index.toString(), className: sorted(column.field) ? 'k-sorted' : undefined, width: column.width !== undefined ?
                Math.floor(parseFloat(column.width.toString())) + 'px' : undefined })); })));
        var enableDragClues = this.dragLogic.reorderable || this.dragLogic.groupable;
        var tableDisplay = this.props.columnVirtualization ? 'block' : 'table';
        if (this.props.scrollable === 'none') {
            return (React.createElement(TableKeyboardNavigationContext.Provider, { value: this.contextStateRef.current },
                React.createElement("div", __assign({ id: this.props.id, style: this.props.style, className: classNames('k-widget k-grid', this.props.className), ref: function (el) { _this._element = el; }, onKeyDown: this.onKeyDown, onFocus: this.onFocus }, tableKeyboardNavigationScopeAttributes),
                    toolbar,
                    groupingPanel,
                    React.createElement(TableSelection, { selectable: this.props.selectable, onRelease: this.selectionRelease, childRef: function (table) {
                            _this.vs.table = table;
                            _this.tableElement = table;
                            _this.resetTableWidth();
                        } },
                        React.createElement("table", { style: { userSelect: tableUserSelect, display: tableDisplay } },
                            colGroups,
                            header,
                            React.createElement("tbody", __assign({}, tableKeyboardNavigationBodyAttributes), body),
                            footer)),
                    this.props.pageable && pager,
                    enableDragClues && (React.createElement(React.Fragment, null,
                        React.createElement(DropClue, { ref: this.dragLogic.refDropElementClue }),
                        React.createElement(DragClue, { ref: this.dragLogic.refDragElementClue }))))));
        }
        var wrapperStyle = this.props.style || {};
        if (this.props.scrollable === 'virtual') {
            // Set the default height for vs if not existing.
            if (!wrapperStyle.height) {
                wrapperStyle = Object.assign({}, wrapperStyle, { height: '450px' });
            }
        }
        return (React.createElement(TableKeyboardNavigationContext.Provider, { value: this.contextStateRef.current },
            React.createElement("div", __assign({ id: this.props.id, style: wrapperStyle, className: classNames('k-widget k-grid', {
                    'k-grid-virtual': this.props.scrollable === 'virtual'
                }, this.props.className), ref: function (el) {
                    _this._element = el;
                    _this.resetTableWidth();
                }, role: "grid", "aria-colcount": leafColumns.length, "aria-rowcount": total, onKeyDown: this.onKeyDown, onFocus: this.onFocus }, tableKeyboardNavigationScopeAttributes),
                toolbar,
                groupingPanel,
                header,
                React.createElement("div", { className: "k-grid-container", role: "presentation" },
                    React.createElement("div", { ref: this.vs.containerRef, className: "k-grid-content k-virtual-content", onScroll: this.scrollHandler, role: "presentation" },
                        React.createElement("div", { style: { 'position': 'relative' }, role: "presentation" },
                            React.createElement(TableSelection, { selectable: this.props.selectable, onRelease: this.selectionRelease, childRef: function (table) {
                                    _this.vs.table = table;
                                    _this.tableElement = table;
                                } },
                                React.createElement("table", { className: 'k-grid-table', role: "presentation", style: { userSelect: tableUserSelect, display: tableDisplay } },
                                    colGroups,
                                    React.createElement("tbody", __assign({ ref: function (tableBody) {
                                            _this.vs.tableBody = tableBody;
                                        }, role: "presentation" }, tableKeyboardNavigationBodyAttributes), body)))),
                        React.createElement("div", { className: "k-height-container", role: "presentation" },
                            React.createElement("div", { style: this.props.scrollable === 'virtual' ?
                                    { 'height': (this.vs.containerHeight) + 'px' } : {} })))),
                footer,
                this.props.pageable && pager,
                enableDragClues && (React.createElement(React.Fragment, null,
                    React.createElement(DropClue, { ref: this.dragLogic.refDropElementClue }),
                    React.createElement(DragClue, { ref: this.dragLogic.refDragElementClue }))))));
    };
    Grid.prototype.selectionChange = function (options) {
        if (this.props.onSelectionChange) {
            var event_1 = options.event, dataItem = options.dataItem, dataIndex = options.dataIndex, columnIndex = options.columnIndex;
            var _a = getSelectionOptions(this.props.selectable), mode = _a.mode, cell = _a.cell;
            var selectionEvent = __assign(__assign({}, this.getArguments(event_1.syntheticEvent)), { dataItem: dataItem, startColIndex: columnIndex, endColIndex: columnIndex, startRowIndex: dataIndex, endRowIndex: dataIndex, dataItems: this.getLeafDataItems(), altKey: false, ctrlKey: false, shiftKey: false, metaKey: false, mode: mode, cell: cell, isDrag: false, componentId: this._gridId, selectedField: this.props.selectedField || '' });
            this.props.onSelectionChange.call(undefined, selectionEvent);
        }
    };
    Grid.prototype.raiseDataEvent = function (handler, data, moreData, syntheticEvent) {
        var dataStateChange = this.props.onDataStateChange;
        if (handler) {
            var ev = __assign(__assign({}, this.getArguments(syntheticEvent)), data);
            handler.call(undefined, ev);
        }
        else if (dataStateChange) {
            dataStateChange.call(undefined, __assign(__assign({}, this.getArguments(syntheticEvent)), { dataState: __assign(__assign({}, this.getDataState()), moreData) }));
        }
    };
    Grid.prototype.columnReorder = function (prev, next, nativeEvent) {
        var _a;
        var _this = this;
        var depth = this._columns[prev].depth;
        var end = function (index) {
            do {
                index++;
            } while (index < _this._columns.length && _this._columns[index].depth > depth);
            return index;
        };
        var spliced = this._columns.splice(prev, end(prev) - prev);
        (_a = this._columns).splice.apply(_a, __spreadArray([prev < next ? end(next - spliced.length) : next, 0], spliced, false));
        this._columns.filter(function (q) { return q.declarationIndex >= 0; }).forEach(function (c, i) { return c.orderIndex = i; });
        var eventColumnProps = this.columns;
        this.forceUpdate();
        if (this.props.onColumnReorder) {
            this.props.onColumnReorder.call(undefined, {
                target: this,
                columns: eventColumnProps,
                nativeEvent: nativeEvent
            });
        }
    };
    Grid.prototype.groupReorder = function (prevIndex, nextIndex, nativeEvent) {
        if (this.props.group === undefined) {
            return;
        }
        var group = this.props.group.slice();
        group.splice.apply(group, __spreadArray([nextIndex, 0], group.splice(prevIndex, 1), false));
        this.groupChange(group, nativeEvent);
    };
    Grid.prototype.columnToGroup = function (columnIndex, groupIndex, nativeEvent) {
        var field = this._columns[columnIndex].field;
        if (!field) {
            return;
        }
        var group = (this.props.group || []).slice();
        group.splice(groupIndex, 0, { field: field });
        this.groupChange(group, nativeEvent);
    };
    Grid.prototype.resetTableWidth = function () {
        var totalWidth = 0;
        if (!this.columnResize.colGroupMain) {
            return;
        }
        var colElements = this.columnResize.colGroupMain.children;
        for (var i = 0; i < colElements.length; i++) {
            var width = colElements[i].width;
            if (!width) {
                return;
            }
            totalWidth += parseFloat(width.toString());
        }
        totalWidth = Math.round(totalWidth);
        if (this._header) {
            this._header.setWidth(totalWidth);
        }
        if (this._footer) {
            this._footer.setWidth(totalWidth);
        }
        if (this.vs.table) {
            this.vs.table.style.width = totalWidth + 'px';
        }
    };
    Grid.prototype.initColumns = function (columnElements, groupCount) {
        var _this = this;
        var idPrefix = navigationTools.getIdPrefix(this.navigationStateRef);
        this._columns = readColumns(columnElements, this.columns, { prevId: 0, idPrefix: idPrefix });
        if (this._columns.length === 0) {
            this._columns = autoGenerateColumns(this.props.data, this.props.group, this.props.expandField, { prevId: 0, idPrefix: idPrefix });
        }
        if (this.props.selectedField) {
            this._columns.filter(function (c) { return c.field === _this.props.selectedField; }).forEach(function (c) {
                c.width = c.width || '50px';
                c.cell = c.cell || GridSelectionCell;
                c.headerCell = c.headerCell || GridHeaderSelectionCell;
            });
        }
        var defaultServiceProps = {
            id: '',
            resizable: true,
            width: '32px',
            title: ' ',
            declarationIndex: -1,
            orderIndex: -1,
            children: [],
            parentIndex: -1,
            depth: 0,
            colSpan: 0,
            rowSpan: 0,
            left: 0,
            right: 0,
            index: 0,
            rightBorder: false,
            ariaColumnIndex: 0,
            isAccessible: true
        };
        var columnIndexOffset = 0;
        if (this.props.expandField && (this.props.onExpandChange) && this.props.detail) {
            this._columns.unshift(__assign(__assign({}, defaultServiceProps), { cell: GridHierarchyCell, field: this.props.expandField, headerClassName: 'k-hierarchy-cell k-header', id: navigationTools.generateNavigatableId("".concat(this._columns.length), idPrefix, 'column') }));
            columnIndexOffset++;
        }
        for (var i = 0; i < groupCount; i++) {
            this._columns.unshift(__assign(__assign({}, defaultServiceProps), { isAccessible: false, cell: GridGroupCell, field: 'value' }));
            columnIndexOffset++;
        }
        this._columns.slice(columnIndexOffset).forEach(function (c) { return c.parentIndex >= 0 && (c.parentIndex += columnIndexOffset); });
        this._columnsMap = mapColumns(this._columns);
        this.columnResize.columns = this._columns;
        this.dragLogic.columns = this._columns;
    };
    Grid.prototype.getDataState = function () {
        return {
            filter: this.props.filter,
            sort: this.props.sort,
            skip: this.props.skip,
            take: (this.props.take !== undefined ? this.props.take : this.props.pageSize),
            group: this.props.group
        };
    };
    Grid.prototype.getArguments = function (syntheticEvent) {
        return {
            nativeEvent: syntheticEvent && syntheticEvent.nativeEvent,
            syntheticEvent: syntheticEvent,
            target: this
        };
    };
    /**
     * @hidden
     */
    Grid.displayName = 'KendoReactGrid';
    /**
     * @hidden
     */
    Grid.defaultProps = {
    // never use this
    };
    /**
     * @hidden
     */
    Grid.propTypes = {
        data: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.shape({
                data: PropTypes.array,
                total: PropTypes.number
            })
        ]),
        sortable: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.shape({
                mode: PropTypes.oneOf(['single', 'multiple']),
                allowUnsort: PropTypes.bool
            })
        ]),
        onSortChange: PropTypes.func,
        sort: PropTypes.array,
        filterable: PropTypes.bool,
        filter: PropTypes.any,
        onFilterChange: PropTypes.func,
        pageable: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.shape({
                buttonCount: PropTypes.number,
                info: PropTypes.bool,
                type: PropTypes.oneOf(['numeric', 'input']),
                pageSizes: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.number)]),
                previousNext: PropTypes.bool
            })
        ]),
        pageSize: PropTypes.number,
        onPageChange: PropTypes.func,
        total: PropTypes.number,
        skip: PropTypes.number,
        take: PropTypes.number,
        fixedScroll: PropTypes.bool,
        onExpandChange: PropTypes.func,
        expandField: PropTypes.string,
        selectedField: PropTypes.string,
        onSelectionChange: PropTypes.func,
        onHeaderSelectionChange: PropTypes.func,
        resizable: PropTypes.bool,
        reorderable: PropTypes.bool,
        group: PropTypes.any,
        groupable: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.shape({
                enabled: PropTypes.bool,
                footer: PropTypes.oneOf(['always', 'visible', 'none'])
            })
        ]),
        onGroupChange: PropTypes.func,
        onRowClick: PropTypes.func,
        onRowDoubleClick: PropTypes.func,
        onItemChange: PropTypes.func,
        editField: PropTypes.string,
        scrollable: PropTypes.oneOf(['none', 'scrollable', 'virtual']),
        rowHeight: PropTypes.number,
        detail: PropTypes.any,
        style: PropTypes.object,
        onDataStateChange: PropTypes.func,
        onColumnResize: PropTypes.func,
        onColumnReorder: PropTypes.func,
        dataItemKey: PropTypes.string,
        navigatable: PropTypes.bool
    };
    /** @hidden */
    Grid.contextType = TableKeyboardNavigationContext;
    return Grid;
}(React.Component));
export { Grid };
