import * as React from 'react';
import * as PropTypes from 'prop-types';
import { PagerNumericButtons } from './PagerNumericButtons';
import { PagerInput } from './PagerInput';
import { PagerPageSizes } from './PagerPageSizes';
import { messages, pagerFirstPage, pagerLastPage, pagerNextPage, pagerPreviousPage, pagerInfo, pagerAriaKeyshortcuts, pagerAriaLabel } from '../messages';
import { registerForIntl, registerForLocalization, provideLocalizationService, provideIntlService } from '../../../../kendo-react-intl';
import { classNames, Icon } from '../../../../kendo-react-common';
import { validatePackage } from '../../../../kendo-react-common';
import { packageMetadata } from '../package-metadata';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * @hidden
 */
var RESPONSIVE_BREAKPOINT_MEDIUM = 600;
/**
 * @hidden
 */
var RESPONSIVE_BREAKPOINT_LARGE = 768;
var Pager = /** @class */ (function (_super) {
    __extends(Pager, _super);
    function Pager(props) {
        var _this = _super.call(this, props) || this;
        _this._element = null;
        _this.onPageChange = function (eventData, syntheticEvent) {
            var event = __assign({ target: _this, syntheticEvent: syntheticEvent, nativeEvent: syntheticEvent.nativeEvent }, eventData);
            if (_this.props.onPageChange) {
                _this.props.onPageChange.call(undefined, event);
            }
        };
        _this.changePage = function (page, event) {
            if (_this.props.onPageChange && page > 0 && page <= _this.totalPages) {
                _this.props.onPageChange({
                    target: _this,
                    skip: (page - 1) * _this.props.take,
                    take: _this.props.take,
                    syntheticEvent: event,
                    nativeEvent: event.nativeEvent
                });
            }
        };
        _this.onWindowResize = function () {
            var element = _this.element;
            if (!element || !_this.props.responsive) {
                return;
            }
            var width = element.offsetWidth;
            if (width < RESPONSIVE_BREAKPOINT_MEDIUM) {
                element.classList.remove('k-pager-md');
                element.classList.add('k-pager-sm');
            }
            else if (width >= RESPONSIVE_BREAKPOINT_MEDIUM && width < RESPONSIVE_BREAKPOINT_LARGE) {
                element.classList.add('k-pager-md');
                element.classList.remove('k-pager-sm');
            }
            else {
                element.classList.remove('k-pager-md');
                element.classList.remove('k-pager-sm');
            }
        };
        validatePackage(packageMetadata);
        return _this;
    }
    Object.defineProperty(Pager.prototype, "element", {
        /**
         * Returns the HTML element of the Pager component.
         */
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    Pager.prototype.componentDidMount = function () {
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    };
    /**
     * @hidden
     */
    Pager.prototype.componentWillUnmount = function () {
        window.removeEventListener('resize', this.onWindowResize);
    };
    /**
     * @hidden
     */
    Pager.prototype.render = function () {
        var _this = this;
        var _a = this.props, skip = _a.skip, take = _a.take, total = _a.total, pageSizes = _a.pageSizes, messagesMap = _a.messagesMap;
        var localizationService = provideLocalizationService(this);
        var intlService = provideIntlService(this);
        var currentPage = Math.floor(skip / take) + 1;
        var changer = this.props.type === 'numeric' ?
            (React.createElement(PagerNumericButtons, { buttonCount: this.props.buttonCount || 0, totalPages: this.totalPages, currentPage: currentPage, pageChange: this.changePage, messagesMap: this.props.messagesMap })) : (React.createElement(PagerInput, { currentPage: currentPage, totalPages: this.totalPages, pageChange: this.changePage, messagesMap: messagesMap }));
        var firstPageMessage = messagesMap ? messagesMap(pagerFirstPage) :
            ({ messageKey: pagerFirstPage, defaultMessage: messages[pagerFirstPage] });
        var previousPageMessage = messagesMap ? messagesMap(pagerPreviousPage) :
            ({ messageKey: pagerPreviousPage, defaultMessage: messages[pagerPreviousPage] });
        var nextPageMessage = messagesMap ? messagesMap(pagerNextPage) :
            ({ messageKey: pagerNextPage, defaultMessage: messages[pagerNextPage] });
        var lastPageMessage = messagesMap ? messagesMap(pagerLastPage) :
            ({ messageKey: pagerLastPage, defaultMessage: messages[pagerLastPage] });
        var infoMessage = messagesMap ? messagesMap(pagerInfo) :
            ({ messageKey: pagerInfo, defaultMessage: messages[pagerInfo] });
        var ariaKeyshortcutsMessage = messagesMap ? messagesMap(pagerAriaKeyshortcuts) :
            ({ messageKey: pagerAriaKeyshortcuts, defaultMessage: messages[pagerAriaKeyshortcuts] });
        var ariaLabelMessage = messagesMap ? messagesMap(pagerAriaLabel) :
            ({ messageKey: pagerAriaLabel, defaultMessage: messages[pagerAriaLabel] });
        var first, prev, next, last;
        if (this.props.previousNext) {
            first = this.renderButton(1, 'k-link k-pager-nav k-pager-first' + (currentPage === 1 ? ' k-disabled' : ''), localizationService.toLanguageString(firstPageMessage.messageKey, firstPageMessage.defaultMessage), 'caret-alt-to-left', (currentPage === 1 ? true : undefined));
            prev = this.renderButton(currentPage - 1, 'k-link k-pager-nav' + (currentPage === 1 ? ' k-disabled' : ''), localizationService.toLanguageString(previousPageMessage.messageKey, previousPageMessage.defaultMessage), 'caret-alt-left', (currentPage === 1 ? true : undefined));
            next = this.renderButton(currentPage + 1, 'k-link k-pager-nav' + (currentPage >= this.totalPages ? ' k-disabled' : ''), localizationService.toLanguageString(nextPageMessage.messageKey, nextPageMessage.defaultMessage), 'caret-alt-right', (currentPage >= this.totalPages ? true : undefined));
            last = this.renderButton(this.totalPages, 'k-link k-pager-nav k-pager-last' + (currentPage >= this.totalPages ? ' k-disabled' : ''), localizationService.toLanguageString(lastPageMessage.messageKey, lastPageMessage.defaultMessage), 'caret-alt-to-right', (currentPage >= this.totalPages ? true : undefined));
        }
        var pagerPageSizes = pageSizes && (React.createElement(PagerPageSizes, { pageChange: this.onPageChange, pageSize: take, pageSizes: pageSizes, messagesMap: messagesMap }));
        var info = this.props.info && (React.createElement("div", { className: "k-pager-info k-label" }, intlService
            .format(localizationService.toLanguageString(infoMessage.messageKey, infoMessage.defaultMessage), [
            Math.min(skip + 1, total),
            Math.min(skip + take, total),
            total
        ])));
        return (React.createElement("div", { className: classNames('k-pager k-widget', this.props.className), style: this.props.style, role: "application", "aria-roledescription": "pager", "aria-keyshortcuts": localizationService.toLanguageString(ariaKeyshortcutsMessage.messageKey, ariaKeyshortcutsMessage.defaultMessage), "aria-label": localizationService.toLanguageString(ariaLabelMessage.messageKey, ariaLabelMessage.defaultMessage), ref: function (element) { return _this._element = element; } },
            first,
            prev,
            changer,
            next,
            last,
            pagerPageSizes,
            info));
    };
    Pager.prototype.renderButton = function (page, className, title, icon, isDisabled) {
        var _this = this;
        return (React.createElement("a", { onClick: function (e) {
                e.preventDefault();
                _this.changePage(page, e);
            }, href: "#", className: className, title: title, role: "button", "aria-disabled": isDisabled },
            React.createElement(Icon, { name: icon })));
    };
    Object.defineProperty(Pager.prototype, "totalPages", {
        get: function () {
            return Math.ceil((this.props.total || 0) / this.props.take);
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    Pager.propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
        total: PropTypes.number.isRequired,
        skip: PropTypes.number.isRequired,
        take: PropTypes.number.isRequired,
        buttonCount: PropTypes.number,
        info: PropTypes.bool,
        type: PropTypes.oneOf(['numeric', 'input']),
        pageSizes: PropTypes.arrayOf(PropTypes.number),
        previousNext: PropTypes.bool,
        onPageChange: PropTypes.func,
        messagesMap: PropTypes.func
    };
    /**
     * @hidden
     */
    Pager.defaultProps = {
        buttonCount: 10,
        info: true,
        type: 'numeric'
    };
    return Pager;
}(React.Component));
export { Pager };
registerForIntl(Pager);
registerForLocalization(Pager);
