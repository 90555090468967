import React, { useMemo, useState } from 'react';
import {
  RowActions,
} from "./widget/RowActions";
import { Box } from '@mui/material';
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { UpdateCreditCardDataDialog, UpdateCreditCardDataDialogPayload } from './widget/UpdateCreditCardDataDialog';

export const resource = "admin/generic-views/credit_cards";

const tableColumns = [
  { field: "id", width: "120px", title: "card id", show: true },
  { field: "created_at", width: "120px", title: "created at", show: true, format: "yyyy-MM-dd" },
  { field: "name", width: "120px", title: "name", show: true },
  { field: "bank_id", width: "100px", title: "bank id", show: true },
  { field: "methodfi_account_id", width: "150px", title: "methodfi_account_id", show: true, },
  { field: "mask", width: "150px", title: "mask", show: true },
  { field: "plaid_account_id", width: "150px", title: "plaid account id", show: true },
  { field: "is_single_bill_active", width: "150px", title: "is single bill active", show: true },
  { field: "apr", width: "150px", title: "apr", show: true },
  { field: "current_balance", width: "150px", title: "current balance", show: true, isCents: true },
  { field: "statement_balance", width: "150px", title: "statement balance", show: true, isCents: true },
  { field: "current_utilization", width: "150px", title: "current utilization", show: false, },
  { field: "limit", width: "150px", title: "limit", show: true, isCents: true },
  { field: "next_minimum_payment", width: "150px", title: "next minimum payment", show: false, },
  { field: "next_payment_due_date", width: "150px", title: "next payment due date", show: false, },
  { field: "placid_balance", width: "150px", title: "placid_balance", show: true, isCents: true },
  { field: "plaid_token_id", width: "150px", title: "plaid token id", show: false, },
  { field: "is_pay_off_plan_active", width: "150px", title: "is pay off plan active", show: false, },
  { field: "last_statement_issued_at", width: "150px", title: "last_statement_issued_at", show: false, },
  { field: "plaid_purchase_apr", width: "150px", title: "plaid_purchase_apr", show: false, },
  { field: "plaid_cash_apr", width: "150px", title: "plaid_cash_apr", show: false, },
  { field: "plaid_balance_transfer_apr", width: "150px", title: "plaid_balance_transfer_apr", show: false, },
  { field: "plaid_special_apr", width: "150px", title: "plaid_special_apr", show: false, },
  { field: "payment_network", width: "150px", title: "payment_network", show: false, },
  { field: "last_statement_id", width: "150px", title: "last_statement_id", show: false, },
  { field: "estimated_due_date_each_month", width: "150px", title: "estimated_due_date_each_month", show: false, },
  { field: "card_type", width: "150px", title: "card_type", show: false, },
  { field: "sys_period", width: "150px", title: "sys_period", show: false, },
  { field: "comment", width: "150px", title: "comment", show: false, },
  { field: "status", width: "150px", title: "status", show: true, },
  { field: "is_bt_auto_payoff_active", width: "150px", title: "is_bt_auto_payoff_active", show: false, },
  { field: "primary_data_aggregator", width: "150px", title: "primary_data_aggregator", show: false, },
  { field: "payment_provider", width: "150px", title: "payment_provider", show: false, },
  { field: "yodlee_provider_id", width: "150px", title: "yodlee_provider_id", show: false, },
  { field: "yodlee_account_id", width: "150px", title: "yodlee_account_id", show: false, },
  { field: "user_apr", width: "150px", title: "user_apr", show: false, },
  { field: "user_limit", width: "150px", title: "user_limit", show: false, isCents: true },
  { field: "user_label", width: "150px", title: "user_label", show: true, },
  { field: "methodfi_account_status", width: "150px", title: "methodfi_account_status", show: true, },

  { field: "actions", width: "140px", title: "actions", show: true },
]

export const CreditCardsCollection: React.FC<Props> = ({ initialFilters }) => {
  const [updateCreditCardDialogPayload, setUpdateCreditCardDialogPayload] = useState<UpdateCreditCardDataDialogPayload>(undefined);

  const updateCreditCardDialogOpenHandler = (record: { [index: string]: any }): void => {
    const { user_id: userId, user_apr: apr, user_limit: limit, id } = record;
    setUpdateCreditCardDialogPayload({
      id,
      userId,
      apr,
      limit,
    })
  }

  const rowActionBuilder = (record: { [index: string]: any }) => (
    <RowActions
      record={record}
      onUpdateCardDialogOpen={updateCreditCardDialogOpenHandler}
    />
  )

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Box sx={{ position: "relative" }}>

      <UpdateCreditCardDataDialog
        payload={updateCreditCardDialogPayload}
        hideDialog={() => setUpdateCreditCardDialogPayload(undefined)}
      />

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`Credit cards: ${initialFilters?.user_id.eq}`}
        </h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        disableUrlFilterListener={false}
        rowActionBuilder={rowActionBuilder}
        columnModel={columnModel}
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
}
