
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Alert, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { AccountsOverview, DepositoryAccount, DepositoryAccountWrapper } from '../../../types/accountsOverview';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import currency from 'currency.js';

export type OneTimePaymentDialogPayload = {
  accountsOverview: AccountsOverview,
  userId: string,
} | null;

type AccountItem = {
  itemId: string
  accountId: string
  bankName: string
  mask?: string
}

const _selectAccountItems = (accountsOverview: AccountsOverview): Array<AccountItem> => {
  const result: Array<AccountItem> = [];
  for (const { accounts, item } of accountsOverview.depositoryAccount) {
    for (const account of accounts) {
      result.push({
        itemId: item.item_id,
        accountId: account.depositoryAccountPlaidId,
        bankName: account.bankName,
        mask: account.mask,
      });
    }
  }

  return result;
}

export const OneTimePaymentDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { debtActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [amount, setAmount] = useState<string>("");
  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    if (payload !== null) {
      setAmount("")
      setSelectedAccountId(undefined);
    }
    setError(null);
  }, [payload]);

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAmount(value);
  }

  const handleSendClick = async () => {
    if (payload === null) return;

    const accountItems = _selectAccountItems(payload.accountsOverview);
    const selectedItem: Array<AccountItem> = accountItems.filter((item) => item.accountId === selectedAccountId);

    if (selectedItem.length === 0) {
      throw Error("Account not found");
    }

    const { accountId, itemId } = selectedItem[0];

    const error = await debtActions.oneTimePayment(payload.userId, {
      itemId,
      accountId,
      amount: currency(amount).intValue,
    });

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Created");
    }
  }

  const handleAccountSelect = useCallback((e: SelectChangeEvent<string>) => {
    setSelectedAccountId(e.target.value);
  }, [selectedAccountId]);

  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title="Charge now"
      actionTitle='Send'
      onSend={handleSendClick}>

      <TextField
        fullWidth
        type="number"
        label="Transaction amount (Dollars)"
        value={amount}
        onChange={onAmountChange}
        variant="outlined"
      />

      {
        payload !== null &&
        <FormControl fullWidth>
          <InputLabel id="account-select-label">Account</InputLabel>
          <Select
            autoFocus={false}
            onFocus={() => { }}
            labelId="account-select-label"
            id="demo-simple-select"
            value={selectedAccountId}
            label="Method"
            onChange={handleAccountSelect}>
            {
              _selectAccountItems(payload.accountsOverview).map(({ accountId, mask, bankName }: AccountItem) =>
                <MenuItem value={accountId}>{bankName} - *{mask}</MenuItem>
              )
            }
          </Select>
        </FormControl>
      }


      {error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload: OneTimePaymentDialogPayload;
  hideDialog: () => void;
}