import { Pagination } from 'react-admin'

interface IPaginationProps {
  perPage: number[]
}

export const CustomPagination = (props: IPaginationProps) => {
  return <Pagination 
    rowsPerPageOptions={props.perPage} />
}
