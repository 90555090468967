import React, { useCallback, useEffect, useMemo } from 'react';
import {
  DateInput,
  FilterButton,
  NumberInput,
  SelectInput,
  TextInput,
  useNotify,
} from "react-admin"
import {
  RowActions,
} from "./widget/RowActions";
// import { RowClickEvent } from "../../common/Table";
import { ColumnActions } from "../../common/ColumnActions";
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { RowClickEvent } from '../../common/Table';
import { request } from 'https';

export const resource = "admin/generic-views/admin_pending_executions";

const tableColumns = [
  { field: "id", width: "120px", title: "user_id", show: true },
  { field: "created_at", width: "120px", title: "created at", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "execution_status", width: "120px", title: "execution_status", show: true },
  { field: "endpoint", width: "250px", title: "endpoint", show: true },
  { field: "admin_id", width: "120px", title: "admin id", show: true, },
  { field: "request", width: "300px", title: "request", show: true },

  { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="id" source="id.eq" alwaysOn />,

  <TextInput label="admin id" source="admin_id.eq" alwaysOn />,

  <DateInput label="created_at gt" source="create_at.gt" />,
  <DateInput label="crated_at lt" source="created_at.lt" />,

  <SelectInput source="execution_status" choices={[
    { id: 'PENDING', name: 'PENDING' },
    { id: 'APPROVED', name: 'APPROVED' },
  ]} />,
];


export const AdminPendingExecutions: React.FC<Props> = (props) => {
  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />
  const notify = useNotify();

  const handleRowClick = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    const { id } = dataItem;

    if (id === undefined) {
      notify("Error: user id not provided");
      return;
    }

    const uri = `/dash/underwriting-user/${id}/`;

    if (nativeEvent.shiftKey || nativeEvent.metaKey) {
      window.open(uri, '_blank', 'toolbar=yes, location=yes, status=yes, menubar=yes, scrollbars=yes');
    } else {
      window.history.pushState('', '', uri);
    }
  }

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, []);

  const customValueFormat = {
    "request": (request?: any) => {
      return JSON.stringify(request);
    }
  }

  return (
    <Resource
      resource={resource}
      filters={filters}
      disableUrlFilterListener={false}
      rowActionBuilder={rowActionBuilder}
      // onRowClick={handleRowClick}
      columnModel={columnModel}
      tableActions={
        <>
          <FilterButton {...props} />
        </>
      }
      rowPerPageOptions={[25, 50, 100]}
      customValueFormat={customValueFormat}
    />
  )
}

type Props = {
  initialFilters?: { [index: string]: string }
  onRowClick?: (userId: number) => void
}