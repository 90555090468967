
import React, { FC, useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';

export type UpdateAddressDialogPayload = {
  record: { [index: string]: any }
} | null;

export const UpdateAddressDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { clientActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [line1, setLine1] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [state, setState] = useState<string>("");

  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    if (payload !== undefined) {
      setLine1(payload?.record['line_1'] ?? "")
      setCity(payload?.record['city'] ?? "")
      setZip(payload?.record['zip'] ?? "")
      setState(payload?.record['state'] ?? "")
    }

    setError(null);
  }, [payload]);

  const onLine1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLine1(value);
  }

  const onCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCity(value);
  }

  const onZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setZip(value);
  }

  const onStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setState(value);
  }

  const handleSendClick = async () => {
    if (payload === null) return;

    const { id } = payload?.record ?? {};

    const error = await clientActions.updatePersonalInfo({
      userId: id,
      payload: {
        income: {},
        address: {
          line1,
          state,
          zipCode: zip,
          city,
        },
      }
    });

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Updated");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Update address"
      actionTitle='Update'
      onSend={handleSendClick}>

      <TextField
        fullWidth
        type="text"
        label="Line1"
        value={line1}
        onChange={onLine1Change}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="text"
        label="City"
        value={city}
        onChange={onCityChange}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="text"
        label="zip"
        value={zip}
        onChange={onZipChange}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="text"
        label="state"
        value={state}
        onChange={onStateChange}
        variant="outlined"
      />

      {error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload?: UpdateAddressDialogPayload;
  hideDialog: () => void;
}