import * as React from 'react';
import { Menu } from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccessTime from '@mui/icons-material/AccessTime';
import CardMembership from '@mui/icons-material/CardMembership';
import SettingsInputAntenna from '@mui/icons-material/SettingsInputAntenna';
import PeopleIcon from '@mui/icons-material/People';
import SubscriptionIcon from '@mui/icons-material/SubscriptionsRounded';
import CollectionsIcon from '@mui/icons-material/Collections';
// import CollectorsIcon from '@mui/icons-material/CleanHands';
import CollectorsIcon from '@mui/icons-material/MonetizationOn';
import { Link } from 'wouter';
import { Box } from '@mui/material';

const ItemLink: React.FC<{
  to: string,
  label: string,
  icon: React.ReactElement
}> = (props) => {
  const { to, icon, label } = props;

  return (
    <Link style={{ display: "flex", alignItems: "center", margin: "8px 0" }} to={to}>
      <span style={{ margin: "0 10px" }}>{icon}</span> {label}
    </Link>
  )
}

export const AdminMenu: React.FC = () => {
  return (
    <Box p={1} >
      <Menu>
        <ItemLink to={"/dash/debt/"} label="Collections" icon={<CollectorsIcon />} />
        {/* <ItemLink to={"/dash/collections/"} label="Collections" icon={<CollectorsIcon />} /> */}
        <ItemLink to={"/dash/underwriting/"} label="Underwriting" icon={<SettingsInputAntenna />} />
        <ItemLink to={"/dash/clients/"} label="Clients" icon={<PersonIcon />} />
        <ItemLink to={"/dash/bt-payments/"} label="BT&nbsp;Payments" icon={<PaymentIcon />} />
        <ItemLink to={"/dash/sb-payments/"} label="SB&nbsp;Payments" icon={<ReceiptIcon />} />
        <ItemLink to={"/dash/executions/"} label="Executions" icon={<PeopleIcon />} />
        <ItemLink to={"/dash/underwriting-monitoring"} label="Underwriting&nbsp;Monitoring" icon={<AccessTime />} />
        <ItemLink to={"/dash/membership-payments"} label="Membership&nbsp;payments" icon={<CardMembership />} />
        <ItemLink to={"/dash/subscriptions"} label="Subscriptions" icon={<SubscriptionIcon />} />
      </Menu>
    </Box>
  );
} 
