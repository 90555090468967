import { Box, FormControlLabel, Popover, Switch } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
} from "react-admin"
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import { Column, ColumnModel } from "../model/ColumnModel";
import { observer } from "mobx-react";

export const ToggleColumnButton: React.FC<Props> = observer((props) => {
  const { columnModel } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchChange = (
    event: React.ChangeEvent, 
    isChecked: boolean, 
    column: Column
  ) => {
    event.preventDefault();
    columnModel.updateShow(column.field, isChecked);
  }

  return (
    <Box style={{ position: "relative" }}>

      <Button onClick={handleClick} startIcon={<ViewWeekIcon />} label="columns" />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Box p={1}>
          {columnModel.columns.map((column) => (
            <Box key={column.field}>
              <FormControlLabel
                control={
                  <Switch
                    checked={column.show}
                    onChange={(e, ch) => handleSwitchChange(e, ch, column)}
                    size="small" />
                }
                label={column.field}
              />
            </Box>
          ))}
        </Box>

      </Popover>
    </Box>
  );
});

type Props = {
  columnModel: ColumnModel
}