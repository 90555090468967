import React from 'react';
import { Button, FilterButton, TopToolbar, useRefresh } from 'react-admin';
import { ColumnModel } from '../model/ColumnModel';
import { ToggleColumnButton } from './ToggleColumnButton';

export const ColumnActions: React.FC<Props> = (props) => {
  const { children, resource } = props;

  const refresh = useRefresh();

  const handleResetClick = () => {
    localStorage.removeItem(`column.${resource}`);
    localStorage.removeItem(`sorts.${resource}`);
    window.location.reload();
  }

  return (
    <TopToolbar>
      {children}
      <ToggleColumnButton {...props} />
      <Button label='Reset' onClick={handleResetClick} />
    </TopToolbar>
  );
}

type Props = {
  columnModel: ColumnModel
  resource: string
}
