
import * as React from 'react';
import { PopupWithoutContext } from './PopupWithoutContext';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * The PopupPropsContext. It allows to configure the Popup props from a wrapper component.
 *
 * @example
 * ```jsx-no-run
 *  <PopupPropsContext.Provider value={props => ({ ...props, appendTo: document.querySelector('myPopupsContainer') })}>
 *      <DropDownList />
 *      <Editor />
 *  </PopupPropsContext.Provider>
 * ```
 */
export var PopupPropsContext = React.createContext(function (props) { return props; });
/**
 * The KendoReact Popup component.
 */
export var Popup = React.forwardRef(function (props, ref) {
    var contextPropsCallback = React.useContext(PopupPropsContext);
    var popupProps = contextPropsCallback.call(undefined, props);
    return (React.createElement(PopupWithoutContext, __assign({ ref: ref }, popupProps)));
});
Popup.displayName = 'Popup';
