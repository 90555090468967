
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames } from '../../../../kendo-react-common';
import { RowHeightService, ScrollerService } from './services';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _a, _b, _c;
/**
 * @hidden
 */
export var ScrollDirection;
(function (ScrollDirection) {
    ScrollDirection[ScrollDirection["Backward"] = 0] = "Backward";
    ScrollDirection[ScrollDirection["Forward"] = 1] = "Forward";
})(ScrollDirection || (ScrollDirection = {}));
var differenceToScroll = function (scrollTop, staticOffset, maxScrollDifference) {
    return Math.min(Math.abs(staticOffset - scrollTop), maxScrollDifference);
};
var FRAME_DURATION = 17;
var scrollModifiers = (_a = {},
    _a[ScrollDirection.Forward] = function (step) { return function (value) { return value + step; }; },
    _a[ScrollDirection.Backward] = function (step) { return function (value) { return value - step; }; },
    _a);
var scrollNormalizers = (_b = {},
    _b[ScrollDirection.Forward] = function (end) { return function (value) { return Math.min(value, end); }; },
    _b[ScrollDirection.Backward] = function (end) { return function (value) { return Math.max(value, end); }; },
    _b);
var scrollValidators = (_c = {},
    _c[ScrollDirection.Forward] = function (end) { return function (start) { return start < end; }; },
    _c[ScrollDirection.Backward] = function (end) { return function (start) { return start > end; }; },
    _c);
/**
 * @hidden
 */
var Virtualization = /** @class */ (function (_super) {
    __extends(Virtualization, _super);
    function Virtualization(props) {
        var _this = _super.call(this, props) || this;
        _this.rowHeightService = null;
        _this.scrollContainer = null;
        _this.lastDirection = null;
        _this.lastTotal = 0;
        _this.lastTake = 0;
        _this.animationInProgress = false;
        _this.scrollToIndexTimeout = 0;
        _this.scrollTo = function (value) {
            var scrollProperty = _this.direction === 'vertical' ? 'scrollTop' : 'scrollLeft';
            if (!_this.scrollContainer) {
                return;
            }
            _this.scrollContainer[scrollProperty] = value;
        };
        _this.scrollToIndex = function (index) {
            if (_this.scrollToIndexTimeout !== undefined) {
                window.cancelAnimationFrame(_this.scrollToIndexTimeout);
            }
            if (!_this.rowHeightService) {
                return;
            }
            _this.scrollToIndexTimeout = window.requestAnimationFrame(function () {
                _this.animationInProgress = false;
                if (_this.rowHeightService) {
                    _this.scrollTo(_this.rowHeightService.offset(index));
                }
            });
        };
        _this.animateToIndex = function (index) {
            if (!_this.rowHeightService || !window) {
                return;
            }
            window.cancelAnimationFrame(_this.cancelAnimation);
            var indexOffset = _this.rowHeightService.offset(index);
            var direction = _this.getContainerScrollDirection(indexOffset);
            var _a = _this.scrollRange(indexOffset, direction), start = _a.start, end = _a.end;
            if (start === end) {
                return;
            }
            var step = _this.scrollStep(start, end);
            var modifyScroll = scrollModifiers[direction](step);
            var normalizeScroll = scrollNormalizers[direction](end);
            var isScrollValid = scrollValidators[direction](modifyScroll(end));
            var animate = function (progress) {
                _this.animationInProgress = true;
                var next = modifyScroll(progress);
                _this.scrollTo(normalizeScroll(next));
                isScrollValid(next)
                    ? _this.cancelAnimation = window.requestAnimationFrame(function () { animate(next); })
                    : _this.animationInProgress = false;
            };
            _this.cancelAnimation = window.requestAnimationFrame(function () { animate(start); });
        };
        _this.scrollToBottom = function () {
            if (!_this.rowHeightService) {
                return;
            }
            _this.scrollTo(_this.rowHeightService.totalHeight() + _this.props.bottomOffset);
        };
        _this.scrollStep = function (start, end) {
            var duration = _this.props.scrollDuration || Virtualization.defaultProps.scrollDuration;
            return Math.abs(end - start) / (duration / FRAME_DURATION);
        };
        _this.scrollRange = function (indexOffset, direction) {
            var containerScroll = _this.containerScrollPosition;
            if (parseInt("".concat(indexOffset), 10) === parseInt("".concat(containerScroll), 10)) {
                return { start: indexOffset, end: indexOffset };
            }
            var maxScroll = _this.containerMaxScroll();
            var sign = direction === ScrollDirection.Backward ? 1 : -1;
            var difference = differenceToScroll(containerScroll, indexOffset, _this.props.maxScrollDifference || 0);
            var end = Math.min(indexOffset, maxScroll);
            var start = Math.min(Math.max(end + (sign * difference), 0), maxScroll);
            return { start: start, end: end };
        };
        _this.containerMaxScroll = function () {
            return _this.containerScrollSize - _this.containerOffsetSize;
        };
        _this.getContainerScrollDirection = function (indexOffset) {
            return indexOffset < _this.containerScrollPosition ? ScrollDirection.Backward : ScrollDirection.Forward;
        };
        _this.initServices = function (props) {
            if (props === void 0) { props = _this.props; }
            var dimension = _this.direction === 'vertical' ? props.itemHeight : props.itemWidth;
            if (dimension === undefined) {
                return;
            }
            _this.rowHeightService = new RowHeightService(props.total, dimension, 0);
            _this.scrollerService.create(_this.rowHeightService, props.skip, props.take, props.total, props.topOffset, _this.scrollOffsetSize, _this.direction);
        };
        _this.getContainerProperty = function (propertyName) {
            if (!_this.scrollContainer) {
                return 0;
            }
            return _this.scrollContainer[propertyName];
        };
        _this.handleScroll = function (event) {
            if (!_this.scrollContainer || !_this.rowHeightService) {
                return;
            }
            var target = event.target;
            _this.scrollerService.onScroll({
                scrollLeft: target.scrollLeft,
                scrollTop: target.scrollTop,
                offsetHeight: target.offsetHeight,
                offsetWidth: target.offsetWidth
            });
            var index = _this.rowHeightService.index(_this.containerScrollPosition - _this.props.topOffset);
            var onScrollAction = _this.props.onScrollAction;
            var args = {
                index: index,
                target: target,
                scrollAction: _this.scrollAction,
                pageAction: _this.pageAction,
                animationInProgress: _this.animationInProgress
            };
            if (_this.props.onScroll) {
                _this.props.onScroll.call(undefined, event);
            }
            if (onScrollAction) {
                onScrollAction.call(undefined, args);
            }
            _this.scrollAction = undefined;
            _this.pageAction = undefined;
        };
        _this.handleScrollAction = function (action) {
            _this.scrollAction = action;
        };
        _this.handlePageAction = function (action) {
            _this.pageAction = action;
        };
        _this.scrollerService = new ScrollerService(_this.handleScrollAction, _this.handlePageAction);
        return _this;
    }
    Object.defineProperty(Virtualization.prototype, "element", {
        get: function () {
            return this.scrollContainer;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Virtualization.prototype, "containerOffsetSize", {
        get: function () {
            return this.getContainerProperty(this.direction === 'vertical' ? 'offsetHeight' : 'offsetWidth');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Virtualization.prototype, "containerScrollSize", {
        get: function () {
            return this.getContainerProperty(this.direction === 'vertical' ? 'scrollHeight' : 'scrollWidth');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Virtualization.prototype, "containerScrollPosition", {
        get: function () {
            return this.getContainerProperty(this.direction === 'vertical' ? 'scrollTop' : 'scrollLeft');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Virtualization.prototype, "direction", {
        get: function () {
            return this.props.direction !== undefined ? this.props.direction : Virtualization.defaultProps.direction;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Virtualization.prototype, "scrollOffsetSize", {
        get: function () {
            return this.props.scrollOffsetSize !== undefined
                ? this.props.scrollOffsetSize : Virtualization.defaultProps.scrollOffsetSize;
        },
        enumerable: false,
        configurable: true
    });
    Virtualization.prototype.activeIndex = function () {
        return this.itemIndex(Math.ceil(this.containerScrollPosition));
    };
    Virtualization.prototype.itemIndex = function (offset) {
        if (!this.rowHeightService) {
            return 0;
        }
        return this.rowHeightService.index(offset);
    };
    Virtualization.prototype.itemOffset = function (index) {
        if (!this.rowHeightService) {
            return 0;
        }
        return this.rowHeightService.offset(index);
    };
    Virtualization.prototype.isIndexVisible = function (index) {
        if (!this.rowHeightService) {
            return false;
        }
        var containerTop = this.containerScrollPosition;
        var containerBottom = containerTop + this.containerOffsetSize;
        var top = this.rowHeightService.offset(index);
        var bottom = top + this.rowHeightService.height(index);
        return top >= containerTop && bottom <= containerBottom;
    };
    Virtualization.prototype.isListScrolled = function (index) {
        if (!this.rowHeightService) {
            return false;
        }
        return this.containerScrollPosition !== this.rowHeightService.offset(index);
    };
    Virtualization.prototype.componentDidMount = function () {
        var onMount = this.props.onMount;
        if (onMount) {
            onMount.call(undefined, this);
        }
    };
    Virtualization.prototype.render = function () {
        var _this = this;
        if (this.lastTotal !== this.props.total
            || this.lastDirection !== this.direction
            || this.lastTake !== this.props.take) {
            this.initServices();
            this.lastTotal = this.props.total;
            this.lastDirection = this.direction;
            this.lastTake = this.props.take;
        }
        var vertexLength = "".concat((this.rowHeightService ? this.rowHeightService.totalHeight() : 0) + this.props.bottomOffset);
        var placeholderStyle = this.direction === 'vertical'
            ? { height: "".concat(vertexLength, "px") }
            : { width: "".concat(vertexLength, "px") };
        var rootClassNames = classNames('k-content k-calendar-content k-scrollable', {
            'k-scrollable-horizontal': this.direction === 'horizontal'
        }, this.props.className);
        var scrollableClassNames = classNames('k-scrollable-placeholder', {
            'k-scrollable-horizontal-placeholder': this.direction === 'horizontal'
        });
        return (React.createElement("div", { ref: function (el) { _this.scrollContainer = el; }, onScroll: this.handleScroll, className: rootClassNames, tabIndex: this.props.tabIndex, role: this.props.role },
            this.props.children,
            React.createElement("div", { style: placeholderStyle, className: scrollableClassNames })));
    };
    Virtualization.propTypes = {
        bottomOffset: PropTypes.number.isRequired,
        className: PropTypes.string,
        direction: PropTypes.oneOf(['horizontal', 'vertical']),
        forceScroll: PropTypes.bool,
        itemHeight: PropTypes.number,
        itemWidth: PropTypes.number,
        maxScrollDifference: PropTypes.number,
        onScroll: PropTypes.func,
        onScrollAction: PropTypes.func,
        scrollDuration: PropTypes.number,
        scrollOffsetSize: PropTypes.number,
        skip: PropTypes.number.isRequired,
        tabIndex: PropTypes.number,
        take: PropTypes.number.isRequired,
        topOffset: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        role: PropTypes.string
    };
    Virtualization.defaultProps = {
        direction: 'vertical',
        forceScroll: false,
        scrollOffsetSize: 0,
        maxScrollDifference: 100,
        scrollDuration: 100
    };
    return Virtualization;
}(React.Component));
export { Virtualization };
