import { Alert, Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { FC, useCallback, useState } from "react";
import { Button } from "react-admin";
import { useInjection } from "../di/useInjection";
import { JSONArray, JSONObject } from "../types/json";


// old values
// "targetProduct": "BalanceTransferLine",
// "product": "Monitoring",
// "forcedEquifaxReportReload": false,
// "forcedExperianReportReload": false,
// "isRejectionLetterEnabled": false
const DEFAULT_DATA = `{
  "flowId": 27
}`;


export const UnderwritingMonitoringForm: FC = () => {
  const [userIds, setUserIds] = useState<string>("");
  const [data, setData] = useState<string>(DEFAULT_DATA);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [results, setResults] = useState<Array<JSONObject>>([]);

  const { underwritingActions } = useInjection();

  const _sendData = async () => {
    setIsLoading(true);
    setResults([]);

    const _userIds = userIds
      .split(',')
      .map((i) => { i.trim(); return i; })
      .filter((i) => i.length != 0)
      .map((i) => parseInt(i));

    const _data = JSON.parse(data);

    const payloads = _userIds.map((userId) => ({
      ..._data, userId,
    }));

    for (const payload of payloads) {
      const response = await underwritingActions.initiateUnderwritingDecisions(payload);
      console.log(response);
      if (response.success) {
        setResults((results) => [...results, { userId: payload.userId, ...response.result }]);
      } else if (response.error.code === "FailedFetch") {
        setResults((results) => [...results, { userId: payload.userId, error: response.error?.message ?? "Failed fetch", }]);
      } else {
        setError(`userId: ${payload.userId} init with error: ${error}`);
        setIsLoading(false);
        return;
      }
    }

    console.log("FINISH");
    console.log(results);

    setIsLoading(false);
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = _validate();
    if (!isValid) return;

    _sendData();
  }

  const _validate = (): boolean => {
    if (userIds.length === 0) {
      setError("no user ids");
      return false;
    }

    if (!/^[0-9,\s]+$/ig.test(userIds)) {
      setError("user ids should consist only digits and commas")
      return false;
    }

    try {
      JSON.parse(data);
    } catch (_) {
      setError("Invalid json");
      return false;
    }

    return true;
  }

  const onUserIdsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setError(null);
    setUserIds(e.target.value);
  }

  const onChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setError(null);
    setData(e.target.value);
  }

  return (
    <form onSubmit={onSubmit}>

      <TextField
        fullWidth
        type="text"
        label="user ids (separated by commas)"
        value={userIds}
        onChange={onUserIdsChange}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="text"
        label="data (json)"
        value={data}
        onChange={onChangeData}
        multiline
        rows={8}
        variant="outlined"
      />

      <Box marginTop={"20px"} marginBottom={"20px"}>
        {
          error !== null &&
          <Alert color={"error"} severity="error">{error}</Alert>
        }
      </Box>

      <Button
        label="send"
        size='small'
        color='secondary'
        role="submit"
        type="submit"
        disabled={isLoading}
      />

      <Box marginTop={"30px"}>
        {
          results.length > 0 && <h3>Results</h3>
        }
      </Box>

      {
        results.map(
          result => <Box>
            <pre>{JSON.stringify(result, null, 2)}</pre>
          </Box>,
        )
      }


    </form>
  );
}