import React, { useMemo, } from 'react';
import { Box } from '@mui/material';
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { DateInput, FilterButton, SelectInput, TextInput } from 'react-admin';
import { DollarInput } from '../../common/DollarInput';

export const resource = "admin/generic-views/cc_payments";

const tableColumns = [
  { field: "id", width: "100px", title: "id", show: true },
  { field: "scheduled_date", width: "150px", title: "scheduled_date", show: true, format: "yyyy-MM-dd" },
  { field: "amount", width: "150px", title: "amount", show: true, isCents: true, },
  { field: "current_status", width: "150px", title: "current_status", show: true },
  { field: "card_name", width: "200px", title: "card_name", show: true },
  { field: "card_mask", width: "200px", title: "card_mask", show: true },
  { field: "bank_name", width: "200px", title: "bank_name", show: true },
  { field: "bank_id", width: "200px", title: "bank_id", show: true },
  { field: "last_seen", width: "200px", title: "last_seen", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "reasons", width: "200px", title: "reasons", show: true, },
  { field: "token_id", width: "200px", title: "token_id", show: true, },
  { field: "card_token_is_expired", width: "200px", title: "card_token_is_expired", show: true, },
  { field: "last_month_income", width: "200px", title: "last_month_income", show: true, isCents: true },
  { field: "card_id", width: "200px", title: "card_id", show: true },

  // { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="id" source="id.eq" alwaysOn />,

  <DateInput label="scheduled_date gt" source="scheduled_date.gt" />,
  <DateInput label="scheduled_date lt" source="scheduled_date.lt" />,

  <DollarInput label="current_balance gt (dollar)" source="current_balance.gt" />,
  <DollarInput label="current_balance lt (dollar)" source="current_balance.lt" />,

  <SelectInput source="current_status" choices={[
    { id: 'onHold', name: 'onHold' },
    { id: 'Canceled', name: 'Canceled' },
    { id: 'Sent', name: 'Sent' },
    { id: 'Scheduled', name: 'Scheduled' },
  ]} />,

  <TextInput label="card_name" source="card_name.eq" />,
  <TextInput label="card_mask" source="card_mask.eq" />,
  <TextInput label="bank_name" source="bank_name.eq" />,
  <TextInput label="bank_id" source="bank_id.eq" />,

  <DateInput label="last seen gt" source="last_seen.gt" />,
  <DateInput label="last_seen lt" source="last_seen.lt" />,

  <TextInput label="reasons" source="reasons.eq" />,

  <SelectInput source="card token is expired" choices={[
    { id: 'true', name: 'Expired' },
    { id: 'false', name: 'Not expired' },
  ]} />,

  <DateInput label="status_updated_at gt" source="status_updated_at.gt" />,
  <DateInput label="status_updated_at lt" source="status_updated_at.lt" />,

  <TextInput label="payment_type" source="payment_type.eq" />,
  <TextInput label="force_send" source="force_send.eq" />,

  <DollarInput label="last month income gt (dollar)" source="last_month_income.gt" />,
  <DollarInput label="last month income lt (dollar)" source="last_month_income.lt" />,

  <TextInput label="card_id" source="card_id.eq" />,
];

export const BillPaySpotCollection: React.FC<Props> = (props) => {
  const {
    initialFilters,
    rowPerPageOptions = [10, 25, 50],
  } = props;

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, []);

  return (
    <Box sx={{ position: "relative", paddingTop: "60px" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px"
      }}>
        <h4>
          {`Placid Bill Pay / Spot (user id: ${initialFilters?.user_id?.eq})`}
        </h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        columnModel={columnModel}
        rowPerPageOptions={rowPerPageOptions}
        filters={filters}
        tableActions={
          <>
            <FilterButton {...props} />
          </>
        }
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
  rowPerPageOptions?: number[]
  disableUrlFilterListener?: boolean
}