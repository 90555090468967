import './lib/intercom.js';
import './index.css'

import { BaseLocationHook, Route, Router } from "wouter";
import React from 'react'
import ReactDOM from 'react-dom'

import OldApp from './apps/OldApps'
import DashboardApp from './apps/DashboardApp'
import reportWebVitals from './reportWebVitals'
import { resource } from './collections/underwrtiting-decision-checks-collection/drivers/UnderwritingUserDriver.js';

const router = () => {
  return (
    <React.StrictMode>
      <Router>
        <Route path="/" component={OldApp} />
        <Route path="/dash/:dashboard/:param?" component={DashboardApp} />
      </Router>
    </React.StrictMode>
  );
}

ReactDOM.render(
  router(),
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
window.Intercom('shutdown');

const cleanLocalStorage = () => {
  const isCleanedTag = "isCleaned";
  const isCleaned = localStorage.getItem(isCleanedTag);
  if (isCleaned !== null) return;

  const resources = [
    "admin/generic-views/plaid_identities",
    "admin/generic-views/underwriting_user",
    "admin/generic-views/waiting_users",
    "admin/generic-views/collection_user_info",
    "admin/generic-views/collection_user_bank_accounts",
    "admin/generic-views/underwriting_details",
    "admin/generic-views/twilio",
    "admin/generic-views/raw_accounts",
    "admin/generic-views/equifax_reports",
    "admin/generic-views/collection_overview",
    "admin/generic-views/collection_scheduled_pb_transactions",
  ]

  for (const resource of resources) {
    localStorage.removeItem(`column.${resource}`);
    localStorage.removeItem(`sorts.${resource}`);
  }

  localStorage.setItem(isCleanedTag, "true");
}

cleanLocalStorage();