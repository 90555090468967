import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LinkIcon from '@mui/icons-material/Link';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNotify } from 'react-admin';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const _getUserId = (target: Element): string | null => {
  const rowNodes = target.parentElement?.parentElement?.parentElement?.childNodes;

  for (const node of Array.from(rowNodes ?? [])) {
    const nodeElement = node as Element;
    if (nodeElement.className.includes("column-user_id")) {
      const id = nodeElement.textContent;
      return id;
    }
  }

  return null;
}

const _getMixpanelLink = (userId: string): string => {
  return `https://mixpanel.com/project/2451292/view/2994464/app/users#list/chosenColumns:!((label:Name,propertyDefaultType:string,resourceType:user,type:string,value:'$name'),(label:Email,propertyDefaultType:string,resourceType:user,type:string,value:'$email'),(label:'Updated%20at',propertyDefaultType:datetime,resourceType:user,type:datetime,value:'$last_seen'),(label:'Country%20Code',propertyDefaultType:string,resourceType:user,type:string,value:'$country_code'),(label:Region,propertyDefaultType:string,resourceType:user,type:string,value:'$region'),(label:City,propertyDefaultType:string,resourceType:user,type:string,value:'$city')),columnWidths:(),filterByCohort:(groups:!((behavioralFiltersOperator:and,event:(label:'All%20Users',resourceType:cohort,value:'$all_users'),filters:!((filterOperator:'is%20equal%20to',filterValue:${userId},propertyDefaultType:number,propertyName:placid_id,propertyObjectKey:!n,propertyType:number,resourceType:user)),filtersOperator:and,groupingOperator:!n,property:!n,type:cohort_group)),id:!n,name:'',unsavedId:!n),sortOrder:descending,sortProperty:(label:'Updated%20at',propertyDefaultType:datetime,resourceType:user,type:datetime,value:'$last_seen'),sortPropertyType:datetime`;
}

const _sendIntercomEvent = ({
  userId,
  name,
  event,
}: {
  userId: string,
  name: string,
  event: string
}) => {
  window.intercomSettings = {
    app_id: "q6hdnrbr",
    name: name,
    user_id: userId,
  };
  window.Intercom("trackEvent", event);
}

export const RowActions: React.FC<Props> = ({ record }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userId, setUserId] = React.useState<string | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const userId = _getUserId(event.target as Element);
    setUserId(userId);
    setAnchorEl(event.currentTarget);
  };

  const notify = useNotify();

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(null);
  };

  const mixpanelClickHandler = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const userId = record.user_id;

      if (userId === null || userId === undefined) {
        alert('no user id');
        return;
      }

      window.open(_getMixpanelLink(userId), "_blank")?.focus();
      setAnchorEl(null);
    },
    [userId],
  );

  const intercomPaydayHandler = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const { user_id, first_name, last_name } = record;

      _sendIntercomEvent({
        userId: user_id,
        name: `${first_name} ${last_name}`,
        event: "due_date_today"
      });

      notify(`event sent with userId: ${user_id}, name: ${first_name} ${last_name}`);
      setAnchorEl(null);
    },
    [record.user_id],
  );

  const intercomNotEnoughMoneyForAutopayHandler = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const { user_id, first_name, last_name } = record;

      console.log(user_id, first_name, last_name);

      _sendIntercomEvent({
        userId: user_id,
        event: "due_date_today_NSF",
        name: `${first_name} ${last_name}`,
      })

      notify(`event sent with userId: ${user_id}, name: ${first_name} ${last_name}`);
      setAnchorEl(null);
    },
    [record.user_id],
  );

  return (
    <>
      <Button
        size='small'
        color='inherit'
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}>
        Actions
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={mixpanelClickHandler} disableRipple>
          <LinkIcon />
          mixpanel
        </MenuItem>

        <Divider sx={{ my: 0.5 }} />

        <MenuItem onClick={intercomPaydayHandler} disableRipple>
          <EventRepeatIcon />
          Intercom: payday
        </MenuItem>

        <MenuItem onClick={intercomNotEnoughMoneyForAutopayHandler} disableRipple>
          <EventRepeatIcon />
          Intercom: not enough money
        </MenuItem>

      </StyledMenu>
    </>
  );
}

type Props = {
  record: {
    [index: string]: any
  };
}