import { wait } from "../common/helpers";
import { Product } from "../types/common";
import { JSONObject } from "../types/json";
import { AdminRequest, PlacidResponse, PostError } from "./AdminRequest";

export type UnderwritingProductTypePayloadTag = "BalanceTransferProduct" | "BillPayProduct";
export type UnderwritingBPProductTypePayloadContent = "BillPay" | "BillPayAfterOffer" | "BillPayOffer";
export type UnderwritingBTProductTypePayloadContent = "BalanceTransfer" | "BalanceTransferAfterOffer" | "BalanceTransferOffer";

export type StartUnderwritingPayload = {
  userId: number
  underwritingProduct: { [index: string]: any }
  withMonitoring: boolean
  // forceEquifaxReportReload: boolean
  forceExperianReportReload: boolean
  isRejectionLetterEnabled: boolean
}

export type UWProductWithMonitoring =
  "BillPayOneStep" |
  "BillPayOffer" |
  "BillPayContract" |
  "BillPayMonitoring" |
  "BalanceTransferOneStep" |
  "BalanceTransferOffer" |
  "BalanceTransferContract" |
  "BalanceTransferContinuous" |
  "BalanceTransferIncreaseLimit" |
  "BalanceTransferMonitoring" |
  "BillPayContinuous";

export type StartUnderwritingWithMonitoringPayload = {
  userId: number
  product: UWProductWithMonitoring
  monitoringConfig: {
    useBehavioralModelInsteadOfOrigination: boolean
  }
}

export type UWProductsWithoutMonitoring = "BalanceTransferLine" | "BillPayLine";

export type StartUnderwritingWithOutMonitoringPayload = {
  userId: number
  isRejectionLetterEnabled: boolean
  lineType: UWProductsWithoutMonitoring
}

export type UpdateChangeTag = "ChangeToApprove" | "ChangeToReject";

export type PrimaryStatus = "QualifiedSystemConfirmed"
  | "QualifiedAdminConfirmed"
  | "NotQualified"

export type UpdatePrimaryStatusPayload = {
  userId: number
  plaidRawAccountId: string
  isPrimary: PrimaryStatus
}

export type Url = {
  url: string
}

export type ExperianResult = {
  [index: string]: ExperianResultItem
}

export type ExperianResultItem = {
  error?: {
    "code": string
    "message": string
  }
  success: boolean
  result?: Url
}

export type UpdateChangeToApprove = {
  userId: number,
  decision: {
    tag: "ChangeToApprove",
    contents: {
      isOffer?: boolean
      limit?: number
      product: Product
    }
  }
}

export type UpdateChangeToReject = {
  userId: number
  decision: {
    tag: "ChangeToReject",
    contents: {
      sendRejectionLetter: boolean
      rejectReason: string
      comment: string
    }
  }
}

export type UpdateUnderwritingPayload = UpdateChangeToApprove | UpdateChangeToReject

export type UnderwritingUserViewResult = {
  balance_transfer_limit?: number
  placid_bill_limit?: number
}

export type UnderwritingDetailsUserResult = {
  bt_limit?: number
  bp_limit?: number
  bt_apr: number
  product: string
}

export type UpdateACHAccountAndRoutingNumbersPayload = {
  accountNumber: string
  plaidAccountId: string
  routingNumber: string
  userId: number
}

export class UnderwritingActions {
  constructor(private adminRequest: AdminRequest) { };

  async revokePlaidAccountByItem(payload: { userId: number, plaidItemId: string }): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post('/admin/plaid-accounts/revoke-by-item', JSON.stringify(payload))
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async closePlaidAccountByItem(payload: { userId: number, plaidItemId: string }): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/plaid-accounts/close-by-item',
          JSON.stringify(payload),
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async closePlaidAccountByIds(payload: { userId: number, plaidIds: string[] }): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post('/admin/plaid-accounts/close-by-ids', JSON.stringify(payload))
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async updateUnderwritingDecision(
    payload: { [index: string]: any },
  ): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post('/admin/underwriting-decision/v3/update', JSON.stringify(payload))
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async startUnderwritingWithMonitoring(
    payload: StartUnderwritingWithMonitoringPayload,
  ): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/underwriting/monitoring/run-underwriting-for-product',
          JSON.stringify(payload),
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async loadEquifax(
    userId: number,
  ): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/load-equifax-reports',
          JSON.stringify({
            "forceReload": true,
            "userIds": [userId]
          }),
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async loadExperian(
    userId: number,
  ): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/experian/load-inquiry',
          JSON.stringify({
            "userIds": [userId]
          }),
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }


  async startUnderwritingWithOutMonitoring(
    payload: StartUnderwritingWithOutMonitoringPayload,
  ): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/underwriting/initiate',
          JSON.stringify(payload),
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async startUnderwriting(
    payload: StartUnderwritingPayload,
  ): Promise<[PostError, { [index: string]: any } | null]> {
    try {
      const responseRun = await this.adminRequest.post(
        '/admin/underwriting-decision/v2/initiate/run',
        JSON.stringify(payload)
      );

      if (!responseRun.success) {
        const error = responseRun.error?.message ?? "Something went wrong";
        return [error, null];
      }

      const { taskId } = responseRun.result;

      while (true) {
        await wait(5000);
        const response = await this.adminRequest.post(
          '/admin/underwriting-decision/v2/initiate/status', JSON.stringify({ taskId })
        );

        if (response.success) {
          return [null, response.result]
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        return [e.message, null];
      }

      return ["Underwriting failed", null];
    }
  }

  async setPrimaryAccountConfirmed(payload: { userId: number, isConfirmed: boolean }): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post('/admin/manage-user/set-primary-account-confirmed', JSON.stringify(payload))
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async forceVeriff(payload: {
    userId: number,
    uwProduct: UWProductWithMonitoring
  }): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/underwriting/force-veriff',
          JSON.stringify(payload)
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async fetchUnderwritingDecisionChecks(decisionId: string): Promise<PlacidResponse> {
    return this.adminRequest.post('/admin/underwriting-decision/v2/get-checks', JSON.stringify({ decisionId }));
  }

  async fetchUnderwritingUserData(userId: string): Promise<UnderwritingUserViewResult[]> {
    return this.adminRequest.getView<UnderwritingUserViewResult[]>(`/admin/generic-views/underwriting_user?id=eq.${userId}`);
  }

  async fetchUnderwritingDetailsData(userId: string): Promise<UnderwritingDetailsUserResult[]> {
    return this.adminRequest.getView<UnderwritingDetailsUserResult[]>(
      `/admin/generic-views/underwriting_details?limit=10&offset=0&user_id=eq.${userId}&order=created_at.desc`,
    );
  }

  async updatePrimaryStatus(payload: UpdatePrimaryStatusPayload): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/depository-accounts/update-primary-status',
          JSON.stringify(payload),
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async updateACHAccountAndRoutingNumber(payload: UpdateACHAccountAndRoutingNumbersPayload): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/depository-accounts/save-account-and-routing-numbers',
          JSON.stringify(payload),
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async closePlaidToken(itemId: string, userId: number) {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/plaid-token/close',
          JSON.stringify({ itemId, userId }),
        )
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request close plaid token failed";
    }
  }

  async expirePlaidToken(itemId: string, userId: number) {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/plaid-token/expire',
          JSON.stringify({ itemId, userId }),
        )
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request expire plaid token failed";
    }
  }

  async openPlaidToken(itemId: string, userId: number) {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/plaid-token/open',
          JSON.stringify({ itemId, userId }),
        )
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request open plaid token failed";
    }
  }

  async getExperianReportLink(userIds: number[]): Promise<PlacidResponse> {
    try {
      const response = await this.adminRequest.post(
        '/admin/experian/load-pdf-report',
        JSON.stringify({ userIds }),
      );

      return response;
    } catch (e) {
      if (e instanceof Error) {
        return {
          success: false,
          error: {
            code: "NetworkError",
            message: e.message
          },
        };
      }

      return {
        success: false,
        error: {
          code: "NetworkError",
          message: "Get experian report link failed",
        },
      };
    }
  }

  async getEquifaxReportLink(reportId: number): Promise<Url | string> {
    try {
      const response = await this.adminRequest.post(
        '/admin/get-equifax-report-link/',
        JSON.stringify({ reportId }),
      );

      if (response.success) {
        return response.result;
      }

      return response.error.message ?? "Get report link failed"
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Get report link failed";
    }
  }

  async initiateUnderwritingDecisions(value: JSONObject): Promise<PlacidResponse> {
    try {
      return await this.adminRequest.post(
        '/admin/underwriting/monitoring/run-underwriting-flow',
        JSON.stringify(value),
      );
    } catch (e) {
      if (e instanceof Error && e.message === "Failed to fetch") {
        return {
          success: false,
          error: {
            code: "FailedFetch",
            message: "Failed to fetch",
          }
        }
      }

      if (e instanceof Error) {
        return {
          success: false,
          error: {
            code: "PostError",
            message: e.message,
          },
        };
      }

      return {
        success: false,
        error: {
          code: "PostError",
          message: "Something went wrong",
        }
      };
    }
  }

  async finishAccountReview(userId: number): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/account/primary-account/finish-account-review',
          JSON.stringify({
            userId,
          })
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }


  async depositoryAccountsApprove(verificationId: number): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/depository-accounts/numbers-verification/approve',
          JSON.stringify({
            verificationId,
          })
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async depositoryAccountsReject(verificationId: number): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/depository-accounts/numbers-verification/reject',
          JSON.stringify({
            verificationId,
          })
        )
      );
    } catch (e) {
      return (e as Error).message;
    }
  }

  async getIncomeInfo(userId: number, flow: string): Promise<PlacidResponse> {
    try {
      return await this.adminRequest.post(
        '/admin/underwriting/get-income-info',
        JSON.stringify({ userId, flow }),
      );
    } catch (e) {
      if (e instanceof Error) {
        return {
          success: false,
          error: {
            code: "PostError",
            message: e.message,
          },
        };
      }

      return {
        success: false,
        error: {
          code: "PostError",
          message: "Something went wrong",
        }
      };
    }
  }

  _checkPlacidError(placidResponse: PlacidResponse): PostError {
    if (placidResponse.success) {
      return null
    } else {
      return placidResponse.error.message ?? "Something went wrong";
    }
  }
}
