
import React, { FC, useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';

export type UpdateDOBDialogPayload = {
  dateOfBirth: string
  id: number
} | null;

export const UpdateDOBDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { clientActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [dob, setDob] = useState<string>("");

  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    if (payload !== undefined) {
      setDob(payload?.dateOfBirth ?? "")
    }

    setError(null);
  }, [payload]);

  const onDobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDob(value);
  }

  const handleSendClick = async () => {
    if (payload === null) return;

    const { id } = payload ?? {};

    if (id === undefined) {
      notify("no id provided");
      return;
    }

    if (!window.confirm(`Update dob?`)) {
      return;
    }

    const error = await clientActions.updatePersonalInfo({
      userId: id,
      payload: {
        dateOfBirth: dob,
        income: {},
        address: {},
      }
    });

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Updated");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Update date of birth"
      actionTitle='Update'
      onSend={handleSendClick}>

      <TextField
        fullWidth
        id="dob"
        label="dob"
        type="date"
        defaultValue={dob}
        value={dob}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onDobChange}
      />

      {error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload?: UpdateDOBDialogPayload;
  hideDialog: () => void;
}