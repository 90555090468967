import { AdminExecutionsHistory } from "../../collections/admin_executions_history/AdminExecutionsHistory";
import { AdminPendingExecutions } from "../../collections/admin_pending_executions/AdminPendingExecutions";

export const ExecutionDashboard = () => {

  return (
    <section className='dashboard'>
      <h1>Access controls view</h1>

      <AdminPendingExecutions />

      <AdminExecutionsHistory />
    </section>
  );
}
