
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { ManualReviewTag, UpdateManualReviewPayload } from '../../../network/ManualReviewActions';
import currency from 'currency.js';

export type UpdateManualReviewDialogPayload = {
  userId: number
  accountId: number
} | null;

export const UpdateManualReviewDialog: FC<Props> = ({
  payload,
  hideDialog,
  onSend,
}) => {
  const [tag, setTag] =
    useState<ManualReviewTag>("Reject");
  const [income, setIncome] =
    useState<string>("0");

  useEffect(() => {
    setTag("Reject");
    setIncome("0");
  }, [payload]);

  const handleSendClick = async () => {
    if (payload === null) return;

    const { userId, accountId } = payload;

    const incomeCents = currency(income).intValue

    const updatePayload = {
      userId,
      accounts: [
        {
          accountId,
          decision: tag == "Approve" ? {
            tag: "Approve", contents: incomeCents
          } : { tag: "Reject" }

        }
      ]
    }

    onSend(updatePayload);

    hideDialog();
  }


  const handleChangeTag = useCallback((e: SelectChangeEvent<ManualReviewTag>) => {
    setTag(e.target.value as ManualReviewTag);
  }, [tag]);

  const onIncomeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setIncome(value);
  }


  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title="Update manual review status"
      onSend={handleSendClick}
      actionTitle="Send">

      <FormControl fullWidth>
        <InputLabel id="method-select-label">type bill pay</InputLabel>
        <Select
          autoFocus={false}
          onFocus={() => { }}
          labelId="method-select-label"
          id="demo-simple-select"
          value={tag}
          label="Method"
          onChange={handleChangeTag}>
          <MenuItem value={"Approve"}>Approve</MenuItem>
          <MenuItem value={"Reject"}>Reject</MenuItem>
        </Select>
      </FormControl>

      {
        tag === "Approve" &&
        <FormControl fullWidth>
          <TextField
            fullWidth
            type="number"
            label="regular (dollars)"
            value={income}
            onChange={onIncomeChange}
            variant="outlined"
          />
        </FormControl>
      }

    </SimpleDialog>
  );
}

type Props = {
  payload: UpdateManualReviewDialogPayload;
  hideDialog: () => void;
  onSend: (payload: UpdateManualReviewPayload) => void
}