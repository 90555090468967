
import React, { FC, useEffect, useState } from 'react';
import { Alert, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import { MoveDirection, UpdateStatementCyclePayload } from '../../../network/ClientActions';

export type UpdateCycleDialogPayload = {
  userId: number
} | undefined;

export const UpdateCycleDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { clientActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [lastCycleDay, setLastCycleDay] = useState<string>("");
  const [newAutopayDay, setNewAutopayDay] = useState<string>("");
  const [moveDirection, setMoveDirection] = useState<MoveDirection>("Forward");

  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    setLastCycleDay("");
    setNewAutopayDay("");
    setMoveDirection("Forward");
    setError(null);
  }, [payload]);

  const onLastCycleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLastCycleDay(value);
  }

  const onNewAutopayDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewAutopayDay(value);
  }

  const handleMoveDirectionSelect = (e: SelectChangeEvent<string>) => {
    setMoveDirection(e.target.value as MoveDirection);
  }

  const handleSendClick = async () => {
    if (payload === undefined) return
    const { userId } = payload;

    if (lastCycleDay === undefined) {
      setError("last cycle day not provided");
      return;
    }

    const updatePayload: UpdateStatementCyclePayload = {
      userId,
      lastCycleDay: parseInt(lastCycleDay),
      newAutopayDay: parseInt(newAutopayDay),
      moveDirection,
    }

    if (newAutopayDay === undefined) {
      delete updatePayload['newAutopayDay'];
    }

    const error = await clientActions.updateStatementCycle(updatePayload);

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Updated");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Move bounds"
      actionTitle='Update'
      onSend={handleSendClick}>

      <TextField
        fullWidth
        id="lastCycleDay"
        label="lastCycleDay"
        type="number"
        value={lastCycleDay}
        onChange={onLastCycleDayChange}
      />

      <TextField
        fullWidth
        id="newAutopayDay"
        label="newAutopayDay"
        type="number"
        value={newAutopayDay}
        onChange={onNewAutopayDayChange}
      />

      <FormControl fullWidth>
        <InputLabel id="account-select-label">Account</InputLabel>
        <Select
          autoFocus={false}
          onFocus={() => { }}
          labelId="account-select-label"
          id="demo-simple-select"
          value={moveDirection}
          label="Method"
          onChange={handleMoveDirectionSelect}>
          <MenuItem value={"Back"}>Back</MenuItem>
          <MenuItem value={"Forward"}>Forward</MenuItem>
        </Select>
      </FormControl>

      {
        error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>
      }

    </SimpleDialog>
  );
}

type Props = {
  payload?: UpdateCycleDialogPayload
  hideDialog: () => void
}