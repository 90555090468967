import { stringify } from 'query-string'
import { DataProvider, FilterPayload } from 'react-admin'
import { config } from '../config'
import { IUserProps } from '../types'
import { httpClient } from './httpClient'

const { apiUrl } = config;

const getFilter = (filter: FilterPayload) => {
  return filter.hasOwnProperty('userId') ? `&filter={"userIds":[${parseInt(filter['userId'])}]}` : '';
}

const getSort = (order: string) => {
  return ''
  // return `&sort=[{"field":"UserId","order": "${order.toLowerCase().replace(/^\w/, (a) => a.toUpperCase())}"}]`;
}

export const dataProvider: DataProvider = {
  getList: async (resource, params) => {
    const { page } = params.pagination

    const query = {
      page: page,
    }

    const filter = getFilter(params.filter);
    const sort = getSort(params.sort.order);

    const url = `${apiUrl}/${resource}?${stringify(query)}${filter}${sort}`;

    try {
      const { json } = await httpClient(url)

      const data = json.result['list'].values.map((acc: IUserProps) => {
        acc.id = acc.userId
        return acc
      })

      return {
        data: data,
        total: json.result['list'].records,
      }
    } catch (e: any) {
      console.log(e.status);
      if ([403, 401].includes(e.status)) {
        return {
          data: [],
          total: 0,
        }
      } else {
        throw e;
      }
    }
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/admin/customer/${params.id}/overview`).then(
      ({ json }) => {
        const data = json.result.overview
        data.id = params.id
        return {
          data: data,
        }
      }
    ),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => ({ data: json }))
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`

    const { headers, json } = await httpClient(url)

    return {
      data: json,
      total: 0,
    };
  },

  update: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'Post',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
  },
}
