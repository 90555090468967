import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import { IUnderwritingsProps, IUnderwritingUpdateProps } from '../types'

const useStyles = makeStyles({
  field: {
    paddingBottom: 15,
  },
  actions: {
    justifyContent: 'flex-end',
    marginRight: 10,
    marginLeft: 10,
  },
})

const schema = yup
  .object({
    comment: yup.string().required(),
    balanceTransferLimit: yup.number().min(0).required(),
    balanceTransferApr: yup.number().min(0).required(),
    placidBillLimit: yup.number().min(0).required(),
  })
  .required()

export const UnderwritingEdit = (props: {
  id: string
  isOpen: boolean
  setOpen: (v: boolean) => void
  underwriting: IUnderwritingsProps
  isLoading: boolean
  handleEdit: (v: IUnderwritingUpdateProps) => void
}) => {
  const { id, isOpen, setOpen, isLoading, handleEdit } = props

  const classes = useStyles()

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), shouldUnregister: true })

  const [isBTEnabled, setIsBTEnabled] = useState(false)
  const [isApproved, setIsApproved] = useState(false)

  const buildData = (): IUnderwritingUpdateProps => {
    const data: IUnderwritingUpdateProps = {
      userId: parseInt(id),
      decision: isApproved ? 'Approved' : 'Reject',
      placidBillLimit: parseInt(watch('placidBillLimit')),
      balanceTransferApr: parseInt(watch('balanceTransferApr')),
      isBalanceTransferEnabled: isBTEnabled,
      balanceTransferLimit: parseInt(watch('balanceTransferLimit')),
      comment: watch('comment'),
    }

    return data
  }

  const handleClose = () => {
    clearErrors()
    setOpen(false)
    setIsApproved(false)
    setIsBTEnabled(false)
  }

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogContent>
          <TextField
            error={errors.placidBillLimit !== undefined}
            helperText={errors.placidBillLimit?.message}
            className={classes.field}
            label="Placid bill limit"
            fullWidth
            {...register('placidBillLimit')}
          />
          <TextField
            error={errors.balanceTransferApr !== undefined}
            helperText={errors.balanceTransferApr?.message}
            className={classes.field}
            label="balanceTransferApr"
            fullWidth
            {...register('balanceTransferApr')}
          />
          <TextField
            error={errors.comment !== null}
            helperText={errors.comment?.message}
            label="comment"
            type="text"
            fullWidth
            className={classes.field}
            {...register('comment')}
          />
          <TextField
            error={errors.balanceTransferLimit !== null}
            helperText={errors.balanceTransferLimit?.message}
            className={classes.field}
            label="BT limit"
            fullWidth
            {...register('balanceTransferLimit')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isBTEnabled}
                onChange={(v) => setIsBTEnabled(v.target.checked)}
                name="checked"
                color="primary"
              />
            }
            label="BT Enabled"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isApproved}
                onChange={(v) => setIsApproved(v.target.checked)}
                name="checked"
                color="primary"
              />
            }
            label="Approved"
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <div>
            <Button />
            <Button onClick={handleClose} color="primary" variant="outlined" disabled={isLoading}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(() => handleEdit(buildData()))}
              color="primary"
              variant="contained"
              style={{ marginLeft: 15 }}
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={14} color='inherit' />}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}
