
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { Account, AccountTag } from '../../../network/RepaymentsActions';
import currency from 'currency.js';
import { useInjection } from '../../../di/useInjection';
import { DepositoryAccount } from '../../../types/accountsOverview';
import { format } from 'date-fns/esm';
import { PostError } from '../../../network/AdminRequest';
import { useNotify, useRefresh } from 'react-admin';

export type AdjustDialogPayload = {
  userId: number
  scheduledDate: string
  amount: number
  transactionId: number
} | undefined;

export const AdjustDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { debtActions, repaymentsActions } = useInjection();
  const notify = useNotify();
  const refresh = useRefresh();

  const [scheduledDate, setScheduledDate] = useState<string>("");
  const [forceSend, setForceSend] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [comment, setComment] = useState<string>("");
  const [fromAccountTag, setFromAccountTag] = useState<AccountTag>("ManualAccount");
  const [fromAccountId, setFromAccountId] = useState<number | null>(null);
  const [depositoryAccounts, setDepositoryAccounts] = useState<DepositoryAccount[]>([]);

  const [error, setError] = useState<PostError>(null);

  useEffect(() => {
    if (payload !== undefined) {
      const userName = localStorage.getItem("username");
      setScheduledDate(payload?.scheduledDate ?? "");
      const amount = currency(payload?.amount ?? "", { fromCents: true }).value;
      setAmount(amount);
      setFromAccountTag("ManualAccount");
      setFromAccountId(null);
      setComment(`Updated from admin panel by ${userName}`);
      setForceSend(false);
      setError(null);
    }
  }, [payload]);

  useEffect(() => {
    if (payload === undefined) {
      return;
    }

    const { userId } = payload;
    debtActions
      .fetchAccountsOverview(userId.toString())
      .then((accountOverview) => {
        const accounts = accountOverview
          .depositoryAccount
          .reduce<DepositoryAccount[]>(
            (acc, value) => [...acc, ...value.accounts],
            [],
          );

        console.log(accounts, accountOverview);

        setDepositoryAccounts(accounts);
      });
  }, [payload]);

  const handleSendClick = async () => {
    if (payload === undefined) return;

    const { userId, transactionId } = payload;

    console.log(amount);

    const amountCents = currency(amount).intValue;
    console.log(amountCents);

    const fromAccount = fromAccountTag === "ManualAccount"
      ? { tag: fromAccountTag }
      : { tag: fromAccountTag, contents: fromAccountId! };

    const _scheduledDate = format(new Date(scheduledDate), "yyyy-MM-dd");

    const error = await repaymentsActions.pbTnxAdjust({
      userId,
      amount: amountCents,
      comment,
      fromAccount,
      scheduledDate: _scheduledDate,
      forceSend,
      transactionId,
      isLocked,
    });

    if (error === null) {
      notify("Done");
      hideDialog();
      refresh();
    } else {
      setError(error);
    }
  }

  const handleForceSendChange = (e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    e.preventDefault();
    setForceSend(isSelect);
  }

  const handleIsLockedChange = (e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    e.preventDefault();
    setIsLocked(isSelect);
  }

  const onScheduledDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setScheduledDate(value);
  }

  const handleFromAccountContentTag = (e: SelectChangeEvent<AccountTag>) => {
    setFromAccountTag(e.target.value as AccountTag);
  }

  const handleFromAccountId = (e: SelectChangeEvent<number>) => {
    setFromAccountId(e.target.value as number);
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = parseFloat(e.target.value);
      setAmount(value);
    } catch (_) { }
  }

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setComment(value);
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Adjust"
      onSend={handleSendClick}
      actionTitle="Update">

      <TextField
        fullWidth
        id="scheduled date"
        label="scheduled date"
        type="date"
        defaultValue={scheduledDate}
        value={scheduledDate}
        InputLabelProps={{ shrink: true }}
        onChange={onScheduledDateChange}
      />

      <TextField
        fullWidth
        id="amount"
        label="amount (dollar)"
        type="number"
        defaultValue={amount}
        value={amount}
        InputLabelProps={{ shrink: true }}
        onChange={handleAmountChange}
      />

      <FormControl fullWidth>
        <InputLabel id="account-id-select-label1">From account</InputLabel>
        <Select
          autoFocus={false}
          onFocus={() => { }}
          labelId="account-id-select-label1"
          id="demo-simple-select1"
          value={fromAccountTag}
          label="from account tag"
          onChange={handleFromAccountContentTag}>
          <MenuItem key={"ManualAccount"} value={"ManualAccount"}>ManualAccount</MenuItem>
          <MenuItem key={"DepositoryAccount"} value={"DepositoryAccount"}>DepositoryAccount</MenuItem>
        </Select>
      </FormControl>

      {fromAccountTag === "DepositoryAccount" &&
        <FormControl fullWidth>
          <InputLabel id="account-id-select-label1">From account id</InputLabel>
          <Select
            autoFocus={false}
            onFocus={() => { }}
            labelId="account-id-select-label1"
            id="demo-simple-select1"
            value={fromAccountId ?? ""}
            label="from account id"
            onChange={handleFromAccountId}>
            {depositoryAccounts.map((account, id) => {
              const { depositoryId, mask, bankName } = account;
              return <MenuItem
                key={depositoryId}
                value={depositoryId}>{bankName} - *{mask} - id {depositoryId}</MenuItem>
            })}
          </Select>
        </FormControl>
      }

      <TextField
        fullWidth
        id="comment"
        label="comment"
        type="text"
        defaultValue={comment}
        value={comment}
        onChange={handleCommentChange}
      />

      <FormControlLabel
        control={<Checkbox checked={forceSend} onChange={handleForceSendChange} />}
        label="force send" />

      <FormControlLabel
        control={<Checkbox checked={isLocked} onChange={handleIsLockedChange} />}
        label="is locked" />


      {error !== null && <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload: AdjustDialogPayload
  hideDialog: () => void
}