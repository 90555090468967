import { FC, useState } from "react";
import { Button, useNotify } from "react-admin";
import { useInjection } from "../../../di/useInjection";

export const UpdatePlaidHistoricalTransactionButton: FC<Props> = ({
  userId,
}) => {
  const [isRequesting, setRequestingStatus] = useState<boolean>(false);
  const { historicalPlaidTransactionActions } = useInjection();
  const notify = useNotify();

  const onClick = async () => {
    setRequestingStatus(true);
    const _userId = parseInt(userId.toString());
    const error = await historicalPlaidTransactionActions.update(_userId);

    if (error !== null) {
      notify(error);
    } else {
      notify("Update historical plaid transactions success");
    }

    setRequestingStatus(false)
  }

  return <Button
    label="Update Plaid historical transaction"
    disabled={isRequesting}
    onClick={onClick} />
}

type Props = {
  userId: number;
}