import { FC, useCallback, useEffect, useState } from 'react';
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { PostError } from '../../../network/AdminRequest';
import { useInjection } from '../../../di/useInjection';
import { PrimaryStatus, UpdatePrimaryStatusPayload } from '../../../network/UnderwritingActions';

export type UpdatePrimaryStatusDialogPayload = Pick<UpdatePrimaryStatusPayload, "userId" | "plaidRawAccountId"> | null;

export const UpdatePrimaryStatusDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { underwritingActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [primaryStatus, setPrimaryStatus] = useState<PrimaryStatus>("QualifiedAdminConfirmed");
  const [error, setError] = useState<PostError>(null);

  useEffect(() => {
    setPrimaryStatus("QualifiedAdminConfirmed")
    setError(null);
  }, [payload]);

  const handlePrimaryStatusChange = useCallback((e: SelectChangeEvent<PrimaryStatus>) => {
    setPrimaryStatus(e.target.value as PrimaryStatus);
  }, [primaryStatus]);

  const handleSendClick = async () => {
    if (payload === null) return;

    const error = await underwritingActions.updatePrimaryStatus({
      ...payload,
      isPrimary: primaryStatus
    });

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("updated");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title="Update primary status"
      onSend={handleSendClick}
      actionTitle="Update">

      <FormControl fullWidth>
        <InputLabel id="method-select-label">Method</InputLabel>
        <Select
          autoFocus={false}
          onFocus={() => { }}
          labelId="method-select-label"
          id="demo-simple-select"
          value={primaryStatus}
          label="Primary status"
          onChange={handlePrimaryStatusChange}>
          <MenuItem key={"QualifiedSystemConfirmed"} value={"QualifiedSystemConfirmed"}>QualifiedSystemConfirmed</MenuItem>
          <MenuItem key={"QualifiedAdminConfirmed"} value={"QualifiedAdminConfirmed"}> QualifiedAdminConfirmed</MenuItem>
          <MenuItem key={"NotQualified"} value={"NotQualified"}>NotQualified</MenuItem>
        </Select>
      </FormControl>

      {
        error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>
      }

    </SimpleDialog>
  );
}

type Props = {
  payload: UpdatePrimaryStatusDialogPayload;
  hideDialog: () => void;
}