import { FC, useState } from "react";
import { Button, useNotify, useRefresh } from "react-admin";
import { useInjection } from "../../../di/useInjection";

export const FinishAccountReviewButton: FC<Props> = ({
  userId,
}) => {
  const { underwritingActions } = useInjection();
  const notify = useNotify();
  const refresh = useRefresh();

  const onClick = async () => {
    const _userId = parseInt(userId.toString());

    const error = await underwritingActions.finishAccountReview(_userId);

    if (error == null) {
      notify(`Done finish account review for user ${_userId}`);
      refresh();
    } else {
      notify(error);
    }
  }

  return <div>
    <Button
      label="Finish account review"
      onClick={onClick} />
  </div>

}

type Props = {
  userId: string;
}