import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  RowActions,
} from "./widget/RowActions";
import { Box } from '@mui/material';
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { Button, DateInput, FilterButton, SelectInput, TextInput, useNotify } from 'react-admin';
import { useInjection } from '../../di/useInjection';
import { CreateBankAccountDialog, CreateBankAccountDialogPayload } from './widget/CreateBankAccountDialog';
import { DollarInput } from '../../common/DollarInput';

export const resource = "admin/generic-views/bank_accounts";

const tableColumns = [
  { field: "id", width: "120px", title: "id", show: true, },
  { field: "created_at", width: "120px", title: "created at", show: true, format: "yyyy-MM-dd" },
  { field: "plaid_account_id", width: "120px", title: "plaid account id", show: true },
  { field: "type", width: "120px", title: "type", show: true },
  { field: "bank_id", width: "100px", title: "bank id", show: true },
  { field: "current_balance", width: "150px", title: "current_balance", show: true, isCents: true },
  { field: "placid_balance", width: "150px", title: "placid balance", show: true, isCents: true },
  { field: "plaid_token_id", width: "150px", title: "plaid token id", show: true },
  { field: "available_balance", width: "150px", title: "available balance", show: true, isCents: true },
  { field: "mask", width: "150px", title: "mask", show: true },
  { field: "account_name", width: "150px", title: "account_name", show: true },
  { field: "sys_period", width: "150px", title: "sys_period", show: true },
  { field: "status", width: "150px", title: "status", show: true },
  { field: "primary_data_aggregator", width: "150px", title: "primary_data_aggregator", show: true },
  { field: "payment_provider", width: "150px", title: "payment_provider", show: true },
  { field: "yodlee_account_id", width: "150px", title: "yodlee_account_id", show: true },
  { field: "supports_outgoing_payments", width: "150px", title: "supports outgoing payments", show: true },

  { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="user_id" source="user_id.eq" alwaysOn />,

  <TextInput label="plaid_account_id" source="plaid_account_id.eq" />,
  <TextInput label="type" source="type.eq" />,
  <TextInput label="bank_id" source="bank_id.eq" />,
  <TextInput label="plaid_token_id" source="plaid_token_id.eq" />,
  <TextInput label="mask" source="mask.eq" />,
  <TextInput label="account_name" source="account_name.eq" />,
  <TextInput label="primary_data_aggregator" source="primary_data_aggregator.eq" />,
  <TextInput label="payment_provider" source="payment_provider.eq" />,
  <TextInput label="yodlee_account_id" source="yodlee_account_id.eq" />,

  <DateInput label="created_at gt" source="created_at.gt" />,
  <DateInput label="created_at lt" source="created_at.lt" />,

  <DollarInput label="current_balance gt (dollar)" source="current_balance.gt" />,
  <DollarInput label="current_balance lt (dollar)" source="current_balance.lt" />,

  <DollarInput label="placid_balance gt (dollar)" source="placid_balance.gt" />,
  <DollarInput label="placid_balance lt (dollar)" source="placid_balance.lt" />,

  <DollarInput label="available_balance gt (dollar)" source="available_balance.gt" />,
  <DollarInput label="available_balance lt (dollar)" source="available_balance.lt" />,

  <SelectInput source="status" choices={[
    { id: 'ACTIVE', name: 'ACTIVE' },
    { id: 'CLOSED', name: 'CLOSED' },
  ]} />,
];

export const BankAccountsCollection: React.FC<Props> = (props) => {
  const {
    initialFilters,
  } = props;

  const { adminRequest } = useInjection();
  const notify = useNotify();

  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />
  const [rawAccounts, setRawAccounts] = useState<any[]>([]);
  const [createBankAccountDialogPayload, setCreateBankAccountDialogPayload] = useState<CreateBankAccountDialogPayload>();

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, []);

  const handleCreateBankAccount = () => {
    if (rawAccounts.length === 0) {
      notify('No any raw account loaded');
      return;
    }

    const userId = initialFilters?.user_id?.eq;

    if (userId === undefined) {
      notify('user id not provided');
      return;
    }

    setCreateBankAccountDialogPayload({
      userId,
      accounts: rawAccounts,
    });
  }

  useEffect(() => {
    const userId = initialFilters?.user_id?.eq;

    if (userId === undefined) {
      return;
    }

    adminRequest
      .getView<any[]>(`/admin/generic-views/raw_accounts?user_id=eq.${userId}`)
      .then((response: any[]) => {
        setRawAccounts(response);
      });
  }, []);

  return (
    <Box sx={{ position: "relative", paddingTop: "60px" }}>

      <CreateBankAccountDialog
        payload={createBankAccountDialogPayload}
        hideDialog={() => setCreateBankAccountDialogPayload(undefined)}
      />

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px"
      }}>
        <h4>
          {`Bank accounts ${initialFilters?.user_id?.eq ?? ""}`}
        </h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        disableUrlFilterListener={false}
        rowActionBuilder={rowActionBuilder}
        columnModel={columnModel}
        filters={filters}
        tableActions={
          <>
            <FilterButton {...props} />
            <Button label="create" onClick={handleCreateBankAccount} />
          </>
        }
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
}