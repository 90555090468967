
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import {
  UWProductsWithoutMonitoring,
  UWProductWithMonitoring
} from '../../../network/UnderwritingActions';

export type StartUnderwritingDialogPayload = {
  userId: number
} | null;

export const StartUnderwritingDialog: FC<Props> = ({
  payload,
  hideDialog,
  onSend,
}) => {
  const [withMonitoring, setWithMonitoring] = useState<boolean>(false);
  const [useBehavioralModelInsteadOfOrigination, setUseBehavioralModelInsteadOfOrigination] = useState<boolean>(true);
  const [uwProduct, setUwProduct] =
    useState<UWProductWithMonitoring>("BalanceTransferContract");
  const [isRejectionLetterEnabled, setIsRejectionLetterEnabled] = useState<boolean>(false);
  const [lineType, setLineType] =
    useState<UWProductsWithoutMonitoring>("BalanceTransferLine");

  useEffect(() => {
    setWithMonitoring(false);
    setUwProduct("BalanceTransferContract");
    setUseBehavioralModelInsteadOfOrigination(true);
    setLineType("BalanceTransferLine");
    setIsRejectionLetterEnabled(false);
  }, [payload]);

  const handleSendClick = async () => {
    if (payload === null) return;

    const { userId } = payload;

    const _userId = parseInt(userId.toString());

    const payloadForSend = withMonitoring
      ? {
        userId: _userId,
        product: uwProduct,
        monitoringConfig: {
          useBehavioralModelInsteadOfOrigination,
        }
      } : {
        userId: _userId,
        isRejectionLetterEnabled,
        lineType,
      };

    onSend(payloadForSend);

    hideDialog();
  }

  const handleWithMonitoring = useCallback((e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    if (isSelect) {
      setWithMonitoring(false);
    }
    setWithMonitoring(isSelect);
  }, []);

  const handleUseBehavioralModel = useCallback((e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    if (isSelect) {
      setUseBehavioralModelInsteadOfOrigination(false);
    }
    setUseBehavioralModelInsteadOfOrigination(isSelect);
  }, []);

  const handleIsRejectionLetterEnabled = useCallback((e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    setIsRejectionLetterEnabled(isSelect);
  }, []);

  const handleChangeUWProduct = useCallback((e: SelectChangeEvent<UWProductWithMonitoring>) => {
    setUwProduct(e.target.value as UWProductWithMonitoring);
  }, [uwProduct]);

  const handleChangeLineType = useCallback((e: SelectChangeEvent<UWProductsWithoutMonitoring>) => {
    setLineType(e.target.value as UWProductsWithoutMonitoring);
  }, [lineType]);

  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title="Start underwriting"
      onSend={handleSendClick}
      actionTitle="Run">

      <FormControlLabel
        control={<Checkbox checked={withMonitoring} onChange={handleWithMonitoring} />}
        label="with monitoring" />

      {
        withMonitoring &&
        <FormControl fullWidth>
          <InputLabel id="method-select-label-1">Product</InputLabel>
          <Select
            autoFocus={false}
            onFocus={() => { }}
            labelId="method-select-label-1"
            id="demo-simple-select"
            value={uwProduct}
            label="Method"
            onChange={handleChangeUWProduct}>
            <MenuItem value={"BillPayOneStep"}>BillPayOneStep</MenuItem>
            <MenuItem value={"BillPayOffer"}>BillPayOffer</MenuItem>
            <MenuItem value={"BillPayContract"}>BillPayContract</MenuItem>
            <MenuItem value={"BillPayMonitoring"}>BillPayMonitoring</MenuItem>
            <MenuItem value={"BalanceTransferOneStep"}>BalanceTransferOneStep</MenuItem>
            <MenuItem value={"BalanceTransferOffer"}>BalanceTransferOffer</MenuItem>
            <MenuItem value={"BalanceTransferContract"}>BalanceTransferContract</MenuItem>
            <MenuItem value={"BalanceTransferContinuous"}>BalanceTransferContinuous</MenuItem>
            <MenuItem value={"BalanceTransferIncreaseLimit"}>BalanceTransferIncreaseLimit</MenuItem>
            <MenuItem value={"BalanceTransferMonitoring"}>BalanceTransferMonitoring</MenuItem>
            <MenuItem value={"BillPayContinuous"}>BillPayContinuous</MenuItem>
          </Select>
        </FormControl>
      }

      {
        withMonitoring &&
        <FormControlLabel
          control={<Checkbox checked={useBehavioralModelInsteadOfOrigination}
            onChange={handleUseBehavioralModel} />}
          label="use behavioral model instead of origination" />
      }

      {
        !withMonitoring &&
        <FormControl fullWidth>
          <InputLabel id="method-select-label">Line product</InputLabel>
          <Select
            autoFocus={false}
            onFocus={() => { }}
            labelId="method-select-label"
            id="demo-simple-select"
            value={lineType}
            label="Method"
            onChange={handleChangeLineType}>
            <MenuItem value={"BalanceTransferLine"}>BalanceTransferLine</MenuItem>
            <MenuItem value={"BillPayLine"}>BillPayLine</MenuItem>
          </Select>
        </FormControl>
      }

      {
        !withMonitoring &&
        <FormControlLabel
          control={<Checkbox checked={isRejectionLetterEnabled} onChange={handleIsRejectionLetterEnabled} />}
          label="is rejection letter enabled" />
      }

    </SimpleDialog>
  );
}

type Props = {
  payload: StartUnderwritingDialogPayload;
  hideDialog: () => void;
  onSend: (payload: any) => Promise<void>
}