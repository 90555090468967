import React, { FC, useCallback, useEffect, useState } from 'react';
import { Alert, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import currency from 'currency.js';

export type CancelMembershipDialogPayload = {
  userId: number
} | null;

export const CancelMembershipDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { membershipActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [isImmediately, setIsImmediately] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    if (payload !== undefined) {
      setIsImmediately(false);
    }

    setError(null);
  }, [payload]);

  const handleImmediatelyChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    setIsImmediately(isSelect);
  }, []);

  const handleSendClick = async () => {
    if (payload === null) return;

    const { userId } = payload ?? {};

    if (userId === undefined) {
      notify("no userId provided");
      return;
    }

    const error = await membershipActions.cancelSubscription(userId, isImmediately);

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Canceled");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Cancel membership"
      actionTitle='Do it!'
      onSend={handleSendClick}>

      <FormControlLabel
        control={<Checkbox checked={isImmediately} onChange={handleImmediatelyChange} />}
        label="immediately" />

      {error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload?: CancelMembershipDialogPayload;
  hideDialog: () => void;
}