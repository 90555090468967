import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { makeStyles, alpha } from '@material-ui/core'
import { Delete } from '@material-ui/icons'

import { ICCPaymentEditProps, ICCTxnProps } from '../types'

const useStyles = makeStyles({
  field: {
    paddingBottom: 15,
  },
  actions: {
    justifyContent: 'space-between',
    marginRight: 10,
    marginLeft: 10,
  },
})

const schema = yup
  .object({
    plannedPaymentAmount: yup.number().min(0).required(),
    extraPaymentAmount: yup.number().min(0).required(),
    comment: yup.string().required(),
  })
  .required()

export const CCPaymentEdit = (props: {
  id: string
  isOpen: boolean
  setOpen: (v: boolean) => void
  isLoading: boolean
  isDeleting: boolean
  transaction: ICCTxnProps
  handleDelete: (props: { transactionId: number; comment: string }) => void
  handleEdit: (props: ICCPaymentEditProps) => void
}) => {
  const { id, isOpen, setOpen, transaction, isLoading, isDeleting, } = props

  const classes = useStyles()

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), shouldUnregister: true })

  const [scheduledDate, setScheduledDate] = useState<Date | null>(null)

  useEffect(() => {
    setScheduledDate(new Date(transaction.plannedPaymentDate))
    setValue('plannedPaymentAmount', transaction.plannedPaymentAmount)
    setValue('extraPaymentAmount', transaction.extraPlanned)
  }, [transaction.plannedPaymentDate])

  const buildData = (): ICCPaymentEditProps => {
    const data: ICCPaymentEditProps = {
      userId: parseInt(id),
      transactionId: transaction.ccTxn,
      scheduledDate: format(scheduledDate!, 'yyyy-MM-dd'),
      minPaymentAmount: parseInt(watch('plannedPaymentAmount')),
      extraPaymentAmount: parseInt(watch('extraPaymentAmount')),
      comment: watch('comment'),
    }

    return data
  }

  const handleClose = () => {
    clearErrors()
    setOpen(false)
    setScheduledDate(new Date(transaction.plannedPaymentDate))
  }

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Scheduled date"
              format="yyyy/MM/dd"
              value={scheduledDate}
              onChange={setScheduledDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <TextField
            error={errors.plannedPaymentAmount !== undefined}
            helperText={errors.plannedPaymentAmount?.message}
            className={classes.field}
            label="Planned payment amount"
            fullWidth
            defaultValue={transaction.plannedPaymentAmount}
            {...register('plannedPaymentAmount')}
          />
          <TextField
            error={errors.extraPaymentAmount !== undefined}
            helperText={errors.plannedPaymentAmount?.message}
            className={classes.field}
            label="extraPaymentAmount"
            fullWidth
            defaultValue={transaction.extraPlanned}
            {...register('extraPaymentAmount')}
          />
          <TextField
            error={errors.comment !== undefined}
            helperText={errors.comment?.message}
            label="comment"
            type="text"
            fullWidth
            className={classes.field}
            {...register('comment')}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleSubmit(() =>
              props.handleDelete({
                transactionId: transaction.ccTxn,
                comment: watch('comment'),
              })
            )}
            variant="outlined"
            disabled={isLoading || isDeleting || transaction.status === 'Canceled'}
            startIcon={isDeleting ? <CircularProgress size={14} color='inherit' /> : <Delete /> }
          >
            Delete
          </Button>
          <div>
            <Button />
            <Button
              onClick={handleClose}
              color="primary"
              variant="outlined"
              disabled={isLoading || isDeleting}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(() => props.handleEdit(buildData()))}
              color="primary"
              variant="contained"
              style={{ marginLeft: 15 }}
              disabled={isLoading || isDeleting}
              startIcon={isLoading && <CircularProgress size={14} color='inherit' />}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}
